import React, { useEffect, useState } from "react";
import CustomButton from "components/common/Button/CustomButton";
import MultiFileInput from "components/common/FileUpload/MultiFileInput";
import { LOADER_STATUS } from "store/constants";
import api from "store/services/sil.services";
import { uploadFilesOnVapour } from "Utils/uploadFileOnS3";
import { toast } from "react-toastify";
import _ from "lodash";
import { capitalizeFirstLetter } from "Utils/utils";

const RoomDocsUploader = ({ room, handleUpdateRoomDetails, type }) => {
  const [isUploadLoading, setIsUploadLoading] = useState(LOADER_STATUS.NA);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [files, setFiles] = useState([]);

  const [isDeleteFileLoader, setisDeleteFileLoader] = useState(null);

  useEffect(() => {
    setFiles(room?.attachments);
  }, [room]);

  const handleUploadAttachments = async () => {
    try {
      setIsUploadLoading(LOADER_STATUS.PENDING);
      const filteredFiles = files?.filter((x) => !x.hasOwnProperty("id"));
      const filesLength = filteredFiles.length;
      const uploadedFiles = [];
      const progressTracker = new Array(filesLength).fill(0);

      let lastUpdate = Date.now();

      const onProgress = (progress, fileIndex) => {
        const currentTime = Date.now();
        if (currentTime - lastUpdate < 100) return;
        lastUpdate = currentTime;
        progressTracker[fileIndex] = progress;
        const totalProgress = progressTracker.reduce(
          (acc, curr) => acc + curr,
          0
        );
        const averageProgress = totalProgress / filesLength;
        setUploadProgress(averageProgress);
      };

      const uploadFileWithProgress = async (file, index) => {
        const options = {
          progress: (progress) => onProgress(progress, index),
        };
        const uploadedFile = await uploadFilesOnVapour([file], options);
        if (uploadedFile && uploadedFile?.[0]) {
          uploadedFiles.push(uploadedFile[0]);
        }
      };
      await Promise.all(
        filteredFiles.map((file, index) => uploadFileWithProgress(file, index))
      );

      const response = await api.silRoomAttachmentUploadApi({
        attachments: uploadedFiles,
        room_uuid: room.uuid,
        type,
      });
      setUploadProgress(0);
      setIsUploadLoading(LOADER_STATUS.COMPLETE);
      toast.success("Attachment uploaded successfully!");
      handleUpdateRoomDetails({ ...room, ...response });
    } catch (err) {
      console.error("Error during upload:", err);
      setUploadProgress(0);
      setIsUploadLoading(LOADER_STATUS.COMPLETE);
      //   setFiles([]);
    }
  };

  const handleDelete = async (item) => {
    try {
      setisDeleteFileLoader(item.id);
      const response = await api.silRoomAttachmentDeleteApi({ id: item.id });
      if (response) {
        toast.success("Attachment deleted successfully!");
        const prevData = _.cloneDeep(files);
        const index = files.findIndex((x) => x.id === item.id);
        if (index !== -1) {
          prevData.splice(index, 1);
        }
        handleUpdateRoomDetails({ ...room, attachments: prevData });
      }
      setisDeleteFileLoader(null);
    } catch (err) {
      setisDeleteFileLoader(null);
      console.log("err", err);
    }
  };

  return (
    <div className='rounded-md rounded-lg border border-gray-300 shadow-sm p-2'>
      <MultiFileInput
        cssClass='mt-6'
        maxFilesAllow={20}
        isDeleteFileLoader={isDeleteFileLoader}
        size={10}
        files={files?.filter((x) => x.hasOwnProperty("id"))}
        type='image'
        callFrom='Silhome-rooms-attachments'
        description={
          type === "outgoing"
            ? "Condition at Lease End"
            : `Room ${capitalizeFirstLetter(type)} Attachments`
        }
        handleFile={(files) => {
          setIsUploadLoading(LOADER_STATUS.NA);
          setFiles(files);
        }}
        handleDelete={(item) => handleDelete(item)}
      />
      {isUploadLoading === LOADER_STATUS.NA &&
      files?.filter((x) => !x.hasOwnProperty("id"))?.length > 0 ? (
        <div className='my-3 rounded-md p-2 bg-blue-100 flex justify-center items-center'>
          {`${files?.filter((x) => !x.hasOwnProperty("id"))?.length} ${
            files?.filter((x) => !x.hasOwnProperty("id"))?.length > 1
              ? "files"
              : "file"
          } Loaded.`}
        </div>
      ) : isUploadLoading === LOADER_STATUS.PENDING ? (
        <div className='w-full bg-gray-200 rounded-full dark:bg-gray-700 my-3'>
          <div
            className='animate-pulse transition duration-300 ease-in-out bg-purple-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full'
            style={{
              width: `${uploadProgress}%`,
            }}
          >
            {Math.round(uploadProgress)}%
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* : (
         isUploadLoading === LOADER_STATUS.COMPLETE && (
           <div className='mt-6 rounded-md p-2 bg-blue-100 flex justify-center items-center'>
             Attachment Uploaded!
           </div>
         )
       )} */}
      <div className=''>
        <CustomButton
          clickHandler={() => {
            if (files && files?.length > 0) {
              handleUploadAttachments();
            }
          }}
          showLoading={isUploadLoading === LOADER_STATUS.PENDING}
          isDisabled={
            isUploadLoading === LOADER_STATUS.PENDING ||
            files?.filter((x) => !x.hasOwnProperty("id"))?.length === 0 ||
            isDeleteFileLoader !== null
          }
          label='Submit'
          cssClass='w-full text-center items-center flex justify-center'
        />
      </div>
    </div>
  );
};

export default RoomDocsUploader;
