import { connect } from "react-redux";
import SilAccommodation from "./SilAccommodation";
import {
  getSilAccommodationListLoading,
  getSilAccommodationListPagination,
  getSilAccommodations,
} from "store/selector/sil.selector";
import { fetchSilAccommodationListRequest } from "store/actions/sil.actions";

const mapDispatchToProps = {
  fetchSilAccommodationListRequest,
};

const mapStateToProps = (state) => {
  const silAccommodations = getSilAccommodations(state);
  const silAccommodationListLoading = getSilAccommodationListLoading(state);
  const silAccommodationListPagination =
    getSilAccommodationListPagination(state);
  return {
    silAccommodations,
    silAccommodationListLoading,
    silAccommodationListPagination,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SilAccommodation);
