import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "helpers/LoginContext";
import SideBarNew from "components/common/SideBar/SideBarNew";
import SilAccommodationTable from "./components/SilAccommodationTable";
import { SORT_TYPE, StatusOptions } from "Constants/constant";
import Header from "components/common/Header/Header";
import { BiPlus } from "react-icons/bi";
import AddSilAccommodationModal from "./components/AddSilAccommodationModal";
import { LOADER_STATUS } from "store/constants";

const SilAccommodation = (props) => {
  const {
    fetchSilAccommodationListRequest,
    silAccommodations,
    silAccommodationListLoading,
    silAccommodationListPagination,
  } = props;

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);

  const [openModal, setOpenModal] = useState(false);
  const [paginationObj, setPaginationObj] = useState(
    silAccommodationListPagination
  );

  useEffect(
    () => setPaginationObj(silAccommodationListPagination),
    [silAccommodationListPagination]
  );

  const handleFetch = (obj, sortBy) => {
    if (!obj.hasOwnProperty("status")) {
      obj.status = "active";
    }
    if (sortBy) {
      Object.assign(obj, {
        sort_field: obj.hasOwnProperty("sort_field")
          ? obj.sort_field
          : paginationObj.sort_field
          ? paginationObj.sort_field
          : null,
        sort_order: obj.hasOwnProperty("sort_order")
          ? obj.sort_order
          : paginationObj.sort_order === SORT_TYPE.DESC
          ? SORT_TYPE.DESC
          : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("page") || paginationObj.page) {
      Object.assign(obj, {
        page: obj.hasOwnProperty("page") ? obj.page : paginationObj.page,
      });
    }
    if (paginationObj.filter) {
      !paginationObj.filter.hasOwnProperty("status")
        ? Object.assign(obj, {
            filter: paginationObj.filter,
          })
        : Object.assign(obj, {
            filter: {
              status: paginationObj.filter.status.map((page) => page),
            },
          });
    }
    if (
      !sortBy &&
      paginationObj.sort_field &&
      !obj.hasOwnProperty("sort_field")
    ) {
      Object.assign(obj, {
        sort_field: sortBy,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, {
        page: 1,
        search: obj.search,
      });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.status !== "both" && obj.hasOwnProperty("status")) {
      Object.assign(obj, {
        filter: {
          status: [obj.status === "active" ? true : false],
        },
      });
    }
    let bodyFilter = obj.hasOwnProperty("filter") ? obj.filter : null;
    if (obj.hasOwnProperty("filter")) {
      delete obj.filter;
    }
    if (obj.status === "both") {
      bodyFilter = null;
    }
    setRenderLoading(LOADER_STATUS.PENDING);
    fetchSilAccommodationListRequest({ ...obj, bodyFilter }, (res) => {
      if (res) {
        setRenderLoading(LOADER_STATUS.COMPLETE);
      }
    });
  };

  return (
    <>
      <div className='min-h-full'>
        <SideBarNew />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <main className='h-screen flex-1 bg-white'>
            <Header
              title='Home listing'
              handleClick={() => setOpenModal(true)}
              btnLabel='Add Home'
              icon={<BiPlus />}
              isButton
            />
            <SilAccommodationTable
              setPaginationObj={setPaginationObj}
              paginationObj={paginationObj}
              silAccommodations={silAccommodations}
              isLoading={silAccommodationListLoading}
              queryParams={paginationObj}
              formCategoriesList={StatusOptions}
              handleFetch={handleFetch}
              renderLoading={renderLoading}
              setOpenModal={setOpenModal}
            />
          </main>
        </div>
      </div>
      {openModal && (
        <AddSilAccommodationModal
          openModal={openModal}
          handleClose={() => setOpenModal(false)}
          isUserGracePeriodOver={isUserGracePeriodOver}
        />
      )}
    </>
  );
};

export default SilAccommodation;
