import React, { useEffect, useState, useContext } from "react";
import SideBar from "../../../components/common/SideBar/SideBarNew";
import { LoginContext } from "../../../helpers/LoginContext";
import {
  emptyPaginationObj,
  POLICIES_LIST_LIMIT,
  SORT_TYPE,
  StatusOptions,
} from "../../../Constants/constant";
import { getPaginationObj } from "../../../Utils/utils";
import { LOADER_STATUS } from "../../../store/constants";
import AddParticipantModal from "./AddParticipantModal";
import ParticipantListTable from "./ParticipantListTable";

const ParticipantList = (props) => {
  const {
    getParticipantList,
    spParticipants,
    loading,
    getSupportCoordinators,
    supportCoordinator,
    supportCoordinatorLoader,
  } = props;

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  const [coSelectedStatus, setCosSelectedStatus] = useState(null);
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [openModal, setOpenModal] = useState(false);
  const [paginationObj, setPaginationObj] = useState(
    spParticipants
      ? getPaginationObj(
          spParticipants.participant,
          spParticipants.query_params
        )
      : emptyPaginationObj
  );

  useEffect(() => {
    if (!loading && renderLoading === LOADER_STATUS.PENDING) {
      setRenderLoading(LOADER_STATUS.COMPLETE);
    }
  }, [loading]);

  useEffect(() => getSupportCoordinators({ page: 1 }), []);

  useEffect(() => {
    if (spParticipants) {
      setPaginationObj(
        getPaginationObj(
          spParticipants.participant,
          spParticipants.query_params
        )
      );
    }
  }, [spParticipants]);

  useEffect(() => {
    if (spParticipants) {
      setPaginationObj(
        getPaginationObj(
          spParticipants.participant,
          spParticipants.query_params
        )
      );
    }
  }, []);

  const fetchFormTemplateList = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: POLICIES_LIST_LIMIT });

    if (filters.sc_id !== 1) {
      Object.assign(filters, {
        sc_id: filters.sc_id === 1 ? undefined : filters.sc_id,
      });
      Object.assign(filters, {
        sc_id:
          filters.sc_id === undefined
            ? coSelectedStatus?.id === 1
              ? undefined
              : coSelectedStatus?.id
            : filters.sc_id === 1
            ? undefined
            : filters.sc_id,
      });
    } else {
      Object.assign(filters, {
        sc_id: undefined,
      });
    }
    let bodyFilter = filters.hasOwnProperty("filter") ? filters.filter : null;
    if (filters.hasOwnProperty("filter")) {
      delete filters.filter;
    }
    if (filters.status === "both") {
      bodyFilter = null;
    }
    if (renderLoading === LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.PENDING);
    }
    getParticipantList({ filters, bodyFilter });
  };

  const handleFetch = (obj, sortBy) => {
    if (!obj.hasOwnProperty("status")) {
      obj.status = "active";
    }
    if (sortBy) {
      Object.assign(obj, {
        sort_field: obj.hasOwnProperty("sort_field")
          ? obj.sort_field
          : paginationObj.sort_field
          ? paginationObj.sort_field
          : null,
        sort_order: obj.hasOwnProperty("sort_order")
          ? obj.sort_order
          : paginationObj.sort_order === SORT_TYPE.DESC
          ? SORT_TYPE.DESC
          : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("page") || paginationObj.page) {
      Object.assign(obj, {
        page: obj.hasOwnProperty("page") ? obj.page : paginationObj.page,
      });
    }
    if (paginationObj.filter) {
      !paginationObj.filter.hasOwnProperty("status")
        ? Object.assign(obj, {
            filter: paginationObj.filter,
          })
        : Object.assign(obj, {
            filter: {
              status: paginationObj.filter.status.map((page) => page),
            },
          });
    }
    if (
      !sortBy &&
      paginationObj.sort_field &&
      !obj.hasOwnProperty("sort_field")
    ) {
      Object.assign(obj, {
        sort_field: sortBy,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, {
        page: 1,
        search: obj.search,
        sc_id: obj.sc_id === 1 ? undefined : obj.sc_id,
      });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.status !== "both" && obj.hasOwnProperty("status")) {
      Object.assign(obj, {
        filter: {
          status: [obj.status],
        },
      });
    }
    fetchFormTemplateList({ ...obj });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paginationObj.page]);

  return (
    <>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <main className='h-screen flex-1 bg-white'>
            <ParticipantListTable
              setPaginationObj={setPaginationObj}
              paginationObj={paginationObj}
              templateList={
                spParticipants &&
                spParticipants?.participant &&
                spParticipants?.participant?.data &&
                spParticipants?.participant?.data?.length > 0
                  ? spParticipants?.participant?.data
                  : []
              }
              isLoading={loading}
              queryParams={paginationObj}
              formCategoriesList={StatusOptions}
              handleFetch={handleFetch}
              renderLoading={renderLoading}
              supportCoordinator={supportCoordinator}
              supportCoordinatorLoader={supportCoordinatorLoader}
              setCosSelectedStatus={setCosSelectedStatus}
              coSelectedStatus={coSelectedStatus}
              setOpenModal={setOpenModal}
            />
          </main>
        </div>
      </div>
      {openModal && (
        <AddParticipantModal
          openModal={openModal}
          handleClose={() => setOpenModal(false)}
          isUserGracePeriodOver={isUserGracePeriodOver}
        />
      )}
    </>
  );
};

export default ParticipantList;
