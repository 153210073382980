import React, { useState, useContext, useEffect } from "react";
import { Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../../common/Header/Header";
// import HeadingWithDescription from "../../Pages/Prefrences/HeadingWithDescription/HeadingWithDescription";
import { AiOutlinePlus } from "react-icons/ai";
import Heading from "../Heading/Heading";
import ConfirmationModal from "../../common/Modal/ConfirmationModal";
import _, { constant } from "lodash";
import { DropDown } from "../../common/DropDown/DropDown";
import { apiGetRequest, apiPostRequest } from "../../../helpers/Requests";
import { toast } from "react-toastify";
import CircularLoader from "../../common/CircularLoader/CircularLoader";
import SideBar from "../../common/SideBar/SideBarNew";
import InputCreationForm from "../../common/InputCreationForm/InputCreationForm";
import SectionCreationForm from "../../common/SectionCreationForm/SectionCreationForm";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { LoginContext } from "../../../helpers/LoginContext";
import CustomButton from "../../common/Button/CustomButton";
import LabelCheckBox from "../../common/checkBox/labelCheckBox";
import { parseBooleanVal } from "Utils/utils";

const EditFormTemplate = ({ role }) => {
  const { slug } = useParams();
  const location = useLocation();

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const { state } = location;

  const routeRole = role === "sil" ? "sil" : role === "hrm" ? "sp/hrm" : "sp";

  const [routeroleAccess, setRouteroleAccess] = useState(null);

  useEffect(() => {
    if (routeroleAccess && routeroleAccess !== role) {
      window.location.reload();
    }
  }, [role]);

  useEffect(() => {
    setRouteroleAccess(role);
    fetchTemplate();
  }, [location.pathname]);

  const [showInputModal, setShowInputModal] = useState(false);
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [showSubSectionModal, setShowSubSectionModal] = useState(false);
  const [showSubSectionInputModal, setShowSubSectionInputModal] =
    useState(false);
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);
  const [showEditSectionTitle, setShowEditSectionTitle] = useState(false);
  const [showEditSubSectionModal, setShowEditSubSectionModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [
    showSectionTitleConfirmationModal,
    setShowSectionTitleConfirmationModal,
  ] = useState(false);
  const [showSubSectionConfirmationModal, setShowSubSectionConfirmationModal] =
    useState(false);
  const [sectionIndex, setSectionIndex] = useState(null);
  const [subSectionIndex, setSubSectionIndex] = useState(null);
  const [fieldIndex, setFieldIndex] = useState(null);
  const [subFieldIndex, setSubFieldIndex] = useState(null);
  const [editData, setEditData] = useState(null);
  const [template, setTemplate] = useState([]);
  const [templateFrom, setTemplateForm] = useState([]);
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
    saveRequested: false,
    saveSuccess: false,
    saveFailed: false,
  });

  const openInputModalHandler = (index) => {
    setShowInputModal(true);
    setSectionIndex(index);
  };

  const closeInputModalHandler = () => {
    setShowInputModal(false);
  };

  const openSubSectionModalHandler = (index) => {
    setShowSubSectionModal(true);
    setSectionIndex(index);
  };

  const closeSubSectionModalHandler = () => {
    setShowSubSectionModal(false);
  };

  const openSubSectionInputModalHandler = (index, i) => {
    setShowSubSectionInputModal(true);
    setSectionIndex(index);
    setFieldIndex(i);
  };

  const closeSubSectionInputModalHandler = () => {
    setShowSubSectionInputModal(false);
  };

  const openSectionModalHandler = () => {
    setShowSectionModal(true);
  };

  const closeSectionModalHandler = () => {
    setShowSectionModal(false);
  };

  const openEditSectionModalHandler = (sectionIndex, fieldIndex, item) => {
    setFieldIndex(fieldIndex);
    setSectionIndex(sectionIndex);
    if (item.hasOwnProperty("required")) {
      item.required.toString();
    }
    setEditData(item);
    setShowEditSectionModal(true);
  };

  const closeEditSectionModalHandler = () => {
    setShowEditSectionModal(false);
  };

  const openEditSectionTitleModal = (
    sectionIndex,
    fieldIndex,
    item,
    subFieldIndex,
    type = "section"
  ) => {
    setSectionIndex(sectionIndex);
    setSubSectionIndex(fieldIndex);
    setEditData(item);
    setShowEditSectionTitle(true);
  };

  const closeSectionTitleHandler = () => {
    setShowEditSectionTitle(false);
  };

  const openEditSubSectionModalHandler = (
    sectionIndex,
    fieldIndex,
    item,
    subFieldIndex
  ) => {
    setFieldIndex(fieldIndex);
    setSectionIndex(sectionIndex);
    setSubFieldIndex(subFieldIndex);
    if (item.hasOwnProperty("required")) {
      item.required.toString();
    }
    setEditData(item);
    setShowEditSubSectionModal(true);
  };

  const closeEditSubSectionModalHandler = () => {
    setShowEditSubSectionModal(false);
  };

  const openConfirmationModalHandler = (
    sectionIndex,
    fieldIndex,
    subFieldIndex,
    type
  ) => {
    setFieldIndex(fieldIndex);
    setSectionIndex(sectionIndex);
    setSubFieldIndex(subFieldIndex);
    setShowConfirmationModal(true);
  };

  const closeConfirmationModalHandler = () => {
    setShowConfirmationModal(false);
  };

  const openSectionTitleConfirmationModalHandler = (sectionIndex) => {
    setSectionIndex(sectionIndex);
    setShowSectionTitleConfirmationModal(true);
  };

  const closeSectionTitleConfirmationModalHandler = () => {
    setShowSectionTitleConfirmationModal(false);
  };

  const openSubSectionConfirmationModalHandler = (
    sectionIndex,
    fieldIndex,
    subFieldIndex
  ) => {
    setFieldIndex(fieldIndex);
    setSectionIndex(sectionIndex);
    setSubFieldIndex(subFieldIndex);
    setShowSubSectionConfirmationModal(true);
  };

  const closeSubSectionConfirmationModalHandler = () => {
    setShowSubSectionConfirmationModal(false);
  };

  const fetchTemplate = async () => {
    setRequest({ ...request, isRequested: true });

    try {
      const url =
        routeRole === "sil"
          ? `${routeRole}/form/template`
          : `${routeRole}/form_template`;

      let res = await apiGetRequest(
        `${url}${
          state && state.hasOwnProperty("templateObj")
            ? `?form_template_uuid=${state.templateObj.templateUUID}&form_template_slug=${state.templateObj.templateSlug}`
            : `?form_template_slug=${slug}`
        }`
      );
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
      }
      if (res.status === 200 && res?.data && res?.data?.data) {
        setTemplate(JSON.parse(res.data.data.template));
        setTemplateForm(res.data.data);
      }
      setRequest({ ...request, isRequested: false, isSuccess: true });
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();

  const updateTemplate = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    const data = {
      ...templateFrom,
      template: template,
    };
    delete data.id;
    setRequest({ ...request, saveRequested: true });
    try {
      const url =
        role === "sil"
          ? `${routeRole}/form/update`
          : `${routeRole}/provider_form_template`;
      const res = await apiPostRequest(`${url}?id=${data.uuid}`, data);
      if (res.status === 201 || res.status === 200) {
        setRequest({ ...request, saveRequested: false, saveSuccess: true });
        toast.success("Template Updated Successfully!");
        setTimeout(
          () =>
            navigate(
              role === "sil"
                ? "/forms-accommodation"
                : routeRole === "sp/hrm"
                ? "/forms-list-hrm"
                : "/forms-list"
            ),
          800
        );
      } else {
        setRequest({ ...request, saveRequested: false, saveFailed: false });
        toast.error("Something Went Wrong");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addInputFieldHandler = (data) => {
    const list = _.cloneDeep(template);
    if (parseBooleanVal(data.required)) {
      data.required = true;
    } else {
      data.required = false;
    }
    list[sectionIndex].input_fields.push(data);
    setTemplate(list);
  };

  const updateFieldHandler = (data) => {
    const list = _.cloneDeep(template);
    if (parseBooleanVal(data.required)) {
      data.required = true;
    } else {
      data.required = false;
    }
    list[sectionIndex].input_fields[fieldIndex] = data;

    setTemplate(list);
  };

  const updateSubSectionFieldHandler = (data) => {
    const list = _.cloneDeep(template);
    if (parseBooleanVal(data.required)) {
      data.required = true;
    } else {
      data.required = false;
    }
    list[sectionIndex].input_fields[fieldIndex].sub_fields[subFieldIndex] =
      data;
    // console.log("list", list);
    setTemplate(list);
  };

  const deleteFieldHandler = () => {
    const list = _.cloneDeep(template);
    list[sectionIndex].input_fields.splice(fieldIndex, 1);
    setTemplate(list);
    setShowConfirmationModal(false);
  };

  const deleteSectionHandler = () => {
    const list = _.cloneDeep(template);
    list.splice(sectionIndex, 1);
    setTemplate(list);
    setShowSectionTitleConfirmationModal(false);
  };

  const deleteSubSectionFieldHandler = () => {
    const list = _.cloneDeep(template);
    if (subFieldIndex) {
      list[sectionIndex].input_fields[fieldIndex].sub_fields.splice(
        subFieldIndex,
        1
      );
    } else list[sectionIndex].input_fields.splice(fieldIndex, 1);
    setTemplate(list);
    setShowSubSectionConfirmationModal(false);
  };

  const addSectionHandler = (data) => {
    const list = _.cloneDeep(template);
    data.input_fields = [];
    list.push(data);
    setTemplate(list);
  };

  const editSectionTitleHandler = (data) => {
    const list = _.cloneDeep(template);
    if (data.type === "sub_section") {
      list[sectionIndex].input_fields[subSectionIndex].isAddOther =
        data.isAddOther;
      list[sectionIndex].input_fields[subSectionIndex].section_title =
        data.section_title;
    } else {
      list[sectionIndex].section_title = data.section_title;
    }
    setTemplate(list);
    setShowEditSectionTitle(false);
  };

  const addSubSectionHandler = (data) => {
    data.type = "sub_section";
    data.lable = "";
    data.sub_fields = [];
    const list = _.cloneDeep(template);
    list[sectionIndex].input_fields.push(data);
    setTemplate(list);
  };

  const addSubSectionInputFieldHandler = (data) => {
    const list = _.cloneDeep(template);
    if (parseBooleanVal(data.required)) {
      data.required = true;
    } else {
      data.required = false;
    }
    list[sectionIndex].input_fields[fieldIndex].sub_fields.push(data);
    setTemplate(list);
  };

  return (
    <div className=''>
      <div className='min-h-screen'>
        <SideBar classes='bg-gradient' />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }`}
        >
          {/* <HeaderAdmin /> */}
          {request.isRequested ? (
            <div className='flex justify-center items-center min-h-screen bg-gray-50'>
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          ) : (
            <div className='md:max-w-7xl mx-auto mb-2 space-y-4 w-full'>
              <Header title={templateFrom?.title || slug} />
              {/* <div className="sm:px-6 lg:px-8">
                <HeadingWithDescription
                  heading="Create a New Report"
                  desc="Supercharge your business with customization on the standard forms."
                />
              </div> */}
              <div className='sm:px-6 lg:px-8'>
                <Tabs>
                  {/* <TabList className="flex border-b space-x-4">
                    <Tab className="text-gray-500 cursor-pointer">Standard</Tab>
                  </TabList> */}
                  <TabPanel>
                    {/* General */}
                    {template.length > 0 &&
                      template.map((section, index) => {
                        return (
                          <div
                            key={index}
                            className='grid grid-cols-3 py-4 border-b'
                          >
                            <div className='col-span-3 lg:col-span-1 space-y-6'>
                              <div className='flex justify-between'>
                                <Heading
                                  heading={section.section_title}
                                  desc=''
                                />
                                <DropDown
                                  type='section'
                                  openConfirmationModalHandler={
                                    openSectionTitleConfirmationModalHandler
                                  }
                                  openEditSectionModalHandler={
                                    openEditSectionTitleModal
                                  }
                                  item={section}
                                  sectionIndex={index}
                                  fieldIndex='null'
                                  subFieldIndex='null'
                                />
                              </div>
                              <button
                                onClick={() => openInputModalHandler(index)}
                                className='flex items-center space-x-2 text-gray-500 text-sm'
                              >
                                <AiOutlinePlus />
                                <span>Add new field </span>
                              </button>
                              <button
                                onClick={() =>
                                  openSubSectionModalHandler(index)
                                }
                                className='flex items-center space-x-2 text-gray-500 text-sm'
                              >
                                <AiOutlinePlus />
                                <span>Add new Sub Section </span>
                              </button>
                            </div>
                            <div className='col-span-3 lg:col-span-2 flex items-start flex-wrap'>
                              {section?.input_fields.length > 0 &&
                                section?.input_fields.map((field, i) => {
                                  if (field.type === "sub_section") {
                                    return (
                                      <div className='flex bg-gray-50 mb-4 p-2 rounded-lg border w-full'>
                                        <div
                                          key={i}
                                          className='w-full flex flex-wrap justify-between items-start'
                                        >
                                          <div className='flex w-full'>
                                            <LabelCheckBox
                                              heading={field.section_title}
                                              desc={field.type}
                                            />
                                            <DropDown
                                              type='sub_section'
                                              openConfirmationModalHandler={
                                                openSubSectionConfirmationModalHandler
                                              }
                                              openEditSectionModalHandler={
                                                openEditSectionTitleModal
                                              }
                                              item={field}
                                              sectionIndex={index}
                                              fieldIndex={i}
                                              subFieldIndex={null}
                                            />
                                          </div>
                                          <button
                                            onClick={() =>
                                              openSubSectionInputModalHandler(
                                                index,
                                                i
                                              )
                                            }
                                            className='flex items-center space-x-2 text-gray-500 text-sm'
                                          >
                                            <AiOutlinePlus />
                                            <span>Add new field </span>
                                          </button>
                                          {field.sub_fields.map((item, sf) => {
                                            return (
                                              <div
                                                key={sf}
                                                className='w-full sm:w-1/2 pb-4 flex justify-between items-start'
                                              >
                                                <LabelCheckBox
                                                  heading={item.lable}
                                                  desc={item.type}
                                                />
                                                <div>
                                                  <DropDown
                                                    openConfirmationModalHandler={
                                                      openSubSectionConfirmationModalHandler
                                                    }
                                                    openEditSectionModalHandler={
                                                      openEditSubSectionModalHandler
                                                    }
                                                    item={item}
                                                    sectionIndex={index}
                                                    fieldIndex={i}
                                                    subFieldIndex={sf}
                                                  />
                                                </div>
                                              </div>
                                            );
                                          })}
                                          {/* <div>
                                      <DropDown
                                        openConfirmationModalHandler={
                                          openConfirmationModalHandler
                                        }
                                        openEditSectionModalHandler={
                                          openEditSectionModalHandler
                                        }
                                        item={field}
                                        sectionIndex={index}
                                        fieldIndex={i}
                                      />
                                    </div> */}
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        key={i}
                                        className='w-full sm:w-1/2 pb-4 flex justify-between items-start'
                                      >
                                        <LabelCheckBox
                                          heading={field.lable}
                                          desc={field.type}
                                        />
                                        <div>
                                          <DropDown
                                            openConfirmationModalHandler={
                                              openConfirmationModalHandler
                                            }
                                            openEditSectionModalHandler={
                                              openEditSectionModalHandler
                                            }
                                            item={field}
                                            sectionIndex={index}
                                            fieldIndex={i}
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </TabPanel>
                </Tabs>
              </div>
              <div className='flex justify-between items-center pt-3 sm:px-6 lg:px-8'>
                <div>
                  <button
                    onClick={openSectionModalHandler}
                    className='flex items-center text-gray-500 text-sm'
                  >
                    <AiOutlinePlus />
                    <span>Add New Section </span>
                  </button>
                </div>

                <div className='flex gap-x-3'>
                  <div onClick={() => navigate(-1)}>
                    <CustomButton variant='danger' label='Cancel' />
                  </div>
                  <div>
                    <CustomButton
                      isDisabled={isUserGracePeriodOver}
                      showLoading={request.saveRequested}
                      label='Update'
                      clickHandler={updateTemplate}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showInputModal && (
        <InputCreationForm
          formCategoryId={templateFrom?.form_category_id}
          showModal={showInputModal}
          title='Add Section Input Field'
          dataSubmitHandler={addInputFieldHandler}
          closeModalHandler={closeInputModalHandler}
          template={template}
          sectionIndex={sectionIndex}
          subSectionIndex={fieldIndex}
        />
      )}
      {showSubSectionModal && (
        <SectionCreationForm
          title='Add New Sub Section'
          showModal={showSubSectionModal}
          type='sub_section'
          dataSubmitHandler={addSubSectionHandler}
          closeModalHandler={closeSubSectionModalHandler}
        />
      )}
      {showSubSectionInputModal && (
        <InputCreationForm
          formCategoryId={templateFrom?.form_category_id}
          title='Add Sub Section Input Field'
          showModal={showSubSectionInputModal}
          dataSubmitHandler={addSubSectionInputFieldHandler}
          closeModalHandler={closeSubSectionInputModalHandler}
          sectionIndex={sectionIndex}
          subSectionIndex={fieldIndex}
          template={template}
        />
      )}
      {showSectionModal && (
        <SectionCreationForm
          title='Add New Section'
          showModal={showSectionModal}
          type='section'
          dataSubmitHandler={addSectionHandler}
          closeModalHandler={closeSectionModalHandler}
        />
      )}
      {showEditSectionTitle && (
        <SectionCreationForm
          title={
            editData && editData.type === "sub_section"
              ? "Edit sub Section Title"
              : "Edit Section Title"
          }
          showModal={showEditSectionTitle}
          type={
            editData && editData.type === "sub_section"
              ? "sub_section"
              : "section"
          }
          editData={editData}
          dataSubmitHandler={editSectionTitleHandler}
          closeModalHandler={closeSectionTitleHandler}
        />
      )}
      {showEditSectionModal && (
        <InputCreationForm
          formCategoryId={templateFrom?.form_category_id}
          title='Edit Section Input Field'
          showModal={showEditSectionModal}
          template={template}
          editData={editData}
          dataSubmitHandler={updateFieldHandler}
          closeModalHandler={closeEditSectionModalHandler}
          sectionIndex={sectionIndex}
          subSectionIndex={fieldIndex}
        />
      )}
      {showEditSubSectionModal && (
        <InputCreationForm
          formCategoryId={templateFrom?.form_category_id}
          title='Edit Sub Section Input Field'
          showModal={showEditSubSectionModal}
          editData={editData}
          dataSubmitHandler={updateSubSectionFieldHandler}
          closeModalHandler={closeEditSubSectionModalHandler}
          sectionIndex={sectionIndex}
          subSectionIndex={fieldIndex}
          template={template}
        />
      )}

      <ConfirmationModal
        showModal={showConfirmationModal}
        closeModalHandler={closeConfirmationModalHandler}
        submitHandler={deleteFieldHandler}
      />
      <ConfirmationModal
        showModal={showSectionTitleConfirmationModal}
        closeModalHandler={closeSectionTitleConfirmationModalHandler}
        submitHandler={deleteSectionHandler}
      />
      <ConfirmationModal
        showModal={showSubSectionConfirmationModal}
        closeModalHandler={closeSubSectionConfirmationModalHandler}
        submitHandler={deleteSubSectionFieldHandler}
      />
    </div>
  );
};

export default EditFormTemplate;
