import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import ModalSkeleton from "../../../components/Modals/ModalSkeleton";
import CustomButton from "../../../components/common/Button/CustomButton";
import { addParticipantLocally } from "../../../store/actions/sp.actions";
import { resetSelectedParticipant } from "../../../store/actions/participant.actions";
import { selectedTabState } from "../../../Utils/utils";
import {
  EMAIL_REGEX_EXP,
  GenderOptions,
  PARTICIPANT_PROFILE_TABS,
  REGEX_NDIS_NUMBER,
  REGEX_PHONE_NUMBER,
  STATE_FULL_LABEL_OPTIONS,
} from "../../../Constants/constant";
import { apiPostRequest } from "../../../helpers/Requests";
import moment from "moment";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const AddParticipantModal = (props) => {
  const { openModal, handleClose, isUserGracePeriodOver } = props;
  const dispatch = useDispatch();

  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  } else {
    month = `${month}`;
  }
  let day = date.getDate();
  let completeDate = `${year}-${month}-${day}`;

  const [request, setRequest] = React.useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    reset();
    return () => reset();
  }, []);

  const onSubmit = async (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    setRequest({ ...request, isRequested: true });
    try {
      const res = await apiPostRequest("sp/participants", data);
      if (res.status === 201) {
        toast.success("Participant Added Successfully");
        handleClose();
        setRequest({ ...request, isRequested: false, isSuccess: true });
        selectedTabState.save(JSON.stringify(PARTICIPANT_PROFILE_TABS[0]));
        if (res.data) {
          dispatch(resetSelectedParticipant());
          dispatch(addParticipantLocally(res.data.participant));
        }
        if (
          res.data.participant &&
          roleAccess(
            [],
            PERMISSION_KEYS.PARTICIPANT,
            PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
          )
        ) {
          setTimeout(
            () => navigate(`/sp-profile/${res.data.participant.uuid}`),
            1000
          );
        }
      } else if (res.status !== 201) {
        toast.error(res.data.message);
        setRequest({ ...request, isRequested: false, isFailed: true });
      }
      if (res.status === 500) {
        throw Error(res?.data?.error);
      }
    } catch (err) {
      // console.log("err", err);
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.error(err.message);
    }
  };

  const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const modalFooter = () => (
    <CustomButton
      showLoading={request.isRequested}
      isDisabled={request.isRequested || isUserGracePeriodOver}
      label='Add Participant'
      variant='primary'
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("addparticipantButtonModal");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  return (
    <>
      <ModalSkeleton
        title='Add New Participant'
        cssClass='w-[60%]'
        isOpen={openModal}
        closeModal={handleClose}
        modalFooter={modalFooter()}
      >
        <div className='flex flex-col'>
          <p className='text-sm text-gray-500 mb-3'>
            Please enter following details to create New Participant
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='w-full'
            onKeyDown={handleFormKeyDown}
          >
            <div>
              <div className='grid grid-cols-2 gap-x-4'>
                <div className='col-span-2 sm:col-span-1'>
                  <div className='mt-3 '>
                    <label className='block text-sm font-medium text-gray-700'>
                      First Name
                    </label>
                    <input
                      {...register("first_name", {
                        required: true,
                        maxLength: 100,
                      })}
                      type='text'
                      className='did-floating-input'
                    />
                    {errors?.first_name?.type === "required" && (
                      <span className='text-sm text-red-500'>
                        Field is required
                      </span>
                    )}
                  </div>
                </div>
                {/* l name */}
                <div className='col-span-2 sm:col-span-1'>
                  <div className='mt-3 '>
                    <label className='block text-sm font-medium text-gray-700'>
                      Last Name
                    </label>
                    <input
                      {...register("last_name", {
                        // required: true,
                        maxLength: 100,
                      })}
                      type='text'
                      className='did-floating-input'
                    />
                    {/*{errors?.last_name?.type === "required" && (*/}
                    {/*  <span className="text-sm text-red-500">*/}
                    {/*    This field is required!*/}
                    {/*  </span>*/}
                    {/*)}*/}
                  </div>
                </div>
                {/* gender */}
                <div className='col-span-2 sm:col-span-1'>
                  <div className='mt-3'>
                    <label className='block text-sm font-medium text-gray-700'>
                      Gender
                    </label>
                    <select
                      className='did-floating-select'
                      {...register("gender", {
                        // required: true,
                        value: "male",
                      })}
                    >
                      {GenderOptions.map((x, index) => (
                        <option key={index} value={x.value}>
                          {x.label}
                        </option>
                      ))}
                    </select>
                    {/*{errors?.gender?.type === "required" && (*/}
                    {/*  <span className="text-sm text-red-500">*/}
                    {/*    This field is required!*/}
                    {/*  </span>*/}
                    {/*)}*/}
                  </div>
                </div>
                {/* email */}
                <div className='col-span-2 sm:col-span-1'>
                  <div className='mt-3'>
                    <label className='block text-sm font-medium text-gray-700'>
                      Primary Email Address
                    </label>
                    <input
                      {...register("email", {
                        // required: true,
                        pattern: EMAIL_REGEX_EXP,
                      })}
                      className='did-floating-input'
                      type='email'
                    />
                    {/*{errors?.email?.type === "required" && (*/}
                    {/*  <span className="text-sm text-red-500">*/}
                    {/*    This field is required!*/}
                    {/*  </span>*/}
                    {/*)}*/}
                    {errors?.email?.type === "pattern" && (
                      <span className='text-sm text-red-500'>
                        Please enter a valid email address!
                      </span>
                    )}
                  </div>
                </div>
                {/* Primary no. */}
                <div className='col-span-2 sm:col-span-1'>
                  <div className='mt-3'>
                    <label className='block text-sm font-medium text-gray-700'>
                      Primary Phone Number
                    </label>
                    <input
                      {...register("primary_no", {
                        // required: true,
                        pattern: REGEX_PHONE_NUMBER,
                        onChange: (e) => {
                          const sanitizedValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          const formattedValue = sanitizedValue.slice(0, 10);
                          e.target.value = formattedValue;
                        },
                        maxLength: 10,
                        // minLength: 10,
                      })}
                      className='did-floating-input appearance-no'
                      type='number'
                    />
                    {errors?.primary_no?.type === "required" && (
                      <span className='text-sm text-red-500'>
                        This field is required!
                      </span>
                    )}
                    {/* {errors?.primary_no?.type === "maxLength" && (
                      <span className="text-sm text-red-500">
                        Phone number must have 10 digits
                      </span>
                    )} */}
                    {errors?.primary_no?.type === "minLength" && (
                      <span className='text-sm text-red-500'>
                        Phone number must have 10 digits
                      </span>
                    )}
                    {errors?.primary_no?.type === "pattern" && (
                      <span className='text-sm text-red-500'>
                        {/* Phone number must start with 04, 1300 or 1800 */}
                        Please enter a valid phone number!
                      </span>
                    )}
                  </div>
                </div>
                {/* secondry no. */}
                <div className='col-span-2 sm:col-span-1'>
                  <div className='mt-3'>
                    <label className='block text-sm font-medium text-gray-700'>
                      Secondary Phone Number
                    </label>
                    <input
                      {...register("secondary_no", {
                        // required: true,
                        pattern: REGEX_PHONE_NUMBER,
                        onChange: (e) => {
                          const sanitizedValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          const formattedValue = sanitizedValue.slice(0, 10);
                          e.target.value = formattedValue;
                        },
                        maxLength: 10,
                        // minLength: 10,
                      })}
                      className='did-floating-input appearance-no'
                      type='number'
                    />
                    {/*{errors?.secondary_no?.type === "required" && (*/}
                    {/*  <span className="text-sm text-red-500">*/}
                    {/*    This field is required!*/}
                    {/*  </span>*/}
                    {/*)}*/}
                    {/* {errors?.secondary_no?.type === "maxLength" && (
                      <span className="text-sm text-red-500">
                        phone number must have 10 digits
                      </span>
                    )} */}
                    {errors?.secondary_no?.type === "minLength" && (
                      <span className='text-sm text-red-500'>
                        phone number must have 10 digits
                      </span>
                    )}
                    {errors?.secondary_no?.type === "pattern" && (
                      <span className='text-sm text-red-500'>
                        {/* Phone number must start with 04, 1300 or 1800 */}
                        Please enter a valid phone number!
                      </span>
                    )}
                  </div>
                </div>

                {/* ndis no. */}
                <div className='col-span-2 sm:col-span-1'>
                  <div className='mt-3'>
                    <label className='block text-sm font-medium text-gray-700'>
                      NDIS No.
                    </label>
                    <input
                      {...register("ndis_no", {
                        // required: true,
                        pattern: {
                          value: REGEX_NDIS_NUMBER,
                          message:
                            "NDIS number should start with either '43' or '5'",
                        },
                      })}
                      className='did-floating-input'
                      type='number'
                    />
                    {/*{errors?.ndis_no?.type === "required" && (*/}
                    {/*  <span className="text-sm text-red-500">*/}
                    {/*    This field is required!*/}
                    {/*  </span>*/}
                    {/*)}*/}
                    {errors?.ndis_no?.type === "pattern" && (
                      <span className='text-sm text-red-500'>
                        {/* NDIS number should be 43XXXXXXX */}
                        {errors?.ndis_no?.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* DOB */}
                <div className='col-span-2 sm:col-span-1'>
                  <div className='mt-3 '>
                    <label className='block text-sm font-medium text-gray-700'>
                      Date of Birth
                    </label>
                    <input
                      {...register("dob", {
                        // required: true,
                      })}
                      min={moment().format("DD-MM-YYYY")}
                      type='date'
                      className='did-floating-input'
                      max={completeDate}
                    />
                    {/*{errors?.dob?.type === "required" && (*/}
                    {/*  <span className="text-sm text-red-500">*/}
                    {/*    This field is required!*/}
                    {/*  </span>*/}
                    {/*)}*/}
                  </div>
                </div>
                {/* street */}
                <div className='col-span-2 sm:col-span-1'>
                  <div className='mt-3'>
                    <label className='block text-sm font-medium text-gray-700'>
                      Street Address
                    </label>
                    <input
                      {...register("street_no", {
                        // required: true,
                        maxLength: 254,
                      })}
                      className='did-floating-input'
                      type='text'
                    />
                    {/*{errors?.street_no?.type === "required" && (*/}
                    {/*  <span className="text-sm text-red-500">*/}
                    {/*    This field is required!*/}
                    {/*  </span>*/}
                    {/*)}*/}
                  </div>
                </div>
                {/* suburb */}
                <div className='col-span-2 sm:col-span-1'>
                  <div className='mt-3 '>
                    <label className='block text-sm font-medium text-gray-700'>
                      Suburb
                    </label>
                    <input
                      {...register("suburb", {
                        // required: true,
                        maxLength: 254,
                      })}
                      className='did-floating-input'
                      type='text'
                    />
                    {/*{errors?.suburb?.type === "required" && (*/}
                    {/*  <span className="text-sm text-red-500">*/}
                    {/*    {" "}*/}
                    {/*    This field is required!*/}
                    {/*  </span>*/}
                    {/*)}*/}
                  </div>
                </div>
                {/* state */}
                <div className='col-span-2 sm:col-span-1'>
                  <div className='mt-3'>
                    <label className='block text-sm font-medium text-gray-700'>
                      State
                    </label>
                    <select
                      className='did-floating-select truncate'
                      {...register("state", {
                        // required: true,
                        value: "New south Wales",
                      })}
                    >
                      {STATE_FULL_LABEL_OPTIONS.map((option, state_index) => (
                        <option
                          key={`${state_index}-${option.value}`}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {/*{errors?.state?.type === "required" && (*/}
                    {/*  <span className="text-sm text-red-500">*/}
                    {/*    This field is required!*/}
                    {/*  </span>*/}
                    {/*)}*/}
                  </div>
                </div>

                {/* pcode */}
                <div className='col-span-2 sm:col-span-1'>
                  <div className='mt-3 '>
                    <label className='block text-sm font-medium text-gray-700'>
                      Postcode
                    </label>
                    <input
                      {...register("post_code", {
                        // required: true,
                        onChange: (e) => {
                          e.target.value = e.target.value
                            .toString()
                            .slice(0, 4);
                        },
                      })}
                      className='did-floating-input'
                      type='number'
                    />
                    {/*{errors?.post_code?.type === "required" && (*/}
                    {/*  <span className="text-sm text-red-500">*/}
                    {/*    {" "}*/}
                    {/*    This field is required!*/}
                    {/*  </span>*/}
                    {/*)}*/}
                  </div>
                </div>
                {/* <div className="col-span-2">
                  <div className="flex justify-end mt-6">
                    <div>
                        <CustomButton label='Clear & Exit'  />
                      <button
                        type="button"
                        // onClick={handleClearAndExit}
                        className="flex items-center text-white px-4 py-2 text-sm bg-red-600 rounded-lg mr-3"
                      >
                        {``}
                      </button>
                    </div>


                  </div>
                </div> */}
              </div>
            </div>
            <button
              className='hudden sr-only'
              type='submit'
              id='addparticipantButtonModal'
            />
          </form>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default AddParticipantModal;
