import React, { useEffect, useState } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { getStateOptions } from "store/selector/general.selector";

const AddressAutocomplete = ({
  google,
  children,
  customNames = {},
  addressId = "address-input",
  isStateName = false,
}) => {
  const { setValue, clearErrors } = useFormContext();
  const statesOptions = useSelector(getStateOptions);

  const [stateOps, setStateOps] = useState(statesOptions);

  useEffect(() => {
    if (statesOptions && statesOptions.length > 0) {
      setStateOps(statesOptions);
    }
  }, [statesOptions]);

  useEffect(() => {
    if (google && stateOps.length > 0) {
      const input = document.getElementById(
        addressId !== null ? addressId : "address-input"
      );

      const options = {
        componentRestrictions: { country: "au" },
      };

      const autocompleteService = new google.maps.places.Autocomplete(
        input,
        options
      );

      autocompleteService.addListener("place_changed", () => {
        const place = autocompleteService.getPlace();

        if (place) {
          let street = "";
          let suburb = "";
          let state = "";
          let postcode = "";

          place.address_components.forEach((component) => {
            const types = component.types;

            if (types.includes("street_number") || types.includes("route")) {
              street += component.long_name + " ";
            } else if (types.includes("locality")) {
              suburb = component.long_name;
            } else if (types.includes("administrative_area_level_1")) {
              state = component.short_name;
            } else if (types.includes("postal_code")) {
              postcode = component.long_name;
            }
          });
          // console.log("autoCompleteData", {
          //   street,
          //   suburb,
          //   state,
          //   postcode,
          //   stateOps,
          //   filedName: customNames?.state ? customNames?.state : "state_id",
          //   data: `${
          //     stateOps.find(
          //       ({ name }) => name.toLowerCase() === state.toLowerCase()
          //     )?.id
          //   }`,
          // });
          if (setValue) {
            setValue(
              customNames?.street_address
                ? customNames?.street_address
                : "street_address",
              street?.trim()
            );
            setValue(
              customNames?.suburb ? customNames?.suburb : "suburb",
              suburb
            );
            setValue(
              customNames?.state ? customNames?.state : "state_id",
              isStateName
                ? state
                : stateOps.find(
                    ({ name }) => name.toLowerCase() === state.toLowerCase()
                  )?.id
            );
            setValue(
              customNames?.postcode ? customNames?.postcode : "postal_code",
              postcode
            );
            clearErrors([
              customNames?.street_address
                ? customNames?.street_address
                : "street_address",
              customNames?.suburb ? customNames?.suburb : "suburb",
              customNames?.state ? customNames?.state : "state_id",
              customNames?.postcode ? customNames?.postcode : "postal_code",
            ]);
          }
        }
      });
    }
  }, [google, stateOps, setValue, clearErrors]);

  return <>{children}</>;
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(AddressAutocomplete);
