import React, { useEffect, useState } from "react";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import { Link, useNavigate } from "react-router-dom";
import Card from "./Cards/Card";
import allStaff from "../../../assets/dashboard/staff.png";
import Participant from "../../../assets/dashboard/participant.png";
import Complaints from "../../../assets/dashboard/complaint.png";
import Incident from "../../../assets/dashboard/incident.png";
import ProgressTable from "./ProgressTable";
import ExpirtingDateTable from "./ExpiringDateTable";
import Tabs from "./Tabs";
import BirthdayCard from "./Cards/BirthdayCard";
import { connect } from "react-redux";
import {
  getHRMTeamManagmentRequest,
  getSPDashboardRequest,
  getSPParticipantsListRequest,
} from "../../../store/actions/sp.actions";
import {
  getDashboard,
  getLoading,
  getParticipantsList,
  getTeamManagmentList,
} from "../../../store/selector/sp.selector";
import AnniversaryCard from "./Cards/AnniversaryCard";
import AnnualPerformanceCard from "./Cards/AnnualPerformanceCard";
import { apiGetRequest } from "helpers/Requests";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import { getCurrentTimezone, parseBooleanVal } from "Utils/utils";
import api from "store/services/sp.services";

const Dashboard = (props) => {
  const {
    loading,
    spDashboard,
    getDashboard,
    teamManagementList,
    getTeamManagmentListCall,
    getParticipantList,
    spParticipants,
  } = props;

  const [birthdayList, setBirthdayList] = useState([]);
  const [workAnniversaries, setAnniversaries] = useState([]);
  const [annualPerformances, setAnnualPerformances] = useState([]);
  const [expirDocs, setExpirDoc] = useState([]);
  const [shifts, setShifts] = useState([]);
  // const navigate = useNavigate();

  useEffect(() => {
    getBirthdays();
    getAnniversaries();
    getExpirDocs();
    getUpcomingShifts();
    getAnnualPerformances();
  }, []);

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
      compliance: true,
    };
    getDashboard({});
    getTeamManagmentListCall({ filters, bodyFilter: null });
    getParticipantList({ filters, bodyFilter: null });
  }, []);

  //get birthdays
  const getBirthdays = async (page) => {
    try {
      const res = await apiGetRequest(
        `/hrm/employee-upcoming-birthday?birthday_days=15&per_page=100`
      );
      if (res && res.status === 200) {
        const birthdays = res.data?.data?.birthdays;
        const birthdayArray = [];

        for (const key in birthdays) {
          if (birthdays.hasOwnProperty(key)) {
            const birthdayData = birthdays[key];
            birthdayArray.push(birthdayData);
          }
        }
        setBirthdayList(birthdayArray);
      }
    } catch (Err) {
      console.log(Err);
    }
  };

  //get anniversaries
  const getAnniversaries = async (page) => {
    try {
      const res = await apiGetRequest(
        `/hrm/employee-work-anniversaries?anniversary_days=15&per_page=100`
      );
      if (res && res.status === 200) {
        setAnniversaries(res.data?.data?.anniversaries?.data);
      }
    } catch (Err) {
      console.log(Err);
    }
  };
  //get annual performances
  const getAnnualPerformances = async (page) => {
    try {
      const res = await apiGetRequest(`/hrm/employee-performance`);
      if (res && res.status === 200) {
        setAnnualPerformances(res.data?.data);
      }
    } catch (Err) {
      console.log(Err);
    }
  };
  //get expire docs
  const getExpirDocs = async (page) => {
    try {
      const res = await apiGetRequest(
        `/hrm/expiring-docs?expiring_days=30&per_page=100`
      );
      if (res && res.status === 200) {
        setExpirDoc(res.data?.data?.documents?.data);
      }
    } catch (Err) {
      console.log(Err);
    }
  };
  //get upcoming shifts
  const getUpcomingShifts = async (page) => {
    try {
      const timezone = getCurrentTimezone();
      const res = await api.getSpDashboardInprogressShifts(timezone);
      setShifts(res?.shifts?.data);
    } catch (Err) {
      console.log(Err);
    }
  };

  const CardData = [
    {
      title: "Active Staff ",
      value: spDashboard?.all_staff || 0,
      icon: allStaff,
      text: "",
    },
    {
      title: "Active Participants",
      value: spDashboard?.all_participant_count || 0,
      icon: Participant,
      text: "",
    },
    {
      title: "Complaints",
      value: spDashboard?.complaints_count || 0,
      icon: Complaints,
      text: "this week",
    },
    {
      title: "Incidents",
      value: spDashboard?.incidents_count || 0,
      icon: Incident,
      text: "this week",
      bgColor: "#FF5151",
    },
  ];

  return (
    <div className='min-h-full w-full bg-[#F6F7F8] '>
      {loading ? (
        <div className='flex justify-center items-center min-h-screen w-full '>
          <CircularLoader
            className='flex justify-center items-center min-h-screen'
            classloader='loader-l'
          />
        </div>
      ) : (
        <div className='grid grid-cols-12 px-5 gap-5 py-3'>
          <div className='col-span-8   '>
            <div className='grid grid-cols-12 gap-x-6  sm:gap-y-4'>
              {CardData?.map((card, index) => {
                const { title, value, icon, text, bgColor } = card;
                return (
                  <div
                    className='lg:col-span-3 md:col-span-4 sm:col-span-8 '
                    key={index}
                  >
                    <Card
                      title={title}
                      value={value}
                      icon={icon}
                      text={text}
                      bgColor={bgColor}
                    />
                  </div>
                );
              })}
            </div>
            <div className='mt-9 grid grid-cols-12 gap-x-5 w-full'>
              {(roleAccess([], PERMISSION_KEYS.PARTICIPANT) ||
                roleAccess([], PERMISSION_KEYS.ROSTERING)) && (
                <div
                  className='col-span-6 bg-white  py-5 shadow-md'
                  style={{
                    borderTopRightRadius: "15px",
                    borderTopLeftRadius: "15px",
                  }}
                >
                  {roleAccess([], PERMISSION_KEYS.PARTICIPANT) &&
                    spParticipants &&
                    spParticipants?.participant?.data?.length > 0 && (
                      <div className='p-2'>
                        <ProgressTable
                          title='Participants Compliance'
                          columnName='Participant Name'
                          item={spParticipants?.participant?.data?.filter(
                            (x) => x?.status === "active"
                          )}
                        />
                      </div>
                    )}
                  {roleAccess([], PERMISSION_KEYS.ROSTERING) && shifts && (
                    <div className='p-2'>
                      <ExpirtingDateTable
                        title='Scheduled Shift'
                        item={shifts}
                      />
                    </div>
                  )}
                </div>
              )}

              {roleAccess([], PERMISSION_KEYS.HRM) && (
                <div
                  className='col-span-6 bg-white  py-5 shadow-md'
                  style={{
                    borderTopRightRadius: "15px",
                    borderTopLeftRadius: "15px",
                  }}
                >
                  {roleAccess([], PERMISSION_KEYS.HRM) &&
                    teamManagementList &&
                    teamManagementList?.employees?.data?.length > 0 && (
                      <div className='p-2 '>
                        <ProgressTable
                          title='HRM Compliance'
                          item={teamManagementList?.employees?.data?.filter(
                            (x) => parseBooleanVal(x?.status)
                          )}
                        />
                      </div>
                    )}
                  {roleAccess([], PERMISSION_KEYS.HRM) && expirDocs && (
                    <div className='p-2'>
                      <ExpirtingDateTable
                        title={"Expiring Docs"}
                        item={expirDocs?.filter(
                          (x) => x.worker?.status === true
                        )}
                        isExpirDoc={true}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='col-span-4 bg-white border-t rounded-md shadow-md py-5 pl-5 pr-2 '>
            <div>
              <Tabs />
            </div>

            <div className='flex-grow p-1 mt-3'>
              <p className='text-lg font-bold pb-2'>Birthday Events</p>
              <div
                className='flex flex-col overflow-y-auto custom-scroll'
                style={{ maxHeight: "280px" }}
              >
                {birthdayList && birthdayList?.length > 0 ? (
                  birthdayList?.map((item) => (
                    <div className='w-full mt-4' key={item.id}>
                      <BirthdayCard item={item} />
                    </div>
                  ))
                ) : (
                  <div className='text-center'>No data available</div>
                )}
              </div>
            </div>
            <div className='flex-grow p-1 mt-2'>
              <p className='text-lg font-bold pb-2'>Work Anniversary</p>
              <div
                className='flex flex-col overflow-y-auto custom-scroll'
                style={{ maxHeight: "300px" }}
              >
                {workAnniversaries && workAnniversaries?.length > 0 ? (
                  workAnniversaries?.map((item) => (
                    <div className='w-full mt-5' key={item?.id}>
                      <AnniversaryCard item={item} />
                    </div>
                  ))
                ) : (
                  <div className='text-center'>No data available</div>
                )}
              </div>
            </div>
            {roleAccess(
              [],
              PERMISSION_KEYS.HRM,
              PERMISSION_KEYS_CHILDREN.HRM.SHOW
            ) && (
              <div className='flex-grow p-1 mt-2'>
                <p className='text-lg font-bold pb-2'>Performance Reviews</p>
                <div
                  className='flex flex-col overflow-y-auto custom-scroll'
                  style={{ maxHeight: "300px" }}
                >
                  {annualPerformances && annualPerformances?.length > 0 ? (
                    annualPerformances?.map((item) => (
                      <div className='w-full mt-5' key={item?.id}>
                        <AnnualPerformanceCard item={item} />
                      </div>
                    ))
                  ) : (
                    <div className='text-center'>No data available</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getDashboard: getSPDashboardRequest,
  getTeamManagmentListCall: getHRMTeamManagmentRequest,
  getParticipantList: getSPParticipantsListRequest,
};

const mapStateToProps = (state) => {
  const spDashboard = getDashboard(state);
  const loading = getLoading(state);
  const teamManagementList = getTeamManagmentList(state);
  const spParticipants = getParticipantsList(state);

  return { loading, spDashboard, teamManagementList, spParticipants };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
