import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import {
  formatDateIntoDMYToDisplay,
  SilHomeselectedTabState,
} from "Utils/utils";
import { useNavigate } from "react-router";
import CircularLoader from "../CircularLoader/CircularLoader";
import { USER_ROLE } from "Constants/constant";
import { roleAccess } from "helpers/RolesPermission";
// import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const SilHomeQuickSnapshots = ({
  isQuickViewCall,
  propertyData,
  cssClass,
  isLoading,
  setToggleState,
  paddingLeft = true,
}) => {
  const navigate = useNavigate();
  // console.log("propertyData", propertyData);

  const handleTabs = () => {
    setToggleState
      ? setToggleState({
          name: "Settings",
          id: "settings",
          children: [],
        })
      : SilHomeselectedTabState.save({
          name: "Settings",
          id: "settings",
          children: [],
        });
    navigate(`/sil-accommodation/${propertyData.uuid}`);
  };

  const getValue = () => (
    <>
      {roleAccess([USER_ROLE.Sp_Standard_User]) ? (
        <dd className='mt-1 text-sm text-gray-900 flex items-center space-x-2'>
          <span className='print:text-red-500 text-red-500'>
            No Data found!
          </span>
        </dd>
      ) : (
        <dd
          onClick={() => {
            if (
              // roleAccess(
              //   [],
              //   PERMISSION_KEYS.PARTICIPANT,
              //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
              // ) &&
              // roleAccess(
              //   [],
              //   PERMISSION_KEYS.PARTICIPANT,
              //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
              // )
              true
            ) {
              handleTabs();
            }
          }}
          className={`${
            // roleAccess(
            //   [],
            //   PERMISSION_KEYS.PARTICIPANT,
            //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
            // ) &&
            // roleAccess(
            //   [],
            //   PERMISSION_KEYS.PARTICIPANT,
            //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
            // ) &&
            "cursor-pointer"
          }  mt-1 text-sm text-gray-900 flex items-center space-x-2`}
        >
          <span
            className={`${cssClass} break-all print:text-black text-gray-600`}
          >
            {
              // roleAccess(
              //   [],
              //   PERMISSION_KEYS.PARTICIPANT,
              //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
              // ) &&
              // roleAccess(
              //   [],
              //   PERMISSION_KEYS.PARTICIPANT,
              //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
              // )
              true ? "Add information" : "No Data Found!"
            }
          </span>
          {
            // roleAccess(
            //   [],
            //   PERMISSION_KEYS.PARTICIPANT,
            //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
            // ) &&
            //   roleAccess(
            //     [],
            //     PERMISSION_KEYS.PARTICIPANT,
            //     PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
            //   )
            true && (
              <span className={`${cssClass}`}>
                <FiArrowUpRight />
              </span>
            )
          }
        </dd>
      )}
    </>
  );

  return (
    <div className='min-h-full'>
      <div
        className={`mt-8 ${cssClass} max-w-3xl mx-auto grid grid-cols-1 gap-6 ${
          isQuickViewCall ? "sm:px-0" : "sm:px-6"
        } lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3`}
      >
        <div className='space-y-6 lg:col-start-1 lg:col-span-3 '>
          <section>
            <div
              className={`${
                isQuickViewCall
                  ? "bg-white"
                  : "bg-light-green-gradient sm:rounded-lg"
              } print:h-full`}
            >
              {isLoading ? (
                <CircularLoader
                  classes='h-full flex justify-center items-center'
                  classloader='loader-l'
                />
              ) : (
                <>
                  <div
                    className={`pt-4 pb-3 ${
                      paddingLeft ? "sm:px-0" : "sm:px-6"
                    }`}
                  >
                    <h2
                      className={`${cssClass} text-lg leading-6 font-medium print:text-black`}
                    >
                      Quick Snapshot
                    </h2>
                    {propertyData?.quick_snapshot ? (
                      <>
                        <p
                          className={`${cssClass} max-h-[180px] overflow-y-auto custom-scroll mt-1 max-w-5xl text-sm break-words w-[430px] print:text-black`}
                        >
                          {propertyData?.quick_snapshot}
                        </p>
                      </>
                    ) : (
                      <>{getValue()}</>
                    )}
                  </div>
                  <div
                    className={`pt-4 pb-10 ${
                      paddingLeft ? "sm:px-0" : "sm:px-6"
                    }`}
                  >
                    <dl className='print:grid print:grid-cols-2 grid grid-cols-1 gap-5 sm:grid-cols-2 gap-x-6'>
                      <div className='print:col-span-1 sm:col-span-1'>
                        <dt
                          className={`${cssClass} text-sm font-medium print:text-black`}
                        >
                          Lease Start Date
                        </dt>
                        {propertyData && propertyData?.occupation_date ? (
                          <dd
                            className={`${cssClass} mt-1 text-sm break-all print:text-black`}
                          >
                            {formatDateIntoDMYToDisplay(
                              propertyData?.occupation_date
                            )}
                          </dd>
                        ) : (
                          <>{getValue()}</>
                        )}
                      </div>
                      <div className='print:col-span-1 sm:col-span-1'>
                        <dt
                          className={`${cssClass} text-sm font-medium print:text-black`}
                        >
                          Lease End Date
                        </dt>
                        {propertyData && propertyData?.leaving_date ? (
                          <dd
                            className={`${cssClass} mt-1 text-sm break-all print:text-black`}
                          >
                            {formatDateIntoDMYToDisplay(
                              propertyData?.leaving_date
                            )}
                          </dd>
                        ) : (
                          <>{getValue()}</>
                        )}
                      </div>

                      <div className='print:col-span-1 sm:col-span-1'>
                        <dt
                          className={`${cssClass} text-sm font-medium print:text-black`}
                        >
                          Number of Rooms
                        </dt>
                        {propertyData && propertyData?.total_rooms ? (
                          <dd
                            className={`${cssClass} mt-1 text-sm overflow-hidden whitespace-nowrap overflow-ellipsis print:text-black`}
                          >
                            {propertyData?.total_rooms}
                          </dd>
                        ) : (
                          <>{getValue()}</>
                        )}
                      </div>
                      <div className='print:col-span-1 sm:col-span-1'>
                        <dt
                          className={`${cssClass} text-sm font-medium print:text-black`}
                        >
                          Region
                        </dt>
                        {propertyData && propertyData?.region?.name ? (
                          <dd
                            className={`${cssClass} mt-1 text-sm print:text-black`}
                          >
                            {propertyData?.region?.name}
                          </dd>
                        ) : (
                          <>{getValue()}</>
                        )}
                      </div>
                      {/* <div className='sm:col-span-1 print:hidden'>
                        <dt
                          className={`${cssClass} text-sm font-medium print:text-black`}
                        >
                          Team Leader
                        </dt>
                        {propertyData && propertyData?.team_leader ? (
                          <dd
                            className={`${cssClass} mt-1 text-sm flex items-center space-x-2 print:text-black`}
                          >
                            <span className={`${cssClass} print:text-black`}>
                              {propertyData?.team_leader}
                            </span>
                          </dd>
                        ) : (
                          <>{getValue()}</>
                        )}
                      </div>
                      <div className='col-span-1' />
                      <div className='sm:col-span-1 print:hidden'>
                        <dt
                          className={`${cssClass} text-sm font-medium print:text-black`}
                        >
                          Upcoming Repair
                        </dt>
                        {propertyData && propertyData?.upcoming_repair ? (
                          <dd
                            className={`${cssClass} mt-1 text-sm flex items-center space-x-2 print:text-black`}
                          >
                            <span className={`${cssClass} print:text-black`}>
                              {formatDateIntoDMYToDisplay(
                                propertyData?.upcoming_repair
                              )}
                            </span>
                          </dd>
                        ) : (
                          <>{getValue()}</>
                        )}
                      </div>
                      <div className='sm:col-span-1 print:hidden'>
                        <dt
                          className={`${cssClass} text-sm font-medium print:text-black`}
                        >
                          Last Repair
                        </dt>
                        {propertyData && propertyData?.last_repair ? (
                          <dd
                            className={`${cssClass} mt-1 text-sm flex items-center space-x-2 print:text-black`}
                          >
                            <span className={`${cssClass} print:text-black`}>
                              {formatDateIntoDMYToDisplay(
                                propertyData?.last_repair
                              )}
                            </span>
                          </dd>
                        ) : (
                          <>{getValue()}</>
                        )}
                      </div> */}
                    </dl>
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default SilHomeQuickSnapshots;
