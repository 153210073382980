import React from "react";
import PropertyAddressSummary from "./components/PropertyAddressSummary";
import PropertyAddressImgUploader from "./components/PropertyAddressImgUploader";
import GoogleMap from "components/common/GoogleMap/GoogleMap";
import OccupantList from "./components/OccupantList";
import RealEstateAgentInfo from "./components/RealEstateAgentInfo";
import { capitalizeFirstLetter } from "Utils/utils";
import PropertyNearBy from "./components/PropertyNearby";

const PropertyProfile = ({ selectedAccommodationInfo, setToggleState }) => {
  return (
    <>
      <div className='p-5'>
        <div className='py-4 px-6'>
          <div className='pb-4 grid grid-cols-11 gap-x-2 '>
            <div className='grid col-span-4'>
              <PropertyAddressSummary
                setToggleState={setToggleState}
                selectedAccommodationInfo={selectedAccommodationInfo}
              />
            </div>
            <div className='col-span-7 px-2'>
              <PropertyAddressImgUploader
                selectedAccommodationInfo={selectedAccommodationInfo}
              />
            </div>
          </div>
          <div className='py-2'>
            <div className='grid grid-cols-2 gap-x-4'>
              <div className='col-span-1'>
                <GoogleMap
                  zoom={13}
                  address={`${capitalizeFirstLetter(
                    selectedAccommodationInfo?.street_address
                  )}, ${capitalizeFirstLetter(
                    selectedAccommodationInfo?.suburb
                  )}, ${
                    selectedAccommodationInfo?.stateObj?.name?.toUpperCase() ||
                    ""
                  } ${selectedAccommodationInfo?.postal_code}`}
                />
              </div>
              <div
                className='col-span-1'
                style={{
                  borderTopRightRadius: "15px",
                  borderTopLeftRadius: "15px",
                }}
              >
                <OccupantList
                  selectedAccommodationInfo={selectedAccommodationInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <PropertyNearBy selectedAccommodationInfo={selectedAccommodationInfo} />
      </div>
      {/* <div>
        <PropertyFeatures />
      </div> */}
      <div>
        <RealEstateAgentInfo
          selectedAccommodationInfo={selectedAccommodationInfo}
        />
      </div>
      {/* <div>
        <AgencyAddress />
      </div> */}
    </>
  );
};

export default PropertyProfile;
