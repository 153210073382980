import { fromJS } from "immutable";
import ACTIONS from "../constants";
import { getPaginationObj } from "Utils/utils";

const initialState = fromJS({
  silAccommodations: [],
});

export default function silReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_PROPERTY_TYPE.PENDING: {
      return state
        .set("propertyTypeLoading", true)
        .set("propertyTypeError", "");
    }
    case ACTIONS.GET_PROPERTY_TYPE.SUCCESS: {
      return state
        .set("propertyTypeLoading", false)
        .set("propertyTypeError", "")
        .set("propertyType", action.data);
    }
    case ACTIONS.GET_PROPERTY_TYPE.ERROR: {
      return state
        .set("propertyTypeLoading", false)
        .set("propertyTypeError", action.error);
    }

    case ACTIONS.GET_SDA_TYPE.PENDING: {
      return state.set("sdaTypeLoading", true).set("sdaTypeError", "");
    }
    case ACTIONS.GET_SDA_TYPE.SUCCESS: {
      return state
        .set("sdaTypeLoading", false)
        .set("sdaTypeError", "")
        .set("sdaType", action.data);
    }
    case ACTIONS.GET_SDA_TYPE.ERROR: {
      return state
        .set("sdaTypeLoading", false)
        .set("sdaTypeError", action.error);
    }

    case ACTIONS.CREATE_SIL_ACCOMMODATION.PENDING: {
      return state
        .set("createSilAccommodationLoading", true)
        .set("createSilAccommodationError", "");
    }
    case ACTIONS.CREATE_SIL_ACCOMMODATION.SUCCESS: {
      const prevData = state.get("silAccommodations") || [];
      prevData.push(action.data);
      return state
        .set("createSilAccommodationLoading", false)
        .set("createSilAccommodationError", "")
        .set("silAccommodations", [...prevData]);
    }
    case ACTIONS.CREATE_SIL_ACCOMMODATION.ERROR: {
      return state
        .set("createSilAccommodationLoading", false)
        .set("createSilAccommodationError", action.error);
    }

    case ACTIONS.GET_SIL_ACCOMMODATION_LIST.PENDING: {
      return state
        .set("silAccommodationListLoading", true)
        .set("silAccommodationListError", "");
    }
    case ACTIONS.GET_SIL_ACCOMMODATION_LIST.SUCCESS: {
      return state
        .set("silAccommodationListLoading", false)
        .set("silAccommodationListError", "")
        .set("silAccommodations", [...(action.data?.homes?.data || [])])
        .set(
          "silAccommodationsPagination",
          getPaginationObj(action.data?.homes)
        );
    }
    case ACTIONS.GET_SIL_ACCOMMODATION_LIST.ERROR: {
      return state
        .set("silAccommodationListLoading", false)
        .set("silAccommodationListError", action.error);
    }

    case ACTIONS.GET_SIL_ACCOMMODATION_DETAIL.PENDING: {
      return state
        .set("silAccommodationDetailLoading", true)
        .set("silAccommodationDetailError", "");
    }
    case ACTIONS.GET_SIL_ACCOMMODATION_DETAIL.SUCCESS: {
      return state
        .set("silAccommodationDetailLoading", false)
        .set("silAccommodationDetailError", "")
        .set("selectedSilAccommodation", action.data);
    }
    case ACTIONS.GET_SIL_ACCOMMODATION_DETAIL.ERROR: {
      return state
        .set("silAccommodationDetailLoading", false)
        .set("silAccommodationDetailError", action.error);
    }

    case ACTIONS.UPDATE_SIL_ACCOMMODATION_SETTINGS.PENDING: {
      return state
        .set("silAccommodationUpdateLoading", true)
        .set("silAccommodationUpdateError", "");
    }
    case ACTIONS.UPDATE_SIL_ACCOMMODATION_SETTINGS.SUCCESS: {
      return state
        .set("silAccommodationUpdateLoading", false)
        .set("silAccommodationUpdateError", "")
        .set("selectedSilAccommodation", action.data);
    }
    case ACTIONS.UPDATE_SIL_ACCOMMODATION_SETTINGS.ERROR: {
      return state
        .set("silAccommodationUpdateLoading", false)
        .set("silAccommodationUpdateError", action.error);
    }

    case ACTIONS.SET_SELECTED_SIL_ACCOMMODATION_LOCALLY: {
      return state.set("selectedSilAccommodation", action.data);
    }

    case ACTIONS.SET_SELECTED_SIL_ACCOMMODATION_IMAGE_LOCALLY: {
      const prevData = state.get("selectedSilAccommodation") || {};
      Object.assign(prevData, {
        images: action.data,
      });
      return state.set("selectedSilAccommodation", { ...prevData });
    }

    case ACTIONS.SET_SELECTED_SIL_ACCOMMODATION_ROOM_VACANT_LOCALLY: {
      return state.set("selectedSilAccommodation", { ...action.data });
    }

    case ACTIONS.GET_SIL_ACCOMMODATION_ROOMS_LIST.PENDING: {
      return state
        .set("silAccommodationRoomListLoading", true)
        .set("silAccommodationRoomListError", "");
    }
    case ACTIONS.GET_SIL_ACCOMMODATION_ROOMS_LIST.SUCCESS: {
      return state
        .set("silAccommodationRoomListLoading", false)
        .set("silAccommodationRoomListError", "")
        .set("selectedSilAccommodationRooms", action.data);
    }
    case ACTIONS.GET_SIL_ACCOMMODATION_ROOMS_LIST.ERROR: {
      return state
        .set("silAccommodationRoomListLoading", false)
        .set("silAccommodationRoomListError", action.error);
    }

    case ACTIONS.SET_SELECTED_SIL_ACCOMMODATION_ROOM_LOCALLY: {
      const prevData = state.get("selectedSilAccommodationRooms") || [];
      if (prevData?.length > 0) {
        const index = prevData.findIndex((x) => x.uuid === action.room_uuid);
        if (index !== -1) {
          prevData.splice(index, 1, { ...action.payload });
        }
      }
      return state.set("selectedSilAccommodationRooms", [...prevData]);
    }

    case ACTIONS.SIL_ACCOMMODATION_ROOM_DELETE.PENDING: {
      return state
        .set("silAccommodationRoomDeleteLoading", true)
        .set("silAccommodationRoomDeleteError", "");
    }
    case ACTIONS.SIL_ACCOMMODATION_ROOM_DELETE.SUCCESS: {
      const prevData = state.get("selectedSilAccommodationRooms") || [];
      const index = prevData?.findIndex(
        ({ uuid }) => uuid === action.data.room_uuid
      );
      if (index !== -1) {
        prevData.splice(index, 1);
      }
      return state
        .set("silAccommodationRoomDeleteLoading", false)
        .set("silAccommodationRoomDeleteError", "")
        .set("selectedSilAccommodationRooms", [...prevData]);
    }
    case ACTIONS.SIL_ACCOMMODATION_ROOM_DELETE.ERROR: {
      return state
        .set("silAccommodationRoomDeleteLoading", false)
        .set("silAccommodationRoomDeleteError", action.error);
    }

    // Sil Forms Reducers

    case ACTIONS.GET_SIL_FORM_CATEGORIES.PENDING: {
      return state
        .set("silFormCategoriesLoading", true)
        .set("silFormCategoriesError", "");
    }
    case ACTIONS.GET_SIL_FORM_CATEGORIES.SUCCESS: {
      return state
        .set("silFormCategoriesLoading", false)
        .set("silFormCategoriesError", "")
        .set("silFormCategories", action.data);
    }
    case ACTIONS.GET_SIL_FORM_CATEGORIES.ERROR: {
      return state
        .set("silFormCategoriesLoading", false)
        .set("silFormCategoriesError", action.error);
    }

    case ACTIONS.GET_SIL_ALL_FORMS_LIST.PENDING: {
      return state
        .set("silAllFormsListLoader", true)
        .set("silAllFormsListError", "");
    }
    case ACTIONS.GET_SIL_ALL_FORMS_LIST.SUCCESS: {
      const obj = action.data.query_params;
      let selectedFormsCat = null;
      if (obj.hasOwnProperty("filter")) {
        if (obj.filter && obj.filter.form_category) {
          selectedFormsCat = obj.filter.form_category.map((x) => x).join(",");
        }
      }
      const form_categories = state.get("silFormCategories") || [];
      if (selectedFormsCat) {
        const options = form_categories.filter(
          (x) => selectedFormsCat.includes(x.id) && x
        );
        Object.assign(action.data.query_params.filter, {
          form_category: options,
        });
      }
      return state
        .set("silAllFormsListLoader", false)
        .set("silAllFormsListError", "")
        .set("silAllForms", action.data);
    }
    case ACTIONS.GET_SIL_ALL_FORMS_LIST.ERROR: {
      return state
        .set("silAllFormsListLoader", false)
        .set("silAllFormsListError", action.error);
    }
    case ACTIONS.UPDATE_SIL_FORM_TEMPLATES_LOCALLY: {
      const prevForms = state.get("silAllForms") || null;
      console.log("prevForms", prevForms);
      if (
        prevForms &&
        prevForms.form_templates &&
        action.data.type === "delete"
      ) {
        const index = prevForms.form_templates.data.findIndex(
          (x) => x.id === action.data.id
        );
        if (index !== -1) {
          prevForms.form_templates.data.splice(index, 1);
          prevForms.form_templates.total -= 1;
          prevForms.form_templates.count -= 1;
          return state.set("silAllForms", { ...prevForms });
        }
      }
      return state;
    }

    // Sil Documents
    case ACTIONS.GET_FOLDER_LIST_FOR_SIL.PENDING: {
      return state
        .set("silhomeDocumentsListLoader", true)
        .set("silhomeDocumentsListError", "");
    }
    case ACTIONS.GET_FOLDER_LIST_FOR_SIL.SUCCESS: {
      return state
        .set("silhomeDocumentsListLoader", false)
        .set("silhomeDocumentsListError", "")
        .set("silHomeDocumentFolderList", action.data.folder_structure)
        .set("silHomeDocumentFileList", action.data.files_managements);
    }
    case ACTIONS.GET_FOLDER_LIST_FOR_SIL.ERROR: {
      return state
        .set("silhomeDocumentsListLoader", false)
        .set("silhomeDocumentsListError", action.error);
    }

    case ACTIONS.CREATE_SIL_DOCUMENT_FOLDER.PENDING: {
      return state
        .set("silhomeDocumentFolderCreateLoader", true)
        .set("silhomeDocumentFolderCreateError", "");
    }
    case ACTIONS.CREATE_SIL_DOCUMENT_FOLDER.SUCCESS: {
      const prevData = state.get("silHomeDocumentFolderList") || [];
      prevData.unshift(action.data);

      return state
        .set("silhomeDocumentFolderCreateLoader", false)
        .set("silhomeDocumentFolderCreateError", "")
        .set("silHomeDocumentFolderList", [...prevData]);
    }
    case ACTIONS.CREATE_SIL_DOCUMENT_FOLDER.ERROR: {
      return state
        .set("silhomeDocumentFolderCreateLoader", false)
        .set("silhomeDocumentFolderCreateError", action.error);
    }

    case ACTIONS.UPDATE_SIL_DOCUMENT_FOLDER.PENDING: {
      return state
        .set("silhomeDocumentFolderUpdateLoader", true)
        .set("silhomeDocumentFolderUpdateError", "");
    }
    case ACTIONS.UPDATE_SIL_DOCUMENT_FOLDER.SUCCESS: {
      const prevData = state.get("silHomeDocumentFolderList") || [];
      const index = prevData?.findIndex((x) => x.uuid === action.data.uuid);
      if (index !== -1) {
        prevData.splice(index, 1, { ...action.data });
      }
      return state
        .set("silhomeDocumentFolderUpdateLoader", false)
        .set("silhomeDocumentFolderUpdateError", "")
        .set("silHomeDocumentFolderList", [...prevData]);
    }
    case ACTIONS.UPDATE_SIL_DOCUMENT_FOLDER.ERROR: {
      return state
        .set("silhomeDocumentFolderUpdateLoader", false)
        .set("silhomeDocumentFolderUpdateError", action.error);
    }

    case ACTIONS.UPDATE_SIL_DOCUMENT_FILE_UPLOAD.PENDING: {
      return state
        .set("silhomeDocumentFileUploadLoader", true)
        .set("silhomeDocumentFileUploadError", "");
    }
    case ACTIONS.UPDATE_SIL_DOCUMENT_FILE_UPLOAD.SUCCESS: {
      const prevData = state.get("silHomeDocumentFileList") || [];
      prevData.unshift(action.data?.data);

      return state
        .set("silhomeDocumentFileUploadLoader", false)
        .set("silhomeDocumentFileUploadError", "")
        .set("silHomeDocumentFileList", [...prevData]);
    }
    case ACTIONS.UPDATE_SIL_DOCUMENT_FILE_UPLOAD.ERROR: {
      return state
        .set("silhomeDocumentFileUploadLoader", false)
        .set("silhomeDocumentFileUploadError", action.error);
    }

    case ACTIONS.SIL_DOCUMENT_FILE_REMOVE.PENDING: {
      return state
        .set("silhomeDocumentFileRemoveLoader", true)
        .set("silhomeDocumentFileRemoveError", "");
    }
    case ACTIONS.SIL_DOCUMENT_FILE_REMOVE.SUCCESS: {
      const prevData = state.get("silHomeDocumentFileList") || [];

      const index = prevData?.findIndex((x) => x.id === action.data.id);
      if (index !== -1) {
        prevData.splice(index, 1);
      }

      return state
        .set("silhomeDocumentFileRemoveLoader", false)
        .set("silhomeDocumentFileRemoveError", "")
        .set("silHomeDocumentFileList", [...prevData]);
    }
    case ACTIONS.SIL_DOCUMENT_FILE_REMOVE.ERROR: {
      return state
        .set("silhomeDocumentFileRemoveLoader", false)
        .set("silhomeDocumentFileRemoveError", action.error);
    }

    case ACTIONS.SIL_DOCUMENT_FOLDER_REMOVE.PENDING: {
      return state
        .set("silhomeDocumentFolderRemoveLoader", true)
        .set("silhomeDocumentFolderRemoveError", "");
    }
    case ACTIONS.SIL_DOCUMENT_FOLDER_REMOVE.SUCCESS: {
      const prevData = state.get("silHomeDocumentFolderList") || [];

      const index = prevData?.findIndex((x) => x.id === action.data.id);
      if (index !== -1) {
        prevData.splice(index, 1);
      }

      return state
        .set("silhomeDocumentFolderRemoveLoader", false)
        .set("silhomeDocumentFolderRemoveError", "")
        .set("silHomeDocumentFolderList", [...prevData]);
    }
    case ACTIONS.SIL_DOCUMENT_FOLDER_REMOVE.ERROR: {
      return state
        .set("silhomeDocumentFolderRemoveLoader", false)
        .set("silhomeDocumentFolderRemoveError", action.error);
    }

    default: {
      return state;
    }
  }
}
