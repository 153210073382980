import { connect } from "react-redux";
import SilRooms from "./SilRooms";
import {
  deleteSilRoomRequest,
  fetchAllSilRoomsListRequest,
  setSelectedSilAccommodationRoomVacantLocally,
} from "store/actions/sil.actions";
import {
  getSelectedSilAccommodationRooms,
  getSilAccommodationRoomListLoading,
} from "store/selector/sil.selector";

const mapDispatchToProps = {
  fetchAllSilRoomsListRequest,
  deleteSilRoomRequest,
  setSelectedSilAccommodationRoomVacantLocally,
};

const mapStateToProps = (state) => {
  const silAccommodationRoomListLoading =
    getSilAccommodationRoomListLoading(state);
  const selectedSilAccommodationRooms = getSelectedSilAccommodationRooms(state);
  return { selectedSilAccommodationRooms, silAccommodationRoomListLoading };
};

export default connect(mapStateToProps, mapDispatchToProps)(SilRooms);
