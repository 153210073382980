import { spawn, all } from "redux-saga/effects";
import authSagas from "../sagas/auth.sagas";
import spSagas from "../sagas/sp.sagas";
import participantSagas from "../sagas/participant.sagas";
import CosSagas from "../sagas/cos.sagas";
import rosteringSagas from "../sagas/rostering.sagas";
import employeeSagas from "../sagas/employee.sagas";
import silSagas from "../sagas/sil.sagas";

export default function* rootSaga() {
  yield all([spawn(CosSagas)]);
  yield all([spawn(authSagas)]);
  yield all([spawn(spSagas)]);
  yield all([spawn(participantSagas)]);
  yield all([spawn(rosteringSagas)]);
  yield all([spawn(employeeSagas)]);
  yield all([spawn(silSagas)]);
}
