import React, { useEffect, useState } from "react";
import SwitchInput from "components/common/Input/SwitchInput";
import SelectOption from "components/common/Select/Select";
import {
  EMAIL_REGEX_EXP,
  portalOption,
  REGEX_PHONE_NUMBER,
} from "Constants/constant";
import { Controller, FormProvider, useForm } from "react-hook-form";
import CustomButton from "components/common/Button/CustomButton";
import { classNames, formatDateIntoDMYToDisplay } from "Utils/utils";
import AddressAutoComplete from "components/common/GoogleMap/AddressAutoComplete";
import api from "store/services/sp.services";
import MultiLevelDropdown from "components/common/MultilevelDropdown";
import CheckBox from "components/common/checkBox/checkbox";

const SilAccommodationSettings = (props) => {
  const {
    propertyTypeList,
    sdaTypeList,
    sdaTypeLoading,
    propertyTypeLoading,
    allCatStates,
    catalogSubCategoryStatesLoading,
    selectedSilAccommodation,
    fetchPropertyTypeListRequest,
    fetchSdaTypeListRequest,
    getCatalogServicesSubCategoryRequest,
    updateSilAccommodationSettingRequest,
    silAccommodationUpdateLoading,

    getPManagerRequest,
    spManagersListLoading,
    managersList,

    getSPParticipantsListRequest,
    participantsListLoading,
    participantsList,

    getHRMTeamManagmentRequest,
    workersListLoading,
    workersList,
  } = props;

  const methods = useForm();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
    reset,
    control,
  } = methods;

  const watchFormData = watch();

  const [isFetchRegionsLoader, setIsFetchRegionsLoader] = useState(false);
  const [regionOps, setRegionOps] = useState([]);

  const onSubmit = (values) => {
    const payload = {
      ...values,
      state_id: values?.state,
      postal_code: values?.postal_code?.toString(),
      sda_type_id: values?.sda_type,
      property_type_id: values?.property_type,
      escalator: values?.escalator === "yes",
      air_conditioning: values?.air_conditioning === "yes",
      dishwasher: values?.dishwasher === "yes",
      uuid: selectedSilAccommodation?.uuid,
      region_id: values?.region_id?.id,
    };

    if (values?.region_id?.id) {
      Object.assign(payload, {
        region_id: values?.region_id?.id,
      });
    }
    updateSilAccommodationSettingRequest(payload, (res) => {
      if (res) {
        // console.log("res", res);
      }
    });
  };

  useEffect(() => {
    reset(selectedSilAccommodation);
  }, [allCatStates]);

  const getRegionList = async () => {
    try {
      setIsFetchRegionsLoader(true);
      const res = await api.getTeamLeadManagerAndRegions(1);
      setRegionOps(res?.data?.regions);
      setIsFetchRegionsLoader(false);
    } catch (err) {
      console.log("err", err);
      setIsFetchRegionsLoader(false);
    }
  };

  useEffect(() => {
    if (propertyTypeList?.length <= 0) {
      fetchPropertyTypeListRequest();
    }
    if (sdaTypeList?.length <= 0) {
      fetchSdaTypeListRequest();
    }
    getCatalogServicesSubCategoryRequest();
    getPManagerRequest();
    getSPParticipantsListRequest({ filters: { page: 1, per_page: 1000 } });
    getHRMTeamManagmentRequest({ filters: { page: 1, per_page: 1000 } });
    getRegionList();
  }, []);

  // console.log("watchFormData", watchFormData);

  return (
    <div className='md:grid md:gap-6'>
      {/* {false ? (
        <div className='col-span-3 h-screen'>
          <CircularLoader
            classes='h-full flex justify-center items-center'
            classloader='loader-l'
          />
        </div>
      ) : ( */}
      <div className='mt-5 md:mt-0 md:col-span-2'>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='shadow sm:rounded-md sm:overflow-hidden'>
              <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                <h2 className='text-lg pb-2 font-bold'>Property Information</h2>
                <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                  <div className='col-span-2'>
                    <label
                      htmlFor='quick_snapshot'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Quick Snapshot
                    </label>
                    <div className='mt-1'>
                      <textarea
                        rows={3}
                        name='quick_snapshot'
                        id='quick_snapshot'
                        className='px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md resize-none overflow-y-auto v ln lk'
                        {...register("quick_snapshot", {
                          //  required: true
                        })}
                      />
                      {errors.quick_snapshot && (
                        <span className='text-red-500'>Field is required!</span>
                      )}
                    </div>
                  </div>
                  {/* <div className='col-span-2'>
                    <label
                      htmlFor='f_name'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Landlord Name<span className='text-red-500'>*</span>
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='text'
                        name='landlord_name'
                        id='landlord_name'
                        className={classNames(
                          "did-floating-input",
                          errors.landlord_name && "errorBorder"
                        )}
                        {...register("landlord_name", {
                          required: true,
                          maxLength: 50,
                        })}
                      />
                      {errors.landlord_name && (
                        <span className='text-red-500 text-sm'>
                          Field is required!
                        </span>
                      )}
                    </div>
                  </div> */}
                  <div className='col-span-2'>
                    <AddressAutoComplete
                      customNames={{ state: "state" }}
                      google={window?.google}
                      errors={errors}
                    >
                      <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                        <div className='col-span-1'>
                          <label
                            htmlFor=''
                            className='block text-sm font-medium text-gray-700'
                          >
                            Street Address
                            <span className='text-red-500'>*</span>
                          </label>
                          <div className='relative mt-1'>
                            <input
                              type='text'
                              name='street_address'
                              id='address-input'
                              className={classNames(
                                "did-floating-input",
                                errors.street_address && "errorBorder"
                              )}
                              {...register("street_address", {
                                required: true,
                                maxLength: 254,
                              })}
                            />
                            {errors.street_address && (
                              <span className='text-red-500 text-sm'>
                                Field is required!
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor=''
                            className='block text-sm font-medium text-gray-700'
                          >
                            Suburb<span className='text-red-500'>*</span>
                          </label>
                          <div className='relative mt-1'>
                            <input
                              type='text'
                              name='suburb'
                              id='suburb'
                              className={classNames(
                                "did-floating-input",
                                errors.suburb && "errorBorder"
                              )}
                              {...register("suburb", {
                                required: true,
                                maxLength: 254,
                              })}
                            />
                            {errors.suburb && (
                              <span className='text-red-500 text-sm'>
                                Field is required!
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='col-span-1'>
                          <div className=''>
                            <label className='block text-sm font-medium text-gray-700 mb-1'>
                              State<span className='text-red-500'>*</span>
                            </label>
                            <SelectOption
                              altName='google_state_id'
                              isDisabled={catalogSubCategoryStatesLoading}
                              loading={catalogSubCategoryStatesLoading}
                              cssClass='truncate'
                              isRequired={true}
                              register={register}
                              setValue={setValue}
                              name='state'
                              isSearch={false}
                              data={allCatStates}
                              selectedOption={allCatStates?.find(
                                (x) => x.value === watchFormData?.state
                              )}
                              errorMessage={
                                errors?.state?.type === "required" ? (
                                  <div className='text-sm text-red-500'>
                                    Field is required!!
                                  </div>
                                ) : null
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor=''
                            className='block text-sm font-medium text-gray-700'
                          >
                            Postcode<span className='text-red-500'>*</span>
                          </label>
                          <div className='relative mt-1'>
                            <input
                              type='number'
                              name='postal_code'
                              id='postal_code'
                              minLength={4}
                              maxLength={4}
                              className={classNames(
                                "did-floating-input",
                                errors.postal_code && "errorBorder"
                              )}
                              {...register("postal_code", {
                                required: true,
                                maxLength: 4,
                                minLength: 4,
                                onChange: (e) => {
                                  e.target.value = e.target.value
                                    .toString()
                                    .slice(0, 4);
                                },
                              })}
                            />
                            {errors?.postal_code?.type === "maxLength" ? (
                              <span className='text-red-500 text-sm'>
                                Postcode must be 4 digits
                              </span>
                            ) : errors?.postal_code?.type === "minLength" ? (
                              <span className='text-red-500 text-sm'>
                                Postcode must be 4 digits
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </AddressAutoComplete>
                  </div>

                  <div>
                    <label className='text-gray-700 text-sm font-medium mb-1'>
                      Property Email
                      {/* <span className='text-red-500'>*</span> */}
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='email'
                        id='email'
                        className={classNames(
                          "did-floating-input",
                          errors.email && "errorBorder"
                        )}
                        {...register("email", {
                          required: false,
                          pattern: EMAIL_REGEX_EXP,
                        })}
                      />
                      {errors.email?.type === "required" ? (
                        <span className='text-red-500 text-sm'>
                          Field is required!
                        </span>
                      ) : (
                        errors?.email?.type === "pattern" && (
                          <span className='text-red-500 text-sm'>
                            Invalid email address
                          </span>
                        )
                      )}
                    </div>
                  </div>

                  <div>
                    <label className='text-gray-700 text-sm font-medium mb-1'>
                      Property Phone
                      {/* <span className='text-red-500'>*</span> */}
                    </label>
                    <div className='mt-1'>
                      <input
                        type='number'
                        // minLength={10}
                        maxLength={10}
                        name='phone'
                        id='phone'
                        className={classNames(
                          "did-floating-input",
                          errors.phone && "errorBorder"
                        )}
                        {...register("phone", {
                          required: false,
                          // minLength: 10,
                          maxLength: 10,
                          pattern: REGEX_PHONE_NUMBER,
                          onChange: (e) => {
                            const sanitizedValue = e.target.value.replace(
                              /\D/g,
                              ""
                            );
                            const formattedValue = sanitizedValue.slice(0, 10);
                            e.target.value = formattedValue;
                          },
                        })}
                      />
                      {errors?.phone?.type === "required" ? (
                        errors.phone && (
                          <span className='text-red-500 text-sm'>
                            Please enter a valid phone number with the max
                            length of 10
                          </span>
                        )
                      ) : (
                        <>
                          {errors.phone?.type === "pattern" && (
                            <span className='text-red-500 text-sm'>
                              {/* Phone number must start with 04, 1300 or 1800 */}
                              Please enter a valid phone number!
                            </span>
                          )}
                          {errors.phone?.type === "minLength" && (
                            <span className='text-red-500 text-sm'>
                              Please enter a valid phone number with the min
                              length of 10
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div>
                    <label className='text-gray-700 text-sm font-medium mb-1'>
                      Property Type
                      {/* <span className='text-red-500 text-sm'>*</span> */}
                    </label>
                    <SelectOption
                      isDisabled={propertyTypeLoading}
                      loading={propertyTypeLoading}
                      isRequired={false}
                      register={register}
                      setValue={setValue}
                      name='property_type'
                      clearErrors={clearErrors}
                      isSearch={false}
                      data={propertyTypeList}
                      selectedOption={propertyTypeList?.find(
                        (x) => x.value === watchFormData?.property_type
                      )}
                      errorMessage={
                        errors?.property_type?.type === "required"
                          ? "Field is required!!"
                          : null
                      }
                    />
                  </div>

                  <div>
                    <label className='text-gray-700 text-sm font-medium mb-1'>
                      SDA Type (Specialist Disability Accommodation)
                      {/* <span className='text-red-500 text-sm'>*</span> */}
                    </label>
                    <SelectOption
                      isDisabled={sdaTypeLoading}
                      loading={sdaTypeLoading}
                      isRequired={false}
                      register={register}
                      setValue={setValue}
                      name='sda_type'
                      clearErrors={clearErrors}
                      isSearch={false}
                      data={sdaTypeList}
                      selectedOption={sdaTypeList?.find(
                        (x) => x.value === watchFormData?.sda_type
                      )}
                      errorMessage={
                        errors?.sda_type?.type === "required"
                          ? "Field is required!!"
                          : null
                      }
                    />
                  </div>

                  {/* Participant and worker assign dropdowns */}
                  <div>
                    <label className='mb-1 block text-sm font-medium text-gray-700'>
                      Participant Assigned
                    </label>
                    <Controller
                      name={"participants"}
                      control={control}
                      defaultValue={selectedSilAccommodation?.participants}
                      render={({ field }) => {
                        return (
                          <SelectOption
                            loading={participantsListLoading}
                            isDisabled={participantsListLoading}
                            isMulti={true}
                            data={participantsList}
                            selectedOption={participantsList?.filter((x) =>
                              watchFormData?.participants?.includes(x.id)
                            )}
                            handleChange={(data) => {
                              field.onChange(data?.map((x) => x.id));
                              //   console.log("dataaaa", data);
                            }}
                          />
                        );
                      }}
                    />
                  </div>

                  <div>
                    <label className='mb-1 block text-sm font-medium text-gray-700'>
                      Workers Assigned
                    </label>
                    <Controller
                      name={"workers"}
                      control={control}
                      defaultValue={selectedSilAccommodation?.workers}
                      render={({ field }) => {
                        return (
                          <SelectOption
                            loading={workersListLoading}
                            isDisabled={workersListLoading}
                            isMulti={true}
                            data={workersList}
                            selectedOption={workersList?.filter((x) =>
                              watchFormData?.workers?.includes(x.id)
                            )}
                            handleChange={(data) => {
                              field.onChange(data?.map((x) => x.id));
                              //   console.log("dataaaa", data);
                            }}
                          />
                        );
                      }}
                    />
                  </div>

                  <div>
                    <label className='mb-1 block text-sm font-medium text-gray-700'>
                      Manager Assigned
                    </label>

                    <Controller
                      name={"managers"}
                      control={control}
                      defaultValue={selectedSilAccommodation?.managers}
                      render={({ field }) => {
                        return (
                          <SelectOption
                            loading={spManagersListLoading}
                            isDisabled={spManagersListLoading}
                            isMulti={true}
                            data={managersList}
                            selectedOption={managersList?.filter((x) =>
                              watchFormData?.managers?.includes(x.manager_id)
                            )}
                            handleChange={(data) => {
                              field.onChange(data?.map((x) => x.manager_id));
                              //   console.log("dataaaa", data);
                            }}
                          />
                        );
                      }}
                    />
                  </div>

                  <div>
                    <div className='col-span-1 sm:col-span-1'>
                      <Controller
                        name={"region_id"}
                        control={control}
                        defaultValue={selectedSilAccommodation?.region_id}
                        render={({ field }) => {
                          return (
                            <>
                              <label className='block text-sm font-medium text-gray-700 mb-1'>
                                Region
                              </label>
                              <MultiLevelDropdown
                                cssClass='h-[40px]'
                                regions={regionOps}
                                setSelectedItem={(e) => field.onChange(e)}
                                selectedItems={watchFormData?.region_id}
                                parentRegionLoader={isFetchRegionsLoader}
                              />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor='created_date'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Created Date
                    </label>
                    <div className='relative mt-1'>
                      <input
                        value={
                          selectedSilAccommodation?.created_at
                            ? formatDateIntoDMYToDisplay(
                                selectedSilAccommodation?.created_at
                              )
                            : ""
                        }
                        disabled
                        type='text'
                        name='created_date'
                        id='created_date'
                        className='did-floating-input'
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor='created_date'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Lease Start Date
                      {/* <span className='text-red-500'>*</span> */}
                    </label>
                    <div className='relative mt-1'>
                      <input
                        {...register("occupation_date", {
                          required: false,
                          value: watchFormData?.occupation_date
                            ? formatDateIntoDMYToDisplay(
                                watchFormData?.occupation_date
                              )
                            : null,
                        })}
                        type='date'
                        name='occupation_date'
                        id='occupation_date'
                        className={classNames(
                          "did-floating-input",
                          errors.occupation_date && "errorBorder"
                        )}
                      />
                      {errors.occupation_date && (
                        <span className='text-red-500 text-sm'>
                          Field is required!
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor='created_date'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Lease End Date
                    </label>
                    <div className='relative mt-1'>
                      <input
                        {...register("leaving_date", {
                          required: false,
                          value: watchFormData?.leaving_date
                            ? formatDateIntoDMYToDisplay(
                                watchFormData?.leaving_date
                              )
                            : null,
                          validate: {
                            notEarlierThanOccupation: (value) => {
                              const occupationDate = new Date(
                                watchFormData?.occupation_date
                              );
                              const leavingDate = new Date(value);
                              if (leavingDate < occupationDate) {
                                return "The Lease End Date cannot be earlier than the Lease Start Date. Please select a valid date.";
                              }
                              return true;
                            },
                          },
                        })}
                        type='date'
                        name='leaving_date'
                        id='leaving_date'
                        className={classNames(
                          "did-floating-input",
                          errors.leaving_date && "errorBorder"
                        )}
                      />
                      {errors.leaving_date && (
                        <span className='text-red-500 text-sm'>
                          {errors.leaving_date.message || "Field is required!"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='px-4 pb-5 pt-7 bg-white space-y-6 sm:p-6'>
                <h2 className='text-lg pb-2 font-bold'>Property Features</h2>

                <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                  <div className='col-span-1'>
                    <label
                      htmlFor='total_rooms'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Rooms<span className='text-red-500'>*</span>
                    </label>
                    <div className='mt-1'>
                      <input
                        placeholder='Enter number of rooms'
                        maxLength={50}
                        type='number'
                        name='total_rooms'
                        id='total_rooms'
                        className={classNames(
                          "did-floating-input",
                          errors.total_rooms && "errorBorder"
                        )}
                        {...register("total_rooms", {
                          required: true,
                          maxLength: 50,
                          validate: {
                            notNegative: (value) => {
                              if (value < 0) {
                                return "Room cannot be a negative number.";
                              }
                              return true;
                            },
                          },
                        })}
                      />
                      {errors.total_rooms && (
                        <span className='text-red-500 text-sm'>
                          {errors.total_rooms.message || "Field is required!"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='col-span-1'>
                    <label
                      htmlFor='total_shared_spaces'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Shared Spaces
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        placeholder='Enter number of shared spaces'
                        type='number'
                        name='total_shared_spaces'
                        id='total_shared_spaces'
                        className={classNames(
                          "did-floating-input",
                          errors.total_shared_spaces && "errorBorder"
                        )}
                        {...register("total_shared_spaces", {
                          required: false,
                          maxLength: 50,
                          validate: {
                            notNegative: (value) => {
                              if (value < 0) {
                                return "Shared Space cannot be a negative number.";
                              }
                              return true;
                            },
                          },
                        })}
                      />
                      {errors.total_shared_spaces && (
                        <span className='text-red-500 text-sm'>
                          {errors.total_shared_spaces.message ||
                            "Field is required!"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='col-span-1'>
                    <label
                      htmlFor='total_beds'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Beds
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='number'
                        name='total_beds'
                        id='total_beds'
                        placeholder='Enter number of Beds'
                        className={classNames(
                          "did-floating-input",
                          errors.total_beds && "errorBorder"
                        )}
                        {...register("total_beds", {
                          required: false,
                          maxLength: 50,
                          validate: {
                            notNegative: (value) => {
                              if (value < 0) {
                                return "Bed cannot be a negative number.";
                              }
                              return true;
                            },
                          },
                        })}
                      />
                      {errors.total_beds && (
                        <span className='text-red-500 text-sm'>
                          {errors.total_beds.message || "Field is required!"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='col-span-1'>
                    <label
                      htmlFor='total_kitchens'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Kitchens
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='number'
                        name='total_kitchens'
                        id='total_kitchens'
                        placeholder='Enter number of kitchens'
                        className={classNames(
                          "did-floating-input",
                          errors.total_kitchens && "errorBorder"
                        )}
                        {...register("total_kitchens", {
                          required: false,
                          maxLength: 50,
                          validate: {
                            notNegative: (value) => {
                              if (value < 0) {
                                return "Kitchen cannot be a negative number.";
                              }
                              return true;
                            },
                          },
                        })}
                      />
                      {errors.total_kitchens && (
                        <span className='text-red-500 text-sm'>
                          {errors.total_kitchens.message ||
                            "Field is required!"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='col-span-1'>
                    <label
                      htmlFor='total_parking'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Car Parking Spaces
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='number'
                        placeholder='Enter number of car parking spaces'
                        name='total_parking'
                        id='total_parking'
                        className={classNames(
                          "did-floating-input",
                          errors.total_parking && "errorBorder"
                        )}
                        {...register("total_parking", {
                          required: false,
                          maxLength: 50,
                          validate: {
                            notNegative: (value) => {
                              if (value < 0) {
                                return "Parrking Space cannot be a negative number.";
                              }
                              return true;
                            },
                          },
                        })}
                      />
                      {errors.total_parking && (
                        <span className='text-red-500 text-sm'>
                          {errors.total_parking.message || "Field is required!"}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='col-span-1'>
                    <label
                      htmlFor='total_bath_rooms'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Bathrooms
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        placeholder='Enter number of Bathrooms'
                        type='number'
                        name='total_bath_rooms'
                        id='total_bath_rooms'
                        className={classNames(
                          "did-floating-input",
                          errors.total_bath_rooms && "errorBorder"
                        )}
                        {...register("total_bath_rooms", {
                          required: false,
                          maxLength: 50,
                          validate: {
                            notNegative: (value) => {
                              if (value < 0) {
                                return "Bath room cannot be a negative number.";
                              }
                              return true;
                            },
                          },
                        })}
                      />
                      {errors.total_bath_rooms && (
                        <span className='text-red-500 text-sm'>
                          {errors.total_bath_rooms.message ||
                            "Field is required!"}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='col-span-1'>
                    <label
                      htmlFor='door_size'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Door Size
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='text'
                        name='door_size'
                        id='door_size'
                        className={classNames(
                          "did-floating-input",
                          errors.door_size && "errorBorder"
                        )}
                        {...register("door_size", {
                          required: false,
                          maxLength: 50,
                          // validate: {
                          //   notNegative: (value) => {
                          //     if (value < 0) {
                          //       return "Door Size cannot be a negative number.";
                          //     }
                          //     return true;
                          //   },
                          // },
                        })}
                      />
                      {errors.door_size && (
                        <span className='text-red-500 text-sm'>
                          Field is required!
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='col-span-1'>
                    <label
                      htmlFor='building_floor'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Stories
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='number'
                        placeholder='Enter number of Stories'
                        name='building_floor'
                        id='building_floor'
                        className={classNames(
                          "did-floating-input",
                          errors.building_floor && "errorBorder"
                        )}
                        {...register("building_floor", {
                          required: false,
                          maxLength: 50,
                          validate: {
                            notNegative: (value) => {
                              if (value < 0) {
                                return "Stories cannot be a negative number.";
                              }
                              return true;
                            },
                          },
                        })}
                      />
                      {errors.building_floor && (
                        <span className='text-red-500 text-sm'>
                          {errors.building_floor.message ||
                            "Field is required!"}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='col-span-1'>
                    <div className=''>
                      <label className='block text-sm font-medium text-gray-700 mb-1'>
                        Escalator
                        {/* <span className='text-red-500'>*</span> */}
                      </label>
                      <SelectOption
                        cssClass='truncate'
                        isRequired={false}
                        register={register}
                        setValue={setValue}
                        name='escalator'
                        isSearch={false}
                        data={portalOption}
                        selectedOption={portalOption?.find(
                          (x) => watchFormData?.escalator === x.value
                        )}
                        errorMessage={
                          errors?.escalator?.type === "required" ? (
                            <div className='text-sm text-red-500'>
                              Field is required!!
                            </div>
                          ) : null
                        }
                      />
                    </div>
                  </div>

                  <div className='col-span-1'>
                    <div className=''>
                      <label className='block text-sm font-medium text-gray-700 mb-1'>
                        Air Conditioning
                      </label>
                      <SelectOption
                        cssClass='truncate'
                        isRequired={false}
                        register={register}
                        setValue={setValue}
                        name='air_conditioning'
                        isSearch={false}
                        data={portalOption}
                        selectedOption={portalOption?.find(
                          (x) => watchFormData?.air_conditioning === x.value
                        )}
                        errorMessage={
                          errors?.air_conditioning?.type === "required" ? (
                            <div className='text-sm text-red-500'>
                              Field is required!!
                            </div>
                          ) : null
                        }
                      />
                    </div>
                  </div>

                  <div className='col-span-1'>
                    <div className=''>
                      <label className='block text-sm font-medium text-gray-700 mb-1'>
                        Dishwasher
                      </label>
                      <SelectOption
                        cssClass='truncate'
                        isRequired={false}
                        register={register}
                        setValue={setValue}
                        name='dishwasher'
                        isSearch={false}
                        data={portalOption}
                        selectedOption={portalOption?.find(
                          (x) => watchFormData?.dishwasher === x.value
                        )}
                        errorMessage={
                          errors?.dishwasher?.type === "required" ? (
                            <div className='text-sm text-red-500'>
                              Field is required!!
                            </div>
                          ) : null
                        }
                      />
                    </div>
                  </div>

                  <div className='col-span-1'>
                    <label
                      htmlFor='outdoor'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Outdoor
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='text'
                        name='outdoor'
                        id='outdoor'
                        className={classNames(
                          "did-floating-input",
                          errors.outdoor && "errorBorder"
                        )}
                        {...register("outdoor", {
                          required: false,
                          maxLength: 50,
                        })}
                      />
                      {errors.outdoor && (
                        <span className='text-red-500 text-sm'>
                          Field is required!
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='col-span-1'>
                    <label
                      htmlFor='other_details'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Others
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='text'
                        name='other_details'
                        id='other_details'
                        className={classNames(
                          "did-floating-input",
                          errors.other_details && "errorBorder"
                        )}
                        {...register("other_details", {
                          required: false,
                          maxLength: 50,
                        })}
                      />
                      {errors.other_details && (
                        <span className='text-red-500 text-sm'>
                          Field is required!
                        </span>
                      )}
                    </div>
                  </div>
                  <div />
                  <div className='col-span-2 pt-2'>
                    <div className='grid sm:grid-cols-6 grid-cols-3 gap-x-3'>
                      <div>
                        <Controller
                          name={"front_yard"}
                          control={control}
                          defaultValue={watchFormData?.front_yard}
                          render={({ field }) => {
                            return (
                              <div className='flex justify-start items-center space-x-2'>
                                <CheckBox
                                  checked={watchFormData?.front_yard}
                                  cssClass='border-gray-500 rounded-sm'
                                  handleChange={(value) => {
                                    field.onChange(value);
                                  }}
                                />
                                <label className='block text-sm font-medium text-gray-700'>
                                  Front Yard
                                </label>
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div>
                        <Controller
                          name={"back_yard"}
                          control={control}
                          defaultValue={watchFormData?.back_yard}
                          render={({ field }) => {
                            return (
                              <div className='flex justify-start items-center space-x-2'>
                                <CheckBox
                                  checked={watchFormData?.back_yard}
                                  handleChange={(value) => {
                                    field.onChange(value);
                                  }}
                                  cssClass='border-gray-500 rounded-sm'
                                />
                                <label className='block text-sm font-medium text-gray-700'>
                                  Backyard
                                </label>
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div>
                        <Controller
                          name={"swimming_pool"}
                          control={control}
                          defaultValue={watchFormData?.swimming_pool}
                          render={({ field }) => {
                            return (
                              <div className='flex justify-start items-center space-x-2'>
                                <CheckBox
                                  checked={watchFormData?.swimming_pool}
                                  handleChange={(value) => {
                                    field.onChange(value);
                                  }}
                                  cssClass='border-gray-500 rounded-sm'
                                />
                                <label className='block text-sm font-medium text-gray-700'>
                                  Swimming Pool
                                </label>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='px-4 pb-5 pt-7 bg-white space-y-6 sm:p-6'>
                <h2 className='text-lg pb-2 font-bold'>
                  Real Estate Information
                </h2>

                <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                  <div className='col-span-1'>
                    <label
                      htmlFor='agency_agent_name'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Full Name
                      {/* <span className='text-red-500'>*</span> */}
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='text'
                        name='agency_agent_name'
                        id='agency_agent_name'
                        className={classNames(
                          "did-floating-input",
                          errors.agency_agent_name && "errorBorder"
                        )}
                        {...register("agency_agent_name", {
                          required: false,
                          maxLength: 50,
                        })}
                      />
                      {errors.agency_agent_name && (
                        <span className='text-red-500 text-sm'>
                          Field is required!
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='col-span-1'>
                    <label
                      htmlFor='agency_agent_phone'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Phone Number
                      {/* <span className='text-red-500'>*</span> */}
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='number'
                        name='agency_agent_phone'
                        id='agency_agent_phone'
                        className={classNames(
                          "did-floating-input",
                          errors.agency_agent_phone && "errorBorder"
                        )}
                        {...register("agency_agent_phone", {
                          required: false,
                          maxLength: 50,
                        })}
                      />
                      {errors.agency_agent_phone && (
                        <span className='text-red-500 text-sm'>
                          Field is required!
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='col-span-1'>
                    <label
                      htmlFor='agency_agent_email'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Email Address
                      {/* <span className='text-red-500'>*</span> */}
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='text'
                        name='agency_agent_email'
                        id='agency_agent_email'
                        className={classNames(
                          "did-floating-input",
                          errors.agency_agent_email && "errorBorder"
                        )}
                        {...register("agency_agent_email", {
                          required: false,
                          maxLength: 50,
                          pattern: EMAIL_REGEX_EXP,
                        })}
                      />
                      {errors?.agency_agent_email?.type === "required" ? (
                        <span className='text-red-500 text-sm'>
                          Field is required!
                        </span>
                      ) : (
                        errors?.agency_agent_email?.type === "pattern" && (
                          <span className='text-red-500 text-sm'>
                            Invalid email address
                          </span>
                        )
                      )}
                    </div>
                  </div>
                  {/* <div className='col-span-1'>
                  <label
                    htmlFor='agency_agent_license_number'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Agent license Number
                  </label>
                  <div className='mt-1'>
                    <input
                      maxLength={50}
                      type='number'
                      name='agency_agent_license_number'
                      id='agency_agent_license_number'
                      className='did-floating-input'
                      {...register("agency_agent_license_number", {
                        required: true,
                        maxLength: 50,
                      })}
                    />
                    {errors.agency_agent_license_number && (
                      <span className='text-red-500 text-sm'>
                        Field is required!
                      </span>
                    )}
                  </div>
                </div> */}

                  {/* <div className='col-span-1'>
                    <label
                      htmlFor='agency_team_leader_name'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Property Team Leader Name
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='text'
                        name='agency_team_leader_name'
                        id='agency_team_leader_name'
                        className={classNames(
                          "did-floating-input",
                          errors.agency_team_leader_name && "errorBorder"
                        )}
                        {...register("agency_team_leader_name", {
                          required: false,
                          maxLength: 50,
                        })}
                      />
                      {errors.agency_team_leader_name && (
                        <span className='text-red-500 text-sm'>
                          Field is required!
                        </span>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>

              <div className='px-4 pb-5 pt-7 bg-white space-y-6 sm:p-6'>
                <h2 className='text-lg pb-2 font-bold'>Agency Address</h2>
                <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                  <div className='col-span-2'>
                    <label
                      htmlFor='agency_name'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Name
                      {/* <span className='text-red-500'>*</span> */}
                    </label>
                    <div className='mt-1'>
                      <input
                        maxLength={50}
                        type='text'
                        name='agency_name'
                        id='agency_name'
                        className={classNames(
                          "did-floating-input",
                          errors.agency_name && "errorBorder"
                        )}
                        {...register("agency_name", {
                          required: false,
                          maxLength: 50,
                        })}
                      />
                      {errors.agency_name && (
                        <span className='text-red-500 text-sm'>
                          Field is required!
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='col-span-2'>
                    <AddressAutoComplete
                      customNames={{
                        street_address: "agency_street_address",
                        suburb: "agency_suburb",
                        state: "agency_state",
                        postcode: "agency_postal_code",
                      }}
                      addressId={"agency_street_address"}
                      google={window?.google}
                      errors={errors}
                      // isStateName={true}
                    >
                      <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                        <div>
                          <label
                            htmlFor='agency_street_address'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Street Address
                          </label>
                          <div className='relative mt-1'>
                            <input
                              type='text'
                              name='agency_street_address'
                              id='agency_street_address'
                              className={classNames(
                                "did-floating-input",
                                errors.agency_street_address && "errorBorder"
                              )}
                              {...register("agency_street_address", {
                                required: false,
                                maxLength: 254,
                              })}
                            />
                            {errors.agency_street_address && (
                              <span className='text-red-500 text-sm'>
                                Field is required!
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor='agency_suburb'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Suburb
                          </label>
                          <div className='relative mt-1'>
                            <input
                              type='text'
                              name='agency_suburb'
                              id='agency_suburb'
                              className={classNames(
                                "did-floating-input",
                                errors.agency_suburb && "errorBorder"
                              )}
                              {...register("agency_suburb", {
                                required: false,
                                maxLength: 254,
                              })}
                            />
                            {errors.agency_suburb && (
                              <span className='text-red-500 text-sm'>
                                Field is required!
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor='agency_state'
                            className='block text-sm font-medium text-gray-700'
                          >
                            State
                          </label>
                          <div className='relative mt-1'>
                            {/* <input
                              type='text'
                              name='agency_state'
                              id='agency_state'
                              className={classNames(
                                "did-floating-input",
                                errors.agency_state && "errorBorder"
                              )}
                              {...register("agency_state", {
                                required: false,
                                maxLength: 254,
                                // value: allCatStates?.find(
                                //   (x) => x.value === watchFormData?.agency_state
                                // )?.label,
                              })}
                            />
                            {errors.agency_state && (
                              <span className='text-red-500 text-sm'>
                                Field is required!
                              </span>
                            )} */}
                            <SelectOption
                              altName='google_state_id'
                              isDisabled={catalogSubCategoryStatesLoading}
                              loading={catalogSubCategoryStatesLoading}
                              cssClass='truncate'
                              isRequired={false}
                              register={register}
                              setValue={setValue}
                              name='agency_state'
                              isSearch={false}
                              data={allCatStates}
                              selectedOption={allCatStates?.find(
                                (x) =>
                                  x.value ===
                                  Number(watchFormData?.agency_state)
                              )}
                              errorMessage={
                                errors?.agency_state?.type === "required" ? (
                                  <div className='text-sm text-red-500'>
                                    Field is required!!
                                  </div>
                                ) : null
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor='agency_postal_code'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Postcode
                          </label>
                          <div className='relative mt-1'>
                            <input
                              type='number'
                              name='agency_postal_code'
                              id='agency_postal_code'
                              minLength={4}
                              maxLength={4}
                              className={classNames(
                                "did-floating-input",
                                errors.agency_postal_code && "errorBorder"
                              )}
                              {...register("agency_postal_code", {
                                required: false,
                                maxLength: 4,
                                minLength: 4,
                                onChange: (e) => {
                                  e.target.value = e.target.value
                                    .toString()
                                    .slice(0, 4);
                                },
                              })}
                            />
                            {errors?.agency_postal_code?.type ===
                            "maxLength" ? (
                              <span className='text-red-500 text-sm'>
                                Postcode must be 4 digits
                              </span>
                            ) : errors?.agency_postal_code?.type ===
                              "minLength" ? (
                              <span className='text-red-500 text-sm'>
                                Postcode must be 4 digits
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </AddressAutoComplete>
                  </div>
                </div>
              </div>

              <div className='px-4 py-5 bg-white space-y-6 sm:p-6 '>
                <div className='flex justify-between'>
                  <Controller
                    name={"status"}
                    control={control}
                    defaultValue={selectedSilAccommodation?.status}
                    render={({ field }) => {
                      return (
                        <SwitchInput
                          {...field}
                          handleChange={(e) => {
                            field.onChange(e);
                          }}
                          checked={field.value}
                          label='Is active'
                          subLabel='is the Accommodation active'
                        />
                      );
                    }}
                  />

                  <CustomButton
                    showLoading={silAccommodationUpdateLoading}
                    label='Save Changes'
                    isDisabled={
                      silAccommodationUpdateLoading ||
                      participantsListLoading ||
                      catalogSubCategoryStatesLoading ||
                      propertyTypeLoading ||
                      sdaTypeLoading ||
                      spManagersListLoading
                    }
                    type='submit'
                  />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      {/* )} */}
    </div>
  );
};

export default SilAccommodationSettings;
