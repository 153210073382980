import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const GoogleMap = ({ google, address, zoom = 15 }) => {
  const [addressCoordinates, setAddressCoordinates] = useState(null);
  useEffect(() => {
    const getCoordinatesFromAddress = async () => {
      if (address) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === "OK" && results && results[0]) {
            const { lat, lng } = results[0].geometry.location;
            setAddressCoordinates({ lat: lat(), lng: lng() });
          } else {
            console.error("Error fetching coordinates from address");
          }
        });
      }
    };

    getCoordinatesFromAddress();
  }, [address, google]);

  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  const bounds = new google.maps.LatLngBounds();
  if (bounds) {
    if (addressCoordinates?.lat && addressCoordinates?.lng) {
      bounds.extend(
        new google.maps.LatLng(addressCoordinates.lat, addressCoordinates.lng)
      );
    }
  }

  return (
    <div style={{ position: "relative", width: "100%", height: "50vh" }}>
      <Map
        google={google}
        style={mapStyles}
        initialCenter={{
          lat: addressCoordinates?.lat || 0,
          lng: addressCoordinates?.lng || 0,
        }}
        zoom={zoom}
        center={addressCoordinates || { lat: 0, lng: 0 }}
        onReady={(mapProps, map) => {
          if (addressCoordinates) {
            map.fitBounds(bounds);
          }
        }}
      >
        {addressCoordinates && (
          <Marker
            position={addressCoordinates}
            title={`Location: ${address}`}
          />
        )}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(GoogleMap);
