import React from "react";
import doubleBedIcon from "assets/sil-homes/double-bed.png";
import bathRoomIcon from "assets/sil-homes/bathroom.png";
import transportIcon from "assets/sil-homes/transport.png";
import areaIcon from "assets/sil-homes/area.png";
import houseIcon from "assets/sil-homes/house.png";
import frontYardIcon from "assets/sil-homes/front-yard.png";
import backYardIcon from "assets/sil-homes/backyard.png";
import swimmingPoolIcon from "assets/sil-homes/swimming-pool.png";
import { parseBooleanVal, SilHomeselectedTabState } from "Utils/utils";
import { FiArrowUpRight } from "react-icons/fi";
import { roleAccess } from "helpers/RolesPermission";
import { USER_ROLE } from "Constants/constant";
import { HiOutlineMail, HiPhone } from "react-icons/hi";

const PropertyAddressSummary = ({
  selectedAccommodationInfo,
  setToggleState,
}) => {
  const PropertyIconWithText = ({ iconName, text }) => {
    const getIcon = (type = "icon") => {
      switch (iconName) {
        case "double-bed":
          return type === "icon" ? doubleBedIcon : "h-6 w-6";
        case "bathroom":
          return type === "icon" ? bathRoomIcon : "h-6 w-6";
        case "car":
          return type === "icon" ? transportIcon : "h-8 w-8";
        case "area":
          return type === "icon" ? areaIcon : "h-6 w-6";
        case "house":
          return type === "icon" ? houseIcon : "h-6 w-6";
        case "swimmingPool":
          return type === "icon" ? swimmingPoolIcon : "h-8 w-8";
        case "frontYard":
          return type === "icon" ? frontYardIcon : "h-7 w-7";
        case "backYard":
          return type === "icon" ? backYardIcon : "h-8 w-8";
        default:
          return type === "icon" ? swimmingPoolIcon : "h-8 w-8";
        // break;
      }
    };
    return (
      <span className='flex space-x-2 items-end'>
        <img className={getIcon("size")} src={getIcon()} alt={`${iconName}`} />
        <span className='flex items-end'>
          <p className='text-sm text-gray-500'>{text}</p>
        </span>
      </span>
    );
  };

  const handleTabs = () => {
    setToggleState
      ? setToggleState({
          name: "Settings",
          id: "settings",
          children: [],
        })
      : SilHomeselectedTabState.save({
          name: "Settings",
          id: "settings",
          children: [],
        });
    // navigate(`/sil-accommodation/${selectedAccommodationInfo.uuid}`);
  };

  const getValue = () => (
    <>
      {roleAccess([USER_ROLE.Sp_Standard_User]) ? (
        <dd className='mt-1 text-sm text-gray-900 flex items-center space-x-2'>
          <span className='print:text-red-500 text-red-500'>
            No Data found!
          </span>
        </dd>
      ) : (
        <dd
          onClick={() => {
            if (
              // roleAccess(
              //   [],
              //   PERMISSION_KEYS.PARTICIPANT,
              //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
              // ) &&
              // roleAccess(
              //   [],
              //   PERMISSION_KEYS.PARTICIPANT,
              //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
              // )
              true
            ) {
              handleTabs();
            }
          }}
          className={`${
            // roleAccess(
            //   [],
            //   PERMISSION_KEYS.PARTICIPANT,
            //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
            // ) &&
            // roleAccess(
            //   [],
            //   PERMISSION_KEYS.PARTICIPANT,
            //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
            // ) &&
            "cursor-pointer"
          }  mt-1 text-sm text-gray-900 flex items-center space-x-2`}
        >
          <span className={`${""} break-all print:text-black text-gray-600`}>
            {
              // roleAccess(
              //   [],
              //   PERMISSION_KEYS.PARTICIPANT,
              //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
              // ) &&
              // roleAccess(
              //   [],
              //   PERMISSION_KEYS.PARTICIPANT,
              //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
              // )
              true ? "Add information" : "No Data Found!"
            }
          </span>
          {
            // roleAccess(
            //   [],
            //   PERMISSION_KEYS.PARTICIPANT,
            //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
            // ) &&
            //   roleAccess(
            //     [],
            //     PERMISSION_KEYS.PARTICIPANT,
            //     PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
            //   )
            true && (
              <span className={`${""}`}>
                <FiArrowUpRight />
              </span>
            )
          }
        </dd>
      )}
    </>
  );

  // console.log("selectedAccommodationInfo", selectedAccommodationInfo);
  return (
    <div className='pt-0 pr-12 h-full relative'>
      <div>
        <div className='flex flex-col'>
          {selectedAccommodationInfo?.region?.name && (
            <span className='text-md text-black'>
              Region:{" "}
              <span className='text-gray-600'>
                {selectedAccommodationInfo?.region?.name}
              </span>
            </span>
          )}
          {/* <span className='text-xl font-semibold text-black'>
            {`${capitalizeFirstLetter(
              selectedAccommodationInfo?.street_address
            )}, ${capitalizeFirstLetter(selectedAccommodationInfo?.suburb)}, ${
              selectedAccommodationInfo?.stateObj?.name?.toUpperCase() || ""
            } ${selectedAccommodationInfo?.postal_code}`}
          </span> */}
          {selectedAccommodationInfo?.email && (
            <span className='pt-1 text-gray-500 flex justify-start space-x-2 items-center'>
              <HiOutlineMail />
              <span>{selectedAccommodationInfo?.email}</span>
            </span>
          )}
          {selectedAccommodationInfo?.phone && (
            <span className='pt-1 text-gray-500 flex justify-start space-x-2 items-center'>
              <HiPhone />
              <span>{selectedAccommodationInfo?.phone}</span>
            </span>
          )}
        </div>
        <div className='py-3 grid grid-cols-4 gap-x-3'>
          {selectedAccommodationInfo?.total_rooms && (
            <PropertyIconWithText
              iconName='double-bed'
              text={selectedAccommodationInfo?.total_rooms}
            />
          )}
          {selectedAccommodationInfo?.total_bath_rooms && (
            <PropertyIconWithText
              iconName='bathroom'
              text={selectedAccommodationInfo?.total_bath_rooms}
            />
          )}
          {selectedAccommodationInfo?.total_parking && (
            <PropertyIconWithText
              iconName='car'
              text={selectedAccommodationInfo?.total_parking}
            />
          )}
          {/* <PropertyIconWithText
            iconName='area'
            text={`${selectedAccommodationInfo?.land_size || 0}m²`}
          /> */}
          {selectedAccommodationInfo?.property_type_name && (
            <PropertyIconWithText
              iconName='house'
              text={selectedAccommodationInfo?.property_type_name}
            />
          )}
        </div>
        <div className='flex items-end space-x-2'>
          {parseBooleanVal(selectedAccommodationInfo?.swimming_pool) && (
            <PropertyIconWithText
              iconName='swimmingPool'
              text='Swimming Pool'
            />
          )}
          {parseBooleanVal(selectedAccommodationInfo?.front_yard) && (
            <PropertyIconWithText iconName='frontYard' text='Front Yard' />
          )}
          {parseBooleanVal(selectedAccommodationInfo?.back_yard) && (
            <PropertyIconWithText iconName='backYard' text='Backyard' />
          )}
        </div>
        <div className={`pt-4 pt-3`}>
          <h2 className={`text-lg leading-6 font-medium print:text-black`}>
            Quick Snapshot
          </h2>
          {selectedAccommodationInfo?.quick_snapshot ? (
            <>
              <p
                className={`${"max-h-[180px] overflow-y-auto custom-scroll"} mt-1 max-w-5xl text-sm break-words w-[430px] print:text-black`}
              >
                {selectedAccommodationInfo?.quick_snapshot}
              </p>
            </>
          ) : (
            <>{getValue()}</>
          )}
        </div>
      </div>

      <div className='absolute bottom-0 font-medium'>
        {/* <span className='cursor-pointer hover:text-blue-500 hover:underline'>
          Floorplan as a link
        </span> */}
      </div>
    </div>
  );
};

export default PropertyAddressSummary;
