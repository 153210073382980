import DocumentsPage from "./DocumentsPage";
import { connect } from "react-redux";
import {
  createFolderRequest,
  updateFolderRequest,
  removeFolderRequest,
  removeFileRequest,
  getFolderListForHRMRequest,
  setUpdatedFile,
  handleDynamicBreadCrumbForHRMRequest,
} from "store/actions/cos.actions";
import {
  getFolderListForSilRequest,
  silHomeDocumentCreateFolderRequest,
  silHomeDocumentRemoveFileRequest,
  silHomeDocumentRemoveFolderRequest,
  silHomeDocumentUpdateFolderRequest,
  silHomeDocumentUploadFileRequest,
} from "store/actions/sil.actions";
import {
  createNewFolderLoadingSelector,
  createNewFolderSuccessSelector,
  DynamicBreadCrumbSelector,
  getFolderListForHRMLoadingSelector,
  getFolderListForHRMSelector,
  getFileListForHRMSelector,
  getRemoveFolder,
  getRemoveFolderLoading,
  getRemoveFolderError,
} from "store/selector/cos.selector";
import {
  getSilhomeDocumentsListLoader,
  getSilHomeDocumentFolderList,
  getSilHomeDocumentFileList,
  getSilHomeDocumentFolderCreateLoading,
  getSilHomeDocumentFolderUpdateLoading,
} from "store/selector/sil.selector";

const mapDispatchToProps = {
  createFolderRequest,
  updateFolderRequest,
  setUpdatedFile,
  removeFolderRequest,
  removeFileRequest,
  getFolderListForHRMRequest,
  handleDynamicBreadCrumbForHRMRequest,

  //Sil Requests
  getFolderListForSilRequest,
  silHomeDocumentCreateFolderRequest,
  silHomeDocumentUpdateFolderRequest,
  silHomeDocumentUploadFileRequest,
  silHomeDocumentRemoveFileRequest,
  silHomeDocumentRemoveFolderRequest,
};

const mapStateToProps = (state) => {
  const getFolderListForHRM = getFolderListForHRMSelector(state);
  const getFileListForHRM = getFileListForHRMSelector(state);
  const DynamicBreadCrumb = DynamicBreadCrumbSelector(state);
  const createNewFolderLoading = createNewFolderLoadingSelector(state);
  const createNewFolderSuccess = createNewFolderSuccessSelector(state);
  const getFolderListForHRMLoading = getFolderListForHRMLoadingSelector(state);
  const removeFolderLoading = getRemoveFolderLoading(state);
  const removeFolderSuccess = getRemoveFolder(state);
  const removeFolderError = getRemoveFolderError(state);

  // Sil Docs Selectors
  const silhomeDocumentsListLoader = getSilhomeDocumentsListLoader(state);
  const silHomeDocumentFolderList = getSilHomeDocumentFolderList(state);
  const silHomeDocumentFileList = getSilHomeDocumentFileList(state);

  const silHomeDocumentFolderCreateLoader =
    getSilHomeDocumentFolderCreateLoading(state);

  const silHomeDocumentFolderUpdateLoader =
    getSilHomeDocumentFolderUpdateLoading(state);

  return {
    DynamicBreadCrumb,
    FolderListForHRM: getFolderListForHRM,
    FileListForHRM: getFileListForHRM,
    createNewFolderLoading,
    createNewFolderSuccess,
    getFolderListForHRMLoading,
    removeFolderLoading,
    removeFolderSuccess,
    removeFolderError,

    //Sil Docs
    silhomeDocumentsListLoader,
    silHomeDocumentFolderList,
    silHomeDocumentFileList,
    silHomeDocumentFolderCreateLoader,
    silHomeDocumentFolderUpdateLoader,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);
