import React, { useEffect, useState } from "react";
import CustomButton from "components/common/Button/CustomButton";
import FileUploadInput from "components/common/FileUpload/FileUploadInput";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { USER_ROLE } from "Constants/constant";
import { apiGetRequest } from "helpers/Requests";
import { roleAccess } from "helpers/RolesPermission";
import { toast } from "react-toastify";
import { apiCall } from "store/services/service";
import { BASE_URL } from "store/services/URL";
import { getUrlRole } from "Utils/utils";
import Select from "../../../../../components/common/Select/Select";
import { connect } from "react-redux";
import { getUser } from "../../../../../store/selector/auth.selector";

const UploadNewFileModel = (props) => {
  const {
    role,
    handleClose,
    setUpdatedFile,
    isOpen,
    workerId,
    parentId,
    currentLoginUser,
    isUserGracePeriodOver,
    // documentList,
  } = props;

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [isFileUploadToS3, setIsFileUploadToS3] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileTypeOptions, setFileTypeOptions] = useState([]);
  const [visibility, setVisibility] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const userType = localStorage.getItem("userType");
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [isDocTypeLoader, setIsDocTypeLoader] = useState(false);

  useEffect(() => {
    getFileTypes();
    if (role === "sil") {
      setSelectedFileType({ label: "Sil Documents", value: 2 });
    }
  }, []);

  const modalFooter = () => (
    <>
      <CustomButton
        label='Cancel'
        variant='secondary'
        clickHandler={() => {
          handleClear();
          setFile("");
          setSelectedDocument("");
          setShowInput(false);
          setFileName("");
          handleClose(false);
        }}
      />
      <CustomButton
        showLoading={isLoading}
        isDisabled={
          (file == null || file === "" || selectedFileType === null
            ? true
            : isLoading) || isUserGracePeriodOver
        }
        label='Upload'
        variant='primary'
        clickHandler={handleSubmitForFile}
      />
    </>
  );

  const handleFileUploadBaseVapour = async (data) => {
    Object.assign(data, {
      name: fileName,
      file_type: data.ext,
      document_type_id: selectedFileType?.value,
      with_expiry: showInput,
      label: "",
      note: "xyz",
      expiry_time: showInput ? expiryDate : null,
      folder_structure_id: parentId,
      visibility: visibility,
    });

    if (role === "sil") {
      Object.assign(data, {
        sil_home_id: workerId,
      });
    } else {
      Object.assign(data, {
        worker_id: workerId,
      });
    }

    role === "participant" &&
      userType === "sp_admin" &&
      Object.assign(data, {
        visibility: visibility,
      });

    const Role = roleAccess([USER_ROLE.Sp_Standard_User]) ? "hrm" : "sp";
    const URL = `${BASE_URL + Role + "/file-management/upload-document"}`;
    const URLNew = `${
      BASE_URL + `${Role}/file-management/participant/upload-document`
    }`;
    if (role === "participant") {
      data = { ...data, pt_id: data.worker_id };
    }

    if (role === "sil") {
      setUpdatedFile(data, (response, error) => {
        if (error) {
          setIsLoading(false);
          toast.error("Something Went Wrong!");
          return;
        }
        if (response) {
          handleFinish(response);
          setShowInput(false);
        }
      });
    } else {
      apiCall
        .post({
          url: role === "participant" ? URLNew : URL,
          params: data,
          isAuthToken: currentLoginUser?.AccessToken,
        })

        .then((response) => {
          handleFinish(response);
          // handleClose(true);
          setShowInput(false);
        })
        .catch((e) => {
          console.log("e", e);
          setIsLoading(false);
          toast.error("Something Went Wrong!");
        });
    }
  };

  const handleClear = () => {
    setVisibility(true);
    setSelectedFileType("");
    setFile("");
    setFileName("");
  };

  const handleFinish = (data) => {
    if (data) {
      if (role !== "sil" && data.data && data.data.file_type) {
        setUpdatedFile({ data });
      }
      handleClose(false);
      handleClear();
      setFile("");
      setSelectedDocument("");
      setFileName("");
    }
    setVisibility(true);
    handleClear();
    setIsLoading(false);
    setIsFileUploadToS3(false);
    if (role !== "sil") {
      toast.success("Document uploaded successfully");
    }
  };

  const handleError = () => {
    setIsLoading(false);
  };

  const handleSubmitForFile = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    setIsLoading(true);
    setIsFileUploadToS3(true);
  };

  const getFileTypes = async () => {
    try {
      setIsDocTypeLoader(true);
      const res = await apiGetRequest(`${getUrlRole()}/doc-types`);
      if (res.status === 200) {
        const options = res.data.doc_types.map((doc) => {
          const obj = {
            label: doc.name,
            value: doc.id,
          };
          return obj;
        });
        setFileTypeOptions([...options]);
      }
      setIsDocTypeLoader(false);
    } catch (err) {
      console.log("err", err);
      setIsDocTypeLoader(false);
    }
  };

  return (
    <>
      <ModalSkeleton
        title='Upload New File'
        cssClass='w-[35%]'
        modalFooter={modalFooter()}
        isOpen={isOpen}
        closeModal={() => {
          setVisibility(true);
          handleClose(false);
          handleClear();
          setShowInput(false);
          setFile("");
          setSelectedDocument("");
          setFileName("");
        }}
      >
        <div className='flex flex-col'>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-2'>
              <div className='space-y-4'>
                <div>
                  <label
                    htmlFor='first_name'
                    className='text-start did-floating-label'
                  >
                    File Name
                  </label>
                  <input
                    type='text'
                    onChange={(e) => setFileName(e.target.value)}
                    className='did-floating-input capitalize'
                  />
                </div>
                {role !== "sil" && (
                  <div className='mt-4 flex items-start justify-start space-x-2'>
                    <div className='did-floating-label-content w-full'>
                      <label
                        htmlFor='interview-questions'
                        className=' text-start did-floating-label'
                      >
                        Category
                      </label>
                      <Select
                        isDisabled={isDocTypeLoader}
                        loading={isDocTypeLoader}
                        cssClass='z-20'
                        isSearch={false}
                        data={fileTypeOptions}
                        selectedOption={selectedFileType}
                        handleChange={(e) => setSelectedFileType(e)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {role === "participant" && (
              <div className='col-span-2 space-x-4'>
                <labe className=' text-start did-floating-label'>
                  Show to Worker
                </labe>
                <input
                  value={visibility}
                  type='checkbox'
                  onClick={() => setVisibility(!visibility)}
                  className='rounded '
                />
              </div>
            )}
            <div className='col-span-2 space-x-4'>
              <labe className=' text-start did-floating-label'>
                Set Expiry Date
              </labe>
              <input
                type='checkbox'
                onClick={() => setShowInput(!showInput)}
                className='rounded '
              />
            </div>

            {showInput && (
              <div className='col-span-2'>
                <label
                  htmlFor='expiry_date'
                  className='text-start did-floating-label'
                >
                  Expiry Date
                </label>
                <input
                  onChange={(e) => setExpiryDate(e.target.value)}
                  name='expiry_date'
                  id='expiry_date'
                  type='date'
                  min={new Date().toISOString().split("T")[0]}
                  className='did-floating-input'
                />
              </div>
            )}

            {!selectedDocument && (
              <div className='col-span-2 mt-4'>
                <FileUploadInput
                  type='any'
                  handleFile={(e) => setFile(e)}
                  size='10'
                  file={file}
                  handleDelete={() => setFile("")}
                  isDisabled={isLoading}
                  isVapourFileUpload={true}
                  isFileUploadToS3={isFileUploadToS3}
                  handleFileUploadBaseVapour={handleFileUploadBaseVapour}
                  handleError={handleError}
                />
              </div>
            )}
          </div>
        </div>
      </ModalSkeleton>
    </>
  );
};

const mapStateToProps = (state) => {
  const currentLoginUser = getUser(state);

  return { currentLoginUser };
};

export default connect(mapStateToProps, null)(UploadNewFileModel);
