import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogServicesSubCategoryRequest } from "store/actions/cos.actions";
import { getCatalogSubCategoryStatesLoading } from "store/selector/cos.selector";
import { getStateOptions } from "store/selector/general.selector";

const AgencyAddress = ({ selectedAccommodationInfo }) => {
  const allCatStates = useSelector(getStateOptions);
  const dispatch = useDispatch();

  const stateFetchLoader = useSelector(getCatalogSubCategoryStatesLoading);

  useEffect(() => {
    dispatch(getCatalogServicesSubCategoryRequest());
  }, []);

  // console.log("stateFetchLoader", stateFetchLoader);

  return (
    <>
      <div className='col-span-2'>
        <div>
          <label className='text-gray-700 text-lg font-bold'>
            Agency Address
          </label>
        </div>
      </div>
      <div className='col-span-2'>
        <label
          htmlFor='agency_name'
          className='capitalize block text-sm font-medium text-gray-700'
        >
          Agency Name
        </label>
        <div className='mt-1'>
          <input
            disabled
            value={selectedAccommodationInfo?.agency_name}
            maxLength={50}
            type='text'
            name='agency_name'
            id='agency_name'
            className='did-floating-input capitalize disabled:opacity-50'
          />
        </div>
      </div>
      <div className='col-span-1'>
        <label
          htmlFor='street_address'
          className='block text-sm font-medium text-gray-800'
        >
          Street Address
        </label>
        <div className='mt-1'>
          <input
            disabled
            value={selectedAccommodationInfo?.agency_street_address}
            maxLength={50}
            type='text'
            name='street_address'
            id='street_address'
            className='did-floating-input  disabled:opacity-50'
          />
        </div>
      </div>
      <div>
        <label
          htmlFor='suburb'
          className='capitalize block text-sm font-medium text-gray-800'
        >
          Suburb
        </label>
        <div className='mt-1'>
          <input
            disabled
            value={selectedAccommodationInfo?.agency_suburb}
            maxLength={50}
            type='text'
            name='suburb'
            id='suburb'
            className='did-floating-input  disabled:opacity-50'
          />
        </div>
      </div>
      <div>
        <label
          htmlFor='state'
          className='capitalize block text-sm font-medium text-gray-700'
        >
          State
        </label>
        <div className='mt-1'>
          <input
            placeholder={stateFetchLoader ? "Loading..." : ""}
            disabled
            value={
              allCatStates?.find(
                (x) =>
                  x.value === Number(selectedAccommodationInfo?.agency_state)
              )?.label
            }
            maxLength={50}
            type='text'
            name='state'
            id='state'
            className='did-floating-input  disabled:opacity-50'
          />
        </div>
      </div>
      <div>
        <label
          htmlFor='postal_code'
          className='capitalize block text-sm font-medium text-gray-700'
        >
          Postal Code
        </label>
        <div className='mt-1'>
          <input
            disabled
            value={selectedAccommodationInfo?.agency_postal_code}
            maxLength={50}
            type='text'
            name='postal_code'
            id='postal_code'
            className='did-floating-input  disabled:opacity-50'
          />
        </div>
      </div>
    </>
    // <div className='py-5 bg-white pb-10'>
    //   <div className='px-12 pt-2 pb-4'>
    //     <label className='text-gray-700 text-lg font-bold'>
    //       Agency Address
    //     </label>
    //   </div>
    // <div className='px-12 grid grid-cols-2 gap-y-6 gap-x-12'>

    // </div>
    // </div>
  );
};

export default AgencyAddress;
