import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import { generalActions, silActions } from "../actions";
import api from "../services/sil.services";
import ACTIONS from "../constants";
import { TOAST_TYPE } from "Constants/constant";

function* getSilPropertyType() {
  try {
    yield put(silActions.propertyTypeListfetched.pending);
    const response = yield call(api.getPropertyTypeList);
    if (response?.data) {
      yield put(silActions.propertyTypeListfetched.success(response?.data));
    }
  } catch (error) {
    yield put(silActions.propertyTypeListfetched.error(error));
  }
}

function* getSilSdaType() {
  try {
    yield put(silActions.sdaTypeListfetched.pending);
    const response = yield call(api.getSdaTypeList);
    if (response?.data) {
      yield put(silActions.sdaTypeListfetched.success(response?.data));
    }
  } catch (error) {
    yield put(silActions.sdaTypeListfetched.error(error));
  }
}

function* createSilAccommodation(state) {
  try {
    yield put(silActions.silAccommodationCreated.pending);
    const response = yield call(api.createSilAccommodationApi, state.payload);
    if (response?.home) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: `Home created successfully!`,
        })
      );
      yield put(silActions.silAccommodationCreated.success(response?.home));
      if (state?.callback) {
        state.callback(response?.home?.uuid);
      }
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something Went Wrong!",
      })
    );
    yield put(silActions.silAccommodationCreated.error(error));
  }
}

function* getSilAccommodations(state) {
  try {
    yield put(silActions.silAccommodationListfetched.pending);
    const response = yield call(api.silAccommodationListApi, state.payload);
    if (response?.data) {
      yield put(silActions.silAccommodationListfetched.success(response?.data));
      if (state?.callback) {
        state.callback(true);
      }
    }
  } catch (error) {
    yield put(silActions.silAccommodationListfetched.error(error));
  }
}

function* getSilAccommodationDetail(state) {
  try {
    yield put(silActions.silAccommodationDetailfetched.pending);
    const response = yield call(api.silAccommodationDetailApi, state.payload);
    if (response) {
      yield put(silActions.silAccommodationDetailfetched.success(response));
      if (state?.callback) {
        state.callback(true);
      }
    }
  } catch (error) {
    yield put(silActions.silAccommodationDetailfetched.error(error));
  }
}

function* getSilFormCategories(state) {
  try {
    yield put(silActions.silFormCategoriesfetched.pending);
    const response = yield call(api.silFormCategoriesApi, state.payload);
    if (response?.form_categories) {
      yield put(
        silActions.silFormCategoriesfetched.success(response?.form_categories)
      );
      if (state?.callback) {
        state.callback(true);
      }
    }
  } catch (error) {
    yield put(silActions.silFormCategoriesfetched.error(error));
  }
}

function* getSilAllForms(state) {
  try {
    yield put(silActions.silAllFormsFetched.pending);
    const response = yield call(api.silAllFormsApi, state.payload);
    if (response?.data) {
      yield put(silActions.silAllFormsFetched.success(response?.data));
      if (state?.callback) {
        state.callback(true);
      }
    }
  } catch (error) {
    yield put(silActions.silAllFormsFetched.error(error));
  }
}

function* updateSilAccommodationSettings(state) {
  try {
    yield put(silActions.silAccommodationSettingsUpdated.pending);
    const response = yield call(
      api.updateSilAccommodationSettingsApi,
      state.payload
    );
    // console.log("response", response);
    if (response) {
      yield put(silActions.silAccommodationSettingsUpdated.success(response));
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: `Home settings updated successfully!`,
        })
      );
      if (state?.callback) {
        state.callback(true);
      }
    }
  } catch (error) {
    yield put(silActions.silAccommodationSettingsUpdated.error(error));
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something Went Wrong!",
      })
    );
  }
}

function* getSilAccommodationRoomList(state) {
  try {
    yield put(silActions.silRoomsListFetched.pending);
    const response = yield call(api.silRoomsListApi, state.payload);
    // console.log("response,response", response);
    if (response?.data) {
      yield put(silActions.silRoomsListFetched.success(response?.data));
      if (state?.callback) {
        state.callback(true);
      }
    }
  } catch (error) {
    yield put(silActions.silRoomsListFetched.error(error));
  }
}

//Sil documents
function* getSilFolderList(state) {
  try {
    yield put(silActions.getFolderListForSil.pending);
    const response = yield call(api.getSilFolderListApi, state.payload);
    // console.log("response", response);
    if (response) {
      yield put(silActions.getFolderListForSil.success(response));
      if (state?.callback) {
        state.callback(true);
      }
    }
  } catch (error) {
    yield put(silActions.getFolderListForSil.error(error));
  }
}

function* createSilDocFolder(state) {
  try {
    yield put(silActions.silHomeDocumentFolderCreated.pending);
    const response = yield call(api.silDocumentFolderCreateApi, state.payload);
    if (response) {
      yield put(silActions.silHomeDocumentFolderCreated.success(response));
      if (state?.callback) {
        state.callback(true);
      }
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: `Folder created successfully!`,
        })
      );
    }
  } catch (error) {
    yield put(silActions.silHomeDocumentFolderCreated.error(error));
  }
}

function* updateSilDocFolder(state) {
  try {
    yield put(silActions.silHomeDocumentFolderUpdated.pending);
    const response = yield call(api.silDocumentFolderUpdateApi, state.payload);
    if (response) {
      yield put(silActions.silHomeDocumentFolderUpdated.success(response));
      if (state?.callback) {
        state.callback(true);
      }
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: `Folder updated successfully!`,
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something Went Wrong!",
      })
    );
    yield put(silActions.silHomeDocumentFolderUpdated.error(error));
  }
}

function* uploadSilDocFile(state) {
  try {
    yield put(silActions.silHomeDocumentFileUploaded.pending);
    const response = yield call(api.silDocumentFileUploadApi, state.payload);
    if (response?.data) {
      yield put(silActions.silHomeDocumentFileUploaded.success(response));
      if (state?.callback) {
        state.callback(response?.data);
      }
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: `File uploaded successfully!`,
        })
      );
    }
  } catch (error) {
    if (error?.message && state.callback) {
      state.callback(null, true);
    }
    yield put(silActions.silHomeDocumentFileUploaded.error(error));
  }
}

function* removeSilDocFile(state) {
  try {
    yield put(silActions.silHomeDocumentFileRemoved.pending);
    const response = yield call(api.silDocumentFileRemoveApi, state.payload);
    if (response?.message) {
      yield put(
        silActions.silHomeDocumentFileRemoved.success({
          id: state.payload?.file_management_id,
        })
      );
      if (state?.callback) {
        state.callback(response?.data);
      }
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: `File removed successfully!`,
        })
      );
    }
  } catch (error) {
    if (error?.message && state.callback) {
      state.callback(null, true);
    }
    yield put(silActions.silHomeDocumentFileRemoved.error(error));
  }
}

function* removeSilDocFolder(state) {
  try {
    yield put(silActions.silHomeDocumentFolderRemoved.pending);
    const response = yield call(api.silDocumentFolderRemoveApi, state.payload);
    if (response?.message) {
      yield put(
        silActions.silHomeDocumentFolderRemoved.success({
          id: state.payload?.folder_id,
        })
      );
      if (state?.callback) {
        state.callback(response?.data);
      }
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: `Folder removed successfully!`,
        })
      );
    }
  } catch (error) {
    if (error?.message && state.callback) {
      state.callback(null, true);
    }
    yield put(silActions.silHomeDocumentFolderRemoved.error(error));
  }
}

function* deleteSilAcommodationRoom(state) {
  try {
    yield put(silActions.silRoomDeleted.pending);
    // console.log("state", state);
    const response = yield call(api.silRoomDeleteApi, state.payload);
    if (response?.message) {
      yield put(
        silActions.silRoomDeleted.success({
          room_uuid: state.payload?.room_uuid,
        })
      );
      if (state?.callback) {
        state.callback();
      }
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: `Room deleted successfully!`,
        })
      );
    }
  } catch (error) {
    if (error?.message && state.callback) {
      state.callback(null, true);
    }
    yield put(silActions.silRoomDeleted.error(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ACTIONS.CREATE_SIL_ACCOMMODATION_CALL, createSilAccommodation),
    takeEvery(ACTIONS.GET_PROPERTY_TYPE_CALL, getSilPropertyType),
    takeEvery(ACTIONS.GET_SDA_TYPE_CALL, getSilSdaType),
    takeEvery(ACTIONS.GET_SIL_ACCOMMODATION_LIST_CALL, getSilAccommodations),
    takeEvery(
      ACTIONS.GET_SIL_ACCOMMODATION_DETAIL_CALL,
      getSilAccommodationDetail
    ),
    takeLatest(
      ACTIONS.UPDATE_SIL_ACCOMMODATION_SETTINGS_CALL,
      updateSilAccommodationSettings
    ),
    takeEvery(
      ACTIONS.GET_SIL_ACCOMMODATION_ROOMS_LIST_CALL,
      getSilAccommodationRoomList
    ),
    takeLatest(
      ACTIONS.SIL_ACCOMMODATION_ROOM_DELETE_CALL,
      deleteSilAcommodationRoom
    ),

    // Sil Form
    takeEvery(ACTIONS.GET_SIL_FORM_CATEGORIES_CALL, getSilFormCategories),
    takeEvery(ACTIONS.GET_SIL_ALL_FORMS_LIST_CALL, getSilAllForms),

    //Sil Documents
    takeEvery(ACTIONS.GET_FOLDER_LIST_FOR_SIL_CALL, getSilFolderList),
    takeLatest(ACTIONS.CREATE_SIL_DOCUMENT_FOLDER_CALL, createSilDocFolder),
    takeLatest(ACTIONS.UPDATE_SIL_DOCUMENT_FOLDER_CALL, updateSilDocFolder),
    takeLatest(ACTIONS.UPDATE_SIL_DOCUMENT_FILE_UPLOAD_CALL, uploadSilDocFile),
    takeEvery(ACTIONS.SIL_DOCUMENT_FILE_REMOVE_CALL, removeSilDocFile),
    takeEvery(ACTIONS.SIL_DOCUMENT_FOLDER_REMOVE_CALL, removeSilDocFolder),
  ]);
}
