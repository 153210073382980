import React, { useState } from "react";
import CustomButton from "components/common/Button/CustomButton";
import MultiFileInput from "components/common/FileUpload/MultiFileInput";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { LOADER_STATUS } from "store/constants";
import api from "store/services/sil.services";
import { uploadFilesOnVapour } from "Utils/uploadFileOnS3";
import { toast } from "react-toastify";

const HomeImageModal = ({
  isOpen,
  handleClose,
  handleUpdateImagesLocally,
  selectedAccommodationInfo,
}) => {
  const [isUploadLoading, setIsUploadLoading] = useState(LOADER_STATUS.NA);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [files, setFiles] = useState([]);

  const modalFooter = () => (
    <>
      <CustomButton
        isDisabled={isUploadLoading === LOADER_STATUS.PENDING}
        label='Cancel'
        variant='secondary'
        clickHandler={() => {
          handleClose();
          setFiles([]);
        }}
      />
      <CustomButton
        showLoading={isUploadLoading === LOADER_STATUS.PENDING}
        isDisabled={isUploadLoading === LOADER_STATUS.PENDING}
        label={"Upload"}
        variant='primary'
        clickHandler={() => {
          if (files && files?.length > 0) {
            handleUploadAttachments();
          }
        }}
      />
    </>
  );

  const handleUploadAttachments = async () => {
    try {
      setIsUploadLoading(LOADER_STATUS.PENDING);
      const filteredFiles = files?.filter((x) => !x.hasOwnProperty("id"));
      const filesLength = filteredFiles.length;
      const uploadedFiles = [];
      const successText = `Attachment${
        filesLength > 1 ? "s" : ""
      } Uploaded Successfully!`;
      const progressTracker = new Array(filesLength).fill(0);

      let lastUpdate = Date.now();

      const onProgress = (progress, fileIndex) => {
        const currentTime = Date.now();
        if (currentTime - lastUpdate < 100) return;
        lastUpdate = currentTime;
        progressTracker[fileIndex] = progress;
        const totalProgress = progressTracker.reduce(
          (acc, curr) => acc + curr,
          0
        );
        const averageProgress = totalProgress / filesLength;
        setUploadProgress(averageProgress);
      };

      const uploadFileWithProgress = async (file, index) => {
        const options = {
          progress: (progress) => onProgress(progress, index),
        };
        const uploadedFile = await uploadFilesOnVapour([file], options);
        if (uploadedFile && uploadedFile?.[0]) {
          uploadedFiles.push(uploadedFile[0]);
        }
      };
      await Promise.all(
        filteredFiles.map((file, index) => uploadFileWithProgress(file, index))
      );

      const response = await api.silHomeUploadImageApi({
        attachments: uploadedFiles,
        home_uuid: selectedAccommodationInfo?.uuid,
      });
      setUploadProgress(0);
      setIsUploadLoading(LOADER_STATUS.COMPLETE);
      handleUpdateImagesLocally(response?.images);
      toast.success(successText);
      handleClose();
    } catch (err) {
      console.error("Error during upload:", err);
      setUploadProgress(0);
      setIsUploadLoading(LOADER_STATUS.COMPLETE);
      //   setFiles([]);
    }
  };

  return (
    <ModalSkeleton
      title={`Upload Home Images`}
      cssClass='w-[40%]'
      modalFooter={modalFooter()}
      isOpen={isOpen}
      closeModal={handleClose}
    >
      <div className='flex flex-col'>
        <div className='w-full'>
          <div>
            <MultiFileInput
              filenameLimit={150}
              size={10}
              maxFilesAllow={20}
              type='image'
              handleFile={(e) => {
                setFiles(e);
              }}
              files={files}
              showDragAndDrop={true}
              // isHardClearAllStates={isSubmitting === true}
              // setFilesData={setFilesData}
              // setIsSubmitting={setIsSubmitting}
            />
          </div>

          {isUploadLoading === LOADER_STATUS.NA &&
          files?.filter((x) => !x.hasOwnProperty("id"))?.length > 0 ? (
            <div className='mt-2 rounded-md p-2 bg-blue-100 flex justify-center items-center'>
              {`${files?.filter((x) => !x.hasOwnProperty("id"))?.length} ${
                files?.filter((x) => !x.hasOwnProperty("id"))?.length > 1
                  ? "files"
                  : "file"
              } Loaded.`}
            </div>
          ) : isUploadLoading === LOADER_STATUS.PENDING ? (
            <div className='w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2'>
              <div
                className='animate-pulse transition duration-300 ease-in-out bg-purple-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full'
                style={{
                  width: `${uploadProgress}%`,
                }}
              >
                {Math.round(uploadProgress)}%
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* (
            isUploadLoading === LOADER_STATUS.COMPLETE && (
              <div className='mt-2 rounded-md p-2 bg-blue-100 flex justify-center items-center'>
                Attachment Uploaded!
              </div>
            )
          )} */}
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default HomeImageModal;
