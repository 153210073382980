import React, { useState, useEffect } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { useNavigate } from "react-router";
import api from "store/services/sil.services";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { PARENT_FORM_CATEGORY_SLUG } from "Constants/constant";

const PropertyNearBy = ({ selectedAccommodationInfo }) => {
  const navigate = useNavigate();

  const [nearByData, setNearByData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleNearByFacilities = () => {
    navigate(
      `/view-template/sil/${selectedAccommodationInfo?.uuid}/${PARENT_FORM_CATEGORY_SLUG.PropertyManagement.SLUG}/nearby-facilities`
    );
  };

  const handleFetchNearByFacility = async () => {
    try {
      setIsLoading(true);
      const response = await api.silHomeNearByPropertyDataApi({
        home_id: selectedAccommodationInfo?.uuid,
        slug: "nearby-facilities",
      });
      if (response.submitted_form) {
        // console.log("response", response);P
        setNearByData(
          JSON.parse(response?.submitted_form?.template)?.[0]?.input_fields?.[0]
            ?.sub_fields
        );
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err:handleFetchNearByFacility", err);
    }
  };

  //   console.log("nearbyData", nearByData);

  useEffect(() => {
    if (selectedAccommodationInfo?.uuid) {
      handleFetchNearByFacility();
    }
  }, []);

  return (
    <div className='py-10 bg-gray-100'>
      {/* Near By Facilities */}
      <div className='px-12 pb-4'>
        <label className='text-gray-700 text-lg font-bold'>
          Nearby Facilities
        </label>
      </div>
      <div className='px-12 grid grid-cols-2 gap-y-6 gap-x-12'>
        {isLoading ? (
          <div
            className='flex justify-center items-center col-span-2'
            style={{ minWidth: "25rem", overflow: "hidden !important" }}
          >
            <CircularLoader
              classes='flex justify-center items-center'
              classloader='loader-l'
            />
          </div>
        ) : (
          <React.Fragment>
            <div>
              <label
                htmlFor='participant_name'
                className='capitalize block text-sm font-medium text-gray-800'
              >
                Nearby Shopping Center
              </label>
              <div className='mt-1'>
                {!nearByData?.find((x) => x.name === "nearby_shopping_center")
                  ?.value ? (
                  <div
                    onClick={() => {
                      if (true) {
                        handleNearByFacilities();
                      }
                    }}
                    className={`${
                      // roleAccess(
                      //   [],
                      //   PERMISSION_KEYS.PARTICIPANT,
                      //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
                      // ) &&
                      // roleAccess(
                      //   [],
                      //   PERMISSION_KEYS.PARTICIPANT,
                      //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                      // ) &&
                      "cursor-pointer"
                    }  mt-1 text-sm text-gray-900 flex items-center space-x-2`}
                  >
                    <span
                      className={`${""} break-all print:text-black text-gray-600`}
                    >
                      {
                        // roleAccess(
                        //   [],
                        //   PERMISSION_KEYS.PARTICIPANT,
                        //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
                        // ) &&
                        // roleAccess(
                        //   [],
                        //   PERMISSION_KEYS.PARTICIPANT,
                        //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                        // )
                        true ? "Add information" : "No Data Found!"
                      }
                    </span>
                    {
                      // roleAccess(
                      //   [],
                      //   PERMISSION_KEYS.PARTICIPANT,
                      //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
                      // ) &&
                      //   roleAccess(
                      //     [],
                      //     PERMISSION_KEYS.PARTICIPANT,
                      //     PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                      //   )
                      true && (
                        <span className={`${""}`}>
                          <FiArrowUpRight />
                        </span>
                      )
                    }
                  </div>
                ) : (
                  <input
                    disabled
                    value={
                      nearByData?.find(
                        (x) => x.name === "nearby_shopping_center"
                      )?.value
                    }
                    maxLength={50}
                    type='text'
                    name='nearest_shopping_center'
                    id='nearest_shopping_center   '
                    className='did-floating-input capitalize disabled:opacity-50 disabled:opacity-50'
                  />
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor='worker_name'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Nearby Bus Stop
              </label>
              <div className='mt-1'>
                {!nearByData?.find((x) => x.name === "nearby_bus_stop")
                  ?.value ? (
                  <div
                    onClick={() => {
                      if (true) {
                        handleNearByFacilities();
                      }
                    }}
                    className={`${
                      // roleAccess(
                      //   [],
                      //   PERMISSION_KEYS.PARTICIPANT,
                      //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
                      // ) &&
                      // roleAccess(
                      //   [],
                      //   PERMISSION_KEYS.PARTICIPANT,
                      //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                      // ) &&
                      "cursor-pointer"
                    }  mt-1 text-sm text-gray-900 flex items-center space-x-2`}
                  >
                    <span
                      className={`${""} break-all print:text-black text-gray-600`}
                    >
                      {
                        // roleAccess(
                        //   [],
                        //   PERMISSION_KEYS.PARTICIPANT,
                        //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
                        // ) &&
                        // roleAccess(
                        //   [],
                        //   PERMISSION_KEYS.PARTICIPANT,
                        //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                        // )
                        true ? "Add information" : "No Data Found!"
                      }
                    </span>
                    {
                      // roleAccess(
                      //   [],
                      //   PERMISSION_KEYS.PARTICIPANT,
                      //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
                      // ) &&
                      //   roleAccess(
                      //     [],
                      //     PERMISSION_KEYS.PARTICIPANT,
                      //     PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                      //   )
                      true && (
                        <span className={`${""}`}>
                          <FiArrowUpRight />
                        </span>
                      )
                    }
                  </div>
                ) : (
                  <input
                    disabled
                    value={
                      nearByData?.find((x) => x.name === "nearby_bus_stop")
                        ?.value
                    }
                    maxLength={50}
                    type='text'
                    name='nearest_bus_stop'
                    id='nearest_bus_stop'
                    className='did-floating-input capitalize disabled:opacity-50'
                  />
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor='reginal_manager'
                className='capitalize block text-sm font-medium text-gray-800'
              >
                Nearby Train Station
              </label>
              <div className='mt-1'>
                {!nearByData?.find((x) => x.name === "nearby_train_station")
                  ?.value ? (
                  <div
                    onClick={() => {
                      if (true) {
                        handleNearByFacilities();
                      }
                    }}
                    className={`${
                      // roleAccess(
                      //   [],
                      //   PERMISSION_KEYS.PARTICIPANT,
                      //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
                      // ) &&
                      // roleAccess(
                      //   [],
                      //   PERMISSION_KEYS.PARTICIPANT,
                      //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                      // ) &&
                      "cursor-pointer"
                    }  mt-1 text-sm text-gray-900 flex items-center space-x-2`}
                  >
                    <span
                      className={`${""} break-all print:text-black text-gray-600`}
                    >
                      {
                        // roleAccess(
                        //   [],
                        //   PERMISSION_KEYS.PARTICIPANT,
                        //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
                        // ) &&
                        // roleAccess(
                        //   [],
                        //   PERMISSION_KEYS.PARTICIPANT,
                        //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                        // )
                        true ? "Add information" : "No Data Found!"
                      }
                    </span>
                    {
                      // roleAccess(
                      //   [],
                      //   PERMISSION_KEYS.PARTICIPANT,
                      //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
                      // ) &&
                      //   roleAccess(
                      //     [],
                      //     PERMISSION_KEYS.PARTICIPANT,
                      //     PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                      //   )
                      true && (
                        <span className={`${""}`}>
                          <FiArrowUpRight />
                        </span>
                      )
                    }
                  </div>
                ) : (
                  <input
                    disabled
                    value={
                      nearByData?.find((x) => x.name === "nearby_train_station")
                        ?.value
                    }
                    maxLength={50}
                    type='text'
                    name='nearest_train_station'
                    id='nearest_train_station'
                    className='did-floating-input capitalize disabled:opacity-50'
                  />
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor='team_leader'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Nearby Hospital
              </label>
              <div className='mt-1'>
                {!nearByData?.find((x) => x.name === "nearby_hospital")
                  ?.value ? (
                  <div
                    onClick={() => {
                      if (true) {
                        handleNearByFacilities();
                      }
                    }}
                    className={`${
                      // roleAccess(
                      //   [],
                      //   PERMISSION_KEYS.PARTICIPANT,
                      //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
                      // ) &&
                      // roleAccess(
                      //   [],
                      //   PERMISSION_KEYS.PARTICIPANT,
                      //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                      // ) &&
                      "cursor-pointer"
                    }  mt-1 text-sm text-gray-900 flex items-center space-x-2`}
                  >
                    <span
                      className={`${""} break-all print:text-black text-gray-600`}
                    >
                      {
                        // roleAccess(
                        //   [],
                        //   PERMISSION_KEYS.PARTICIPANT,
                        //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
                        // ) &&
                        // roleAccess(
                        //   [],
                        //   PERMISSION_KEYS.PARTICIPANT,
                        //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                        // )
                        true ? "Add information" : "No Data Found!"
                      }
                    </span>
                    {
                      // roleAccess(
                      //   [],
                      //   PERMISSION_KEYS.PARTICIPANT,
                      //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
                      // ) &&
                      //   roleAccess(
                      //     [],
                      //     PERMISSION_KEYS.PARTICIPANT,
                      //     PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                      //   )
                      true && (
                        <span className={`${""}`}>
                          <FiArrowUpRight />
                        </span>
                      )
                    }
                  </div>
                ) : (
                  <input
                    disabled
                    value={
                      nearByData?.find((x) => x.name === "nearby_hospital")
                        ?.value
                    }
                    maxLength={50}
                    type='text'
                    name='nearest_hospital'
                    id='nearest_hospital'
                    className='did-floating-input capitalize disabled:opacity-50'
                  />
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default PropertyNearBy;
