import React, { useEffect } from "react";
import SelectOption from "components/common/Select/Select";
import WorkerShiftCustomSelect from "./asyncSelect";
import MultiSelectDropdown from "components/common/MultiSelectDropdown";
import {
  fetchOptionsForWorkerAndParticpant,
  parseBooleanVal,
} from "Utils/utils";
import _ from "lodash";

const ParticipantSelection = (props) => {
  const {
    watchFormData,
    calendarView,
    participantOptions,
    register,
    setValue,
    selectedShift,
    checkRunningShiftStatus,
    checkDisabilityOfShiftStatus,
    handleChangeWorkerOrParticipant,
    isFetchRecordDetailLoader,
    control,
    watch,
    setSelectedOptions,
    selectedOptions,
    clearErrors,
    errors,
    userItem,
    setIsWorkerLoaded,
    isCallFromAddShift,
    setWorkerOptions,
    setIsParticipantLoaded,
    setParticipantOptions,
    isParticipantLoaded,
    selectedParticipant,
    handleChangeServiceCategory,
    createOrUpdateWorkerShiftLoader,
    getSelectedOption,
    isOpenShift=false,
    shiftDetailData,
    
  } = props;
  
  useEffect(() => {
    if (
      selectedParticipant &&
      !_.isArray(selectedParticipant) &&
      !createOrUpdateWorkerShiftLoader &&
      selectedShift === null &&
      watchFormData?.pt_id
    ) {
      handleChangeServiceCategory(selectedParticipant?.invoice_category_id);
    } else if (selectedParticipant && _.isArray(selectedParticipant)) {
      // handleChangeServiceCategory(null);
    }
  }, [watchFormData?.invoice_category_id, selectedParticipant]);


  return (
    <div className=''>
      <label className='text-gray-700 text-sm font-medium mb-1'>
        Participant
      </label>

      {/* Group shift with worker view*/}
      {watchFormData?.is_group_shift && calendarView === "worker-view" && (
        <MultiSelectDropdown
          showIcon
          data={participantOptions}
          cssClass='z-30'
          name='pt_id'
          register={register}
          setValue={setValue}
          isDisabled={
            checkRunningShiftStatus(selectedShift?.status) ||
            isFetchRecordDetailLoader
          }
          control={control}
          watch={watch}
          selectedOption={getSelectedOption("pt_id")}
          setSelectedOptions={setSelectedOptions}
          selectedOptions={selectedOptions}
          clearErrors={clearErrors}
          errorMessage={
            errors?.pt_id?.type === "required"
              ? "This field is required!"
              : null
          }
          calendarView={calendarView === "worker-view"}
        />
      )}

{calendarView === "participant-view" && isOpenShift && (
        <MultiSelectDropdown
          showIcon
          data={participantOptions}
          cssClass='z-30'
          name='pt_id'
          register={register}
          setValue={setValue}
          isDisabled={
            checkRunningShiftStatus(selectedShift?.status) ||
            isFetchRecordDetailLoader
          }
          control={control}
          watch={watch}
          selectedOption={getSelectedOption("pt_id")}
          setSelectedOptions={setSelectedOptions}
          selectedOptions={selectedOptions}
          clearErrors={clearErrors}
          handleInputChange={(e)=>{
            if(isCallFromAddShift && e && selectedShift === null ){
             
              const UUIDs= e?.map((el)=>el?.uuid);
              handleChangeWorkerOrParticipant({
                type: "participant",
                uuid: UUIDs,
              })
            }
          }}
          errorMessage={
            errors?.pt_id?.type === "required"
              ? "This field is required!"
              : null
          }
          calendarView={calendarView === "participant-view"}
        />
      )}

      
      {/* Not Group shift with worker view*/}
      {!watchFormData?.is_group_shift && calendarView === "worker-view" && (
        <SelectOption
          isClearable={isCallFromAddShift}
          isClearCall={async () => {
            if (!isCallFromAddShift) return;
            if (watchFormData?.is_group_shift) {
              setValue("is_group_shift", false);
            }
            // setClickStack([]);
            setValue("worker_id", null);
            setValue("pt_id", null);
            setIsWorkerLoaded(true);
            setIsParticipantLoaded(true);
            fetchOptionsForWorkerAndParticpant("worker")
              .then((response) => {
                setWorkerOptions([...response]);
                setIsWorkerLoaded(false);
              })
              .catch(() => setIsWorkerLoaded(false));
            fetchOptionsForWorkerAndParticpant("participant")
              .then((response) => {
                setParticipantOptions([...response]);
                setIsParticipantLoaded(false);
              })
              .catch(() => setIsParticipantLoaded(false));
          }}
          noOptionsMessage={"No Participant Assigned"}
          isRequired={true}
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          name='pt_id'
          loading={isFetchRecordDetailLoader || isParticipantLoaded}
          isDisabled={
            checkRunningShiftStatus(selectedShift?.status) ||
            isFetchRecordDetailLoader ||
            isParticipantLoaded ||
            (userItem && calendarView === "participant-view" ? true : false)
          }
          isValueChanges={(e, val) => {
            const isNotEmptyWorkerId =
              watchFormData?.worker_id !== null &&
              watchFormData?.worker_id !== "" &&
              watchFormData?.worker_id !== undefined;
            if (isNotEmptyWorkerId && isCallFromAddShift) {
              setIsWorkerLoaded(true);
              handleChangeWorkerOrParticipant({
                type: "participant",
                uuid: val,
              })
                .then(() => setIsWorkerLoaded(false))
                .catch(() => setIsWorkerLoaded(false));
              return;
            }
            if (userItem === null && e) {
              if (!isNotEmptyWorkerId) {
                setWorkerOptions([]);
                setValue("worker_id", null);
              }
              setIsWorkerLoaded(true);
              handleChangeWorkerOrParticipant({
                type: "participant",
                uuid: val,
              })
                .then(() => setIsWorkerLoaded(false))
                .catch(() => setIsWorkerLoaded(false));
            }
          }}
          cssClass='z-30'
          showIcon={true}
          isSearch={true}
          data={participantOptions}
          selectedOption={
            watchFormData?.pt_id
              ? participantOptions.find((x) => x.value === watchFormData?.pt_id)
              : null
          }
          errorMessage={
            errors?.pt_id?.type === "required"
              ? "This field is required!"
              : null
          }
        />
      )}

      {/* Participant View */}
      {calendarView === "participant-view" && !isOpenShift && 
        (isCallFromAddShift  ? (
          <>
            {/* Add Shift */}
            <select
              className='hidden'
              {...register("pt_id", {
                value: watchFormData?.pt_id || null,
                required: true,
              })}
            />
            <WorkerShiftCustomSelect
              isClearable={isCallFromAddShift}
              // isMultiple={
              //   watchFormData?.is_group_shift &&
              //   calendarView === "worker-view"
              // }
              loading={isParticipantLoaded}
              isClearCall={async () => {
                if (!isCallFromAddShift) return;
                if (watchFormData?.is_group_shift) {
                  setValue("is_group_shift", false);
                }
                // setClickStack([]);
                setValue("worker_id", null);
                setValue("pt_id", null);
                setIsWorkerLoaded(true);
                setIsParticipantLoaded(true);
                fetchOptionsForWorkerAndParticpant("worker")
                  .then((response) => {
                    setWorkerOptions([...response]);
                    setIsWorkerLoaded(false);
                  })
                  .catch(() => setIsWorkerLoaded(false));
                fetchOptionsForWorkerAndParticpant("participant")
                  .then((response) => {
                    setParticipantOptions([...response]);
                    setIsParticipantLoaded(false);
                  })
                  .catch(() => setIsParticipantLoaded(false));
              }}
              isOptionRefreshed={(e = []) => setParticipantOptions([...e])}
              clearErrors={clearErrors}
              name='pt_id'
              setValue={setValue}
              defaultOptions={
                watchFormData?.worker_id ? participantOptions : null
              }
              isDisabled={
                watchFormData?.is_group_shift && calendarView === "worker-view"
                  ? checkRunningShiftStatus(selectedShift?.status) ||
                    isFetchRecordDetailLoader
                  : checkDisabilityOfShiftStatus(selectedShift?.status) ||
                    isFetchRecordDetailLoader ||
                    isParticipantLoaded ||
                    (userItem && calendarView === "participant-view"
                      ? true
                      : false) ||
                    selectedShift !== null
              }
              selectedValue={watchFormData?.pt_id}
              errorMessage={
                errors?.pt_id?.type === "required"
                  ? "This field is required!"
                  : null
              }
              isValueChanges={(e, val) => {
                const clearWorkerAndFetchData = async () => {
                  setWorkerOptions([]);
                  setValue("worker_id", null);
                  setIsParticipantLoaded(true);
                  setIsWorkerLoaded(true);
                  handleChangeWorkerOrParticipant({
                    type: "participant",
                    uuid: val.uuid,
                  })
                    .then(() => {
                      setIsWorkerLoaded(false);
                      setIsParticipantLoaded(false);
                    })
                    .catch(() => {
                      setIsWorkerLoaded(false);
                      setIsParticipantLoaded(false);
                    });
                };
                if (
                  isCallFromAddShift &&
                  watchFormData?.worker_id !== null &&
                  watchFormData?.worker_id !== undefined &&
                  watchFormData?.worker_id !== ""
                ) {
                  if (parseBooleanVal(watchFormData?.is_group_shift)) {
                    clearWorkerAndFetchData().then();
                  }

                  setIsParticipantLoaded(true);
                  handleChangeWorkerOrParticipant(
                    {
                      type: "participant",
                      uuid: val.uuid,
                    },
                    { isCheckWorkerExist: true }
                  )
                    .then(() => {
                      setIsParticipantLoaded(false);
                    })
                    .catch(() => {
                      setIsParticipantLoaded(false);
                    });
                  return;
                }

                if (userItem === null && e && val?.uuid) {
                  clearWorkerAndFetchData().then();
                }
              }}
            />
          </>
        ) : (
          <SelectOption
            isValueChanges={(e, val) => {
              if (userItem === null && e) {
                setWorkerOptions([]);
                setValue("worker_id", null);
                handleChangeWorkerOrParticipant({
                  type: "participant",
                  uuid: val,
                }).then(() => setIsParticipantLoaded(true));
              }
            }}
            noOptionsMessage={"No Participant Assigned"}
            isRequired={true}
            register={register}
            setValue={setValue}
            clearErrors={clearErrors}
            name='pt_id'
            loading={isFetchRecordDetailLoader}
            isDisabled={
              checkDisabilityOfShiftStatus(selectedShift?.status) ||
              isFetchRecordDetailLoader ||
              (userItem && calendarView === "participant-view" && !shiftDetailData?.open_group_shift
                ? true
                : false) 
              //   ||
              // selectedShift !== null 
            }
            cssClass='z-30'
            showIcon={true}
            isSearch={true}
            isClearable={false}
            data={participantOptions}
            selectedOption={
              watchFormData?.pt_id
                ? participantOptions.find(
                    (x) => x.value === watchFormData?.pt_id
                  )
                : null
            }
            errorMessage={
              errors?.pt_id?.type === "required"
                ? "This field is required!"
                : null
            }
          />
        ))}
    </div>
  );
};

export default ParticipantSelection;
