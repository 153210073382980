import React, { useEffect, useState } from "react";
import CustomButton from "components/common/Button/CustomButton";
import FileUploadInput from "components/common/FileUpload/FileUploadInput";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { apiGetRequest } from "helpers/Requests";
import moment from "moment";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { apiCall } from "store/services/service";
import { BASE_URL } from "store/services/URL";
import { getUrlRole, sleep } from "Utils/utils";
import { roleAccess } from "helpers/RolesPermission";
import { USER_ROLE } from "Constants/constant";

function EditModel(props) {
  const {
    handleClose,
    setUpdatedFile,
    isOpen,
    workerId,
    parentId,
    isUserGracePeriodOver,

    //not use
    setParentId,
  } = props;

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setValue,
  } = useForm();

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState();
  const [expiryDate, setExpiryDate] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [label, setIslabel] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [isFileUploadToS3, setIsFileUploadToS3] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileTypeOptions, setFileTypeOptions] = useState([]);

  const [selectedDocument, setSelectedDocument] = useState(null);

  const modalFooter = () => (
    <>
      <CustomButton
        //   showLoading={request.isRequested}
        //   isDisabled={request.isRequested}
        label='Cancel'
        variant='secondary'
        clickHandler={() => {
          handleClear();
          setFile("");
          setSelectedDocument("");
          setFileName("");
          handleClose(false);
          const buttonRef = document.getElementById(
            "addparticipantButtonModal"
          );
          if (buttonRef) {
            buttonRef.click();
          }
        }}
      />
      <CustomButton
        showLoading={isLoading}
        isDisabled={
          (file == null || file === "" ? true : isLoading) ||
          isUserGracePeriodOver
        }
        label='Upload'
        variant='primary'
        // clickHandler={handleSubmitForFile }
        clickHandler={handleSubmitForFile}
      />
    </>
  );
  const onSubmit = (data) => {};

  // console.log("file", file);
  const handleFileUploadBaseVapour = async (data) => {
    Object.assign(data, {
      // key: "tmp/cea2aee1-5128-467e-9748-4091232f7ec9",
      worker_id: workerId,
      file_type: data.ext,
      document_type_id: 2,
      with_expiry: showInput,
      label: label,
      note: "xyz",
      expiry_time: showInput ? expiryDate : null,
      folder_structure_id: parentId,
    });
    const Role = roleAccess([USER_ROLE.Sp_Standard_User]) ? "hrm" : "sp";
    apiCall
      .post({
        url: `${BASE_URL}${Role}/file-management/upload-document`,
        params: data,
      })
      .then((response) => {
        handleFinish(response);
        handleClose(false);
      })
      .catch((e) => {
        console.log("e", e);
        setIsLoading(false);
        toast.error("Something Went Wrong!");
      });
  };

  const handleClear = () => {
    setSelectedFileType("");
    setFile("");
    setFileName("");
  };

  const handleFinish = (data) => {
    if (data && data.data && data.data.file_type) {
      setUpdatedFile({ data });
      handleClose(false);
      handleClear();
      setFile("");
      setSelectedDocument("");
      setFileName("");
    }
    // setParentId(null);
    handleClear();
    setIsLoading(false);
    setIsFileUploadToS3(false);

    // addSPDocumentsLocally(data.document);
    toast.success("Document uploaded successfully");
    setDisabledButton(true);

    sleep(1200).then(() => {
      // handleCloseAddDocument();
    });
  };
  const handleError = () => {
    setIsLoading(false);
  };
  const getError = (value) => {
    if (!value) return "";
    return errors[value] && errors[value].length > 0
      ? "The Field is required!"
      : "";
  };

  const handleSubmitForFile = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    // if (isValidInputs()) return;
    setIsLoading(true);
    setIsFileUploadToS3(true);
  };
  // const isValidInputs = () => {
  //   const fnError = errors;
  //   if (!fileName || fileName.length === 0) {
  //     Object.assign(fnError, { FileName: "File Name is Required" });
  //   }
  //   if (!file || file.length === 0) {
  //     Object.assign(fnError, { File: "File is Required" });
  //   }
  //   if (!selectedFileType || Object.keys(selectedFileType).length <= 0) {
  //     Object.assign(fnError, { FileType: "Category is Required" });
  //   }
  //   if (Object.values(fnError).filter((x) => x !== null).length > 0) {
  //     setErrors({ ...fnError });
  //     return true;
  //   }
  //   setDisabledButton(false);
  //   return false;
  // };

  // setShowInput(!showInput);
  const getFileTypes = async () => {
    try {
      const res = await apiGetRequest(`${getUrlRole()}/doc-types`);
      if (res.status === 200) {
        const options = res.data.doc_types.map((doc) => {
          const obj = {
            label: doc.name,
            value: doc.name,
          };
          return obj;
        });
        setFileTypeOptions([...options]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getFileTypes();
  }, []);
  const [selectedFileType, setSelectedFileType] = useState();
  return (
    <>
      <ModalSkeleton
        title='Edit Document'
        cssClass='w-[35%]'
        modalFooter={modalFooter()}
        isOpen={isOpen}
        closeModal={() => {
          handleClose(false);
          handleClear();
          // handleCloseAddDocument();
          // setErrors([]);

          setFile("");
          setSelectedDocument("");
          setFileName("");
        }}
      >
        <div className='flex flex-col'>
          <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
            <div>
              <div className='grid grid-cols-2 gap-x-4'>
                <div className='col-span-2'>
                  <div className='space-y-4'>
                    <div>
                      <label
                        htmlFor='first_name'
                        className='text-start did-floating-label'
                      >
                        File Name
                      </label>
                      <div className=''>
                        <input
                          type='text'
                          className='did-floating-input capitalize'
                          {...register("first_name", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                      </div>
                    </div>
                    {/* <div className="mt-4 flex items-start justify-start space-x-2">
                      <div className="did-floating-label-content w-full">
                        <label
                          htmlFor="interview-questions"
                          className=" text-start did-floating-label"
                        >
                          Category
                        </label>
                        <Select
                          cssClass="z-20"
                          name="FileType"
                          isSearch={false}
                          data={fileTypeOptions}
                          selectedOption={selectedFileType}
                          handleChange={(e) => setSelectedFileType(e)}
                        />
                        {getError("FileType") && (
                          <span className="errorMessage ">
                            {getError("FileType")}
                          </span>
                        )}
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* <div className="col-span-2 space-x-4">
                  <labe className=" text-start did-floating-label">
                    Set Expiry Date
                  </labe>
                  <input
                    type="checkbox"
                    onClick={() => setShowInput(!showInput)}
                    className="rounded "
                  />
                </div> */}
                {
                  <div className='col-span-2'>
                    <label
                      htmlFor=''
                      className=' text-start did-floating-label'
                    >
                      Expiry Date
                    </label>
                    <input
                      {...register("delivered_to", {
                        required: true,
                      })}
                      onChange={(e) => {
                        // setValidationDeliveredDate(false);
                        // clearErrors("delivered_date");
                        // clearErrors("delivered_to");
                      }}
                      name='delivered_to'
                      id='delivered_to'
                      type='date'
                      min={moment().format("DD-MM-YYYY")}
                      // placeholder="OCT 12, 2020"
                      className='did-floating-input'
                    />
                    {errors?.delivered_to?.type === "required" && (
                      <span className='text-sm text-red-500'>
                        This field is required!
                      </span>
                    )}
                  </div>
                }
                {
                  <div className='col-span-2'>
                    <label
                      htmlFor=''
                      className=' text-start did-floating-label'
                    >
                      Expiry Date
                    </label>
                    <input
                      {...register("delivered_to", {
                        required: true,
                      })}
                      onChange={(e) => {
                        // setValidationDeliveredDate(false);
                        // clearErrors("delivered_date");
                        // clearErrors("delivered_to");
                      }}
                      name='delivered_to'
                      id='delivered_to'
                      type='date'
                      min={moment().format("DD-MM-YYYY")}
                      // placeholder="OCT 12, 2020"
                      className='did-floating-input'
                    />
                    {errors?.delivered_to?.type === "required" && (
                      <span className='text-sm text-red-500'>
                        This field is required!
                      </span>
                    )}
                  </div>
                }

                {!selectedDocument && (
                  <div className='col-span-2 mt-4'>
                    <FileUploadInput
                      type='any'
                      handleFile={(e) => setFile(e)}
                      size='10'
                      file={file}
                      handleDelete={() => setFile("")}
                      isDisabled={isLoading}
                      isVapourFileUpload={true}
                      isFileUploadToS3={isFileUploadToS3}
                      handleFileUploadBaseVapour={handleFileUploadBaseVapour}
                      handleError={handleError}
                    />
                    {getError("File") && (
                      <span className='errorMessage '>{getError("File")}</span>
                    )}
                  </div>
                )}
                {/* <div className="col-span-2">
                  <label htmlFor="" className="did-floating-label text-start">
                    Notes
                  </label>
                  <textarea
                    onChange={"setIslabel"}
                    {...register("note", {
                      // required: true,
                    })}
                    rows="3"
                    className="w-full border-gray-300 rounded oeverflow-y-auto resize-none"
                  ></textarea>
                  {errors?.note?.type === "required" && (
                    <span className="text-sm text-red-500">
                      This field is required!
                    </span>
                  )}
                </div> */}
              </div>
            </div>
            <button
              className='hudden sr-only'
              type='submit'
              id='addparticipantButtonModal'
            />
          </form>
        </div>
      </ModalSkeleton>
      {/* <div>UploadNewFileModel</div> */}
    </>
  );
}

export default EditModel;
