import React from "react";
import { USER_ROLE } from "../../../../Constants/constant";
import { roleAccess } from "../../../../helpers/RolesPermission";
import {
  baseURLImage,
  getNameProfile,
  selectedTabState,
  SilHomeselectedTabState,
} from "../../../../Utils/utils";

const WorkerSupportTeam = (props) => {
  const { workers, setToggleState, callFrom } = props;

  const handleTabs = () => {
    if (setToggleState) {
      setToggleState(
        callFrom === "silProfile"
          ? {
              name: "Settings",
              id: "settings",
              children: [],
              roleAccess: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
              //   permissions: PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING,
            }
          : {
              name: "Settings",
              id: 13,
              children: [],
            }
      );
    }

    if (callFrom === "silProfile") {
      SilHomeselectedTabState.save({
        name: "Settings",
        id: "settings",
        children: [],
        roleAccess: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
        //   permissions: PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING,
      });
    } else {
      selectedTabState.save(
        JSON.stringify({
          name: "Settings",
          id: 13,
          children: [],
        })
      );
    }
  };

  // console.log("workers", workers);

  return (
    <>
      {workers && workers.length > 0 ? (
        <div
          style={{ maxHeight: "99%", height: "780px" }}
          className='overflow-y-auto custom-scroll'
        >
          <ul className='divide-y divide-gray-200'>
            {workers.map((worker, index) => (
              <li key={index} className='py-4'>
                <div className='flex space-x-3'>
                  <img
                    className='h-10 w-10 rounded-full object-cover'
                    src={
                      worker.photo
                        ? baseURLImage(worker.photo)
                        : getNameProfile(worker.first_name, worker.last_name)
                    }
                    alt=''
                  />
                  <div className='flex-1'>
                    <div className='flex items-center justify-between'>
                      <h3 className='text-sm font-medium'>{`${worker.first_name} ${worker.last_name}`}</h3>
                    </div>
                    <p className='text-sm text-gray-500'>{worker.job_title}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className='bg-white'>
          <div className='max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
            <h2 className='block pt-6 text-xl font-semibold text-gray-900'>
              No Workers Assigned
            </h2>
            <div className='p-6 mt-8 flex justify-center'>
              {roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager]) ? (
                <div
                  onClick={handleTabs}
                  tabIndex={0}
                  className='mt-2 text-sm text-gray-700 inline-flex hover:underline underline-offset-1 cursor-pointer hover:text-blue-800'
                >
                  Get started by assigning a new worker
                </div>
              ) : (
                <div className='mt-2 text-sm text-gray-700 inline-flex hover:underline underline-offset-1 cursor-pointer hover:text-blue-800'>
                  Get started by assigning a new worker
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkerSupportTeam;
