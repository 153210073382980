import { apiCall } from "./service";
import {
  URL_SIL_ACCOMMODATION_CREATE,
  URL_SIL_ACCOMMODATION_DELETE_IMAGE,
  URL_SIL_ACCOMMODATION_DETAIL,
  URL_SIL_ACCOMMODATION_DOCUMENT_FILE_REMOVE,
  URL_SIL_ACCOMMODATION_DOCUMENT_FILE_UPLOAD,
  URL_SIL_ACCOMMODATION_DOCUMENT_FOLDER_CREATE,
  URL_SIL_ACCOMMODATION_DOCUMENT_FOLDER_LIST,
  URL_SIL_ACCOMMODATION_DOCUMENT_FOLDER_REMOVE,
  URL_SIL_ACCOMMODATION_DOCUMENT_FOLDER_UPDATE,
  URL_SIL_ACCOMMODATION_FORM_DATA_BY_SLUG,
  URL_SIL_ACCOMMODATION_LIST,
  URL_SIL_ACCOMMODATION_QUICK_VIEW,
  URL_SIL_ACCOMMODATION_SETTINGS_UPDATE,
  URL_SIL_ACCOMMODATION_UPLOAD_IMAGE,
  URL_SIL_FORM_CATEGORIES,
  URL_SIL_FORM_DRAFT_TO_PUBLISH,
  URL_SIL_FORM_LIST,
  URL_SIL_PROPERTY_TYPE_LIST,
  URL_SIL_ROOM_ATTACHMENT_DELETE,
  URL_SIL_ROOM_ATTACHMENTS,
  URL_SIL_ROOM_CREATE,
  URL_SIL_ROOM_DELETE,
  URL_SIL_ROOM_DETAIL,
  URL_SIL_ROOM_NAME_UPDATE,
  URL_SIL_ROOM_UPDATE,
  URL_SIL_ROOMS_LIST,
  URL_SIL_SDA_TYPE_LIST,
} from "./URL";

const token = localStorage.getItem("accessToken");

export const api = {
  getPropertyTypeList() {
    return apiCall.get({
      url: URL_SIL_PROPERTY_TYPE_LIST,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  getSdaTypeList() {
    return apiCall.get({
      url: URL_SIL_SDA_TYPE_LIST,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  createSilAccommodationApi(params) {
    return apiCall.post({
      url: URL_SIL_ACCOMMODATION_CREATE,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
  silAccommodationListApi(params) {
    // console.log("params", params);
    return apiCall.post({
      url: URL_SIL_ACCOMMODATION_LIST,
      isAuthToken: token,
      skipQueryParams: false,
      params,
      bodyParams: params?.bodyFilter || null,
    });
  },
  silAccommodationQuickViewApi(uuid) {
    return apiCall.get({
      url: `${URL_SIL_ACCOMMODATION_QUICK_VIEW}?uuid=${uuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  silAccommodationDetailApi(params) {
    return apiCall.get({
      url: `${URL_SIL_ACCOMMODATION_DETAIL}?uuid=${params}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  updateSilAccommodationSettingsApi(params) {
    return apiCall.post({
      url: `${URL_SIL_ACCOMMODATION_SETTINGS_UPDATE}/${params.uuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
  silRoomsListApi(params) {
    return apiCall.get({
      url: `${URL_SIL_ROOMS_LIST}/${params?.uuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  silRoomDetailApi(params) {
    return apiCall.get({
      url: `${URL_SIL_ROOM_DETAIL}?uuid=${params?.uuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  silRoomDetailUpdateApi(params) {
    return apiCall.post({
      url: `${URL_SIL_ROOM_UPDATE}/${params?.uuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
  silRoomNameUpdateApi(params) {
    return apiCall.post({
      url: `${URL_SIL_ROOM_NAME_UPDATE}/${params?.uuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

  silRoomAttachmentUploadApi(params) {
    return apiCall.post({
      url: `${URL_SIL_ROOM_ATTACHMENTS}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

  silRoomAttachmentDeleteApi(params) {
    return apiCall.delete({
      url: `${URL_SIL_ROOM_ATTACHMENT_DELETE}?id=${params?.id}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },

  silRoomCreateApi(params) {
    return apiCall.post({
      url: `${URL_SIL_ROOM_CREATE}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

  silRoomDeleteApi(params) {
    return apiCall.delete({
      url: `${URL_SIL_ROOM_DELETE}?room_uuid=${params?.room_uuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },

  silHomeUploadImageApi(params) {
    return apiCall.post({
      url: `${URL_SIL_ACCOMMODATION_UPLOAD_IMAGE}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

  silHomeDeleteImageApi(params) {
    return apiCall.delete({
      url: `${URL_SIL_ACCOMMODATION_DELETE_IMAGE}?id=${params.id}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },

  // Sil Form
  silHomeNearByPropertyDataApi(params) {
    return apiCall.get({
      url: `${URL_SIL_ACCOMMODATION_FORM_DATA_BY_SLUG}?home_id=${params?.home_id}&slug=${params.slug}`,
      isAuthToken: token,
      params: {},
      skipQueryParams: true,
    });
  },
  silFormDraftToPublishApi(params) {
    return apiCall.post({
      url: `${URL_SIL_FORM_DRAFT_TO_PUBLISH}`,
      isAuthToken: token,
      params,
      skipQueryParams: true,
    });
  },
  silFormCategoriesApi() {
    return apiCall.get({
      url: `${URL_SIL_FORM_CATEGORIES}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  silAllFormsApi(params) {
    const parm = {
      ...params.filters,
    };
    return apiCall.post({
      url: URL_SIL_FORM_LIST,
      isAuthToken: token,
      params: { filter: params.bodyFilter, ...parm },
      skipQueryParams: true,
    });
  },
  getSilFolderListApi(params) {
    let url = `${URL_SIL_ACCOMMODATION_DOCUMENT_FOLDER_LIST}?home_uuid=${params.uuid}`;
    if (params?.folder_uuid) {
      url = `${url}&folder_uuid=${params?.folder_uuid}`;
    }
    return apiCall.get({
      url,
      isAuthToken: token,
      params: {},
      skipQueryParams: true,
    });
  },
  silDocumentFolderCreateApi(params) {
    return apiCall.post({
      url: `${URL_SIL_ACCOMMODATION_DOCUMENT_FOLDER_CREATE}`,
      isAuthToken: token,
      params,
      skipQueryParams: true,
    });
  },
  silDocumentFolderUpdateApi(params) {
    return apiCall.post({
      url: `${URL_SIL_ACCOMMODATION_DOCUMENT_FOLDER_UPDATE}`,
      isAuthToken: token,
      params,
      skipQueryParams: true,
    });
  },
  silDocumentFileUploadApi(params) {
    return apiCall.post({
      url: `${URL_SIL_ACCOMMODATION_DOCUMENT_FILE_UPLOAD}`,
      isAuthToken: token,
      params,
      skipQueryParams: true,
    });
  },
  silDocumentFileRemoveApi(params) {
    return apiCall.delete({
      url: `${URL_SIL_ACCOMMODATION_DOCUMENT_FILE_REMOVE}?file_management_id=${params.file_management_id}`,
      isAuthToken: token,
      params: {},
      skipQueryParams: true,
    });
  },
  silDocumentFolderRemoveApi(params) {
    return apiCall.delete({
      url: `${URL_SIL_ACCOMMODATION_DOCUMENT_FOLDER_REMOVE}?folder_id=${params.folder_id}`,
      isAuthToken: token,
      params: {},
      skipQueryParams: true,
    });
  },
};

export default api;
