import { combineReducers } from "redux";
import userReducer from "./reducers/auth.reducers";
import spAdminReducer from "./reducers/sp.reducers";
import participantReducer from "./reducers/participant.reducers";
import geenralReducer from "./reducers/general.reducers";
import cosReducer from "./reducers/cos.reducers";
import rosteringReducer from "./reducers/rostering.reducers";
import employeeReducer from "./reducers/employee.reducer";
import silReducer from "./reducers/sil.reducers";

import ACTIONS from "../store/constants";

const appReducer = combineReducers({
  users: userReducer,
  spAdmin: spAdminReducer,
  participant: participantReducer,
  general: geenralReducer,
  cos: cosReducer,
  rostering: rosteringReducer,
  employee: employeeReducer,
  sil: silReducer,
});

const rootReducer = (state, action) => {
  let prevState = state;
  if (
    action.type === ACTIONS.LOGOUT.SUCCESS ||
    action.type === ACTIONS.LOGOUT.ERROR
  ) {
    prevState = undefined;
  }

  return appReducer(prevState, action);
};

export default rootReducer;
