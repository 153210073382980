import React from "react";
import { roleAccess } from "helpers/RolesPermission";
import { BiPrinter } from "react-icons/bi";
import { PARENT_FORM_CATEGORY_SLUG, USER_ROLE } from "Constants/constant";
import DirectOpenFirstForm from "components/DynamicForms/DirectOpenFirstForm/DirectOpenFirstForm";
import CustomButton from "components/common/Button/CustomButton";
import CategoryFormsListing from "components/Modals/CategoryFormsListingHRM/CategoryFormsListing";
import { Link } from "react-router-dom";
import {
  baseURLImage,
  formatDateIntoDMYToDisplay,
  getNameProfile,
} from "Utils/utils";
import moment from "moment";
import NotificationDropDown from "components/common/NotificationDropDown/NotificationDropDown";

const workerProfileHeader = (props) => {
  const { selectedTab, workerInfo, isUserGracePeriodOver } = props;

  const loadHeaderButton = () => {
    if (roleAccess([USER_ROLE.Sp_Standard_User])) {
      switch (selectedTab) {
        case 1:
          return (
            <CustomButton
              clickHandler={() => {
                window.scrollTo(0, 0);
                window.print();
              }}
              label='Print Support Plan'
              Icon={<BiPrinter />}
            />
          );
        case 9:
          return (
            <DirectOpenFirstForm
              role='crm'
              buttonText='Injury management'
              participantId={workerInfo.id}
              slug={PARENT_FORM_CATEGORY_SLUG.INJURY_MANAGEMENT.SLUG}
              particpantUuid={workerInfo.uuid}
            />
          );
        case 5:
          return (
            <CategoryFormsListing
              role='hrm'
              participantId={workerInfo.id}
              buttonText='Compliance'
              slug={PARENT_FORM_CATEGORY_SLUG.Compliance.SLUG}
              particpantUuid={workerInfo.uuid}
            />
          );
        case 8:
          return (
            <CategoryFormsListing
              participantId={workerInfo.id}
              buttonText='High Care'
              slug={PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG}
              particpantUuid={workerInfo.uuid}
            />
          );
        default:
          <></>;
      }
    } else {
      switch (selectedTab) {
        case 5:
          return (
            <CategoryFormsListing
              role='hrm'
              participantId={workerInfo.id}
              buttonText='Compliance'
              slug={PARENT_FORM_CATEGORY_SLUG.Compliance.SLUG}
              particpantUuid={workerInfo.uuid}
            />
          );
        case 6:
          return (
            <CategoryFormsListing
              role='hrm'
              participantId={workerInfo.id}
              buttonText='Onboarding'
              slug={PARENT_FORM_CATEGORY_SLUG.Onboarding.SLUG}
              particpantUuid={workerInfo.uuid}
            />
          );
        case 8:
          return (
            <CategoryFormsListing
              role='hrm'
              participantId={workerInfo.id}
              buttonText='Other'
              slug={PARENT_FORM_CATEGORY_SLUG.Other.SLUG}
              particpantUuid={workerInfo.uuid}
            />
          );
        case 9:
          return (
            <CategoryFormsListing
              role='hrm'
              participantId={workerInfo.id}
              buttonText='Training And Development'
              slug={PARENT_FORM_CATEGORY_SLUG.Training_And_Development.SLUG}
              particpantUuid={workerInfo.uuid}
            />
          );
        case 18:
          return (
            <CategoryFormsListing
              role='hrm'
              buttonText='Performance Management'
              participantId={workerInfo.id}
              slug={PARENT_FORM_CATEGORY_SLUG.Performance_Management.SLUG}
              particpantUuid={workerInfo.uuid}
            />
          );
        case 19:
          return (
            <CategoryFormsListing
              role='hrm'
              buttonText={PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.NAME}
              participantId={workerInfo.id}
              slug={PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.SLUG}
              particpantUuid={workerInfo.uuid}
            />
          );
        default:
          <></>;
      }
    }
  };

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  return (
    <>
      {workerInfo && (
        <div className='px-6 flex justify-between items-center bg-white h-[80px] border-b border-gray-200'>
          <div className='px-6 flex justify-between items-center bg-white h-[80px] border-b border-gray-200 sticky top-0 z-[9]'>
            <div className='flex items-center space-x-4'>
              <div className=''>
                <div className='sm:flex sm:space-x-5'>
                  <div className='flex-shrink-0 relative top-[18px]'>
                    <img
                      onError={(e) =>
                        handleImageError(
                          e,
                          workerInfo.first_name,
                          workerInfo.last_name
                        )
                      }
                      style={{ height: "50px", width: "50px" }}
                      className='mx-auto rounded-full'
                      src={
                        workerInfo.photo
                          ? baseURLImage(workerInfo.photo)
                          : getNameProfile(
                              workerInfo.first_name,
                              workerInfo.last_name
                            )
                      }
                      alt=''
                    />
                  </div>
                  <div className='mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'>
                    <p className='text-xl font-bold text-gray-900 sm:text-2xl'>
                      {`${workerInfo.first_name || ""} ${
                        workerInfo.last_name || ""
                      }`}
                      {!workerInfo.status ? (
                        <span
                          className={`bg-red-100 relative ml-2 inline-flex h-fit rounded-full  px-2 text-xs font-semibold leading-5 text-black`}
                        >
                          <span className='deactiveDot text-red-500'>.</span>
                          <span className='pl-3 text-red-500'>Inactive</span>
                        </span>
                      ) : (
                        <span
                          className={`bg-green-100 relative ml-2 inline-flex h-fit rounded-full  px-2 text-xs font-semibold leading-5 text-black`}
                        >
                          <span className='deactiveDot text-green-500'>.</span>
                          <span className='pl-3 text-green-500'>active</span>
                        </span>
                      )}
                    </p>
                    <p className='text-sm font-medium text-gray-600'>
                      {workerInfo.email}
                    </p>
                    <p className='text-sm font-medium text-gray-600'>
                      <strong>{workerInfo.job_title}</strong> - Joining Date {formatDateIntoDMYToDisplay(workerInfo.joining_date)}
                    </p>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className='flex space-x-4 items-center text-sm print:hidden'>
            <div>{loadHeaderButton()}</div>
            <Link
              aria-disabled={isUserGracePeriodOver}
              to='#'
              onClick={(e) => {
                if (isUserGracePeriodOver) return;
                window.location.href = "mailto:info@vertex360.io";
                e.preventDefault();
              }}
              className='background-gradient border-gradient text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 tracking-normal border-2 border-solid py-2 px-4 text-sm font-semibold rounded-lg inline-flex items-center capitalize gap-x-1'
            >
              Send an Email
            </Link>
            <NotificationDropDown />
          </div>
        </div>
      )}
    </>
  );
};

export default workerProfileHeader;
