import { connect } from "react-redux";
import SilAccommodationSettings from "./SilAccommodationSettings";
import {
  getPropertyTypeList,
  getPropertyTypeLoading,
  getSdaTypeList,
  getSdaTypeLoading,
  getSelectedSilAccommodation,
  getSilAccommodationUpdateLoading,
} from "store/selector/sil.selector";
import {
  getAllCatalogServicesSubCategory,
  getCatalogSubCategoryStatesLoading,
} from "store/selector/cos.selector";
import {
  fetchPropertyTypeListRequest,
  fetchSdaTypeListRequest,
  updateSilAccommodationSettingRequest,
} from "store/actions/sil.actions";
import { getCatalogServicesSubCategoryRequest } from "store/actions/cos.actions";
import {
  getHRMTeamManagmentRequest,
  getPManagerRequest,
  getSPParticipantsListRequest,
} from "store/actions/sp.actions";
import {
  getManagers,
  getParticipantsListOptions,
  getSpManagersListLoading,
  getSpParticipantsListLoading,
  getTeamManagmentListOptions,
  getTeamManagmentListLoading,
} from "store/selector/sp.selector";
import { getStateOptions } from "store/selector/general.selector";

const mapDispatchToProps = {
  fetchPropertyTypeListRequest,
  fetchSdaTypeListRequest,
  getCatalogServicesSubCategoryRequest,
  updateSilAccommodationSettingRequest,
  getPManagerRequest,
  getSPParticipantsListRequest,
  getHRMTeamManagmentRequest,
};

const mapStateToProps = (state) => {
  const selectedSilAccommodation = getSelectedSilAccommodation(state);

  const propertyTypeList = getPropertyTypeList(state);
  const sdaTypeList = getSdaTypeList(state);
  const sdaTypeLoading = getSdaTypeLoading(state);
  const propertyTypeLoading = getPropertyTypeLoading(state);
  const allCatStates = getStateOptions(state);
  const catalogSubCategoryStatesLoading =
    getCatalogSubCategoryStatesLoading(state);

  const silAccommodationUpdateLoading = getSilAccommodationUpdateLoading(state);
  const spManagersListLoading = getSpManagersListLoading(state);
  const managersList = getManagers(state);

  const participantsListLoading = getSpParticipantsListLoading(state);
  const participantsList = getParticipantsListOptions(state);

  const workersListLoading = getTeamManagmentListLoading(state);
  const workersList = getTeamManagmentListOptions(state);

  return {
    propertyTypeList,
    sdaTypeList,
    sdaTypeLoading,
    propertyTypeLoading,
    allCatStates,
    catalogSubCategoryStatesLoading,
    selectedSilAccommodation,
    silAccommodationUpdateLoading,
    spManagersListLoading,
    managersList,
    participantsListLoading,
    participantsList,
    workersListLoading,
    workersList,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SilAccommodationSettings);
