import { connect } from "react-redux";
import SilHomeProfile from "./SilHomeProfile";
import {
  fetchSilAccommodationDatailRequest,
  setSelectedSilAccommodationLocally,
} from "store/actions/sil.actions";
import {
  getSelectedSilAccommodation,
  getSilAccommodationDetailLoading,
} from "store/selector/sil.selector";

const mapDispatchToProps = {
  setSelectedSilAccommodationLocally,
  fetchSilAccommodationDatailRequest,
};

const mapStateToProps = (state) => {
  const selectedSilAccommodation = getSelectedSilAccommodation(state);
  const silAccommodationDetailLoading = getSilAccommodationDetailLoading(state);
  return { selectedSilAccommodation, silAccommodationDetailLoading };
};

export default connect(mapStateToProps, mapDispatchToProps)(SilHomeProfile);
