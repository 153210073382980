import React, { useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import {
  capitalizeFirstLetter,
  formatDateIntoDMYToDisplay,
  parseBooleanVal,
} from "Utils/utils";
import SilAccommodationQuickView from "./SilAccommodationQuickView";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { useNavigate } from "react-router";

const SilAccommodationTableItem = ({ index, home }) => {
  const [isOpenQuickView, setIsOpenQuickView] = useState(false);
  // console.log("home", home);
  const navigate = useNavigate();
  return (
    <>
      <tr key={index} className='even:bg-white odd:bg-gray-50'>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <div
            role='button'
            className='text-gray-900'
            onClick={() => {
              navigate(`/sil-accommodation/${home?.uuid}`);
            }}
          >
            {/* {`${home?.street_address},${home?.postal_code || ""}`}
            <br /> {home?.suburb} */}
            {`${capitalizeFirstLetter(home?.street_address)},`}
            <br />{" "}
            {`${capitalizeFirstLetter(home?.suburb)}, ${
              home?.state?.name?.toUpperCase() || ""
            } ${home?.postal_code}`}
          </div>
        </td>

        <td className='whitespace-nowrap py-4  px-3 text-sm'>
          <span className='flex items-center justify-center'>
            <AvatarGroup
              type='Participant'
              arrayData={home?.participants || []}
              cssClass='mt-0'
              maxLength={5}
              noDataMsg='-'
            />
          </span>
        </td>

        <td className='whitespace-nowrap py-4  px-3 text-sm'>
          <span className='flex items-center justify-center'>
            <AvatarGroup
              type='worker'
              arrayData={home?.workers || []}
              cssClass='mt-0'
              maxLength={5}
              noDataMsg='-'
            />
          </span>
        </td>

        <td className='whitespace-nowrap py-4  px-3 text-sm'>
          <span className='flex items-center justify-center'>
            {home?.region?.name || "-"}
          </span>
        </td>

        {/* <td className='px-2 py-[11px] text-sm text-gray-500 items-center text-center'>
          <span
            className={`${
              home?.status === true
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 capitalize items-center`}
          >
            {parseBooleanVal(home?.status) ? "Active" : "Inactive"}
          </span>
        </td> */}

        <td className='whitespace-nowrap py-4  px-3 text-sm'>
          <span className='flex items-center justify-center'>
            {home?.total_vacant || "-"}
          </span>
        </td>

        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium'>
          <div className='flex items-center justify-start gap-x-3'>
            <div
              onClick={() => {
                setIsOpenQuickView(true);
              }}
              tabIndex={0}
              role='button'
              className='icon-eye-wrapper'
              title='View'
            >
              <HiOutlineEye className='icon-size' />
              <span className='sr-only'>{home?.uuid}</span>
            </div>
          </div>
        </td>
      </tr>
      {isOpenQuickView && (
        <SilAccommodationQuickView
          selectedHome={home}
          isOpen={isOpenQuickView}
          setIsOpen={() => setIsOpenQuickView(false)}
          paddingLeft=''
        />
      )}
    </>
  );
};

export default SilAccommodationTableItem;
