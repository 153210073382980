import React, {
  Fragment,
  useState,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { NavLink, useNavigate, useLocation, Link } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import { MenuAlt1Icon, XIcon } from "@heroicons/react/outline";
import logo from "../../../assets/logo/colorNWhite.svg";
import logoIcon from "../../../assets/logo/favIcon.svg";
import { BsArrowLeftShort } from "react-icons/bs";
import { USER_ROLE } from "../../../Constants/constant";
import {
  baseURLImage,
  classNames,
  getNameProfile,
  getUserTypeFromLC,
  parseBooleanVal,
  textTruncate,
} from "../../../Utils/utils";
import { connect } from "react-redux";
import { getSpSetting } from "../../../store/selector/sp.selector";
import { getUser } from "../../../store/selector/auth.selector";
import { LoginContext } from "../../../helpers/LoginContext";
import { roleAccess } from "../../../helpers/RolesPermission";
import { SpStandardUserNavigation } from "../../../Constants/Navigations/SpStandardUserNavigations";
import { SuperAdminNavigation } from "../../../Constants/Navigations/SuperAdminNavigations";
import { SpAdminNavigation } from "../../../Constants/Navigations/SpAdminNavigations";
import LogoutModel from "../../Modals/LogoutModal";
import { getSpSettingFetchRequest } from "store/actions/sp.actions";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowDownSLine } from "react-icons/ri";
// import ForgetPassword from "pages/Authentication/ForgetPassword/ForgetPassword";
import Version from "Versions";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import NotificationDropDown from "../NotificationDropDown/NotificationDropDown";

const SideBar = (props) => {
  const { getSpSettingRequest, spAdminSettingData, currentLoginUser } = props;

  const { sideBarPadding, setSideBarPadding, refreshNavigations } =
    useContext(LoginContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLogoutModal, setIsLogoutModal] = useState(false);
  const [userType] = useState(getUserTypeFromLC());
  const [showSubMenu, setShowSubMenu] = useState(false);

  const [user, setUser] = useState({
    name: null,
    email: null,
    profile_picture: null,
  });

  useEffect(() => {
    if (
      currentLoginUser &&
      Object.keys(currentLoginUser).length > 0
      // user.name !== currentLoginUser.name &&
      // user.email !== currentLoginUser.email
    ) {
      setUser({
        name: currentLoginUser.name ?? null,
        email: currentLoginUser.email ?? null,
        profile_picture:
          "profile_picture" in currentLoginUser
            ? currentLoginUser.profile_picture
            : null,
      });
    }
  }, [currentLoginUser]);

  useEffect(() => {
    if (
      currentLoginUser &&
      currentLoginUser?.uuid &&
      (!spAdminSettingData || spAdminSettingData === null) &&
      roleAccess([USER_ROLE.Sp_Admin])
    ) {
      getSpSettingRequest();
    }
  }, [spAdminSettingData]);

  const leftNavigation = useMemo(() => {
    if (!userType) {
      return [];
    }
    switch (userType) {
      case USER_ROLE.Super_Admin:
        return SuperAdminNavigation;
      case USER_ROLE.Sp_Admin:
        return SpAdminNavigation.filter((x) =>
          x.role.find((y) => y === userType)
        );
      case USER_ROLE.Sp_manager:
        return SpAdminNavigation.filter((x) =>
          x.role.find((y) => y === userType)
        ).filter(
          (u) =>
            !u.hasOwnProperty("permissionName") ||
            roleAccess([], u?.permissionName)
        );
      case USER_ROLE.Sp_Standard_User:
        return SpStandardUserNavigation.filter(
          (u) =>
            !u.hasOwnProperty("permissionName") ||
            roleAccess([], u?.permissionName)
        );
      default:
        return [];
    }
  }, [userType, currentLoginUser, refreshNavigations]);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const imgRender = useMemo(() => {
    if (currentLoginUser && Object.keys(currentLoginUser).length > 0) {
      return (
        <img
          onError={(e) => handleImageError(e, currentLoginUser.name, " ")}
          className='w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 object-cover'
          src={
            "profile_picture" in currentLoginUser &&
            currentLoginUser.profile_picture &&
            !currentLoginUser.profile_picture.includes("www.pngitem.com")
              ? roleAccess([USER_ROLE.Sp_manager])
                ? getNameProfile(currentLoginUser.name, " ")
                : baseURLImage(currentLoginUser.profile_picture)
              : getNameProfile(currentLoginUser.name, " ")
          }
          alt=''
        />
      );
    }
    return (
      <img
        onError={(e) => handleImageError(e, user.name ? user.name : " ", " ")}
        className='w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 object-cover'
        src={
          user &&
          user.profile_picture &&
          !user.profile_picture.includes("www.pngitem.com")
            ? roleAccess([USER_ROLE.Sp_manager])
              ? getNameProfile(user.name ? user.name : " ", " ")
              : baseURLImage(user.profile_picture)
            : getNameProfile(user.name ? user.name : " ", " ")
        }
        alt=''
      />
    );
  }, [currentLoginUser, spAdminSettingData]);

  const logoRender = useMemo(() => {
    if (spAdminSettingData && spAdminSettingData?.logo) {
      return baseURLImage(spAdminSettingData?.logo);
    } else if (currentLoginUser && currentLoginUser?.service_provider?.logo) {
      return baseURLImage(currentLoginUser?.service_provider?.logo);
    } else if (!sideBarPadding) {
      return logo;
    } else {
      return logoIcon;
    }
  }, [spAdminSettingData, currentLoginUser, sideBarPadding]);

  return (
    <div className='print:hidden z-[9999]'>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-40 lg:hidden'
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 flex z-40'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className=' z-[50] relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className=' z-[50] absolute top-0 right-0 -mr-12 pt-2'>
                    <button
                      type='button'
                      className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XIcon
                        className='h-6 w-6 text-white'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className='flex-shrink-0 flex items-center px-4'>
                  <img className='h-8 w-auto' src={logoRender} alt='Workflow' />
                </div>
                <div className=' flex-1 h-0 overflow-y-auto'>
                  <nav className='px-2'>
                    <div className='space-y-1'>
                      {leftNavigation.map((item, index) => (
                        <NavLink
                          key={`${item.name}-${index}`}
                          to={item.to}
                          className={classNames(
                            "text-gray-600 hover:text-gray-900 hover:bg-gray-50",
                            "group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              "text-gray-400 group-hover:text-gray-500",
                              "mr-3 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden='true'
                          />
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className='flex-shrink-0 w-14' aria-hidden='true' />
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={`${
          roleAccess([USER_ROLE.Sp_Standard_User])
            ? "bg-gray-900"
            : "bg-gradient"
        } hidden lg:flex lg:flex-col ${
          sideBarPadding ? "lg:w-16" : "lg:w-64"
        } absolute z-[500] lg:fixed duration-300 lg:inset-y-0 lg:border-r lg:border-gray-200   lg:bg-gray-100`}
      >
        <BsArrowLeftShort
          className={`absolute -right-3 top-19 bg-white text-purple-600 text-3xl mt-5 rounded-full border border-purple-600 cursor-pointer ${
            sideBarPadding && "rotate-180"
          } `}
          onClick={() => {
            setSideBarPadding(!sideBarPadding);
          }}
        />

        <div
          className={`flex items-center ${
            sideBarPadding ? "px-0" : "px-6 flex-shrink-0"
          }`}
          role='button'
          tabIndex={0}
          onClick={() => navigate("/dashboard")}
        >
          {sideBarPadding ? (
            //small
            <img className='h-10 w-15 ml-2' src={logoRender} alt='vertex 360' />
          ) : (
            // large
            <img className='logo h-8 w-auto' src={logoRender} alt='vertex360' />
          )}
        </div>

        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className=' h-0 flex-1 flex flex-col'>
          {/* User account dropdown */}

          {!sideBarPadding ? (
            <Menu as='div' className='px-3  relative inline-block text-left'>
              <div>
                <Menu.Button
                  className={`${
                    roleAccess([USER_ROLE.Sp_Standard_User])
                      ? "profile-bg-hover bg-profile group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-ndisPurple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
                      : "profile-bg-hover bg-profile group w-full bg-gray-100 rounded-md px-3.5 lg:py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
                  }`}
                >
                  <span className='flex w-full justify-between items-center'>
                    <span className='flex min-w-0 items-center justify-between space-x-3'>
                      {imgRender}
                      <span className='flex-1 flex flex-col min-w-0'>
                        <span
                          className='text-white'
                          title={
                            currentLoginUser &&
                            Object.keys(currentLoginUser).length > 0 &&
                            currentLoginUser.name !== null
                              ? currentLoginUser.name
                              : user.name
                          }
                        >
                          {currentLoginUser &&
                          Object.keys(currentLoginUser).length > 0 &&
                          currentLoginUser.name !== null
                            ? textTruncate(currentLoginUser.name, 15, 14)
                            : textTruncate(user.name, 15, 14)}
                        </span>
                        <span
                          title={
                            currentLoginUser &&
                            Object.keys(currentLoginUser).length > 0 &&
                            currentLoginUser.email !== null
                              ? currentLoginUser.email
                              : user.email
                          }
                          className='user-username text-gray-500 text-sm truncate'
                        >
                          {currentLoginUser &&
                          Object.keys(currentLoginUser).length > 0 &&
                          currentLoginUser.email !== null
                            ? currentLoginUser.email
                            : user.email}
                        </span>
                      </span>
                      <SelectorIcon
                        className='flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                        aria-hidden='true'
                      />
                    </span>
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='z-[50] mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none'>
                  <div className='py-1 cursor-pointer'>
                    <Menu.Item>
                      {({ active }) => (
                        <>
                          {roleAccess([
                            USER_ROLE.Sp_Admin,
                            USER_ROLE.Sp_Standard_User,
                          ]) && (
                            <>
                              <span
                                tabIndex={0}
                                role='button'
                                onMouseEnter={() => setShowSubMenu(true)}
                                onMouseLeave={() => setShowSubMenu(false)}
                                className={classNames(
                                  "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                                  "block px-4 py-2 text-sm flex items-center justify-between"
                                )}
                              >
                                {/* Conditional rendering of the arrow icons */}

                                <Menu.Button>Settings</Menu.Button>
                                {showSubMenu ? (
                                  <RiArrowDownSLine className='inline-block h-5 w-5 text-gray-500' />
                                ) : (
                                  <RiArrowRightSLine className='inline-block h-5 w-5 text-gray-500' />
                                )}
                              </span>
                              {showSubMenu && (
                                <Transition
                                  as='div'
                                  enter='transition ease-out duration-100'
                                  enterFrom='transform opacity-0 scale-95'
                                  enterTo='transform opacity-100 scale-100'
                                  leave='transition ease-in duration-75'
                                  leaveFrom='transform opacity-100 scale-100'
                                  leaveTo='transform opacity-0 scale-95'
                                >
                                  <Menu.Items
                                    className='z-[50] origin-top-left absolute left-full top-1 w-48  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none'
                                    onMouseEnter={() => setShowSubMenu(true)}
                                    onMouseLeave={() => setShowSubMenu(false)}
                                  >
                                    <div className='py-1 cursor-pointer sticky'>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <span
                                            tabIndex={0}
                                            role='button'
                                            className={classNames(
                                              "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                            onClick={() => {
                                              if (
                                                roleAccess([USER_ROLE.Sp_Admin])
                                              ) {
                                                navigate("/settings");
                                              } else if (
                                                roleAccess([
                                                  USER_ROLE.Sp_Standard_User,
                                                ])
                                              ) {
                                                navigate("/settings");
                                              }
                                              setShowSubMenu(false);
                                            }}
                                          >
                                            {roleAccess([USER_ROLE.Sp_Admin])
                                              ? "Business Settings"
                                              : "Personal Settings"}
                                          </span>
                                        )}
                                      </Menu.Item>
                                      {roleAccess([USER_ROLE.Sp_Admin]) && (
                                        <Menu.Item>
                                          {({ active }) => (
                                            <span
                                              tabIndex={0}
                                              role='button'
                                              onClick={() => {
                                                navigate("/payroll-setting");
                                                setShowSubMenu(false);
                                              }}
                                              className={classNames(
                                                "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                                                "block px-4 py-2 text-sm"
                                              )}
                                            >
                                              Payroll Settings
                                            </span>
                                          )}
                                        </Menu.Item>
                                      )}
                                      {roleAccess([USER_ROLE.Sp_Admin]) && (
                                        <Menu.Item>
                                          {({ active }) => (
                                            <span
                                              tabIndex={0}
                                              role='button'
                                              onClick={() => {
                                                navigate(
                                                  "/notification-setting"
                                                );
                                                setShowSubMenu(false);
                                              }}
                                              className={classNames(
                                                "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                                                "block px-4 py-2 text-sm"
                                              )}
                                            >
                                              Notifications Settings
                                            </span>
                                          )}
                                        </Menu.Item>
                                      )}
                                      {roleAccess([USER_ROLE.Sp_Admin]) && (
                                        <Menu.Item>
                                          {({ active }) => (
                                            <span
                                              tabIndex={0}
                                              role='button'
                                              onClick={() => {
                                                navigate(
                                                  "/roles-permissions-setting"
                                                );
                                                setShowSubMenu(false);
                                              }}
                                              className={classNames(
                                                "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                                                "block px-4 py-2 text-sm"
                                              )}
                                            >
                                              Roles & Permissions Settings
                                            </span>
                                          )}
                                        </Menu.Item>
                                      )}
                                      {roleAccess([USER_ROLE.Sp_Admin]) &&
                                        parseBooleanVal(
                                          currentLoginUser?.payment_setting
                                            ?.wildcard
                                        ) === false && (
                                          <Menu.Item>
                                            {({ active }) => (
                                              <span
                                                tabIndex={0}
                                                role='button'
                                                onClick={() => {
                                                  navigate("/subscription");
                                                  setShowSubMenu(false);
                                                }}
                                                className={classNames(
                                                  "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                                                  "block px-4 py-2 text-sm"
                                                )}
                                              >
                                                Subscription Details
                                              </span>
                                            )}
                                          </Menu.Item>
                                        )}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              )}
                            </>
                          )}
                          {roleAccess([USER_ROLE.Sp_manager]) &&
                            roleAccess(
                              [USER_ROLE.Sp_manager],
                              PERMISSION_KEYS.ROSTERING,
                              PERMISSION_KEYS_CHILDREN.ROSTERING
                                .TIMESHEET_EXPORT.SHOW
                            ) && (
                              <>
                                <span
                                  tabIndex={0}
                                  role='button'
                                  onMouseEnter={() => setShowSubMenu(true)}
                                  onMouseLeave={() => setShowSubMenu(false)}
                                  className={classNames(
                                    "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                                    "block px-4 py-2 text-sm flex items-center justify-between"
                                  )}
                                >
                                  <Menu.Button>Settings</Menu.Button>
                                  {showSubMenu ? (
                                    <RiArrowDownSLine className='inline-block h-5 w-5 text-gray-500' />
                                  ) : (
                                    <RiArrowRightSLine className='inline-block h-5 w-5 text-gray-500' />
                                  )}
                                </span>
                                {showSubMenu && (
                                  <Transition
                                    as='div'
                                    enter='transition ease-out duration-100'
                                    enterFrom='transform opacity-0 scale-95'
                                    enterTo='transform opacity-100 scale-100'
                                    leave='transition ease-in duration-75'
                                    leaveFrom='transform opacity-100 scale-100'
                                    leaveTo='transform opacity-0 scale-95'
                                  >
                                    <Menu.Items
                                      className='origin-top-left absolute left-full top-6 w-48  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none'
                                      onMouseEnter={() => setShowSubMenu(true)}
                                      onMouseLeave={() => setShowSubMenu(false)}
                                    >
                                      <div className='py-1 cursor-pointer'>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <span
                                              tabIndex={0}
                                              role='button'
                                              onClick={() => {
                                                navigate("/payroll-setting");
                                                setShowSubMenu(false);
                                              }}
                                              className={classNames(
                                                "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                                                "block px-4 py-2 text-sm"
                                              )}
                                            >
                                              Payroll Settings
                                            </span>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                )}
                              </>
                            )}
                          <span
                            tabIndex={0}
                            role='button'
                            onClick={() => setIsLogoutModal(true)}
                            className={classNames(
                              "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Logout
                          </span>
                        </>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <Menu as='div' className='ml-4 relative'>
              <div>
                <Menu.Button className='max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'>
                  <span className='sr-only'>Open user menu</span>
                  {imgRender}
                </Menu.Button>
              </div>
            </Menu>
          )}

          {/* Navigation */}
          <nav className='px-3 mt-1'>
            <div
              className='overflow-y-auto flex-1 gray-scroll mb'
              style={{
                maxHeight: `calc(100vh - ${sideBarPadding ? "10rem" : "10rem"})`,
                overflowY: "auto",
              }}
            >
              {leftNavigation.map((item, index) => (
                <NavLink
                  tabIndex={0}
                  role='button'
                  key={`${index}-${item.name}`}
                  to={item.to}
                  className={classNames(
                    location.pathname === item.to ||
                      location.pathname.includes(item.current)
                      ? `${
                          roleAccess([USER_ROLE.Sp_Standard_User])
                            ? "navLink-title bg-ndisPurple text-gray-900 flex px-2 py-2 rounded-md"
                            : // : "navLink-title bg-gray-200 text-gray-900"
                              "bg-profile text-gray-700 hover:text-gray-900 hover:bg-gray-50 navLink-title group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        }`
                      : `${
                          roleAccess([USER_ROLE.Sp_Standard_User])
                            ? "text-gray-700 hover:text-white hover:bg-ndisPurple navLink-title group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            : "text-gray-700 hover:text-gray-900 hover:bg-gray-50 navLink-title group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        }`
                  )}
                >
                  <item.icon
                    className={classNames(
                      "text-gray-400 group-hover:text-black",
                      "navLink-title mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden='true'
                  />
                  <div className={`${sideBarPadding && "hidden scale-0"}`}>
                    {item.name}
                  </div>
                </NavLink>
              ))}
            </div>
          </nav>
          <div
            className={`absolute bottom-0  bg-gradient pt-2  w-full  text-center`}
          >
            <Version sideBarPadding={sideBarPadding} />
            <p className='text-xs text-blue-400'>
              <Link to='/release-notes'>Release Notes</Link>
            </p>
          </div>
        </div>
      </div>

      <div className='sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden'>
        <button
          type='button'
          className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden'
          onClick={() => setSidebarOpen(true)}
        >
          <span className='sr-only'>Open sidebar</span>
          <MenuAlt1Icon className='h-6 w-6' aria-hidden='true' />
        </button>
        <div className='flex-1 flex justify-end px-4 sm:px-6 lg:px-8'>
          {currentLoginUser?.id && (
            <div className='md:hidden flex items-center'>
              <NotificationDropDown cssClass='!block' />
            </div>
          )}
          <div className='flex items-center'>
            {/* Profile dropdown */}
            <Menu as='div' className='ml-3 relative'>
              <div>
                <Menu.Button className='max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'>
                  <span className='sr-only'>Open user menu</span>
                  {imgRender}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none'>
                  <div className='py-1 cursor-pointer'>
                    <Menu.Item>
                      {({ active }) => (
                        <>
                          {roleAccess([USER_ROLE.Sp_Admin]) && (
                            <span
                              tabIndex={0}
                              role='button'
                              onClick={() => navigate("/settings")}
                              className={classNames(
                                "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Settings
                            </span>
                          )}
                          <span
                            tabIndex={0}
                            role='button'
                            onClick={() => setIsLogoutModal(true)}
                            className={classNames(
                              "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Logout
                          </span>
                        </>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      {/* Logout Modal  */}
      {isLogoutModal && (
        <LogoutModel
          isOpen={isLogoutModal}
          closeModal={() => setIsLogoutModal(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const spAdminSettingData = getSpSetting(state);
  const currentLoginUser = getUser(state);
  return { spAdminSettingData, currentLoginUser };
};

const mapDispatchToProps = {
  getSpSettingRequest: getSpSettingFetchRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
