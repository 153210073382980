import { DUMMY_REGEX_EXP } from "Constants/constant";
import { classNames } from "Utils/utils";
import React from "react";
// import { useRef, useEffect } from "react";

const InputField = (props, ref) => {
  const {
    label,
    type,
    placeHolder,
    handleChange,
    ErrorMessage,
    value,
    blurHandler,
    name,
    readOnly,
    pattern = null,
    min,
    max,
    maxLength,
    isDisabled = false,
    register = null,
    setValue = null,
    isGeneralPattern = false,
    isRequired = false,
    isOnKeyUp = false,
    icon,
    handleClickIcon,
    validate,
    cssClass,
    inputWidth,
    minLength,
    redstar,
    autoComplete,
    id,
    ...rest
  } = props;

  const addRegisterComponent = () => {
    if (register && register !== null) {
      return {
        ...register(name, {
          required: isRequired,
          value: value,
          disabled: isDisabled,
          readOnly: readOnly,
          maxLength: maxLength,
          max: max,
          min: min,
          validate: () => validate,
          onChange: (e) => {
            const val = e.target.value;
            const nativeEventVal = e.nativeEvent.data;
            if (
              isGeneralPattern &&
              pattern instanceof RegExp &&
              nativeEventVal !== null &&
              (pattern.test(nativeEventVal) || pattern.test(val))
            ) {
              e.target.value = val;
            } else if (
              isGeneralPattern &&
              pattern instanceof RegExp &&
              nativeEventVal !== null &&
              (!pattern.test(nativeEventVal) || !pattern.test(val))
            ) {
              setValue(name, value);
              e.target.value = value;
            } else if (pattern === false || pattern === null) {
              e.target.value = val;
            }
          },
          pattern:
            pattern !== null && pattern !== false && value
              ? pattern.test(value)
                ? false
                : DUMMY_REGEX_EXP
              : false,
        }),
      };
    } else {
      return {
        ...{
          value: value === null ? "" : value,
        },
      };
    }
  };

  const handleOnChange = (e) => {
    if (pattern && handleChange) {
      handleChange(e.target.validity.valid ? e.target.value : value);
    } else if (handleChange) {
      handleChange(e.target.value);
    }
  };

  return (
    <>
      {label && (
        <label
          htmlFor={label}
          className='block text-sm font-medium text-gray-700'
        >
          {redstar ? (
            <>
              {label} <span className='text-red-500'>*</span>
            </>
          ) : (
            label
          )}
        </label>
      )}
      <div className={`flex relative content-center ${inputWidth}`}>
        <input
          id={id}
          autoComplete={autoComplete}
          // ref={inputRef}
          autoFocus={ErrorMessage ? true : false}
          // ref={inputRef}
          //autoFocus={ErrorMessage ? true : false}
          {...addRegisterComponent()}
          minLength={minLength}
          maxLength={maxLength}
          readOnly={readOnly ? readOnly : ""}
          name={name}
          type={type}
          {...(register === null && {
            onChange: (e) => handleOnChange(e),
            pattern: pattern,
            disabled: isDisabled,
            maxLength: maxLength,
            max: max,
            required: isRequired,
          })}
          style={{
            backgroundColor:
              isDisabled || readOnly ? "#4b6bb710" : "transparent",
          }}
          placeholder={placeHolder ? placeHolder : ""}
          className={classNames(
            "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md did-floating-input relative",
            (isDisabled || readOnly) && "bg-[#4b6bb710]",
            ErrorMessage && "errorBorder",
            cssClass && cssClass
          )}
          onBlur={(e) => {
            if (blurHandler) blurHandler(e);
          }}
          onKeyUp={(e) => {
            if (isOnKeyUp) blurHandler(e);
          }}
          {...rest}
        />
        {icon && (
          <span
            {...(handleClickIcon && {
              onClick: () => handleClickIcon(),
            })}
            style={{ top: "15px", right: "10px" }}
            // className={`pointer absolute top-1/2 -translate-y-1/2 right-4 text-blue-dark
            // ${
            //   request.isFailed ? "textRed" : "text-blue-dark"
            // }`}
            className={`${
              handleClickIcon ? "pointer" : ""
            } absolute text-blue-dark`}
          >
            {icon}
          </span>
        )}
      </div>
      {ErrorMessage && (
        <span
          title={ErrorMessage}
          className='truncate'
          style={{ fontSize: "13px", color: "red", marginLeft: "5px" }}
          // className="capitalize"
        >
          {ErrorMessage}
        </span>
      )}
    </>
  );
};

export default InputField;
