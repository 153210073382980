import React, { useState } from "react";
import MultiFileInput from "components/common/FileUpload/MultiFileInput";
import HomeImageModal from "./HomeImagesModal";
import { toast } from "react-toastify";
import _ from "lodash";
import api from "store/services/sil.services";
import { useDispatch } from "react-redux";
import { setSelectedSilAccommodationImageLocally } from "store/actions/sil.actions";

const PropertyAddressImgUploader = ({ selectedAccommodationInfo }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteFileLoader, setisDeleteFileLoader] = useState(null);

  const dispatch = useDispatch();

  const handleDelete = async (item) => {
    try {
      setisDeleteFileLoader(item.id);
      const response = await api.silHomeDeleteImageApi({ id: item.id });
      if (response) {
        toast.success("Attachment Deleted Successfully!");
        const prevData = _.cloneDeep(selectedAccommodationInfo.images);
        const index = prevData.findIndex((x) => x.id === item.id);
        if (index !== -1) {
          prevData.splice(index, 1);
        }
        handleUpdateImagesLocally(prevData);
      }
      setisDeleteFileLoader(null);
    } catch (err) {
      setisDeleteFileLoader(null);
      console.log("err", err);
    }
  };

  const handleUpdateImagesLocally = (data) => {
    dispatch(setSelectedSilAccommodationImageLocally(data));
  };
  return (
    <div className='rounded-lg border border-gray-300 shadow-sm p-4'>
      <div>
        <MultiFileInput
          callFrom='Silhome-Property-profile'
          onlyShow={false}
          files={selectedAccommodationInfo?.images?.map((x) => {
            const imgPath = x.hasOwnProperty("path") ? x?.path : x?.file_path;
            return { ...x, path: imgPath, file_path: imgPath };
          })}
          isClickUploaderButton={(val) => {
            if (val) {
              setIsOpenModal(true);
            }
          }}
          isDeleteFileLoader={isDeleteFileLoader}
          handleDelete={(item) => handleDelete(item)}
          noFileMsg='No Home Attachments Found!'
        />
      </div>
      {isOpenModal && (
        <HomeImageModal
          handleUpdateImagesLocally={handleUpdateImagesLocally}
          selectedAccommodationInfo={selectedAccommodationInfo}
          isOpen={isOpenModal}
          handleClose={() => {
            setIsOpenModal(false);
          }}
        />
      )}
    </div>
  );
};

export default PropertyAddressImgUploader;
