import ACTIONS, { FETCH_STATUS } from "../constants";

export const fetchPropertyTypeListRequest = () => ({
  type: ACTIONS.GET_PROPERTY_TYPE_CALL,
});

export const propertyTypeListfetched = {
  pending: {
    type: ACTIONS.GET_PROPERTY_TYPE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_PROPERTY_TYPE.SUCCESS,
    data: data,
  }),
  error: (error) => ({
    type: ACTIONS.GET_PROPERTY_TYPE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchSdaTypeListRequest = () => ({
  type: ACTIONS.GET_SDA_TYPE_CALL,
});

export const sdaTypeListfetched = {
  pending: {
    type: ACTIONS.GET_SDA_TYPE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_SDA_TYPE.SUCCESS,
    data: data,
  }),
  error: (error) => ({
    type: ACTIONS.GET_SDA_TYPE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createSilAccommodationRequest = (payload, callback) => ({
  type: ACTIONS.CREATE_SIL_ACCOMMODATION_CALL,
  payload,
  callback,
});

export const silAccommodationCreated = {
  pending: {
    type: ACTIONS.CREATE_SIL_ACCOMMODATION.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CREATE_SIL_ACCOMMODATION.SUCCESS,
    data: data,
  }),
  error: (error) => ({
    type: ACTIONS.CREATE_SIL_ACCOMMODATION.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchSilAccommodationListRequest = (payload, callback) => ({
  type: ACTIONS.GET_SIL_ACCOMMODATION_LIST_CALL,
  payload,
  callback,
});

export const silAccommodationListfetched = {
  pending: {
    type: ACTIONS.GET_SIL_ACCOMMODATION_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_SIL_ACCOMMODATION_LIST.SUCCESS,
    data: data,
  }),
  error: (error) => ({
    type: ACTIONS.GET_SIL_ACCOMMODATION_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

// Selected Home
export const setSelectedSilAccommodationLocally = (payload) => ({
  type: ACTIONS.SET_SELECTED_SIL_ACCOMMODATION_LOCALLY,
  data: payload,
});

export const setSelectedSilAccommodationImageLocally = (payload) => ({
  type: ACTIONS.SET_SELECTED_SIL_ACCOMMODATION_IMAGE_LOCALLY,
  data: payload,
});

export const setSelectedSilAccommodationRoomVacantLocally = (payload) => ({
  type: ACTIONS.SET_SELECTED_SIL_ACCOMMODATION_ROOM_VACANT_LOCALLY,
  data: payload,
});

export const fetchSilAccommodationDatailRequest = (payload, callback) => ({
  type: ACTIONS.GET_SIL_ACCOMMODATION_DETAIL_CALL,
  payload,
  callback,
});

export const silAccommodationDetailfetched = {
  pending: {
    type: ACTIONS.GET_SIL_ACCOMMODATION_DETAIL.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_SIL_ACCOMMODATION_DETAIL.SUCCESS,
    data: data,
  }),
  error: (error) => ({
    type: ACTIONS.GET_SIL_ACCOMMODATION_DETAIL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateSilAccommodationSettingRequest = (payload, callback) => ({
  type: ACTIONS.UPDATE_SIL_ACCOMMODATION_SETTINGS_CALL,
  payload,
  callback,
});

export const silAccommodationSettingsUpdated = {
  pending: {
    type: ACTIONS.UPDATE_SIL_ACCOMMODATION_SETTINGS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.UPDATE_SIL_ACCOMMODATION_SETTINGS.SUCCESS,
    data: data,
  }),
  error: (error) => ({
    type: ACTIONS.UPDATE_SIL_ACCOMMODATION_SETTINGS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchAllSilRoomsListRequest = (payload, callback = null) => ({
  type: ACTIONS.GET_SIL_ACCOMMODATION_ROOMS_LIST_CALL,
  payload,
  callback,
});

export const silRoomsListFetched = {
  pending: {
    type: ACTIONS.GET_SIL_ACCOMMODATION_ROOMS_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_SIL_ACCOMMODATION_ROOMS_LIST.SUCCESS,
    data: data,
  }),
  error: (error) => ({
    type: ACTIONS.GET_SIL_ACCOMMODATION_ROOMS_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const setSelectedSilAccommodationRoomLocally = (payload, uuid) => ({
  type: ACTIONS.SET_SELECTED_SIL_ACCOMMODATION_ROOM_LOCALLY,
  payload,
  room_uuid: uuid,
});

export const deleteSilRoomRequest = (
  payload,
  callback = null,
  home_uuid = null
) => ({
  type: ACTIONS.SIL_ACCOMMODATION_ROOM_DELETE_CALL,
  payload,
  callback,
  home_uuid,
});

export const silRoomDeleted = {
  pending: {
    type: ACTIONS.SIL_ACCOMMODATION_ROOM_DELETE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SIL_ACCOMMODATION_ROOM_DELETE.SUCCESS,
    data: data,
  }),
  error: (error) => ({
    type: ACTIONS.SIL_ACCOMMODATION_ROOM_DELETE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

// Sil forms
export const fetchSilFormCategoriesRequest = () => ({
  type: ACTIONS.GET_SIL_FORM_CATEGORIES_CALL,
});

export const silFormCategoriesfetched = {
  pending: {
    type: ACTIONS.GET_SIL_FORM_CATEGORIES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_SIL_FORM_CATEGORIES.SUCCESS,
    data: data,
  }),
  error: (error) => ({
    type: ACTIONS.GET_SIL_FORM_CATEGORIES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchSilAllFormsRequest = (payload) => ({
  type: ACTIONS.GET_SIL_ALL_FORMS_LIST_CALL,
  payload,
});

export const silAllFormsFetched = {
  pending: {
    type: ACTIONS.GET_SIL_ALL_FORMS_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_SIL_ALL_FORMS_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_SIL_ALL_FORMS_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateSilFormTemplatesLocally = (data) => ({
  type: ACTIONS.UPDATE_SIL_FORM_TEMPLATES_LOCALLY,
  data,
});

// SIL Documents

export const getFolderListForSilRequest = (payload) => ({
  type: ACTIONS.GET_FOLDER_LIST_FOR_SIL_CALL,
  payload,
});

export const getFolderListForSil = {
  pending: {
    type: ACTIONS.GET_FOLDER_LIST_FOR_SIL.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_FOLDER_LIST_FOR_SIL.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_FOLDER_LIST_FOR_SIL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const silHomeDocumentCreateFolderRequest = (payload, callback) => ({
  type: ACTIONS.CREATE_SIL_DOCUMENT_FOLDER_CALL,
  payload,
  callback,
});

export const silHomeDocumentFolderCreated = {
  pending: {
    type: ACTIONS.CREATE_SIL_DOCUMENT_FOLDER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CREATE_SIL_DOCUMENT_FOLDER.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CREATE_SIL_DOCUMENT_FOLDER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const silHomeDocumentUpdateFolderRequest = (payload, callback) => ({
  type: ACTIONS.UPDATE_SIL_DOCUMENT_FOLDER_CALL,
  payload,
  callback,
});

export const silHomeDocumentFolderUpdated = {
  pending: {
    type: ACTIONS.UPDATE_SIL_DOCUMENT_FOLDER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.UPDATE_SIL_DOCUMENT_FOLDER.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.UPDATE_SIL_DOCUMENT_FOLDER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const silHomeDocumentUploadFileRequest = (payload, callback) => ({
  type: ACTIONS.UPDATE_SIL_DOCUMENT_FILE_UPLOAD_CALL,
  payload,
  callback,
});

export const silHomeDocumentFileUploaded = {
  pending: {
    type: ACTIONS.UPDATE_SIL_DOCUMENT_FILE_UPLOAD.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.UPDATE_SIL_DOCUMENT_FILE_UPLOAD.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.UPDATE_SIL_DOCUMENT_FILE_UPLOAD.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const silHomeDocumentRemoveFileRequest = (payload, callback) => ({
  type: ACTIONS.SIL_DOCUMENT_FILE_REMOVE_CALL,
  payload,
  callback,
});

export const silHomeDocumentFileRemoved = {
  pending: {
    type: ACTIONS.SIL_DOCUMENT_FILE_REMOVE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SIL_DOCUMENT_FILE_REMOVE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SIL_DOCUMENT_FILE_REMOVE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const silHomeDocumentRemoveFolderRequest = (payload, callback) => ({
  type: ACTIONS.SIL_DOCUMENT_FOLDER_REMOVE_CALL,
  payload,
  callback,
});

export const silHomeDocumentFolderRemoved = {
  pending: {
    type: ACTIONS.SIL_DOCUMENT_FOLDER_REMOVE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SIL_DOCUMENT_FOLDER_REMOVE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SIL_DOCUMENT_FOLDER_REMOVE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
