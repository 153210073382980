import React, { useEffect, useState } from "react";
import moment from "moment";
import CustomButton from "components/common/Button/CustomButton";
import FileUploadInput from "components/common/FileUpload/FileUploadInput";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { toast } from "react-toastify";
import { apiCall } from "store/services/service";
import { BASE_URL } from "store/services/URL";
import { useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { workerProfileDetailsFetchRequest } from "store/actions/sp.actions";
import { getUser } from "store/selector/auth.selector";
import { roleAccess } from "helpers/RolesPermission";
import { USER_ROLE } from "Constants/constant";

const UploadFileTableFolder = (props) => {
  const {
    selectedFile,
    SelectedFileName,
    handleClose,
    setUpdatedFile,
    isOpen,
    workerId,
    fileScore,
    parentId,
    isUserGracePeriodOver,
    getEmployeeDocuments,

    //from container
    WorkerProfileDataRequest,
    currentLoginUser,

    //not use
    setParentId,
  } = props;
  const location = useLocation();
  const params = useParams();

  const { state } = location;

  const [file, setFile] = useState(null);
  const [issueDate, setIssueDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [isFileUploadToS3, setIsFileUploadToS3] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const [error, setError] = useState("");

  useEffect(() => {
    const currentDate = moment();
    if (expiryDate) {
      const formattedExpiryDate = moment(expiryDate);
      if (currentDate.isAfter(formattedExpiryDate)) {
        setError("Expiry date cannot be a current or past date.");
        return;
      }
      if (issueDate) {
        const formattedIssueDate = moment(issueDate);
        if (formattedIssueDate.isAfter(formattedExpiryDate)) {
          setError("Issue date cannot be greater than expiry date.");
          return;
        } else {
          setError("");
        }
      }
    }
    setError("");
  }, [issueDate, expiryDate]);

  useEffect(() => {
    if (selectedFile) {
      setShowInput(selectedFile?.expire_date ? true : false);
      if (selectedFile?.expire_date) {
        setExpiryDate(selectedFile.expire_date);
      }
      if (selectedFile?.issue_date) {
        setIssueDate(selectedFile.issue_date);
      }
    }
  }, [selectedFile]);

  const modalFooter = () => (
    <>
      <CustomButton
        label='Cancel'
        variant='secondary'
        clickHandler={() => {
          handleClear();
          setFile("");
          setSelectedDocument("");
          handleClose();
          const buttonRef = document.getElementById("cancelBTN");
          if (buttonRef) {
            buttonRef.click();
          }
        }}
      />
      <CustomButton
        showLoading={isLoading}
        isDisabled={
          (file == null || file === "" ? true : isLoading) ||
          isUserGracePeriodOver ||
          error?.length > 0
        }
        label='Upload'
        variant='primary'
        clickHandler={handleSubmitForFile}
      />
    </>
  );

  const user = localStorage.getItem("userType");
  const [errorUploadAPI, setErrorUploadAPI] = useState("");

  const handleFileUploadBaseVapour = async (data) => {
    Object.assign(data, {
      worker_id: workerId,
      file_type: "Screening",
      document_type_id: 2,
      with_expiry: showInput,
      label: "",
      note: "xyz",
      name: SelectedFileName,
      expiry_date: showInput ? expiryDate : null,
      issue_date: showInput ? issueDate : null,
      // is_expire: showInput ? true : false,
      folder_structure_id: parentId,
      score: fileScore,
    });

    if (selectedFile && selectedFile?.docuemnt_id) {
      Object.assign(data, {
        document_id: selectedFile.docuemnt_id,
      });
    }

    // setIsLoading(true);
    let URL = "";
    if (user === "sp_standard_user") {
      URL = `${BASE_URL}hrm/file-management/upload-required-document`;
    } else {
      // Object.assign(data, {
      //   score: fileScore,
      // });
      URL = `${BASE_URL}sp/file-management/upload-required-document`;
    }

    apiCall
      .post({
        url: URL,
        params: data,
        isAuthToken: currentLoginUser?.AccessToken,
      })
      .then((response) => {
        handleFinish(response);
        handleClose();
        setIsLoading(false);
        if (!roleAccess([USER_ROLE.Sp_Standard_User])) {
          // setUpdateDocumentsList(!updateDocumentsList);
          getEmployeeDocuments("from-uploaded");
        }
        // else if (roleAccess([USER_ROLE.Sp_Standard_User])) {
        //   sleep(1000).then(() => {
        //     window.location.reload();
        //   });
        // }
      })
      .catch((e) => {
        console.log("e", e);
        setIsLoading(false);
        toast.error(e?.message ?? "Something Went Wrong!");
        setErrorUploadAPI(e?.message ?? "Something Went Wrong!");
        setIsFileUploadToS3(false);
      });
  };

  const handleClear = () => {
    setShowInput(false);
    setFile("");
    setSelectedDocument("");
  };

  const handleFinish = (data) => {
    if (data && data.data && data.data.file_type) {
      setUpdatedFile({ data });
      handleClose();
      handleClear();
    }
    handleClear();
    setIsLoading(false);
    setIsFileUploadToS3(false);
    toast.success("Document uploaded successfully");
    if (!roleAccess([USER_ROLE.Sp_Standard_User])) {
      WorkerProfileDataRequest({
        profile_id:
          state && state?.workerUUID
            ? state?.workerUUID
            : params && params.workerId
            ? params?.workerId
            : workerId,
      });
    }
  };

  const handleError = () => {
    setIsLoading(false);
  };

  const handleSubmitForFile = async () => {
    if (isUserGracePeriodOver) {
      return;
    }

    if (error && error?.length > 0) {
      return;
    }
    setIsLoading(true);
    setIsFileUploadToS3(true);
  };

  return (
    <ModalSkeleton
      title='Upload Document'
      cssClass='w-[35%]'
      modalFooter={modalFooter()}
      isOpen={isOpen}
      closeModal={() => {
        handleClose();
        handleClear();
        setShowInput(false);
        setFile("");
        setSelectedDocument("");
      }}
    >
      <div className='flex flex-col'>
        <div className='grid grid-cols-2 gap-x-4'>
          <div className='col-span-2 space-x-4'>
            <label className=' text-start did-floating-label'>
              Set Issue/Expiry Date
            </label>
            <input
              checked={showInput}
              value={showInput}
              type='checkbox'
              onChange={(e) => setShowInput(e.target.checked)}
              className='rounded '
            />
          </div>
          {showInput && (
            <>
              <div className='col-span-2'>
                <label htmlFor='' className=' text-start did-floating-label'>
                  Issue Date
                </label>
                <input
                  value={issueDate}
                  name='issue_date'
                  id='issue_date'
                  type='date'
                  onChange={(e) => setIssueDate(e.target.value)}
                  className='did-floating-input'
                />
              </div>
              <div className='col-span-2'>
                <label htmlFor='' className=' text-start did-floating-label'>
                  Expiry Date
                </label>
                <input
                  value={expiryDate}
                  onChange={(e) => setExpiryDate(e.target.value)}
                  name='expiry_date'
                  id='expiry_date'
                  type='date'
                  min={moment().format("DD-MM-YYYY")}
                  className='did-floating-input'
                />
              </div>
            </>
          )}

          {!selectedDocument && (
            <div className='col-span-2 mt-4'>
              <FileUploadInput
                type='any'
                handleFile={(e) => setFile(e)}
                size='10'
                file={file}
                handleDelete={() => setFile("")}
                isDisabled={isLoading}
                isVapourFileUpload={true}
                isFileUploadToS3={isFileUploadToS3}
                handleFileUploadBaseVapour={handleFileUploadBaseVapour}
                handleError={handleError}
                updateFileName={selectedFile ? selectedFile?.name : null}
                isHardClearAllStates={errorUploadAPI?.length > 0}
                clearParentError={() => setErrorUploadAPI("")}
                selectedFile={selectedFile}
              />
            </div>
          )}
          {error && error?.length > 0 && (
            <div className='col-span-2 mt-1'>
              <span className='errorMessage'>{error}</span>
            </div>
          )}
        </div>
      </div>
    </ModalSkeleton>
  );
};

const mapDispatchToProps = {
  WorkerProfileDataRequest: workerProfileDetailsFetchRequest,
};
const mapStateToProps = (state) => {
  const currentLoginUser = getUser(state);

  return { currentLoginUser };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadFileTableFolder);
