import React, { useState } from "react";
import { HiEye, HiOutlineTrash } from "react-icons/hi";
import Slider from "react-slick";
import { baseURLImage } from "Utils/utils";
import Loader from "../Loader/Loader";
// import "./FileViewerSlider.css";

function FileViewerSlider({
  files = [],
  handleDelete,
  isDeleteFileLoader,
  callFor = "simpleSlider",
  uploadButton = null,
}) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    dotsClass: "slick-dots",
    beforeChange: (current, next) => {
      setCurrentSlide(next); // Update current slide index
    },
    // customPaging: function (i) {
    //   return (
    //     <a>
    //       <img
    //         // className='w-20 h-10'
    //         src={`${baseURLImage(files[i]?.file_path)}`}
    //         alt='Home Img'
    //       />
    //     </a>
    //   );
    // },
  };

  const customSettings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb customDotClass max-auto",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleDeleteClick = (file, index) => {
    if (isDeleteFileLoader !== null) return;
    handleDelete(files[currentSlide]);
  };

  return (
    <div className='slider-container'>
      {/* <Slider {...(callFor === "simpleSlider" ? settings : customSettings)}> */}
      <Slider {...(callFor === "simpleSlider" ? settings : settings)}>
        {files.map((file, index) => {
          return (
            <div
              key={file.id}
              className='!flex justify-start items-start space-x-2 w-full'
            >
              <div className='w-[85%] flex justify-center items-center'>
                <img
                  className='h-[200px]'
                  src={baseURLImage(file.file_path)}
                  alt={`slide-${index}`}
                />
              </div>
              <div className='ml-4 w-[10%] flex items-center text-center'>
                {isDeleteFileLoader === file.id ? (
                  <Loader cssClass={"text-black"} />
                ) : (
                  <div className='flex-col flex gap-y-2'>
                    <div
                      onClick={() =>
                        window.open(
                          baseURLImage(files[currentSlide].file_path),
                          "_blank"
                        )
                      }
                      className='icon-eye-wrapper cursor-pointer'
                      title='View'
                    >
                      <HiEye className='icon-size' />
                    </div>
                    <div
                      role='button'
                      onClick={handleDeleteClick}
                      className='icon-delete-wrapper'
                      title='Delete'
                    >
                      <HiOutlineTrash className='icon-size' />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
      <div
        className={`pt-2 flex justify-${
          uploadButton ? "between" : "end"
        } items-center`}
      >
        <span className='inline-flex items-center rounded-md bg-indigo-50 py-1 px-3 text-md font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10'>
          {files.length > 0 && `${currentSlide + 1} of ${files.length}`}
        </span>
        {uploadButton}
      </div>
    </div>
  );
}

export default FileViewerSlider;
