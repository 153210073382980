import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { AiOutlineFileText } from "react-icons/ai";
import Loader from "../Loader/Loader";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SelectBox = (props) => {
  const {
    setReportType,
    isLoading = false,
    isUserGracePeriodOver = false,
    rightAlign,
  } = props;
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <div>
          <Menu.Button
            disabled={isLoading || isUserGracePeriodOver}
            className={classNames(
              " background-gradient text-white tracking-normal border-2 border-solid py-2 px-4 text-sm font-semibold rounded-lg inline-flex items-center capitalize gap-x-1",
              isUserGracePeriodOver && "disabled:opacity-50"
            )}
          >
            <div className=''>Export</div>
            {isLoading ? (
              <Loader isSmallView={true} cssClass='text-black' />
            ) : (
              <AiOutlineFileText
                className=' h-4 w-4 text-white group-hover:text-gray-500'
                aria-hidden='true'
              />
            )}
          </Menu.Button>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className={`absolute ${rightAlign ? 'right-100' : 'right-0'} z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
          <div className='py-1'>
            <Menu.Item>
              {({ active }) => (
                <span
                  onClick={() => setReportType("pdf")}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer"
                  )}
                >
                  <AiOutlineFileText
                    className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                    aria-hidden='true'
                  />
                  PDF
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <span
                  onClick={() => setReportType("xlsx")}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer"
                  )}
                >
                  <AiOutlineFileText
                    className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                    aria-hidden='true'
                  />
                  EXCEL
                </span>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default SelectBox;
