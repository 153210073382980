import React from "react";
import { HiOutlineMail, HiPhone } from "react-icons/hi";
import { useNavigate } from "react-router";
import { baseURLImage, getNameProfile } from "Utils/utils";

const QuickViewTabs = (props) => {
  const { tabs, handleChange, selectedTab, data, type } = props;

  const navigate = useNavigate();

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  return (
    <>
      <>
        <div
          className='max-w-lg mx-auto  bg-[#F6F7F8] py-5'
          style={{
            borderTopRightRadius: "15px",
            borderTopLeftRadius: "15px",
          }}
        >
          {/* <p className='text-lg font-bold text-[#161736] pl-5'>Feeds</p> */}
          <div className='flex border-b-2 w-full'>
            {tabs.map((tab) => (
              <span
                key={tab.id}
                className={`text-center  sm:text-xs py-2 px-1 w-full cursor-pointer ${
                  tab.value === selectedTab
                    ? "border-b-2 border-blue-500 font-bold text-[#0177FB] "
                    : "text-gray-500 font-bold hover:text-gray-500"
                }`}
                onClick={() => handleChange(tab.value)}
              >
                {tab.name}
              </span>
            ))}
          </div>
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`${
                tab.value === selectedTab ? "block" : "hidden"
              } px-6 mt-2 flex justify-start `}
            >
              {data?.length > 0 ? (
                <ul className='divide-y divide-gray-200'>
                  {data?.map((item, index) => (
                    <li key={index} className='py-4'>
                      <div
                        role='button'
                        onClick={() => {
                          if (type === "participant-list") {
                            navigate(`/sp-profile/${item.uuid}`);
                          } else if (type === "worker-list") {
                            navigate(`/worker-profile/${item.uuid}`);
                          }
                        }}
                        className='flex space-x-3'
                      >
                        {type !== "utility-provider-list" && (
                          <img
                            onError={(e) =>
                              handleImageError(
                                e,
                                item?.name ? item.name : item?.first_name,
                                item?.name ? "" : item?.last_name
                              )
                            }
                            className='h-10 w-10 rounded-full object-cover'
                            src={
                              item?.profile_picture !== null
                                ? baseURLImage(item?.profile_picture)
                                : getNameProfile(
                                    item?.name ? item.name : item?.first_name,
                                    item?.name ? " " : item?.last_name
                                  )
                            }
                            alt=''
                          />
                        )}
                        <div className='flex-1'>
                          <div className='flex items-center justify-between'>
                            <h3 className='text-sm font-medium capitalize'>{`${
                              item?.name ? item.name : item?.first_name
                            } ${item?.name ? " " : item?.last_name}`}</h3>
                          </div>
                          {type === "utility-provider-list" && (
                            <p className='text-sm text-gray-500 pb-1'>
                              {item?.companyName}
                            </p>
                          )}
                          {item?.email && (
                            <p className='text-sm text-gray-500 flex justify-start space-x-2 items-center'>
                              <HiOutlineMail />
                              <span>{item?.email}</span>
                            </p>
                          )}

                          {type === "utility-provider-list" && (
                            <p className='text-sm text-gray-500 flex justify-start space-x-2 items-center'>
                              <HiPhone />
                              <span>{item?.phone}</span>
                            </p>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className='w-full text-sm text-red-500 font-semibold text-gray-900 py-3 flex justify-center items-center space-x-3'>
                  <span className='text-red-500'>
                    No{" "}
                    {`${
                      type === "participant-list"
                        ? "Participant"
                        : type === "worker-list"
                        ? "Worker"
                        : "Data"
                    }`}{" "}
                    Found!
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </>
    </>
  );
};

export default QuickViewTabs;
