import { fromJS } from "immutable";
import ACTIONS from "../constants";
import { getPaginationObj, parseBooleanVal } from "Utils/utils";
import {
  TIMESHEET_STATUS,
  PAYROLL_EXPORT_STATUS,
  emptyPaginationObj,
} from "Constants/constant";
import _ from "lodash";

const initialState = fromJS({
  workerAndParticipantList: null,
  isSortCalendarShiftsItem: 0,
  isCalendarStartOfDate: null,
  calendarView: null,
  isAuthenticateForPayroll: null,
  showRecurringModal: false,
  rosterInvoices: {},
  
});

export default function rosteringReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.FETCH_WORKER_AND_PARTICIPANT_LIST.PENDING: {
      const calView = state.get("calendarView") || null;

      if (calView && calView !== action.calendarView) {
        return state
          .set("getWorkerAndPartsListLoader", true)
          .set("getWorkerAndPartsListError", "")
          .set("calendarView", action.calendarView)
          .set("workerAndParticipantList", null)
          .set(
            "searchtextOfScheduleShifts",
            action.hasOwnProperty("search") ? action.search : null
          );
      }

      return state
        .set(
          "searchtextOfScheduleShifts",
          action.hasOwnProperty("search") ? action.search : null
        )
        .set("getWorkerAndPartsListLoader", true)
        .set("getWorkerAndPartsListError", "")
        .set("calendarView", action.calendarView);
    }
    case ACTIONS.FETCH_WORKER_AND_PARTICIPANT_LIST.SUCCESS: {
      const calView = state.get("calendarView");
      const prevData = action.isRefreshCall
        ? null
        : state.get("workerAndParticipantList") || null;
      const newData = action.data.worker_participant_listing;

      const paginationObj = calView
        ? { ...getPaginationObj(newData?.[`${calView}`]) }
        : { ...emptyPaginationObj };

      if (
        calView &&
        prevData &&
        prevData[`${calView}`] &&
        newData &&
        newData[`${calView}`]
      ) {
        prevData[`${calView}`].data.push(...newData[`${calView}`].data);
        Object.assign(prevData, {
          ...paginationObj,
        });
      }

      const finalData = {};

      if (calView === "worker") {
        Object.assign(finalData, {
          worker: prevData !== null ? prevData?.worker : newData?.worker,
        });
      } else {
        Object.assign(finalData, {
          participant:
            prevData !== null ? prevData?.participant : newData?.participant,
        });
      }

      Object.assign(finalData, {
        ...getPaginationObj(newData[`${calView}`]),
      });

      return state
        .set("getWorkerAndPartsListLoader", false)
        .set("getWorkerAndPartsListError", "")
        .set("workerAndParticipantList", { ...finalData });
    }
    case ACTIONS.FETCH_WORKER_AND_PARTICIPANT_LIST.ERROR: {
      return state
        .set("getWorkerAndPartsListLoader", false)
        .set("getWorkerAndPartsListError", "");
    }

    case ACTIONS.SORT_CALENDAR_SHIFTS_ITEM: {
      const isSort = state.get("isSortCalendarShiftsItem");
      return state.set("isSortCalendarShiftsItem", isSort === 0 ? 1 : 0);
    }

    case ACTIONS.CALENDAR_DATE_CHANGE_LOCALLY: {
      return state
        .set("isCalendarStartOfDate", action.data.date)
        .set("scheduleCalendarView", action.data.view);
    }

    case ACTIONS.CREATE_OR_UPDATE_WORKER_SHIFT.PENDING: {
      return state
        .set("createOrUpdateWorkerShiftLoader", true)
        .set("createOrUpdateWorkerShiftError", "");
    }
    case ACTIONS.CREATE_OR_UPDATE_WORKER_SHIFT.SUCCESS: {
      const prevWorkerAndParticipantList =
        state.get("workerAndParticipantList") || null;
      const targetKey =
        action.calendarView === "worker-view" ? "worker" : "participant";

      if (
        prevWorkerAndParticipantList &&
        action.data &&
        action.data?.length > 0
      ) {
        for (let i = 0; i < action.data.length; i++) {
          let targetId =
            targetKey === "worker"
              ? action?.isUpdate
                ? action.data[i]?.worker?.id
                : action.data[i]?.worker_id
              : action?.isUpdate
              ? action.data[i]?.participants?.[i]?.id
              : action.data[i]?.pt_id;

          if (
            action.calendarView &&
            prevWorkerAndParticipantList[`${targetKey}`] &&
            prevWorkerAndParticipantList[`${targetKey}`]?.data.length > 0
          ) {
            const index = prevWorkerAndParticipantList[
              `${targetKey}`
            ].data.findIndex((x) => x.id === targetId);

            if (
              index !== -1 &&
              prevWorkerAndParticipantList[`${targetKey}`].data[index]?.shifts
            ) {
              if (action.isUpdate) {
                if (action.isWorkerChange && action.prevWorkerUUID) {
                  let prevWorkerData = null;
                  const prevWorkerIndex = prevWorkerAndParticipantList[
                    `${targetKey}`
                  ].data.findIndex((x) => x.uuid === action.prevWorkerUUID);
                  if (prevWorkerIndex !== -1) {
                    prevWorkerData =
                      prevWorkerAndParticipantList[`${targetKey}`].data[
                        prevWorkerIndex
                      ];
                  }

                  if (prevWorkerData !== null && prevWorkerData.shifts) {
                    if (action.data[i].uuid) {
                      const shiftIndex = prevWorkerData.shifts.findIndex(
                        (item) => item.uuid === action.data[i].uuid
                      );
                      if (shiftIndex !== -1) {
                        prevWorkerAndParticipantList[`${targetKey}`].data[
                          prevWorkerIndex
                        ]?.shifts.splice(shiftIndex, 1);
                      }
                    }
                  }

                  prevWorkerAndParticipantList[`${targetKey}`].data[
                    index
                  ].shifts.push({
                    ...action.data[i],
                  });
                } else {
                  const shiftIndex = prevWorkerAndParticipantList[
                    `${targetKey}`
                  ].data[index].shifts.findIndex(
                    (shift) => shift.uuid === action.data[i].uuid
                  );

                  if (shiftIndex !== -1) {
                    prevWorkerAndParticipantList[`${targetKey}`].data[
                      index
                    ].shifts.splice(shiftIndex, 1, {
                      ...prevWorkerAndParticipantList[`${targetKey}`].data[
                        index
                      ].shifts[shiftIndex],
                      ...action.data[i],
                    });
                  }
                }
              } else {
                prevWorkerAndParticipantList[`${targetKey}`].data[
                  index
                ].shifts.push({
                  ...action.data[i],
                });
              }
            }
          }
        }
      }
      return state
        .set("createOrUpdateWorkerShiftLoader", false)
        .set("createOrUpdateWorkerShiftError", "")
        .set("workerAndParticipantList", { ...prevWorkerAndParticipantList })
        .set("recurringMessage", action.recurring);
    }
    case ACTIONS.CREATE_OR_UPDATE_WORKER_SHIFT.ERROR: {
      return state
        .set("createOrUpdateWorkerShiftLoader", false)
        .set("createOrUpdateWorkerShiftError", action.error);
    }

    case ACTIONS.WORKER_AND_PARTICIPANT_SHIFT_DELETE_LOCALLY: {
      const prevWorkerAndParticipantList =
        state.get("workerAndParticipantList") || null;
      if (prevWorkerAndParticipantList) {
        if (
          action.data.calendarView &&
          prevWorkerAndParticipantList[`${action.data.calendarView}`] &&
          prevWorkerAndParticipantList[`${action.data.calendarView}`]?.data &&
          prevWorkerAndParticipantList[`${action.data.calendarView}`].data
            .length > 0
        ) {
          const index = prevWorkerAndParticipantList[
            `${action.data.calendarView}`
          ].data.findIndex((x) => x.id === action.data.userId);
          if (
            index !== -1 &&
            prevWorkerAndParticipantList[`${action.data.calendarView}`].data[
              index
            ]?.shifts
          ) {
            if (action.data.shiftUuid) {
              const shiftIndex = prevWorkerAndParticipantList[
                `${action.data.calendarView}`
              ].data[index].shifts.findIndex(
                (shift) => shift.uuid === action.data.shiftUuid
              );
              if (shiftIndex !== -1) {
                prevWorkerAndParticipantList[
                  `${action.data.calendarView}`
                ].data[index].shifts.splice(shiftIndex, 1);
              }
            }
          }
        }
      }
      return state.set("workerAndParticipantList", {
        ...prevWorkerAndParticipantList,
      });
    }

    case ACTIONS.WORKER_AND_PARTICIPANT_SHIFT_STATUS_CHANGED_LOCALLY: {
      const prevWorkerAndParticipantList =
        state.get("workerAndParticipantList") || null;
      if (prevWorkerAndParticipantList) {
        if (
          action.data.calendarView &&
          prevWorkerAndParticipantList[`${action.data.calendarView}`] &&
          prevWorkerAndParticipantList[`${action.data.calendarView}`].data &&
          prevWorkerAndParticipantList[`${action.data.calendarView}`].data
            .length > 0
        ) {
          const shifts = action.data.shifts || [];
          for (let i = 0; i < shifts.length; i++) {
            const compareIdBaseOnCalender =
              action.data.calendarView === "worker"
                ? shifts[i]?.["worker_id"]
                : shifts[i]?.participants?.length > 0
                ? shifts[i]?.participants[0]?.id
                : shifts[i]?.["pt_id"];
            const userIndex = prevWorkerAndParticipantList[
              `${action.data.calendarView}`
            ].data.findIndex((x) => x.id === compareIdBaseOnCalender);
            if (
              userIndex !== -1 &&
              prevWorkerAndParticipantList[`${action.data.calendarView}`].data[
                userIndex
              ]?.shifts
            ) {
              const shiftIndex = prevWorkerAndParticipantList[
                `${action.data.calendarView}`
              ].data[userIndex].shifts.findIndex(
                (shift) => shift.uuid === shifts[i]?.uuid
              );
              if (shiftIndex !== -1) {
                const prevShift =
                  prevWorkerAndParticipantList[`${action.data.calendarView}`]
                    .data[userIndex].shifts[shiftIndex];
                prevWorkerAndParticipantList[
                  `${action.data.calendarView}`
                ].data[userIndex].shifts.splice(shiftIndex, 1, {
                  ...prevShift,
                  status: action.data.status,
                });
              }
            }
          }
        }
      }
      return state.set("workerAndParticipantList", {
        ...prevWorkerAndParticipantList,
      });
    }

    case ACTIONS.WORKER_AND_PARTICIPANT_SHFIT_COPY.PENDING: {
      return state
        .set("copyShiftLoader", action.data || "")
        .set("copyShiftError", "");
    }
    case ACTIONS.WORKER_AND_PARTICIPANT_SHFIT_COPY.SUCCESS: {
      const prevWorkerAndParticipantList =
        state.get("workerAndParticipantList") || null;
      if (prevWorkerAndParticipantList) {
        if (
          action.calendarView === "worker-view" &&
          prevWorkerAndParticipantList?.worker?.data &&
          prevWorkerAndParticipantList.worker.data.length > 0
        ) {
          const index = prevWorkerAndParticipantList.worker.data.findIndex(
            (x) => x.id === action.data.worker_id
          );
          if (
            index !== -1 &&
            prevWorkerAndParticipantList.worker.data[index]?.shifts
          ) {
            prevWorkerAndParticipantList.worker.data[index].shifts.push({
              ...action.data,
            });
          }
        } else if (
          action.calendarView === "participant-view" &&
          prevWorkerAndParticipantList?.participant?.data &&
          prevWorkerAndParticipantList.participant.data.length > 0
        ) {
          const index =
            prevWorkerAndParticipantList?.participant.data.findIndex(
              (x) => x.id === action.data?.participants[0]?.id
            );
          if (
            index !== -1 &&
            prevWorkerAndParticipantList.participant.data[index]?.shifts
          ) {
            prevWorkerAndParticipantList.participant.data[index].shifts.push({
              ...action.data,
            });
          }
        }
      }

      return state
        .set("copyShiftLoader", null)
        .set("copyShiftError", "")
        .set("workerAndParticipantList", { ...prevWorkerAndParticipantList });
    }
    case ACTIONS.WORKER_AND_PARTICIPANT_SHFIT_COPY.ERROR: {
      return state
        .set("copyShiftLoader", null)
        .set("copyShiftError", action.error);
    }

    case ACTIONS.PARTICIPANT_LIST_FOR_TIMESHEET.PENDING: {
      return state
        .set("workerTimeSheetsList", null)
        .set("participantListForTimesheetLoader", true)
        .set("participantListForTimesheetError", "");
    }
    case ACTIONS.PARTICIPANT_LIST_FOR_TIMESHEET.SUCCESS: {
      return state
        .set("participantListForTimesheetLoader", false)
        .set("participantListForTimesheetError", "")
        .set("participantListForTimesheet", [...action.data]);
    }
    case ACTIONS.PARTICIPANT_LIST_FOR_TIMESHEET.ERROR: {
      return state
        .set("participantListForTimesheetLoader", false)
        .set("participantListForTimesheetError", action.error);
    }

    case ACTIONS.WORKER_TIMESHEETS.PENDING: {
      const prevList = state.get("workerTimeSheetsList") || [];
      const PrevSelectedTimesheetItem=state.get("selectedTimesheetOfParticipant") || null;

      return state
        .set("workerTimesheetLoader", true)
        .set(
          "workerTimesheetPaginationLoader",
          action.data?.paginationCall || false
        )
        .set("workerTimesheetError", "")
        .set("selectedTimesheetOfParticipant", action.data.isRefreshList ? null : PrevSelectedTimesheetItem)
        .set("selectedTimeSheetComments", null)
        .set("workerTimeSheetsList", action.data.isRefreshList ? [] : prevList);
    }
    case ACTIONS.WORKER_TIMESHEETS.SUCCESS: {
      const prevList = state.get("workerTimeSheetsList") || [];
      return state
        .set("workerTimesheetLoader", false)
        .set("workerTimesheetError", "")
        .set("workerTimesheetPaginationLoader", false)
        .set("workerTimeSheetsListPagination", getPaginationObj(action?.data))
        .set("workerTimeSheetsList", [...prevList, ...action?.data?.data]);
    }
    case ACTIONS.WORKER_TIMESHEETS.ERROR: {
      return state
        .set("workerTimesheetLoader", false)
        .set("workerTimesheetPaginationLoader", false)
        .set("workerTimesheetError", action.error);
    }

    case ACTIONS.WORKER_TIMESHEETS_LOCALLY_SEARCH: {
      return state.set("workerTimeSheetsListSearch", action.search);
    }

    case ACTIONS.RESET_WORKER_TIMESHEETS: {
      return state
        .set("workerTimeSheetsListPagination", emptyPaginationObj)
        .set("workerTimeSheetsList", []);
    }

    case ACTIONS.SET_SELECTED_TIMESHEET_LOCALLY: {
      return state.set("selectedTimesheetOfParticipant", action.data);
    }

    case ACTIONS.TIMESHEET_COMMENT_CREATED.PENDING: {
      return state
        .set("timesheetCommentCreateLoader", true)
        .set("timesheetCommentCreateError", "");
    }
    case ACTIONS.TIMESHEET_COMMENT_CREATED.SUCCESS: {
      const prevSelectedTimesheetOfParticipant =
        state.get("selectedTimesheetOfParticipant") || null;
      if (prevSelectedTimesheetOfParticipant) {
        Object.assign(prevSelectedTimesheetOfParticipant, {
          comment: action.data.text,
        });
      }
      const prevComments = state.get("selectedTimeSheetComments") || {
        ...emptyPaginationObj,
        data: [],
      };
      if (prevComments && prevComments?.data && action?.data) {
        prevComments.data.push({ ...action.data });
      }
      return state
        .set("timesheetCommentCreateLoader", false)
        .set("timesheetCommentCreateError", "")
        .set("selectedTimesheetOfParticipant", {
          ...prevSelectedTimesheetOfParticipant,
        })
        .set("selectedTimeSheetComments", { ...prevComments });
    }
    case ACTIONS.TIMESHEET_COMMENT_CREATED.ERROR: {
      return state
        .set("timesheetCommentCreateLoader", false)
        .set("timesheetCommentCreateError", action.error);
    }

    case ACTIONS.TIMESHEET_COMMENTS_FETCHED.PENDING: {
      const prevComments = state.get("selectedTimeSheetComments") || {
        ...emptyPaginationObj,
        data: [],
      };
      return state
        .set("timesheetCommentsListLoader", true)
        .set("timesheetCommentsListError", "")
        .set(
          "selectedTimeSheetComments",
          parseBooleanVal(action.isFirstCall) ? null : prevComments
        );
    }
    case ACTIONS.TIMESHEET_COMMENTS_FETCHED.SUCCESS: {
      const prevComments = state.get("selectedTimeSheetComments") || {
        ...emptyPaginationObj,
        data: [],
      };
      const obj = _.cloneDeep(action?.data || {});
      if (prevComments && prevComments?.data && action?.data) {
        Object.assign(obj, {
          data: [...prevComments.data, ...action?.data?.data],
        });
      }
      return state
        .set("timesheetCommentsListLoader", false)
        .set("timesheetCommentsListError", "")
        .set("selectedTimeSheetComments", { ...obj });
    }
    case ACTIONS.TIMESHEET_COMMENTS_FETCHED.ERROR: {
      return state
        .set("timesheetCommentsListLoader", false)
        .set("timesheetCommentsListError", action.error);
    }

    case ACTIONS.TIMESHEET_DATA_UPDATED.PENDING: {
      return state
        .set("timesheetupdateLoader", true)
        .set("timesheetupdateError", "");
    }
    case ACTIONS.TIMESHEET_DATA_UPDATED.SUCCESS: {
      // const selectedTimeSheet = state.get('selectedTimesheetOfParticipant') || null;
      const prevWorkerTimeSheetsList =
        state.get("workerTimeSheetsList") || null;
      const prevSelectedTimesheetOfParticipant =
        state.get("selectedTimesheetOfParticipant") || null;
      if (prevSelectedTimesheetOfParticipant) {
        Object.assign(prevSelectedTimesheetOfParticipant, {
          ...prevSelectedTimesheetOfParticipant,
          ...action.data,
        });
      }
      if (prevWorkerTimeSheetsList && action?.uuid) {
        const parentIndex = prevWorkerTimeSheetsList.findIndex(
          (x) => x.uuid === action.uuid.parent_uuid
        );
        if (
          parentIndex !== -1 &&
          prevWorkerTimeSheetsList[parentIndex]?.timeSheet &&
          action?.uuid?.timeSheetUuid
        ) {
          const timeSheetIndex = prevWorkerTimeSheetsList[
            parentIndex
          ].timeSheet.findIndex((x) => x.uuid === action.uuid.timeSheetUuid);
          if (timeSheetIndex !== -1) {
            prevWorkerTimeSheetsList[parentIndex]?.timeSheet.splice(
              timeSheetIndex,
              1,
              { ...action.data }
            );
          }
        }
      }
      return state
        .set("timesheetupdateLoader", false)
        .set("timesheetupdateError", "")
        .set("selectedTimesheetOfParticipant", {
          ...prevSelectedTimesheetOfParticipant,
        })
        .set("workerTimeSheetsList", [...prevWorkerTimeSheetsList]);
    }
    case ACTIONS.TIMESHEET_DATA_UPDATED.ERROR: {
      return state
        .set("timesheetupdateLoader", false)
        .set("timesheetupdateError", action.error);
    }

    case ACTIONS.TIMESHEET_STATUS_CHANGED.PENDING: {
      return state
        .set("timesheetStatusChangeLoader", {
          ...action.data.data,
          isNext: action.data.isNext,
        })
        .set("timesheetStatusChangeError", "");
    }
    case ACTIONS.TIMESHEET_STATUS_CHANGED.SUCCESS: {
      const statusChangeNavigate =
        state.get("timesheetStatusChangeLoader") || null;
      const prevWorkerTimeSheetsList =
        state.get("workerTimeSheetsList") || null;
      const prevSelectedTimesheetOfParticipant =
        state.get("selectedTimesheetOfParticipant") || null;
      let isAnyPendingExist = [];

      //Worker Timesheet List find and update status changed for ( single & multiple timesheets base on approveAll check)
      if (!action.data.hasOwnProperty("approveAll")) {
        if (
          prevSelectedTimesheetOfParticipant &&
          prevWorkerTimeSheetsList &&
          action?.data?.data?.uuid[0]
        ) {
          const parentIndex = prevWorkerTimeSheetsList.findIndex(
            (x) => x.uuid === prevSelectedTimesheetOfParticipant.parent_uuid
          );
          if (
            parentIndex !== -1 &&
            prevWorkerTimeSheetsList[parentIndex]?.timeSheet &&
            action?.data?.data?.uuid[0]
          ) {
            const timeSheetIndex = prevWorkerTimeSheetsList[
              parentIndex
            ].timeSheet.findIndex(
              (x) => x.uuid === action?.data?.data?.uuid[0]
            );
            const filterPendingArrayClone = _.cloneDeep(
              prevWorkerTimeSheetsList[parentIndex]?.timeSheet
            );

            if (timeSheetIndex !== -1) {
              prevWorkerTimeSheetsList[parentIndex]?.timeSheet.splice(
                timeSheetIndex,
                1,
                {
                  ...prevWorkerTimeSheetsList[parentIndex]?.timeSheet[
                    timeSheetIndex
                  ],
                  status: action?.data?.data?.status,
                }
              );
            }

            // If approve and Next is True
            isAnyPendingExist = filterPendingArrayClone.filter(
              (x) => x.status === TIMESHEET_STATUS.PENDING
            );
            if (
              isAnyPendingExist.length > 1 &&
              statusChangeNavigate !== null &&
              statusChangeNavigate.isNext === true
            ) {
              const length = isAnyPendingExist.length;
              const currentCursorIndex = isAnyPendingExist.findIndex(
                (x) => x.uuid === prevSelectedTimesheetOfParticipant.uuid
              );
              if (currentCursorIndex !== -1) {
                Object.assign(prevSelectedTimesheetOfParticipant, {
                  ...isAnyPendingExist[
                    currentCursorIndex === length - 1
                      ? 0
                      : currentCursorIndex + 1
                  ],
                });
              }
            }
          }
        }
        //Selected Participant Timesheet status changed
        if (
          statusChangeNavigate !== null &&
          statusChangeNavigate.isNext === false &&
          prevSelectedTimesheetOfParticipant &&
          action?.data?.data &&
          prevSelectedTimesheetOfParticipant.uuid ===
            action?.data?.data?.uuid[0]
        ) {
          Object.assign(prevSelectedTimesheetOfParticipant, {
            status: action.data.data.status,
          });
        }
      }

      if (
        action.data.hasOwnProperty("approveAll") &&
        action.data?.data?.approvalSheets
      ) {
        const { approvalSheets, status } = action.data?.data;
        approvalSheets?.forEach((sheet) => {
          const parentIndex = prevWorkerTimeSheetsList.findIndex(
            (x) => x.uuid === sheet.parent_uuid
          );
          if (
            parentIndex !== -1 &&
            prevWorkerTimeSheetsList[parentIndex]?.timeSheet
          ) {
            const timeSheetIndex = prevWorkerTimeSheetsList[
              parentIndex
            ].timeSheet.findIndex((x) => x.uuid === sheet.uuid);
            if (timeSheetIndex !== -1) {
              prevWorkerTimeSheetsList[parentIndex]?.timeSheet.splice(
                timeSheetIndex,
                1,
                {
                  ...prevWorkerTimeSheetsList[parentIndex]?.timeSheet[
                    timeSheetIndex
                  ],
                  status: status,
                }
              );
            }
          }
        });
      }

      return state
        .set("timesheetStatusChangeLoader", null)
        .set("timesheetStatusChangeError", "")
        .set("workerTimeSheetsList", [...prevWorkerTimeSheetsList])
        .set(
          "isAnyPendingExist",
          action.data.hasOwnProperty("approveAll")
            ? null
            : isAnyPendingExist.length > 1 ||
              (statusChangeNavigate !== null &&
                statusChangeNavigate.isNext === false)
            ? true
            : "false"
        )
        .set(
          "selectedTimesheetOfParticipant",
          action.data.hasOwnProperty("approveAll")
            ? null
            : isAnyPendingExist.length > 1 ||
              (statusChangeNavigate !== null &&
                statusChangeNavigate.isNext === false)
            ? {
                ...prevSelectedTimesheetOfParticipant,
              }
            : null
        );
    }
    case ACTIONS.TIMESHEET_STATUS_CHANGED.ERROR: {
      return state
        .set("timesheetStatusChangeLoader", null)
        .set("timesheetStatusChangeError", action.error);
    }

    case ACTIONS.RESET_IS_ANY_PENDING_EXIST_FOR_WORKER: {
      return state.set("isAnyPendingExist", null);
    }

    case ACTIONS.WORKER_TIMESHEET_EXPORT_LIST.PENDING: {
      return state
        .set("workerTimeSheetExportListLoader", true)
        .set("workerTimeSheetExportListError", "")
        .set("workerTimeSheetExportList", null);
    }
    case ACTIONS.WORKER_TIMESHEET_EXPORT_LIST.SUCCESS: {
      return state
        .set("workerTimeSheetExportListLoader", false)
        .set("workerTimeSheetExportListError", "")
        .set("workerTimeSheetExportList", action.data);
    }
    case ACTIONS.WORKER_TIMESHEET_EXPORT_LIST.ERROR: {
      return state
        .set("workerTimeSheetExportListLoader", false)
        .set("workerTimeSheetExportListError", action.error);
    }

    case ACTIONS.SINGLE_TIMESHEET_DETAILS.PENDING: {
      return state
        .set("singleTimesheetDetailsLoader", true)
        .set("singleTimesheetDetailsError", "")
        .set("singleTimesheetDetails", null);
    }
    case ACTIONS.SINGLE_TIMESHEET_DETAILS.SUCCESS: {
      return state
        .set("singleTimesheetDetailsLoader", false)
        .set("singleTimesheetDetailsError", "")
        .set("singleTimesheetDetails", action.data);
    }
    case ACTIONS.SINGLE_TIMESHEET_DETAILS.ERROR: {
      return state
        .set("singleTimesheetDetailsLoader", false)
        .set("singleTimesheetDetailsError", action.error);
    }

    case ACTIONS.EXPORT_TIMESHEET_TO_PAYROLL.PENDING: {
      return state
        .set("exportTimesheetToPayrollLoader", true)
        .set("exportTimesheetToPayrollError", "")
        .set("singleTimesheetDetails", null);
    }
    case ACTIONS.EXPORT_TIMESHEET_TO_PAYROLL.SUCCESS: {
      const payrollType = action.data.type;
      const payload = action?.data?.employees;
      const prevWorkerTimeSheetExportList =
        state.get("workerTimeSheetExportList") || null;
      let updatedList = state.get("workerTimeSheetExportList") || null;
      if (
        prevWorkerTimeSheetExportList &&
        action?.data &&
        action.data?.employees?.length > 0
      ) {
        updatedList = prevWorkerTimeSheetExportList.map((parent) => {
          const payloadItem = payload.find((item) => item.uuid === parent.uuid);
          if (payloadItem) {
            const exportStatusKey = `${payrollType}_export_status`;
            parent.timeSheet = parent.timeSheet.map((child) => ({
              ...child,
              export_status: payloadItem.timesheets.includes(child.uuid)
                ? action?.data[`${exportStatusKey}`] ||
                  PAYROLL_EXPORT_STATUS.INITIATED
                : child[`${exportStatusKey}`],
              [`${exportStatusKey}`]: payloadItem.timesheets.includes(
                child.uuid
              )
                ? action?.data[`${exportStatusKey}`] ||
                  PAYROLL_EXPORT_STATUS.INITIATED
                : child[`${exportStatusKey}`],
            }));
          }
          return parent;
        });
      }
      return state
        .set("exportTimesheetToPayrollLoader", false)
        .set("exportTimesheetToPayrollError", "")
        .set("workerTimeSheetExportList", [...updatedList]);
    }
    case ACTIONS.EXPORT_TIMESHEET_TO_PAYROLL.ERROR: {
      return state
        .set("exportTimesheetToPayrollLoader", false)
        .set("exportTimesheetToPayrollError", action.error);
    }

    case ACTIONS.TIMESHEET_STATUS_CHANGED_LOCALLY: {
      const payload = action?.data?.employees;
      const prevWorkerTimeSheetExportList =
        state.get("workerTimeSheetExportList") || null;
      let updatedList = state.get("workerTimeSheetExportList") || null;
      if (
        prevWorkerTimeSheetExportList &&
        action?.data &&
        action.data?.employees?.length > 0
      ) {
        updatedList = prevWorkerTimeSheetExportList.map((parent) => {
          const payloadItem = payload.find(
            (item) => item.p_uuid === parent.uuid
          );

          if (payloadItem) {
            parent.timeSheet = parent.timeSheet.map((child) => ({
              ...child,
              progress: payloadItem.children.includes(child.uuid)
                ? action?.data?.status
                : child.progress,
            }));
          }
          return parent;
        });
      }
      return state.set("workerTimeSheetExportList", [...updatedList]);
    }

    case ACTIONS.PAYROLL_EXPORT_CALLBACK: {
      const payrollType = action.data.type;
      const prevWorkerTimeSheetExportList =
        state.get("workerTimeSheetExportList") || null;
      if (
        prevWorkerTimeSheetExportList &&
        prevWorkerTimeSheetExportList.length > 0 &&
        action.data &&
        action.data.worker_uuid
      ) {
        const workerIndex = prevWorkerTimeSheetExportList.findIndex(
          (x) => x.uuid === action.data.worker_uuid
        );
        if (workerIndex !== -1) {
          const payrollTimeSheetsMsg = {};
          if (payrollType) {
            Object.assign(payrollTimeSheetsMsg, {
              [`${payrollType}_time_sheets`]: {
                type: payrollType,
                message: action.data?.message,
                [`${payrollType}_status`]:
                  action.data.status === true ? "success" : "error",
              },
            });
          }
          const payrollTypeKey = `${payrollType}_export_status`;
          prevWorkerTimeSheetExportList.splice(workerIndex, 1, {
            ...prevWorkerTimeSheetExportList[workerIndex],
            timeSheet: prevWorkerTimeSheetExportList[
              workerIndex
            ]?.timeSheet?.map((item) => ({
              ...item,
              export_status:
                item[`${payrollTypeKey}`] === PAYROLL_EXPORT_STATUS.INITIATED
                  ? action.data.status === true
                    ? PAYROLL_EXPORT_STATUS.SUCCESS
                    : PAYROLL_EXPORT_STATUS.ERROR
                  : item[`${payrollTypeKey}`],
              [`${payrollTypeKey}`]:
                item[`${payrollTypeKey}`] === PAYROLL_EXPORT_STATUS.INITIATED
                  ? action.data.status === true
                    ? PAYROLL_EXPORT_STATUS.SUCCESS
                    : PAYROLL_EXPORT_STATUS.ERROR
                  : item[`${payrollTypeKey}`],
            })),
            ...payrollTimeSheetsMsg,
          });
        }
      }

      return state.set(
        "workerTimeSheetExportList",
        prevWorkerTimeSheetExportList
          ? [...prevWorkerTimeSheetExportList]
          : null
      );
    }

    case ACTIONS.AUTHENTICATED_PAYROLL_UPDATE_LOCALLY: {
      return state.set("isAuthenticateForPayroll", action.data);
    }

    case ACTIONS.SHOW_RECURRING_SHIFT_MODAL: {
      return state.set("showRecurringModal", action.payload);
    }
    case ACTIONS.GET_ROSTER_INVOICE.PENDING: {
      return state
        .setIn(["rosterInvoices", "rosterInvoicesLoading"], true)
        .setIn(["rosterInvoices", "error"], "");
    }
    case ACTIONS.GET_ROSTER_INVOICE.SUCCESS: {
      return state
        .setIn(["rosterInvoices", "rosterInvoicesLoading"], false)
        .setIn(["rosterInvoices", "rosterInvoicesList"], action.data);
    }
    case ACTIONS.GET_ROSTER_INVOICE.ERROR: {
      return state
        .setIn(["rosterInvoices", "rosterInvoicesLoading"], false)
        .setIn(["rosterInvoices", "error"], action.error);
    }

    case ACTIONS.UPDATE_CALENDER_SEARCH_LOCALLY: {
      return state.set("searchtextOfScheduleShifts", action.data);
    }

    default: {
      return state;
    }
  }
}
