import React from "react";
import { Controller } from "react-hook-form";
import {
  convertLabelIntoName,
  dynamicFormDateValidation,
  getError,
  getExtensionName,
  parseBooleanVal,
} from "Utils/utils";
import SignatureCanvas from "react-signature-canvas";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { FiPlus } from "react-icons/fi";
import {
  EMAIL_REGEX_EXP,
  REGEX_PHONE_NUMBER,
  STATE_FULL_LABEL_OPTIONS,
} from "Constants/constant";
import CustomButton from "components/common/Button/CustomButton";
import SubSectionInputFields from "./SubSectionInputFields";
import TextEditor from "components/common/TextEditor/TextEditor";

const SectionInputFields = (props) => {
  const {
    isViewable = false,
    section,
    register = null,
    setValue = null,
    control,
    watchFormData = null,
    index,
    errors,
    fileUploadHandler,
    fileUploadChecker,
    fileLoader,
    signatures,
    formatIntoPngManager,
    sigCanvasManager,
    clearSignatureManager,
    setSelectedLegal,
    delteRowHandler,
    addRowHandler,
    updatedBody,
    templateForm = null,
    setFieldName = null,
    participantList = [],
    setSelectedBody,
    setOpenBodyModal,
    participant = null,
    handleRemoveOther,
    handleAddOther,
  } = props;

  const checkIsDependent = (element) => {
    if (
      parseBooleanVal(element?.is_dependent_field) &&
      element?.dependent_field_name
    ) {
      const tempData = [];
      section.input_fields.forEach((field, index) => {
        if (field.type !== "sub_section") {
          tempData.push({ ...field, subIndex: null, sectionIndex: index });
        } else {
          field.sub_fields.forEach((subField, subIndex) => {
            tempData.push({ ...subField, subIndex, sectionIndex: index });
          });
        }
      });
      if (tempData.length > 0) {
        const fieldIndex = tempData.findIndex(
          (y) => y.name === element.dependent_field_name
        );
        if (
          fieldIndex !== -1 &&
          watchFormData &&
          Object.keys(watchFormData).length > 0
        ) {
          let isCheckForSectionOrSubSection = false;
          const { input_fields } = watchFormData?.template?.[index] || {};
          const { sectionIndex, subIndex } = tempData?.[fieldIndex] || {};

          if (subIndex === null) {
            if (sectionIndex != null && Array.isArray(input_fields)) {
              const section = input_fields[sectionIndex];
              if (
                section &&
                typeof section === "object" &&
                Object.keys(section).length > 0
              ) {
                isCheckForSectionOrSubSection = section;
              }
            }
          } else {
            if (sectionIndex != null && Array.isArray(input_fields)) {
              const section = input_fields[sectionIndex];

              if (
                section &&
                typeof section === "object" &&
                section.sub_section &&
                Array.isArray(section.sub_section)
              ) {
                const subSection = section.sub_section[subIndex];
                if (
                  subSection &&
                  typeof subSection === "object" &&
                  Object.keys(subSection).length > 0
                ) {
                  isCheckForSectionOrSubSection = subSection;
                }
              }
            }
          }

          if (isCheckForSectionOrSubSection) {
            const name = tempData[fieldIndex].name;
            const dependentField = {
              [`${name}`]: Object.values(isCheckForSectionOrSubSection)[0],
              type:
                tempData[fieldIndex].subIndex === null
                  ? section?.input_fields[tempData[fieldIndex].sectionIndex]
                      .type
                  : section?.input_fields[tempData[fieldIndex].sectionIndex]
                      ?.sub_fields[tempData[fieldIndex].subIndex].type,
            };
            if (dependentField && Object.keys(dependentField).length > 0) {
              if (dependentField.type === "checkbox") {
                return (
                  dependentField[`${element.dependent_field_name}`] ===
                  parseBooleanVal(element.dependent_field_value)
                );
              }
              return (
                dependentField[`${element.dependent_field_name}`] ===
                element.dependent_field_value
              );
            }
          }
        }

        return false;
      }
    }
    return true;
  };

  return (
    <>
      {section?.input_fields.length > 0 &&
        section?.input_fields.map((field, i) => {
          // if (field.type === "content" && checkIsDependent(field)) {
          //   return (
          //     <div
          //       key={`${convertLabelIntoName(field.type)}-${i}`}
          //       className={`col-span-12 flex flex-col`}
          //     >
          //       {register === null &&
          //         field.hasOwnProperty("content") &&
          //         field.content && (
          //           <div
          //             // className="text-xs text-gray-600 rounded-lg px-2 py-2"
          //             className='py-2 border p-4 rounded-lg'
          //             dangerouslySetInnerHTML={{
          //               __html: field.content,
          //             }}
          //           />
          //         )}
          //       {register && (
          //         <TextEditor
          //           text={field.content}
          //           setText={(e) =>
          //             setValue(
          //               `template[${index}].input_fields[${i}].${convertLabelIntoName(
          //                 field.type
          //               )}`,
          //               e
          //             )
          //           }
          //         />
          //       )}
          //     </div>
          //   );
          // }
          if (field.type === "content" && checkIsDependent(field)) {
            return (
              <div
                key={`${convertLabelIntoName(field.type)}-${i}`}
                className={`col-span-12 flex flex-col`}
              >
                {register === null && field.content && (
                  <div
                    className='py-2 border p-4 rounded-lg'
                    dangerouslySetInnerHTML={{
                      __html: field.content,
                    }}
                  />
                )}

                {register && field.content && (
                  <TextEditor
                    text={field.content}
                    setText={(e) =>
                      setValue(
                        `template[${index}].input_fields[${i}].${convertLabelIntoName(
                          field.type
                        )}`,

                        e
                      )
                    }
                    showToolbar={false}
                    readOnly={true}
                    className='border border-gray-300 rounded-md p-4'
                  />
                )}
              </div>
            );
          } else if (field.type === "textarea" && checkIsDependent(field)) {
            return (
              <div
                key={`${convertLabelIntoName(field.lable)}-${i}`}
                className={`${
                  parseBooleanVal(field.isFullWidth)
                    ? "col-span-12"
                    : "col-span-12 md:col-span-6"
                } flex flex-col did-floating-label-content`}
              >
                <label htmlFor='' className='did-floating-label text-sm'>
                  {field.lable}
                </label>
                <textarea
                  {...(register
                    ? register(
                        `template[${index}].input_fields[${i}].${convertLabelIntoName(
                          field.lable
                        )}`,
                        {
                          required: field.required,
                          value: field.value,
                        }
                      )
                    : {
                        value: field.value,
                        readOnly: true,
                        disabled: true,
                      })}
                  type={field.type}
                  className='resize-none overflow-y-auto focus:bg-[#4b6bb710] border px-4 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                  rows={3}
                  style={{ paddingTop: ".5rem" }}
                />
                {!isViewable && getError(errors, field.lable, index, i)}
              </div>
            );
          } else if (field.type === "select" && checkIsDependent(field)) {
            return (
              <div
                key={`${convertLabelIntoName(field.lable)}-${i}`}
                className={`${
                  parseBooleanVal(field.isFullWidth)
                    ? "col-span-12"
                    : "col-span-12 md:col-span-6"
                } flex flex-col did-floating-label-content`}
              >
                <label className='did-floating-label text-sm'>
                  {field.lable}
                </label>
                <div className='print:hidden'>
                  <select
                    {...(register
                      ? register(
                          `template[${index}].input_fields[${i}].${convertLabelIntoName(
                            field.lable
                          )}`,
                          {
                            required: field.required,
                            value: field.value,
                          }
                        )
                      : {
                          value: field.value,
                          readOnly: true,
                          disabled: true,
                        })}
                    className='did-floating-select'
                  >
                    {field.options.map((option, oi) => {
                      return (
                        <option value={option.lable} key={oi}>
                          {option.lable}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className={`did-floating-input sectionInputViewPrint`}>
                  {field.value}
                </div>
                {!isViewable && getError(errors, field.lable, index, i)}
              </div>
            );
          } else if (field.type === "phone" && checkIsDependent(field)) {
            return (
              <div
                key={`${convertLabelIntoName(field.lable)}-${i}`}
                className={`${
                  parseBooleanVal(field.isFullWidth)
                    ? "col-span-12"
                    : "col-span-12 md:col-span-6"
                } flex flex-col did-floating-label-content`}
              >
                <div className='did-floating-label-content'>
                  <label className='did-floating-label truncate-text text-sm'>
                    {field.lable}
                  </label>
                  <input
                    {...(register
                      ? register(
                          `template[${index}].input_fields[${i}].${convertLabelIntoName(
                            field.lable
                          )}`,
                          {
                            required: parseBooleanVal(field.required),
                            // minLength: 10,
                            maxLength: 10,
                            value: field.value,
                            pattern: REGEX_PHONE_NUMBER,
                            onChange: (e) => {
                              const sanitizedValue = e.target.value.replace(
                                /\D/g,
                                ""
                              );
                              const formattedValue = sanitizedValue.slice(
                                0,
                                10
                              );
                              e.target.value = formattedValue;
                            },
                          }
                        )
                      : {
                          value: field.value,
                          readOnly: true,
                          disabled: true,
                        })}
                    className='did-floating-input appearance-no'
                    type='number'
                  />
                  {!isViewable && getError(errors, field.lable, index, i)}
                  {errors?.template?.[index]?.input_fields?.[i]?.[
                    `${field.name}`
                  ]?.type === "minLength" ||
                    (errors?.template?.[index]?.input_fields?.[i]?.[
                      `${field.name}`
                    ]?.type === "maxLength" ? (
                      <span className='text-sm text-red-500'>
                        Phone number must have 10 digits
                      </span>
                    ) : (
                      <></>
                    ))}

                  {errors?.template?.[index]?.input_fields?.[i]?.[
                    `${field.name}`
                  ]?.type === "pattern" && (
                    <span className='text-sm text-red-500'>
                      {/* Phone number must start with 04, 1300 or 1800 */}
                      Please enter a valid phone number!
                    </span>
                  )}
                </div>
              </div>
            );
          } else if (field.type === "email" && checkIsDependent(field)) {
            return (
              <div
                key={`${convertLabelIntoName(field.lable)}-${i}`}
                className={`${
                  parseBooleanVal(field.isFullWidth)
                    ? "col-span-12"
                    : "col-span-12 md:col-span-6"
                } flex flex-col did-floating-label-content`}
              >
                <label className='did-floating-label text-sm'>
                  {field.lable}
                </label>
                <input
                  {...(register
                    ? register(
                        `template[${index}].input_fields[${i}].${convertLabelIntoName(
                          field.lable
                        )}`,
                        {
                          value: field.value,
                          required: parseBooleanVal(field.required),
                          pattern: EMAIL_REGEX_EXP,
                        }
                      )
                    : {
                        value: field.value,
                        readOnly: true,
                        disabled: true,
                      })}
                  className='did-floating-input'
                  type='email'
                />
                {!isViewable && getError(errors, field.lable, index, i)}
                {errors?.template?.[index]?.input_fields?.[i]?.[`${field.name}`]
                  ?.type === "pattern" && (
                  <span className='text-sm text-red-500'>
                    Please Enter a valid email
                  </span>
                )}
              </div>
            );
          } else if (field.type === "address" && checkIsDependent(field)) {
            return (
              <div
                key={`${convertLabelIntoName(field.lable)}-${i}`}
                className={`col-span-12 flex flex-col did-floating-label-content`}
              >
                <div className='grid grid-cols-4 space-x-4'>
                  <div className='col-span-2 md:col-span-1 did-floating-label-content'>
                    <label className='did-floating-label text-sm'>
                      Street Name
                    </label>
                    <div className='print:hidden'>
                      <input
                        {...(register
                          ? register(
                              `template[${index}].input_fields[${i}].address.street`,
                              {
                                required: field.required,
                                value: field.hasOwnProperty("address")
                                  ? field.address.street
                                  : "",
                              }
                            )
                          : {
                              value: field.hasOwnProperty("address")
                                ? field.address.street
                                : "",
                              readOnly: true,
                              disabled: true,
                            })}
                        className='did-floating-input'
                        type='text'
                      />
                      {errors?.template?.[index]?.input_fields?.[i]?.address
                        ?.street?.type === "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                    </div>
                    <div className={`did-floating-input sectionInputViewPrint`}>
                      {field.hasOwnProperty("address")
                        ? field.address.street
                        : ""}
                    </div>
                  </div>
                  <div className='col-span-2 md:col-span-1'>
                    <label className='did-floating-label text-sm'>Suburb</label>
                    <div className='print:hidden'>
                      <input
                        {...(register
                          ? register(
                              `template[${index}].input_fields[${i}].address.subrub`,
                              {
                                required: field.required,
                                value: field.hasOwnProperty("address")
                                  ? field.address.subrub
                                  : "",
                              }
                            )
                          : {
                              value: field.hasOwnProperty("address")
                                ? field.address.subrub
                                : "",
                              readOnly: true,
                              disabled: true,
                            })}
                        className='did-floating-input'
                        type='text'
                      />
                      {errors?.template?.[index]?.input_fields?.[i]?.address
                        ?.subrub?.type === "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                    </div>
                    <div className={`did-floating-input sectionInputViewPrint`}>
                      {field.hasOwnProperty("address")
                        ? field.address.subrub
                        : ""}
                    </div>
                  </div>
                  <div className='col-span-2 md:col-span-1'>
                    <label className='did-floating-label text-sm'>
                      Postcode
                    </label>
                    <input
                      {...(register
                        ? register(
                            `template[${index}].input_fields[${i}].address.postcode`,
                            {
                              required: field.required,
                              value: field.hasOwnProperty("address")
                                ? field.address.postcode
                                : "",
                              onChange: (e) => {
                                e.target.value = e.target.value
                                  .replace(/[^0-9]/g, "")
                                  .slice(0, 4);
                              },
                              maxLength: 4,
                              minLength: 4,
                            }
                          )
                        : {
                            value: field.hasOwnProperty("address")
                              ? field.address.postcode
                              : "",
                            readOnly: true,
                            disabled: true,
                          })}
                      maxLength={4}
                      minLength={4}
                      className='did-floating-input'
                      type='number'
                    />
                    {errors?.template?.[index]?.input_fields?.[i]?.address
                      ?.postcode?.type === "required" && (
                      <span className='text-sm text-red-500'>
                        This field is required!
                      </span>
                    )}
                    {(errors?.template?.[index]?.input_fields?.[i]?.address
                      ?.postcode?.type === "maxLength" ||
                      errors?.template?.[index]?.input_fields?.[i]?.address
                        ?.postcode?.type === "minLength") && (
                      <span className='text-sm text-red-500'>
                        Postcode must be 4 digits!
                      </span>
                    )}
                  </div>

                  <div className='col-span-2 md:col-span-1'>
                    <label className='did-floating-label text-sm'>State</label>
                    <select
                      className='did-floating-select'
                      {...(register
                        ? register(
                            `template[${index}].input_fields[${i}].address.state`,
                            {
                              required: field.required,
                              value: field.hasOwnProperty("address")
                                ? field.address.state
                                : "New south Wales",
                            }
                          )
                        : {
                            value: field.hasOwnProperty("address")
                              ? field.address.state
                              : "",
                            readOnly: true,
                            disabled: true,
                          })}
                    >
                      {STATE_FULL_LABEL_OPTIONS.map((option, state_index) => (
                        <option
                          key={`${state_index}-${option.value}`}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>

                    {errors?.template?.[index]?.input_fields?.[i]?.address
                      ?.state?.type === "required" && (
                      <span className='text-sm text-red-500'>
                        This field is required!
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          } else if (field.type === "signature" && checkIsDependent(field)) {
            return (
              <div
                key={`${convertLabelIntoName(field.lable)}-${i}`}
                className='bg-white border rounded-lg shadow px-8 col-span-12 md:col-span-6 flex flex-col mb-6'
              >
                <div className='flex flex-col py-2'>
                  <p className='font-bold text-green-700'>Signatures</p>
                </div>
                <div className='flex flex-col lg:flex-row justify-between items-center'>
                  <div className=' flex flex-col text-gray-500 py-2 '>
                    <p className='did-floating-label text-sm pb-2'>
                      {field.lable}
                    </p>
                    {signatures.find(
                      (x) => x.name === convertLabelIntoName(field.lable)
                    ) ? (
                      <img
                        src={`${
                          signatures.find(
                            (signature) =>
                              signature.name ===
                              convertLabelIntoName(field.lable)
                          ).image
                        }`}
                        alt='signature'
                        // height={"auto"}
                        // width={"auto"}
                        className='h-auto w-auto sigCanvas border rounded-lg bg-gray-100'
                      />
                    ) : (
                      register && (
                        <>
                          <Controller
                            name={field.name}
                            control={control}
                            render={({ field }) => (
                              <SignatureCanvas
                                ref={(ef) =>
                                  (sigCanvasManager.current[
                                    `${field.name}`
                                  ].current = ef)
                                }
                                onEnd={() =>
                                  field.onChange(
                                    formatIntoPngManager(field.name, index, i)
                                  )
                                }
                                penColor='green'
                                canvasProps={{
                                  width: "auto",
                                  height: "auto",
                                  className:
                                    "h-auto w-auto sigCanvas border rounded-lg bg-gray-100",
                                }}
                              />
                            )}
                          />

                          <input
                            className='hidden sr-only'
                            {...register(
                              `${convertLabelIntoName(field.lable)}`,
                              {
                                required: parseBooleanVal(field.required),
                                value: signatures.find(
                                  (x) =>
                                    x.name === convertLabelIntoName(field.lable)
                                )
                                  ? "signature-added"
                                  : null,
                              }
                            )}
                          />

                          {errors?.[`${convertLabelIntoName(field.lable)}`]
                            ?.type === "required" && (
                            <span className='text-sm text-red-500'>
                              This field is required!
                            </span>
                          )}
                        </>
                      )
                    )}
                    {!isViewable && (
                      <div onClick={() => clearSignatureManager(field.name)}>
                        <p className='flex items-center mt-1 cursor-pointer'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-6 w-6'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15'
                            />
                          </svg>
                          {signatures.find((x) => x.name === field.name)
                            ? "update"
                            : "clear"}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          } else if (field.type === "table" && checkIsDependent(field)) {
            return (
              <div
                key={`${convertLabelIntoName(field.lable)}-${i}`}
                className='col-span-12 flex flex-col'
              >
                <label
                  htmlFor=''
                  className='did-floating-label truncate-text text-sm'
                >
                  {field.lable}
                </label>
                <div className='text-center flex flex-col justify-center'>
                  <div className='mt-8 shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg min-h-[48.4px] max-h-[300px] overflow-auto custom-scroll w-full'>
                    <table className='divide-y divide-gray-300 w-full print:table-fixed'>
                      <thead className='bg-blue-50 capitalize'>
                        <tr className=''>
                          {field?.columns.map((item, a) => (
                            <th
                              key={`${convertLabelIntoName(item.lable)}-${a}`}
                              scope='col'
                              // className="did-floating-label text-sm"
                              className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 table-col-min-width'
                            >
                              {item.lable}
                            </th>
                          ))}
                          {!isViewable && (
                            <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 max-w-1/5 print:hidden' />
                          )}
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200 bg-white '>
                        {field.hasOwnProperty("rows") &&
                          field.rows.length > 0 &&
                          field?.rows.map((row, ri) => {
                            return (
                              <tr className='text-sm text-gray-500' key={ri}>
                                {row?.map((col, ci) => {
                                  if (col.type === "select") {
                                    return (
                                      <td
                                        key={`${convertLabelIntoName(
                                          col.lable
                                        )}-${ci}`}
                                        className='text-sm font-medium text-gray-900 table-col-min-width'
                                      >
                                        <select
                                          {...(register
                                            ? register(
                                                `template[${index}].input_fields[${i}].rows[${ri}].columns[${ci}].${convertLabelIntoName(
                                                  col.lable
                                                )}`,
                                                {
                                                  value: col.value,
                                                }
                                              )
                                            : {
                                                value: col.value,
                                                readOnly: true,
                                                disabled: true,
                                              })}
                                          key={ci}
                                          id=''
                                          className='w-full border px-4 py-2 focus:bg-gray-100 focus:outline-none'
                                        >
                                          {col.options.map((option, co) => {
                                            return (
                                              <option
                                                key={co}
                                                value={option.lable}
                                              >
                                                {option.lable}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </td>
                                    );
                                  } else if (col.type === "checkbox") {
                                    return (
                                      <td
                                        key={`${convertLabelIntoName(
                                          col.lable
                                        )}-${ci}`}
                                        className='text-sm font-medium text-gray-900 print:break-all print:p-2 table-col-min-width print:border print:border-solid print:border-gray-200'
                                      >
                                        <div className='w-8 mx-auto'>
                                          <input
                                            {...(register
                                              ? register(
                                                  `template[${index}].input_fields[${i}].rows[${ri}].columns[${ci}].${convertLabelIntoName(
                                                    col.lable
                                                  )}`,
                                                  {
                                                    value: col.value,
                                                  }
                                                )
                                              : {
                                                  value: col.value,
                                                  readOnly: true,
                                                  disabled: true,
                                                })}
                                            style={{
                                              border: "1px solid #ebe8e8",
                                            }}
                                            type={col.type}
                                            checked={col.value}
                                            className='w-full border p-2.5 py-3 focus:bg-gray-100 focus:outline-none'
                                          />
                                        </div>
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <td
                                        key={`${convertLabelIntoName(
                                          col.lable
                                        )}-${ci}`}
                                        className='text-sm font-medium  text-gray-900 print:break-all print:p-2 table-col-min-width print:border print:border-solid print:border-gray-200'
                                      >
                                        <div className='hidden print:block text-left print:break-all'>
                                          {col.value}
                                        </div>
                                        <input
                                          {...(register
                                            ? register(
                                                `template[${index}].input_fields[${i}].rows[${ri}].columns[${ci}].${convertLabelIntoName(
                                                  col.lable
                                                )}`,
                                                {
                                                  value: col.value,
                                                }
                                              )
                                            : {
                                                value: col.value,
                                                readOnly: true,
                                                disabled: true,
                                              })}
                                          style={{
                                            border: "1px solid #ebe8e8",
                                          }}
                                          type={col.type}
                                          className='block print:hidden w-full border px-4 py-2 focus:bg-gray-100 focus:outline-none'
                                          step='0.01'
                                        />
                                      </td>
                                    );
                                  }
                                })}
                                {!isViewable && (
                                  <td className='text-sm font-medium text-gray-900 max-w-1/5 print:hidden'>
                                    <button
                                      type='button'
                                      onClick={() =>
                                        delteRowHandler(index, i, ri)
                                      }
                                      className='w-full border px-4 py-2 focus:bg-gray-100 focus:outline-none'
                                    >
                                      Delete
                                    </button>
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className='pagebreak'></div>
                  {!isViewable && (
                    <div className='flex justify-start items-center space-x-4 my-4'>
                      <span
                        onClick={() => addRowHandler(index, i)}
                        className='cursor-pointer border py-1.5 pl-2 pr-3 bg-blue-800 text-white rounded-lg flex items-center space-x-1'
                      >
                        <FiPlus />
                        <span>Row</span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            );
          } else if (field.type === "date" && checkIsDependent(field)) {
            return (
              <div
                key={`${convertLabelIntoName(field.lable)}-${i}`}
                className={`${
                  parseBooleanVal(field.isFullWidth)
                    ? "col-span-12"
                    : "col-span-12 md:col-span-6"
                } flex flex-col did-floating-label-content`}
              >
                <label htmlFor='' className='did-floating-label text-sm'>
                  {field.lable}
                </label>
                <input
                  {...(register
                    ? register(
                        `template[${index}].input_fields[${i}].${convertLabelIntoName(
                          field.lable
                        )}`,
                        {
                          value: field.value,
                          required: parseBooleanVal(field.required),
                          pattern: dynamicFormDateValidation(
                            field,
                            watchFormData,
                            index,
                            i,
                            null
                          ),
                        }
                      )
                    : {
                        value: field.value,
                        readOnly: true,
                        disabled: true,
                      })}
                  type={field.type}
                  // max={completeDate}
                  className='did-floating-input'
                />
                {!isViewable && getError(errors, field.lable, index, i)}
                {errors?.template?.[index]?.input_fields?.[i]?.[
                  `${convertLabelIntoName(field.lable)}`
                ]?.type === "pattern" && (
                  <span className='text-sm text-red-500'>
                    {dynamicFormDateValidation(
                      field,
                      watchFormData,
                      index,
                      i,
                      null,
                      false
                    )}
                  </span>
                )}
              </div>
            );
          } else if (field.type === "checkbox" && checkIsDependent(field)) {
            return (
              <div
                key={`${convertLabelIntoName(field.lable)}-${i}`}
                className={`${
                  parseBooleanVal(field.isFullWidth)
                    ? "col-span-12"
                    : "col-span-12 md:col-span-6"
                }`}
              >
                <div className=''>
                  <input
                    {...(register
                      ? register(
                          `template[${index}].input_fields[${i}].${convertLabelIntoName(
                            field.lable
                          )}`,
                          {
                            required: parseBooleanVal(field.required),
                            value: field.value,
                          }
                        )
                      : {
                          checked: field.value,
                          readOnly: true,
                          disabled: true,
                        })}
                    type='checkbox'
                    className='h-4 w-4 mr-3 ml-2'
                    id={`template[${index}].input_fields[${i}].${convertLabelIntoName(
                      field.lable
                    )}`}
                  />
                  <label
                    htmlFor={`template[${index}].input_fields[${i}].${convertLabelIntoName(
                      field.lable
                    )}`}
                    className='did-floating-label truncate-text text-sm'
                  >
                    {field.lable}
                  </label>
                  {field.hasOwnProperty("hint") && field.hint && (
                    <div
                      className='text-sm text-gray-600 rounded-lg px-2 py-2'
                      dangerouslySetInnerHTML={{
                        __html: field.hint,
                      }}
                    />
                  )}
                </div>
                {!isViewable && getError(errors, field.lable, index, i)}
              </div>
            );
          } else if (field.type === "radio" && checkIsDependent(field)) {
            return (
              <div
                key={`${convertLabelIntoName(field.lable)}-${i}`}
                className={`${
                  parseBooleanVal(field.isFullWidth)
                    ? "col-span-12"
                    : "col-span-12 md:col-span-6"
                }  pb-3`}
              >
                <label className='did-floating-label truncate-text text-sm'>
                  {field.lable}
                </label>
                <div
                  className={`${
                    field.options.length > 2
                      ? "flex flex-wrap"
                      : "flex flex-col"
                  }`}
                >
                  {field.options.map((option, oi) => {
                    return (
                      <div
                        key={oi}
                        className='flex items-center space-x-4 mr-4'
                      >
                        <input
                          {...(register
                            ? register(
                                `template[${index}].input_fields[${i}].${convertLabelIntoName(
                                  field.lable
                                )}`,
                                {
                                  required: parseBooleanVal(field.required),
                                  value: !field.hasOwnProperty("value")
                                    ? null
                                    : field.value
                                    ? field.value
                                    : option.lable,
                                }
                              )
                            : {
                                checked: option.lable === field.value,
                                readOnly: true,
                                disabled: true,
                              })}
                          value={option.lable}
                          id={`template[${index}].input_fields[${i}].${convertLabelIntoName(
                            field.lable
                          )}.${option.lable}`}
                          type='radio'
                          className='h-4 w-4'
                        />
                        <label
                          htmlFor={`template[${index}].input_fields[${i}].${convertLabelIntoName(
                            field.lable
                          )}.${option.lable}`}
                          className='truncate-text text-sm'
                        >
                          {option.lable}
                        </label>
                      </div>
                    );
                  })}
                </div>
                {!isViewable && getError(errors, field.lable, index, i)}
              </div>
            );
          } else if (field.type === "file" && checkIsDependent(field)) {
            return (
              <div
                key={`${convertLabelIntoName(field.lable)}-${i}`}
                className={`${
                  parseBooleanVal(field.isFullWidth)
                    ? "col-span-12"
                    : "col-span-12 md:col-span-6"
                } flex flex-col did-floating-label-content`}
              >
                <label className='did-floating-label text-sm'>
                  {field.lable}
                </label>
                {register === null && isViewable && (
                  <div className='mt-1'>
                    <p className='text-sm text-gray-500 truncate'>
                      {field.value}
                    </p>
                    <a
                      href={field.path}
                      download
                      target='_blank'
                      className='inline-block mt-1 cursor-pointer bg-gradient text-white font-bold py-1 px-4 rounded print:hidden'
                      rel='noreferrer'
                    >
                      {`${getExtensionName(field.path)}`}
                    </a>
                  </div>
                )}
                {!isViewable && register !== null && (
                  <>
                    <input
                      {...(register
                        ? register(
                            `template[${index}].input_fields[${i}].${convertLabelIntoName(
                              field.lable
                            )}`,
                            {
                              required:
                                parseBooleanVal(field.required) && !field.value
                                  ? true
                                  : false,
                              onChange: (e) => {
                                fileUploadHandler(e, index, i);
                              },
                              value: field.value
                                ? [new File([Blob], "file")]
                                : null,
                            }
                          )
                        : {
                            value: field.value
                              ? [new File([Blob], "file")]
                              : null,
                            readOnly: true,
                            disabled: true,
                          })}
                      type='file'
                      id={`template[${index}].input_fields[${i}].${field.name}`}
                      style={{ display: "none" }}
                    />
                    <div className='did-floating-input'>
                      <div className='flex justify-between items-center h-full'>
                        <label
                          htmlFor={`template[${index}].input_fields[${i}].${field.name}`}
                          className='cursor-pointer bg-gradient p-1 rounded text-white p-0.5'
                        >
                          {field.value
                            ? "Change file"
                            : "Click me to upload file"}
                        </label>
                        {field.value && (
                          <p
                            style={{ whiteSpace: "pre" }}
                            className='truncate pl-3'
                          >
                            {field.value}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {!isViewable && getError(errors, field.lable, index, i)}
                {!fileUploadChecker && fileLoader === `${index}-${i}` && (
                  <h1>Loading</h1>
                )}
              </div>
            );
          } else if (field.type === "sub_section") {
            return (
              <SubSectionInputFields
                key={`${i}-${index}-${field.section_title}`}
                isViewable={isViewable}
                field={field}
                i={i}
                register={register}
                index={index}
                errors={errors}
                fileUploadHandler={fileUploadHandler}
                fileUploadChecker={fileUploadChecker}
                fileLoader={fileLoader}
                signatures={signatures}
                control={control}
                clearSignatureManager={clearSignatureManager}
                sigCanvasManager={sigCanvasManager}
                formatIntoPngManager={formatIntoPngManager}
                watchFormData={watchFormData}
                setSelectedLegal={setSelectedLegal}
                addRowHandler={addRowHandler}
                delteRowHandler={delteRowHandler}
                handleRemoveOther={handleRemoveOther}
                handleAddOther={handleAddOther}
                checkIsDependent={checkIsDependent}
              />
            );
          } else {
            return (
              <>
                {checkIsDependent(field) && (
                  <>
                    <div
                      key={`${convertLabelIntoName(field.lable)}-${i}`}
                      className={`${
                        parseBooleanVal(field.isFullWidth) &&
                        parseBooleanVal(field.isBodyModal)
                          ? "col-span-8"
                          : parseBooleanVal(field.isFullWidth)
                          ? "col-span-12"
                          : "col-span-12 md:col-span-6"
                      } flex flex-col did-floating-label-content `}
                    >
                      <label className='did-floating-label truncate-text text-sm'>
                        {field.lable}
                      </label>
                      <div className='mt-1 rounded-md'>
                        <div className='print:hidden'>
                          <input
                            title={
                              field.name === "participant_name"
                                ? `${
                                    templateForm
                                      ? templateForm.submitted_against
                                      : participant
                                      ? ` ${participant.first_name} ${participant.last_name}`
                                      : " "
                                  }`
                                : updatedBody
                                ? updatedBody
                                : field.value
                            }
                            {...(register
                              ? register(
                                  `template[${index}].input_fields[${i}].${convertLabelIntoName(
                                    field.lable
                                  )}`,
                                  {
                                    required: parseBooleanVal(field.required),
                                    value:
                                      field.name === "participant_name"
                                        ? `${
                                            templateForm
                                              ? templateForm.submitted_against
                                              : participant
                                              ? ` ${participant.first_name} ${participant.last_name}`
                                              : " "
                                          }`
                                        : updatedBody
                                        ? updatedBody
                                        : field.value,
                                  }
                                )
                              : {
                                  value:
                                    field.name === "participant_name"
                                      ? `${
                                          templateForm
                                            ? templateForm.submitted_against
                                            : participant
                                            ? ` ${participant.first_name} ${participant.last_name}`
                                            : " "
                                        }`
                                      : updatedBody
                                      ? updatedBody
                                      : field.value,
                                  readOnly: true,
                                  disabled: true,
                                })}
                            disabled={
                              parseBooleanVal(field.isBodyModal) ||
                              field.name === "participant_name"
                            }
                            type={field.type}
                            step={field.type === "number" ? "0.01" : undefined} // allows two decimal places for number type
                            className={`${
                              parseBooleanVal(field.isBodyModal) && "capitalize"
                            } did-floating-input `}
                          />
                        </div>

                        <div
                          className={`${
                            parseBooleanVal(field.isBodyModal) && "capitalize"
                          } did-floating-input sectionInputViewPrint`}
                        >
                          {field.name === "participant_name"
                            ? `${
                                templateForm
                                  ? templateForm.submitted_against
                                  : participant
                                  ? ` ${participant.first_name} ${participant.last_name}`
                                  : " "
                              }`
                            : updatedBody
                            ? updatedBody
                            : field.value}
                        </div>

                        {field?.legalRef && (
                          <div className='absolute right-[10px] top-[37px]'>
                            <span
                              onClick={() => setSelectedLegal(field.legalRef)}
                              role='button'
                              tabIndex='0'
                              className='cursor-pointer'
                            >
                              <QuestionMarkCircleIcon
                                className='h-5 w-5 text-gray-400'
                                aria-hidden='true'
                              />
                            </span>
                          </div>
                        )}
                      </div>
                      {field.hasOwnProperty("hint") && field.hint && (
                        <div
                          className='text-xs text-gray-600 rounded-lg px-2 py-2'
                          dangerouslySetInnerHTML={{
                            __html: field.hint,
                          }}
                        />
                      )}
                      {field.name === "participant_name" && (
                        <div className='bg-gray-100 rounded-lg px-4'>
                          {field.name === "participant_name" &&
                            participantList.length > 0 &&
                            participantList.map((pl) => {
                              return (
                                <div className='flex items-center space-x-3 p-1'>
                                  <div className='h-6 w-6 font-bold rounded-full'>
                                    <img
                                      src={`https://ui-avatars.com/api/?name=${pl.first_name} ${pl.first_name}&background=random&size=128`}
                                      alt=''
                                      className='w-full rounded-full font-bold'
                                    />
                                  </div>
                                  <div>{`${pl.first_name} ${pl.last_name}`}</div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                      {!isViewable && getError(errors, field.lable, index, i)}
                    </div>
                    {isViewable &&
                      field?.isBodyModal &&
                      parseBooleanVal(field.isBodyModal) && (
                        <div className='col-span-2 flex items-center did-floating-label-content print:hidden'>
                          <CustomButton
                            label='View'
                            type='button'
                            cssClass='mb-2 text-white py-2 px-4 rounded-lg bg-blue-dark h-fit'
                            clickHandler={() => {
                              setSelectedBody(field?.value);
                              setOpenBodyModal(true);
                            }}
                          />
                        </div>
                      )}
                    {!isViewable &&
                      setFieldName &&
                      field?.isBodyModal &&
                      parseBooleanVal(field.isBodyModal) && (
                        <div className='col-span-2 flex items-center did-floating-label-content print:hidden'>
                          <CustomButton
                            cssClass='mb-2 text-white py-2 px-4 rounded-lg bg-blue-dark h-fit'
                            label='Update'
                            type='button'
                            varinat='primary'
                            clickHandler={() => {
                              setFieldName(
                                `template[${index}].input_fields[${i}].${convertLabelIntoName(
                                  field.lable
                                )}`
                              );
                              setSelectedBody(field?.value);
                              setOpenBodyModal(true);
                            }}
                          />
                        </div>
                      )}
                    {!isViewable &&
                      setFieldName === null &&
                      field?.isBodyModal &&
                      parseBooleanVal(field.isBodyModal) && (
                        <div className='col-span-2 flex items-center did-floating-label-content print:hidden'>
                          <CustomButton
                            type='button'
                            clickHandler={() => {
                              const element = document.getElementById(
                                `template[${index}].input_fields[${i}].${convertLabelIntoName(
                                  field.lable
                                )}`
                              );
                              const obj = {
                                index,
                                input_field_index: i,
                                name: field.name,
                                field,
                                value:
                                  element && element.value ? element.value : "",
                              };
                              setSelectedBody(obj);
                              setOpenBodyModal(true);
                            }}
                            cssClass='mb-2 text-white py-2 px-4 rounded-lg bg-blue-dark h-fit'
                            label='Select Body'
                          />
                        </div>
                      )}
                  </>
                )}
              </>
            );
          }
        })}
    </>
  );
};

export default SectionInputFields;
