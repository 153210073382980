import React, { useMemo } from "react";
import { classNames } from "Utils/utils";

const PricingItemCard = (props) => {
  const {
    renderFor,
    data,
    handleChange,
    selectedData,
    isSubscriptionNotChangeable,
  } = props;

  const dataToMap = useMemo(() => {
    if (renderFor === "participants") {
      return data;
    }
    return data ? Object.entries(data) : [];
  }, [data]);

  return (
    <div className='border border-1 border-gray-400 rounded-lg'>
      <div className='pt-8 pb-6 px-4'>
        <span className='bg-gradient text-white font-extrabold py-2 flex rounded-full justify-center items-center'>
          {renderFor === "year_1" ? (
            "Monthly Price"
          ) : renderFor === "yearly" ? (
            <div className='flex items-center gap-x-2'>
              <span>Annually Price</span>
              <span className='text-xs bg-red-500 text-white px-2 py-1 rounded-full'>
                10% Off
              </span>
            </div>
          ) : (
            "Participants"
          )}
        </span>
      </div>
      {renderFor === "year_1" &&
        dataToMap.map(([participants, pricing], index) => (
          <div
            key={index}
            role='button'
            tabIndex={0}
            onClick={() => {
              if (isSubscriptionNotChangeable) return;
              if (pricing && Object.values(pricing).length === 0) return;
              handleChange("price", { [`${participants}`]: { ...pricing } });
            }}
            className={classNames(
              "transition duration-300 ease-in py-4 flex justify-center items-center",
              pricing && Object.values(pricing).length === 0
                ? "bg-gray-200 opacity-50 cursor-auto"
                : "hover:bg-gray-100",
              pricing &&
                Object.values(pricing).length > 0 &&
                selectedData?.selectedPrice?.price_id === pricing.price_id
                ? "bg-gradient text-white"
                : "text-black"
            )}
          >
            {pricing?.monthly ?? "-"}
          </div>
        ))}
      {renderFor === "yearly" &&
        dataToMap.map(([participants, pricing], index) => {
          const discountedPriceString = pricing?.monthly || "-";
          const priceParts = discountedPriceString.split(" ");

          const discountedBasePrice = priceParts[0]?.split("$")[1];

          const originalBasePrice = discountedBasePrice
            ? Number.isInteger(parseFloat(discountedBasePrice) / 0.9)
              ? (parseFloat(discountedBasePrice) / 0.9).toString()
              : (parseFloat(discountedBasePrice) / 0.9).toFixed(2)
            : 0;
          return (
            <div
              key={index}
              role='button'
              tabIndex={0}
              onClick={() => {
                if (isSubscriptionNotChangeable) return;
                if (pricing && Object.values(pricing).length === 0) return;
                handleChange("price", { [`${participants}`]: { ...pricing } });
              }}
              className={classNames(
                "transition duration-300 ease-in py-4 flex justify-center items-center",
                pricing && Object.values(pricing).length === 0
                  ? "bg-gray-200 opacity-50 cursor-auto"
                  : "hover:bg-gray-100",
                pricing &&
                  Object.values(pricing).length > 0 &&
                  selectedData?.selectedPrice?.price_id === pricing.price_id
                  ? "bg-gradient text-white"
                  : "text-black"
              )}
            >
              <span className='text-sm line-through'>
                ${originalBasePrice || "-"}
              </span>
              <span className='mx-1'> </span>
              <span>{`${pricing?.monthly}/year` || "-"}</span>
            </div>
            // <div
            //   key={index}
            //   role='button'
            //   tabIndex={0}
            //   onClick={() => {
            //     if (isSubscriptionNotChangeable) return;
            //     if (pricing && Object.values(pricing).length === 0) return;
            //     handleChange("price", { [`${participants}`]: { ...pricing } });
            //   }}
            //   className={classNames(
            //     "transition duration-300 ease-in py-4 flex flex-col items-center",
            //     pricing && Object.values(pricing).length === 0
            //       ? "bg-gray-200 opacity-50 cursor-auto"
            //       : "hover:bg-gray-100",
            //     pricing &&
            //       Object.values(pricing).length > 0 &&
            //       selectedData?.selectedPrice?.price_id === pricing.price_id
            //       ? "bg-gradient text-white"
            //       : "text-black"
            //   )}
            // >
            //   <div className='flex items-center gap-2'>
            //      className={`text-sm line-through ${
            //         pricing &&
            //         Object.values(pricing).length > 0 &&
            //         selectedData?.selectedPrice?.price_id === pricing.price_id
            //           ? "text-white-500"
            //           : "text-gray-500"
            //       }`}
            //     >
            //       {originalBasePrice ? `${originalBasePrice}` : "-"}
            //     </span>  <span

            //     <span className='text-md font-bold'>
            //     {pricing?.monthly ?? "-"}
            //     </span>

            //   </div>
            // </div>
          );
        })}

      {renderFor === "participants" &&
        dataToMap.map((item, index) => (
          <div
            key={index}
            role='button'
            tabIndex={0}
            onClick={() => {
              if (isSubscriptionNotChangeable) return;
              if (item?.includes("200+")) return;
              handleChange("noOfParticipants", item);
            }}
            className={classNames(
              "transition duration-300 ease-in py-4 flex justify-center items-center",
              item?.includes("200+")
                ? "bg-gray-200 opacity-50 cursor-auto"
                : "hover:bg-gray-100",
              selectedData?.selectedParticipants === item
                ? " bg-gradient text-white"
                : "text-black"
            )}
          >
            {item}
          </div>
        ))}
    </div>
  );
};

export default PricingItemCard;
