import { connect } from "react-redux";
import ParticipantList from "./ParticipantList";
import { getSPParticipantsListRequest } from "../../../store/actions/sp.actions";
import {
  getParticipantsList,
  getSpParticipantsListLoading,
} from "../../../store/selector/sp.selector";

import {
  getSupportCoordinatorLoader,
  getSupportCoordinator,
} from "../../../store/selector/cos.selector";
import { supportCoordinatorsRequest } from "../../../store/actions/cos.actions";

const mapDispatchToProps = {
  getParticipantList: getSPParticipantsListRequest,
  getSupportCoordinators: supportCoordinatorsRequest,
};

const mapStateToProps = (state) => {
  const spParticipants = getParticipantsList(state);
  const loading = getSpParticipantsListLoading(state);

  const supportCoordinator = getSupportCoordinator(state);
  const supportCoordinatorLoader = getSupportCoordinatorLoader(state);

  return {
    loading,
    spParticipants,
    supportCoordinator,
    supportCoordinatorLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantList);
