import React, { useState } from "react";
import moment from "moment";
import Folder from "../../../assets/image14.svg";
import PDFI from "../../../assets/pdf.svg";
import GIFI from "../../../assets/gif.svg";
import JPGI from "../../../assets/jpg.svg";
import PNGI from "../../../assets/png.svg";
// import TXTI from "../../../assets/txt.svg";
import CSVI from "../../../assets/csv.svg";
import DOCXI from "../../../assets/doscii.svg";
import XLSXI from "../../../assets/xlsx.svg";
import DOCI from "../../../assets/doc.svg";
import { DropDown } from "../Enhansment/DropDown/DropdownMenu/DropDown";
import {
  baseURLImage,
  formatDateIntoDMYToDisplay,
  textTruncate,
} from "Utils/utils";
import { apiCall } from "store/services/service";
import {
  URL_GET_FILE,
  URL_SIL_ACCOMMODATION_DOCUMENT_VIEW_FILE,
  URL_WORKER_GET_FILE,
} from "store/services/URL";
import { RiGitRepositoryPrivateLine } from "react-icons/ri";
import { MdOutlinePublic } from "react-icons/md";
import { BASE_URL } from "store/services/URL";
import { toast } from "react-toastify";
import { roleAccess } from "helpers/RolesPermission";
import { USER_ROLE } from "Constants/constant";
import { connect } from "react-redux";
import { getUser } from "store/selector/auth.selector";
import Loader from "../Loader/Loader";

const FolderView = (props) => {
  const {
    setUpdatePermission,
    role,
    ext,
    permissionRename,
    type,
    badged,
    label,
    createdDate,
    onClick,
    item,
    removeFolder,
    removeFile,
    isEdit,
    currentFolder,
    currentLoginUser,
    isUserGracePeriodOver,

    //not use
    documents,
    response,
  } = props;

  const [isLoadingViewFile, setIsLoadingViewFile] = useState(null);

  const getImage = () => {
    switch (type) {
      case "folder":
        return Folder;
      case "file":
        return getIcon();
      default:
        return "";
    }
  };

  const getIcon = () => {
    // const ext = label.substring(label.lastIndexOf(".") + 1, label.length);
    switch (ext) {
      case "pdf":
        return PDFI;
      case "gif":
        return GIFI;
      case "jpg":
        return JPGI;
      case "jpeg":
        return JPGI;
      case "png":
        return PNGI;
      // case "png":
      //   return TXTI;
      case "csv":
        return CSVI;

      case "docx":
        return DOCXI;

      case "xlsx":
        return XLSXI;

      case "doc":
        return DOCI;

      default:
        return Folder;
    }
  };

  const getBadge = () => {
    if (type !== "file") {
      return null;
    }

    switch (badged) {
      case "Replaced":
        return (
          <span className='inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800'>
            Replaced
          </span>
        );
      case "Expiry":
        return (
          <span className='inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-semibold text-red-800'>
            Expired
          </span>
        );
      default:
        return null;
    }
  };

  const handleViewDocument = (url, e, response) => {
    if (type === "file" && (ext === "jpg" || ext === "jpeg" || ext === "png")) {
      window.open(url, "_blank");
    } else {
      const googleDocsUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
        url
      )}&embedded=true`;
      window.open(googleDocsUrl, "_blank");
    }
    e.preventDefault();
  };

  const handleClickHandler = async (e) => {
    if (e === "Change Permission") {
      setUpdatePermission(null);
      apiCall
        .post({
          url: `${BASE_URL}sp/file-management/file-access`,
          skipQueryParams: false,
          isAuthToken: currentLoginUser?.AccessToken,
          params: {
            id: item?.id, // file id
            visibility: item?.visibility === true ? false : true, // this should be true or false
          },
        })
        .then((response) => {
          setUpdatePermission(response.file);
          toast.success("Updated Successfully!");
        })
        .catch((e) => {
          console.log("errrr", e);
        });
    } else if (e === "Rename") {
      isEdit(true);
      currentFolder(item);
    } else if (e === "Remove") {
      if (type === "folder") {
        setIsLoadingViewFile(item.uuid);
        removeFolder({ folder_id: item.id }, (res, error) => {
          if (error || res) {
            setIsLoadingViewFile(item.uuid);
          }
        });
      } else {
        setIsLoadingViewFile(item.uuid);
        removeFile({ file_management_id: item.id }, (res, error) => {
          if (error || res) {
            setIsLoadingViewFile(item.uuid);
          }
        });
      }
      localStorage.setItem("removedItem", JSON.stringify(item));
    } else if (e === "Download") {
      viewfile(e, true);
      // apiCall
      //   .get({
      //     url: `${
      //       roleAccess([USER_ROLE.Sp_Standard_User])
      //         ? URL_WORKER_GET_FILE
      //         : URL_GET_FILE
      //     }?file_management_uuid=${item.uuid}`,
      //     isAuthToken: currentLoginUser?.AccessToken,
      //     params: {},
      //   })
      //   .then((response) => {
      //     window.open(baseURLImage(response?.link), "_blank");
      //   })
      //   .catch((e) => {
      //     console.log("errrr", e);
      //   });
    } else if (e === "View") {
      // Fetch document URL and open Google Docs viewer
      await viewfile(e);
      // apiCall
      //   .get({
      //     url: `${
      //       roleAccess([USER_ROLE.Sp_Standard_User])
      //         ? URL_WORKER_GET_FILE
      //         : URL_GET_FILE
      //     }?file_management_uuid=${item.uuid}`,
      //     isAuthToken: currentLoginUser?.AccessToken,
      //     params: {},
      //   })
      //   .then((response) => {
      //     const documentUrl = baseURLImage(response?.link);
      //     if (documentUrl) {
      //       handleViewDocument(documentUrl, e, response);
      //     } else {
      //       console.error("Document URL not found");
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Error fetching document URL:", error);
      //   });
    }
  };

  const viewfile = async (e, isForDownload = false) => {
    setIsLoadingViewFile(item.uuid);
    apiCall
      .get({
        url: `${
          roleAccess([USER_ROLE.Sp_Standard_User])
            ? URL_WORKER_GET_FILE
            : role === "sil"
            ? URL_SIL_ACCOMMODATION_DOCUMENT_VIEW_FILE
            : URL_GET_FILE
        }?file_management_uuid=${item.uuid}`,
        isAuthToken: currentLoginUser?.AccessToken,
        params: {},
      })
      .then((response) => {
        if (isForDownload) {
          window.open(baseURLImage(response?.link), "_blank");
        } else {
          const documentUrl = baseURLImage(response?.link);
          if (documentUrl) {
            handleViewDocument(documentUrl, e, response);
          } else {
            console.error("Document URL not found");
          }
        }
        setIsLoadingViewFile(null);
      })
      .catch((error) => {
        setIsLoadingViewFile(null);
        console.error("Error fetching document URL:", error);
      });
  };

  return (
    <div
      className='border border-solid shadow-md rounded-lg bg-[#FFFFFF] border-gray-200'
      onDoubleClick={onClick}
    >
      <div className=' px-6 py-4 space-y-4' onDoubleClick={viewfile}>
        <div className='flex justify-between'>
          <div className=' flex items-center gap-2'>
            <img
              src={getImage()}
              alt='image_folder'
              className='h-[44px] w-[36px]'
            />
            <div className=''>
              <div className=' font-semibold text-normal text-gray-800'>
                {textTruncate(label, 15, 14)}
              </div>
              <p className='text-gray-400 font-normal leading-5 text-sm'>
                {formatDateIntoDMYToDisplay(createdDate)}
              </p>
            </div>
          </div>
          <div>
            <div className='flex justify-end'>
              <div>
                {isLoadingViewFile === item.uuid ? (
                  // || item.uuid === "b5f9b8f3-9f7b-4c05-9f87-4a85b150d43c"
                  <Loader isSmallView={true} cssClass={"text-black mr-0"} />
                ) : (
                  <DropDown
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    role={role}
                    permissionRename={permissionRename}
                    type={type}
                    clickHandler={(e) => handleClickHandler(e)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          {badged !== "" && item?.is_expire === true && getBadge()}
          {type === "file" && (
            <div className='flex items-center justify-between text-gray-400 font-normal text-sm'>
              {item?.expiry_time && item?.is_expire === true
                ? "Expired on " + moment(item?.expiry_time).format("DD-MM-YYYY")
                : item?.expiry_time &&
                  item?.is_expire === false &&
                  "Expires on " +
                    moment(item?.expiry_time).format("DD-MM-YYYY")}
              {role === "participant" && type === "file" && (
                <div
                  // onClick={handleChangeVisibli}
                  className={`${
                    item?.visibility === true
                      ? "hover:text-red-700 text-[#DDDDD]"
                      : "hover:text-green-700 text-[#DDDDD]"
                  }`}
                >
                  {item?.visibility === false ? (
                    <MdOutlinePublic
                      title='Public'
                      className='text-xl font font-semibold'
                    />
                  ) : (
                    <RiGitRepositoryPrivateLine
                      title='Private'
                      className='text-xl font font-semibold'
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const currentLoginUser = getUser(state);
  return { currentLoginUser };
};
export default connect(mapStateToProps, null)(FolderView);
