import React, { useEffect } from "react";
import WorkerSupportTeam from "../../../Pages/Dashboard/Instances/Instances";
import CaseNotes from "./CaseNotes";

const CaseNotesTab = ({
  participantInfo,
  isCaseNoteCall,
  resetCaseNoteCall,
  setToggleState,
  callFrom = "participantProfile",
}) => {
  useEffect(() => {
    if (resetCaseNoteCall) {
      resetCaseNoteCall();
    }
  }, []);

  return (
    <>
      <div className='py-4 px-4 grid grid-cols-3 gap-4'>
        <div
          className={
            callFrom === "silProfile"
              ? "col-span-3"
              : "md:col-span-2 col-span-3"
          }
        >
          <div className=''>
            <CaseNotes
              callFrom={callFrom}
              isCaseNoteCall={isCaseNoteCall}
              participantInfo={participantInfo}
            />
          </div>
        </div>
        {callFrom !== "silProfile" && (
          <div className='md:col-span-1 col-span-3'>
            <div className=' bg-white rounded-md shadow border p-4 supportTeam'>
              <div className='flex justify-between items-center'>
                <span className='font-medium text-gray-900'>Support Team</span>
              </div>
              <WorkerSupportTeam
                callFrom={callFrom}
                workers={
                  callFrom === "silProfile"
                    ? participantInfo?.workersList || []
                    : participantInfo?.sp_workers || []
                }
                setToggleState={setToggleState}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CaseNotesTab;
