import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { apiGetRequest, apiPostRequest } from "helpers/Requests";
import { useForm } from "react-hook-form";
import {
  baseURLImage,
  downloadFileFromServer,
  extractFileNameFromS3URL,
  getExtension,
  getFileExtension,
  getNameProfile,
  getPaginationObj,
  getUrlRole,
  textTruncate,
} from "Utils/utils";
import { toast } from "react-toastify";
import { USER_ROLE, emptyPaginationObj } from "Constants/constant";
import { useSelector } from "react-redux";
import { getUser } from "store/selector/auth.selector";
import { roleAccess } from "helpers/RolesPermission";
import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PERMISSION_KEYS,
} from "store/constants";
import { LoginContext } from "helpers/LoginContext";
import {
  HiDownload,
  HiEye,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import SwitchInput from "components/common/Input/SwitchInput";
import { BiPlus } from "react-icons/bi";
import MultiFileInput from "components/common/FileUpload/MultiFileInput";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import CustomButton from "components/common/Button/CustomButton";
import LoaderButton from "components/common/LoaderButton/LoaderButton";
import DeleteCaseNoteModal from "./Modals/DeleteCaseNoteModal";
import EditCaseNoteModal from "./Modals/EditCaseNoteModal";
import FileIcon from "components/common/FileIcon/FileIcon";
import * as FileSaver from "file-saver";
import {
  BASE_URL,
  URL_SIL_ACCOMMODATION_CASE_NOTE_CREATE,
  URL_SIL_ACCOMMODATION_CASE_NOTES_LIST,
} from "store/services/URL";
import { uploadFilesOnVapour } from "Utils/uploadFileOnS3";

const CaseNotes = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const watchFormData = watch();

  useEffect(
    () =>
      reset({
        comment: "",
        attachments: [],
        show_to_worker: false,
        worker_to_worker: false,
      }),
    []
  );

  const { participantInfo, isCaseNoteCall, callFrom = null } = props;

  const { uuid } = useParams();
  const currentUser = useSelector(getUser);
  const { isUserGracePeriodOver } = useContext(LoginContext);

  const myRef = useRef(null);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [reachedLast, setReachedLast] = useState(false);

  const [selectedComment, setSelectedComment] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fetchNotes, setFetchNotes] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [fileData, setFilesData] = useState([]);

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  useEffect(() => {
    scrollToRef(myRef);
  }, [myRef]);

  useEffect(() => fetchCaseNotes(), []);

  useEffect(() => {
    if (reachedLast && paginationObj.totalPages > paginationObj.page) {
      const obj = paginationObj;
      obj.page = paginationObj.page + 1;
      setPaginationObj({ ...obj });
      fetchCaseNotes();
    }
  }, [reachedLast]);

  const scrollToRef = (ref) => {
    if (ref?.current) {
      ref.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (isCaseNoteCall) handleFocusOnCaseNote();
  }, [isCaseNoteCall]);

  const handleFocusOnCaseNote = () => {
    if (document.getElementById("caseNote")) {
      document.getElementById("caseNote").focus();
    }
  };

  const fetchCaseNotes = async () => {
    if (!reachedLast) setRequest({ ...request, isRequested: true });
    try {
      let res =
        callFrom === "silProfile"
          ? await apiGetRequest(
              `/${URL_SIL_ACCOMMODATION_CASE_NOTES_LIST.replace(
                BASE_URL,
                ""
              )}/${participantInfo?.uuid}?page=${
                paginationObj.page
              }&worker_to_worker=${
                watchFormData?.worker_to_worker ? "true" : "false"
              }`
            )
          : await apiGetRequest(
              `/${getUrlRole()}/case-notes?pt_id=${uuid}&page=${
                paginationObj.page
              }&worker_to_worker=${
                watchFormData?.worker_to_worker ? "true" : "false"
              }`
            );
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        // console.log("Something Went Wrong");
      }
      if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });

        if (
          callFrom === "silProfile" &&
          res?.data?.data?.notes?.data?.length > 0
        ) {
          const obj = fetchNotes;
          obj.push(...res?.data?.data?.notes?.data);
          setFetchNotes(
            [...new Set(obj.map((item) => item.id))].map((id) =>
              obj.find((item) => item.id === id)
            )
          );
          setPaginationObj(getPaginationObj(res?.data?.data?.notes));
        } else if (res?.data?.case_notes?.data?.length > 0) {
          const obj = fetchNotes;
          obj.push(...res?.data?.case_notes?.data);
          setFetchNotes(
            [...new Set(obj.map((item) => item.id))].map((id) =>
              obj.find((item) => item.id === id)
            )
          );
          setPaginationObj(getPaginationObj(res.data.case_notes));
        }
        setReachedLast(false);
      }
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      console.log(err.message);
    }
  };

  const onSubmit = async (data) => {
    if (isSubmitting || isUserGracePeriodOver) return;
    setIsSubmitting(true);
    // console.log("dataaa", data);
    let uploadedFiles = [];

    if (callFrom === "silProfile") {
      uploadedFiles = await uploadFilesOnVapour(data?.attachments);
    }
    // console.log("uploadedFiles", uploadedFiles);
    try {
      const preparFilesData =
        Array.isArray(fileData) &&
        fileData?.length > 0 &&
        fileData?.map((item, index) => ({
          key: item?.key,
          ext: getExtension(data?.attachments[index].type),
          name: data?.attachments[index]?.name,
        }));
      // console.log({ fileData, data, preparFilesData });
      const formData = new FormData();
      const payloadData = {};
      if (callFrom === "silProfile") {
        Object.assign(payloadData, {
          home_uuid: participantInfo.uuid,
          user_id: currentUser.id,
          notes: data.comment,
          attachments: uploadedFiles,
          show_to_worker: watchFormData?.show_to_worker,
        });
      } else {
        formData.append("pt_id", participantInfo.id);
        formData.append("notes", data.comment);
        if (preparFilesData && preparFilesData?.length > 0) {
          preparFilesData?.forEach((file, index) => {
            formData.append(`attachments[${index}][key]`, file.key);
            formData.append(`attachments[${index}][ext]`, file.ext);
            formData.append(`attachments[${index}][name]`, file.name);
          });
        }
        if (roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) {
          formData.append("show_to_worker", watchFormData?.show_to_worker);
        }
      }

      if (
        roleAccess([USER_ROLE.Sp_Standard_User]) &&
        callFrom !== "silProfile"
      ) {
        formData.append("worker_to_worker", watchFormData?.worker_to_worker);
      }

      const response =
        callFrom === "silProfile"
          ? await apiPostRequest(
              URL_SIL_ACCOMMODATION_CASE_NOTE_CREATE.replace(BASE_URL, ""),
              payloadData
            )
          : await apiPostRequest(`/${getUrlRole()}/case-notes`, formData);

      const obj = fetchNotes;
      obj.unshift(
        callFrom === "silProfile"
          ? response.data.note
          : response.data.case_notes
      );
      setFetchNotes([...obj]);
      reset();
      setFilesData([]);
      setIsSubmitting(false);
      toast.success(
        `${callFrom === "silProfile" ? "Note" : "Case note"} added successfully`
      );
    } catch (err) {
      toast.error(err?.message || "Something went wrong!");
      setIsSubmitting(false);
    }
  };

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const handleScroll = () => {
    if (myRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = myRef.current;
      if (
        scrollTop + clientHeight + 10 >= scrollHeight &&
        paginationObj.totalPages > paginationObj.page
      ) {
        setReachedLast(true);
      }
    }
  };

  const handleDownload = (file, name) => {
    if (file instanceof File) {
      const blob = new Blob([file], { type: file.type });
      FileSaver.saveAs(blob, name || "downloadedFile");
    } else {
      downloadFileFromServer(
        file,
        name ? name : `download.${getFileExtension(file)}`
      );
    }
  };

  const renderItem = (items, indexKey) => {
    // console.log("items", { items, indexKey });
    return (
      <div
        index={indexKey}
        className='grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-2 max-h-[100px] overflow-y-auto custom-scroll'
      >
        {items?.map((item, index) => {
          const fileName =
            callFrom === "silProfile" ? item?.name : item?.original_name;

          const file =
            callFrom === "silProfile" ? item?.attachment : item?.attachments;

          return (
            <div
              index={`${index}-${indexKey}`}
              className=' border border-1 rounded-md px-2 py-1 text-sm'
            >
              <div className='flex items-center justify-start'>
                <div className='w-[15%] bg-blue-50 rounded-full h-8 w-8 flex items-center text-center justify-center'>
                  {FileIcon(
                    getFileExtension(
                      fileName ? fileName : extractFileNameFromS3URL(file)
                    ),
                    12
                  )}
                </div>
                <div className='w-[80%]'>
                  <span
                    style={{ fontWeight: "500" }}
                    className='ml-2 flex-1 w-0 font-medium'
                    title={fileName ? fileName : extractFileNameFromS3URL(file)}
                  >
                    {textTruncate(
                      fileName ? fileName : extractFileNameFromS3URL(file),
                      30,
                      29
                    )}
                  </span>
                  <div>
                    {/* <span className='text-sm font-normal ml-2 text-gray-500'>
                      {getFileSize(file)}
                    </span> */}
                  </div>
                </div>
                <div className=' ml-2 space-x-2 flex'>
                  <div
                    onClick={() => window.open(baseURLImage(file), "_blank")}
                    className='icon-eye-wrapper cursor-pointer'
                    title='View'
                  >
                    <HiEye className='icon-size' />
                  </div>
                  <div
                    onClick={() => handleDownload(file, fileName)}
                    className='icon-download-wrapper cursor-pointer'
                    title='download'
                  >
                    <HiDownload className='icon-size' />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // covers 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const abbreviateMonthIfNeeded = (month) => {
    const longMonths = ["September", "October", "November", "December"];
    if (longMonths.includes(month)) {
      return month.slice(0, 3); // Abbreviate to Sep, Oct, etc.
    }
    return month; // Keep full month name for other months
  };

  const formatDateWithSuffix = (date) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.toLocaleString("en-AU", { month: "long" });
    const year = d.getFullYear();
    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;
    const formattedMonth = abbreviateMonthIfNeeded(month);

    return `${dayWithSuffix} ${formattedMonth} ${year}`;
  };

  return (
    <>
      <section aria-labelledby='notes-title'>
        <div className='bg-white shadow sm:rounded-lg sm:overflow-hidden'>
          <div>
            {(roleAccess(
              [USER_ROLE.Sp_Standard_User],
              PERMISSION_KEYS.PARTICIPANT,
              BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT.CASE_NOTES.STORE
            ) ||
              roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) && (
              <div className='bg-gray-50 px-4 py-6 sm:px-6'>
                <div className='flex space-x-3'>
                  {currentUser && (
                    <div className='flex-shrink-0'>
                      <img
                        onError={(e) =>
                          handleImageError(e, currentUser.name || " ", " ")
                        }
                        className='h-10 w-10 rounded-full object-cover'
                        src={
                          currentUser.profile_picture
                            ? baseURLImage(currentUser.profile_picture)
                            : getNameProfile(currentUser.name || " ", " ")
                        }
                        alt='profilePicture'
                      />
                    </div>
                  )}
                  <div className='min-w-0 flex-1'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div>
                        <label htmlFor='comment' className='sr-only'>
                          Comment
                        </label>
                        <textarea
                          id='caseNote'
                          name='comment'
                          rows={3}
                          className='shadow-sm block w-full resize-none overflow-y-auto focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md p-2 focus:bg-gray-50'
                          defaultValue={""}
                          {...register("comment", { required: true })}
                        />
                      </div>
                      {errors.comment && (
                        <span className='text-red-600 text-sm'>
                          {`${
                            callFrom === "silProfile" ? "Note" : "Case note"
                          } is required`}
                        </span>
                      )}
                      <br />
                      <div className='col-span-1'>
                        <label className='did-floating-label text-start'>
                          Attachment
                        </label>
                        <div>
                          <MultiFileInput
                            filenameLimit={150}
                            type='casenote'
                            handleFile={(e) => {
                              setValue("attachments", e);
                            }}
                            size={5}
                            files={watchFormData?.attachments}
                            handleDelete={(e) => {
                              setValue("attachments", e);
                            }}
                            showDragAndDrop={false}
                            isHardClearAllStates={isSubmitting === true}
                            setFilesData={setFilesData}
                            setIsSubmitting={setIsSubmitting}
                            isFileUploadToS3={callFrom !== "silProfile"}
                          />
                        </div>
                      </div>
                      <div className='mt-3 flex items-center justify-between'>
                        {callFrom !== "silProfile" && (
                          <div className='flex gap-x-4'>
                            {roleAccess([
                              USER_ROLE.Sp_Admin,
                              USER_ROLE.Sp_manager,
                            ]) && (
                              <SwitchInput
                                handleChange={(e) =>
                                  setValue("show_to_worker", e)
                                }
                                checked={watchFormData?.show_to_worker || false}
                                label='Show to worker'
                              />
                            )}

                            {roleAccess([USER_ROLE.Sp_Standard_User]) && (
                              <SwitchInput
                                label='Show to other workers'
                                checked={
                                  watchFormData?.worker_to_worker || false
                                }
                                handleChange={(val) =>
                                  setValue("worker_to_worker", val)
                                }
                              />
                            )}
                          </div>
                        )}
                        <CustomButton
                          type='submit'
                          label='Submit'
                          showLoading={isSubmitting}
                          isDisabled={isUserGracePeriodOver || isSubmitting}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            <div
              onScroll={handleScroll}
              ref={myRef}
              className='px-4 py-6 sm:px-6 overflow-y-auto custom-scroll caseNotesContainer'
            >
              {request.isRequested ? (
                <div
                  style={{
                    justifyContent: "center",
                    minHeight: "100%",
                    height: "100%",
                  }}
                  className='flex items-center'
                >
                  <CircularLoader
                    classes='flex justify-center items-center'
                    classloader='loader-l'
                  />
                </div>
              ) : (
                <ul className='space-y-8'>
                  {fetchNotes &&
                    fetchNotes?.map((comment, index) => (
                      <li key={index}>
                        <div className='flex space-x-3'>
                          <div className='flex-shrink-0'>
                            <img
                              onError={(e) =>
                                handleImageError(e, comment?.author || " ", " ")
                              }
                              alt='avatar'
                              className='h-10 w-10 rounded-full object-cover'
                              src={
                                comment.profile_picture
                                  ? baseURLImage(comment.profile_picture)
                                  : getNameProfile(comment?.author || " ", " ")
                              }
                            />
                          </div>
                          <div className='flex-1'>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className='mt-2 text-sm space-x-2'
                                style={{ textAlign: "right" }}
                              >
                                <p>{comment.author} </p>
                              </div>
                              <div
                                className='flex border-b last:border-b-0 px-2 py-4 border-b-neutral-200'
                                style={{ textAlign: "left" }}
                              >
                                {/* {checkCurrentUserLoggedInByUUID() && ( */}
                                <button
                                  className='mr-3'
                                  onClick={() => {
                                    setIsOpen(true);
                                    setSelectedComment(comment);
                                  }}
                                >
                                  <div
                                    className='icon-pencil-wrapper'
                                    title='Edit'
                                  >
                                    <HiOutlinePencil className='icon-size' />
                                  </div>
                                </button>
                                {/* )} */}
                                {roleAccess([
                                  USER_ROLE.Sp_Admin,
                                  USER_ROLE.Sp_manager,
                                ]) && (
                                  <button
                                    className=''
                                    onClick={() => {
                                      setSelectedComment(comment);
                                      setDeleteModalOpen(true);
                                    }}
                                  >
                                    <div
                                      className='icon-delete-wrapper'
                                      title='Delete'
                                    >
                                      <HiOutlineTrash className='icon-size' />
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>

                            <div className='mt-1 text-sm text-gray-700'>
                              <p style={{ wordBreak: "break-word" }}>
                                {comment.notes}
                              </p>
                            </div>

                            {/* Attachments */}
                            {comment.attachments &&
                              Array.isArray(comment.attachments) &&
                              comment.attachments.length > 0 && (
                                <div className='py-2 px-0'>
                                  {renderItem(
                                    comment.attachments,
                                    comment?.uuid
                                  )}
                                </div>
                              )}
                            {/* Attachments */}

                            <div className='flex justify-between items-center'>
                              <div className='mt-2 text-sm space-x-2 text-right'>
                                <strong>
                                  {formatDateWithSuffix(comment?.date)} at{" "}
                                  {new Date(comment?.date).toLocaleTimeString(
                                    "en-AU",
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )}
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  {reachedLast &&
                    paginationObj.totalPages >= paginationObj.page && (
                      <div className='flex justify-center items-center'>
                        <LoaderButton
                          classes='flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-dark hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark'
                          svgClasses='inline mr-3 w-5 h-5 text-white animate-spin'
                          textbutton='Loading More...'
                        />
                      </div>
                    )}
                  {fetchNotes &&
                    fetchNotes.length === 0 &&
                    request.isSuccess && (
                      <div className='bg-white'>
                        <div className='max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
                          <h2 className='block pt-6 text-xl font-semibold text-gray-900'>
                            No Data Found!
                          </h2>
                          <div className='p-6 flex justify-center'>
                            <div className='mt-2 text-sm text-gray-700 inline-flex'>
                              {`Get started by creating a new ${
                                callFrom === "silProfile" ? "Note" : "case Note"
                              }`}
                            </div>
                          </div>
                          {(roleAccess(
                            [USER_ROLE.Sp_Standard_User],
                            PERMISSION_KEYS.PARTICIPANT,
                            BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT
                              .CASE_NOTES.STORE
                          ) ||
                            roleAccess([
                              USER_ROLE.Sp_Admin,
                              USER_ROLE.Sp_manager,
                            ])) && (
                            <div className='mt-8 flex justify-center'>
                              <div className='ml-3 inline-flex'>
                                <CustomButton
                                  label={
                                    callFrom === "silProfile"
                                      ? "Notes"
                                      : "Case Notes"
                                  }
                                  clickHandler={handleFocusOnCaseNote}
                                  Icon={<BiPlus />}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
      <DeleteCaseNoteModal
        deleteModalOpen={deleteModalOpen}
        selectedComment={selectedComment}
        onCloseModal={() => {
          setSelectedComment(null);
          setDeleteModalOpen(false);
        }}
        fetchNotes={fetchNotes}
        setFetchNotes={setFetchNotes}
        callFrom={callFrom}
      />

      {isOpen && (
        <EditCaseNoteModal
          participantIdUUID={participantInfo?.uuid}
          participantId={participantInfo.id}
          fetchNotes={fetchNotes}
          callFrom={callFrom}
          setFetchNotes={setFetchNotes}
          selectedComment={selectedComment}
          isOpen={isOpen}
          onCloseModal={() => {
            setSelectedComment(null);
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

export default CaseNotes;
