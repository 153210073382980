import axios from "axios";
import { apiCall } from "store/services/service";
import { BASE_URL } from "store/services/URL";

const uploadFilesOnVapour = async (files, options = {}) => {
  if (!files || files.length === 0) return [];
  try {
    const signedUrls = await Promise.all(
      files.map((file) =>
        apiCall.post({
          url: `${BASE_URL}signed-url`,
          params: { contentType: file.type },
          isAuthToken: true,
        })
      )
    );
    const uploadedFiles = await Promise.all(
      files.map((file, index) =>
        storeFileOnS3(file, signedUrls[index], options)
      )
    );
    return uploadedFiles;
  } catch (error) {
    console.error("Error during file upload:", error);
    return [];
  }
};

const storeFileOnS3 = async (file, response, options) => {
  const headers = { ...response.headers };
  if ("Host" in headers) {
    delete headers.Host;
  }

  try {
    await axios.put(response.url, file, {
      headers: headers,
      cancelToken: options.cancelToken,
      onUploadProgress: (progressEvent) => {
        if (typeof options.progress === "function") {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          options.progress(progress);
        }
      },
    });
    return {
      key: response.key,
      ext: file.name.split(".").pop(),
      name: file.name,
    };
  } catch (error) {
    console.error(`Error uploading file ${file.name} to S3:`, error);
    return null;
  }
};

export { uploadFilesOnVapour, storeFileOnS3 };
