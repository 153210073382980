import { fromJS } from "immutable";
import { emptyPaginationObj } from "../../Constants/constant";
import ACTIONS from "../constants";

const initialState = fromJS({
  loading: false,
  authError: "",
  error: "",
  sp_workers: null,
  sp_applicants: [],
  sp_employee_documents: [],
  sp_dashboard: {},
  sp_feedback: null,
  sp_form_Categories: [],
  sp_documents: null,
  sp_managers: null,
  refreshManagerList: false,
});

export default function spAdminReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SP_DASHBOARD.PENDING: {
      return state.set("loading", true);
    }
    case ACTIONS.SP_DASHBOARD.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .set("sp_dashboard", action.data.data);
    }
    case ACTIONS.SP_DASHBOARD.ERROR: {
      return state.set("loading", false).set("error", action.error);
    }

    case ACTIONS.SP_SETTING_FETCHED.PENDING: {
      return state.set("spSettingLoader", true).set("spSettingError", "");
    }
    case ACTIONS.SP_SETTING_FETCHED.SUCCESS: {
      return state
        .set("spSettingLoader", false)
        .set("spSettingError", "")
        .set("spSetting", action.data.data);
    }
    case ACTIONS.SP_SETTING_FETCHED.ERROR: {
      return state
        .set("spSettingLoader", false)
        .set("spSettingError", action.error);
    }

    case ACTIONS.SP_SETTING_UPDATED.PENDING: {
      return state
        .set("spSettingUpdateLoader", true)
        .set("spSettingUpdateError", "");
    }
    case ACTIONS.SP_SETTING_UPDATED.SUCCESS: {
      return state
        .set("spSettingUpdateLoader", false)
        .set("spSettingUpdateError", "")
        .set("spSetting", action.data);
    }
    case ACTIONS.SP_SETTING_UPDATED.ERROR: {
      return state
        .set("spSettingUpdateLoader", false)
        .set("spSettingUpdateError", action.error);
    }

    case ACTIONS.SP_PARTICIPANTS_LIST.PENDING: {
      return state
        .set("spParticipantsListLoading", true)
        .set("spParticipantsListError", "");
    }
    case ACTIONS.SP_PARTICIPANTS_LIST.SUCCESS: {
      return state
        .set("spParticipantsListLoading", false)
        .set("spParticipantsListError", "")
        .set("sp_participants", action.data.data);
    }
    case ACTIONS.SP_PARTICIPANTS_LIST.ERROR: {
      return state
        .set("spParticipantsListLoading", false)
        .set("spParticipantsListError", action.error);
    }

    case ACTIONS.SP_APPLICANTS_LIST.PENDING: {
      return state.set("loading", true);
    }
    case ACTIONS.SP_APPLICANTS_LIST.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .set("sp_applicants", action.data.recruitment_stages);
    }
    case ACTIONS.SP_APPLICANTS_LIST.ERROR: {
      return state.set("loading", false).set("error", action.error);
    }

    case ACTIONS.SP_EMPLOYEE_DOCUMENTS.PENDING: {
      return state.set("loading", true);
    }
    case ACTIONS.SP_EMPLOYEE_DOCUMENTS.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .set("sp_employee_documents", action.data.worker_docs);
    }
    case ACTIONS.SP_EMPLOYEE_DOCUMENTS.ERROR: {
      return state.set("loading", false).set("error", action.error);
    }

    case ACTIONS.SP_WORKER.PENDING: {
      return state.set("loading", true).set("error", "");
    }
    case ACTIONS.SP_WORKER.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .set("sp_workers", action.data.options);
    }
    case ACTIONS.SP_WORKER.ERROR: {
      return state.set("loading", false).set("error", action.error);
    }

    case ACTIONS.SP_FEEDBACK.PENDING: {
      return state.set("loading", true);
    }
    case ACTIONS.SP_FEEDBACK.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .set("sp_feedback", action.data.data);
    }
    case ACTIONS.SP_FEEDBACK.ERROR: {
      return state.set("loading", false).set("error", action.error);
    }

    case ACTIONS.SP_COMPLAINTS.PENDING: {
      return state.set("loading", true);
    }
    case ACTIONS.SP_COMPLAINTS.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .set("sp_complaints", action.data.data);
    }
    case ACTIONS.SP_COMPLAINTS.ERROR: {
      return state.set("loading", false).set("error", action.error);
    }

    case ACTIONS.SP_QUESTIONS.PENDING: {
      return state.set("loading", true);
    }
    case ACTIONS.SP_QUESTIONS.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .set("sp_questions", action.data.data);
    }
    case ACTIONS.SP_QUESTIONS.ERROR: {
      return state.set("loading", false).set("error", action.error);
    }

    case ACTIONS.SP_FORM_CATEGORIES.PENDING: {
      return state
        .set("form_category_loading", true)
        .set("form_category_error", "");
    }
    case ACTIONS.SP_FORM_CATEGORIES.SUCCESS: {
      return state
        .set("form_category_loading", false)
        .set("form_category_error", "")
        .set("sp_form_categories", action.data.form_categories);
    }
    case ACTIONS.SP_FORM_CATEGORIES.ERROR: {
      return state
        .set("form_category_loading", false)
        .set("form_category_error", action.error);
    }

    case ACTIONS.SP_ALL_FORM.PENDING: {
      return state
        .set("form_templates_loading", true)
        .set("form_templates_error", "");
    }
    case ACTIONS.SP_ALL_FORM.SUCCESS: {
      const obj = action.data.data.query_params;
      let selectedFormsCat = null;
      if (obj.hasOwnProperty("filter")) {
        if (obj.filter && obj.filter.form_category) {
          selectedFormsCat = obj.filter.form_category.map((x) => x).join(",");
        }
      }
      const form_categories = state.get("sp_form_categories") || [];
      if (selectedFormsCat) {
        const options = form_categories.filter(
          (x) => selectedFormsCat.includes(x.id) && x
        );
        Object.assign(action.data.data.query_params.filter, {
          form_category: options,
        });
      }
      return state
        .set("form_templates_loading", false)
        .set("form_templates_error", "")
        .set("sp_form_templates", action.data.data);
    }
    case ACTIONS.SP_ALL_FORM.ERROR: {
      return state
        .set("form_templates_loading", false)
        .set("form_templates_error", action.error)
        .set("sp_form_templates", null);
    }

    case ACTIONS.UPDATE_ALL_FORMS_TEMPLATES_LOCALLY: {
      const prevForms = state.get("sp_form_templates") || null;
      if (
        prevForms &&
        prevForms.form_templates &&
        action.data.type === "delete"
      ) {
        const index = prevForms.form_templates.data.findIndex(
          (x) => x.id === action.data.id
        );
        if (index !== -1) {
          prevForms.form_templates.data.splice(index, 1);
          prevForms.form_templates.total -= 1;
          prevForms.form_templates.count -= 1;
          return state.set("sp_form_templates", { ...prevForms });
        }
      }
      return state;
    }

    case ACTIONS.UPDATE_PARTICIPANT_DATA: {
      const SpParticipants = state.get("sp_participants") || {};
      if (
        SpParticipants &&
        Object.keys(SpParticipants).length > 0 &&
        SpParticipants.hasOwnProperty("participant") &&
        SpParticipants.participant.data.length > 0
      ) {
        const index = SpParticipants.participant.data.findIndex(
          (x) => x.uuid === action.data.uuid
        );
        if (index !== -1) {
          const data = {
            id: action.data.id,
            uuid: action.data.uuid,
            status: action.data.status,
            first_name: action.data.first_name,
            last_name: action.data.last_name,
            email: action.data.email,
            primary_no: action.data.primary_no,
            profile_picture: action.data.profile_picture,
            ndis_no: action.data.ndis_no,
            address: action.data.street_no,
            workers: action.data.sp_workers,
            sp_support_coordinators: action.data.sp_support_coordinators,
          };
          SpParticipants.participant.data[index] = data;
        }
        return state.set("sp_participants", { ...SpParticipants });
      }
      return state;
    }

    case ACTIONS.ADD_PARTICIPANT_LOCALLY: {
      const SpParticipants = state.get("sp_participants") || {};
      if (SpParticipants.hasOwnProperty("participant")) {
        SpParticipants.participant.data.unshift({
          ...action.data,
          workers: [],
          status: "active",
        });
        return state.set("sp_participants", { ...SpParticipants });
      }
      return state;
    }
    case ACTIONS.SP_DOCUMENTS.PENDING: {
      return state.set("loading", true).set("error", "");
    }
    case ACTIONS.SP_DOCUMENTS.SUCCESS: {
      return state
        .set("loading", false)
        .set("documents_error", "")
        .set("sp_documents", action.data.data);
    }
    case ACTIONS.SP_DOCUMENTS.ERROR: {
      return state.set("loading", false).set("error", action.error);
    }

    case ACTIONS.SP_DOCUMENTS_UPDATE.PENDING: {
      return state.set("spDocUpdateLoader", true).set("spDocUpdateError", "");
    }
    case ACTIONS.SP_DOCUMENTS_UPDATE.SUCCESS: {
      const prevDoc = state.get("sp_documents") || null;
      if (prevDoc && "documents" in prevDoc) {
        const index = prevDoc.documents.data.findIndex(
          (x) => x.id === action.data.document_id
        );
        if (index !== -1) {
          const updateDoc = prevDoc.documents.data[index];
          prevDoc.documents.data.splice(index, 1, {
            ...updateDoc,
            file_type: action.data.file_type,
            description: action.data.description,
          });
        }
      }
      return state
        .set("spDocUpdateLoader", false)
        .set("spDocUpdateError", "")
        .set("sp_documents", prevDoc);
    }
    case ACTIONS.SP_DOCUMENTS_UPDATE.ERROR: {
      return state
        .set("spDocUpdateLoader", false)
        .set("spDocUpdateError", action.error);
    }

    case ACTIONS.ADD_SP_DOCUMENTS_LOCALLY: {
      const prevDocuments = state.get("sp_documents");
      if (!prevDocuments || prevDocuments === null) {
        const obj = {};
        Object.assign(obj, {
          query_params: {
            sort_field: null,
            sort_order: "desc",
            page: 1,
            search: "",
            per_page: 10,
            filter: null,
          },
          documents: {
            data: [{ ...action.data }],
            ...emptyPaginationObj,
          },
        });
        return state.set("sp_documents", { ...obj });
      }

      prevDocuments.documents.data.unshift({
        ...action.data,
      });
      prevDocuments.documents.total = +1;

      return state.set("sp_documents", { ...prevDocuments });
    }

    case ACTIONS.DELETE_SP_DOCUMENTS_LOCALLY: {
      const prevDocuments = state.get("sp_documents") || null;
      if (
        prevDocuments &&
        prevDocuments.documents &&
        prevDocuments.documents.data &&
        prevDocuments.documents.data.length > 0
      ) {
        const index = prevDocuments.documents.data.findIndex(
          (x) => x.uuid === action.data.documentId
        );
        if (index !== -1) {
          prevDocuments.documents.data.splice(index, 1);
        }
      }
      return state.set("sp_documents", { ...prevDocuments });
    }

    case ACTIONS.RESET_SELECTED_PARTICIPANT_DOCUMENTS: {
      return state.set("sp_documents", {});
    }

    case ACTIONS.HRM_DOCUMENTS.PENDING: {
      return state.set("loading", true);
    }
    case ACTIONS.HRM_DOCUMENTS.SUCCESS: {
      return state
        .set("loading", false)
        .set("documents_error", "")
        .set("hrm_documents", action.data.data);
    }
    case ACTIONS.HRM_DOCUMENTS.ERROR: {
      return state.set("loading", false).set("error", action.error);
    }
    case ACTIONS.SP_RISK_RATING_TAB_LIST.PENDING: {
      return state.set("riskRatingTabList_loading", true);
    }
    case ACTIONS.SP_RISK_RATING_TAB_LIST.SUCCESS: {
      return state
        .set("riskRatingTabList_loading", false)
        .set("riskRatingTabList_error", "")
        .set("sp_riskRatingTabList", action.data.data);
    }
    case ACTIONS.SP_RISK_RATING_TAB_LIST.ERROR: {
      return state
        .set("riskRatingTabList_loading", false)
        .set("riskRatingTabList_error", action.error);
    }

    case ACTIONS.CREATE_SP_MANAGER_LIST_REFRESH: {
      return state.set("refreshManagerList", action.payload);
    }

    case ACTIONS.CREATE_SP_MANAGER.PENDING: {
      return state.set("loading", true);
    }
    case ACTIONS.CREATE_SP_MANAGER.SUCCESS: {
      const prevManagers = state.get("sp_managers") || [];
      if (prevManagers) {
        if (action.isUpdateCall) {
          const index = prevManagers.findIndex(
            (x) => x.id === action.data.manager.manager_id
          );
          if (index !== -1) {
            prevManagers.splice(index, 1, {
              ...prevManagers[index],
              role_id: action.data.manager.role_id,
              status: action.data.manager.status,
            });
          }
        } else {
          prevManagers.unshift(action.data.manager);
        }
      }
      return state
        .set("loading", false)
        .set("error", "")
        .set("sp_managers", prevManagers ? [...prevManagers] : null);
    }
    case ACTIONS.CREATE_SP_MANAGER.ERROR: {
      return state.set("loading", false).set("error", action.error);
    }

    case ACTIONS.SP_MANAGER_LIST.PENDING: {
      return state.set("spManagersloading", true).set("spManagersError", "");
    }
    case ACTIONS.SP_MANAGER_LIST.SUCCESS: {
      return state
        .set("spManagersloading", false)
        .set("spManagersError", "")
        .set("sp_managers", action.data.manager_list);
    }
    case ACTIONS.SP_MANAGER_LIST.ERROR: {
      return state
        .set("spManagersloading", false)
        .set("spManagersError", action.error);
    }

    case ACTIONS.SUPPORT_PLAN_SUBMITTED_FORMS_LIST.PENDING: {
      return state.set("submittedFormsList_loading", true);
    }
    case ACTIONS.SUPPORT_PLAN_SUBMITTED_FORMS_LIST.SUCCESS: {
      return state
        .set("submittedFormsList_loading", false)
        .set("submittedFormsList_error", "")
        .set("sp_submittedFormsList", action.data.data);
    }
    case ACTIONS.SUPPORT_PLAN_SUBMITTED_FORMS_LIST.ERROR: {
      return state
        .set("submittedFormsList_loading", false)
        .set("submittedFormsList_error", action.error);
    }
    case ACTIONS.RESET_SUBMITTED_FORMS_LIST: {
      return state.set("sp_submittedFormsList", null);
    }

    case ACTIONS.SUPPORT_PLAN_HRM_SUBMITTED_FORMS_LIST.PENDING: {
      return state.set("submittedFormsList_loading_hrm", true);
    }
    case ACTIONS.SUPPORT_PLAN_HRM_SUBMITTED_FORMS_LIST.SUCCESS: {
      return state
        .set("submittedFormsList_loading_hrm", false)
        .set("submittedFormsList_error", "")
        .set("sp_submittedFormsListHrm", action.data.data);
    }
    case ACTIONS.SUPPORT_PLAN_HRM_SUBMITTED_FORMS_LIST.ERROR: {
      return state
        .set("submittedFormsList_loading_hrm", false)
        .set("submittedFormsList_error", action.error);
    }

    case ACTIONS.HRM_TEAM_MANAGMENT_LIST.PENDING: {
      return state
        .set("team_mamangement_list_loading", true)
        .set("team_managment_error", "");
    }
    case ACTIONS.HRM_TEAM_MANAGMENT_LIST.SUCCESS: {
      return state
        .set("team_mamangement_list_loading", false)
        .set("team_managment_error", "")
        .set("hrm_team_managment", action.data.data);
    }
    case ACTIONS.HRM_TEAM_MANAGMENT_LIST.ERROR: {
      return state
        .set("team_mamangement_list_loading", false)
        .set("team_managment_error", action.error);
    }

    case ACTIONS.HRM_WORKER_PROFILE_DETAIL.PENDING: {
      return state
        .set("workerProfileDataLoader", true)
        .set("workerProfileDataError", "");
    }
    case ACTIONS.HRM_WORKER_PROFILE_DETAIL.SUCCESS: {
      return state
        .set("workerProfileDataLoader", false)
        .set("workerProfileDataError", "")
        .set("selectedWorker", action.data);
    }
    case ACTIONS.HRM_WORKER_PROFILE_DETAIL.ERROR: {
      return state
        .set("workerProfileDataLoader", false)
        .set("workerProfileDataError", action.error);
    }

    case ACTIONS.HRM_WORKER_HOURLY_SETTING.PENDING: {
      return state
        .set("workerHourlySettingLoader", true)
        .set("workerHourlySettingError", "");
    }
    case ACTIONS.HRM_WORKER_HOURLY_SETTING.SUCCESS: {
      return state
        .set("workerHourlySettingLoader", false)
        .set("workerHourlySettingError", "")
        .set("selectedWorker", action.data.data);
    }
    case ACTIONS.HRM_WORKER_HOURLY_SETTING.ERROR: {
      return state
        .set("workerHourlySettingLoader", false)
        .set("workerHourlySettingError", action.error);
    }

    case ACTIONS.HRM_WORKER_STATUS_UPDATE.PENDING: {
      return state
        .set("workerStatusUpdateLoader", true)
        .set("workerStatusUpdateError", "");
    }
    case ACTIONS.HRM_WORKER_STATUS_UPDATE.SUCCESS: {
      const worker = state.get("selectedWorker") || null;
      if (worker) {
        Object.assign(worker, { status: action.data.status });
      }
      return state
        .set("workerStatusUpdateLoader", false)
        .set("workerStatusUpdateError", "")
        .set("selectedWorker", { ...worker });
    }
    case ACTIONS.HRM_WORKER_STATUS_UPDATE.ERROR: {
      return state
        .set("workerStatusUpdateLoader", false)
        .set("workerStatusUpdateError", action.error);
    }

    case ACTIONS.CLEAR_CRM_HRM_FORMS_TEMPLATES_LOCALLY: {
      return state
        .set("sp_form_categories", null)
        .set("sp_form_templates", null);
    }

    case ACTIONS.STRIPE_PRICING_TABLE_LIST.PENDING: {
      return state
        .set("stripePricingTableLoader", true)
        .set("stripePricingTableError", "");
    }
    case ACTIONS.STRIPE_PRICING_TABLE_LIST.SUCCESS: {
      return state
        .set("stripePricingTableLoader", false)
        .set("stripePricingTableError", "")
        .set("stripePricingTableList", action.data);
    }
    case ACTIONS.STRIPE_PRICING_TABLE_LIST.ERROR: {
      return state
        .set("stripePricingTableLoader", false)
        .set("stripePricingTableError", action?.error);
    }

    case ACTIONS.STRIPE_SAVE_CARD_DATA.PENDING: {
      return state
        .set("stripeSaveCardDataLoader", true)
        .set("stripeSaveCardDataError", "");
    }
    case ACTIONS.STRIPE_SAVE_CARD_DATA.SUCCESS: {
      // console.log("ACTIONS.STRIPE_SAVE_CARD_DATA.SUCCESS", action);
      const prevCards = state.get("stripeCards") || [];
      if (action.card) {
        prevCards.unshift({ ...action.card });
      }
      return state
        .set("stripeSaveCardDataLoader", false)
        .set("stripeSaveCardDataError", "")
        .set("stripeCards", [...prevCards]);
    }
    case ACTIONS.STRIPE_SAVE_CARD_DATA.ERROR: {
      return state
        .set("stripeSaveCardDataLoader", false)
        .set("stripeSaveCardDataError", action?.error);
    }

    case ACTIONS.STRIPE_LIST_ALL_CARD.PENDING: {
      return state
        .set("stripeListAllCardLoader", true)
        .set("stripeListAllCardError", "");
    }
    case ACTIONS.STRIPE_LIST_ALL_CARD.SUCCESS: {
      return state
        .set("stripeListAllCardLoader", false)
        .set("stripeListAllCardError", "")
        .set("stripeCards", action.data);
    }
    case ACTIONS.STRIPE_LIST_ALL_CARD.ERROR: {
      return state
        .set("stripeListAllCardLoader", false)
        .set("stripeListAllCardError", action?.error);
    }

    case ACTIONS.STRIPE_DEFAULT_PAYMENT_METHOD.PENDING: {
      return state
        .set("stripeGetDefaultPaymentMethodLoader", true)
        .set("stripeGetDefaultPaymentMethodError", "");
    }
    case ACTIONS.STRIPE_DEFAULT_PAYMENT_METHOD.SUCCESS: {
      // console.log("ACTIONS.STRIPE_DEFAULT_PAYMENT_METHOD.SUCCESS: ", action);
      return state
        .set("stripeGetDefaultPaymentMethodLoader", false)
        .set("stripeGetDefaultPaymentMethodError", "");
      // .set("stripeCards", action.data);
    }
    case ACTIONS.STRIPE_DEFAULT_PAYMENT_METHOD.ERROR: {
      return state
        .set("stripeGetDefaultPaymentMethodLoader", false)
        .set("stripeGetDefaultPaymentMethodError", action?.error);
    }

    case ACTIONS.STRIPE_DELETE_CARD_LOCALLY: {
      const prevData = state.get("stripeCards") || [];
      if (prevData) {
        const index = prevData.findIndex((x) => x.id === action.data);

        if (index !== -1) {
          prevData.splice(index, 1);
        }
      }

      return state.set("stripeCards", [...prevData]);
    }

    case ACTIONS.SP_STAFF_COMPLIANCE_SETTING.PENDING: {
      return state
        .set("spStaffComplianceSettingFetchLoader", true)
        .set("spStaffComplianceSettingFetchError", "");
    }
    case ACTIONS.SP_STAFF_COMPLIANCE_SETTING.SUCCESS: {
      return state
        .set("spStaffComplianceSettingFetchLoader", false)
        .set("spStaffComplianceSettingFetchError", "")
        .set("spStaffComplianceSetting", action.data);
    }
    case ACTIONS.SP_STAFF_COMPLIANCE_SETTING.ERROR: {
      return state
        .set("spStaffComplianceSettingFetchLoader", false)
        .set("spStaffComplianceSettingFetchError", action?.error);
    }

    case ACTIONS.SP_STAFF_COMPLIANCE_SETTING_UPDATE.PENDING: {
      return state
        .set("spStaffComplianceSettingUpdateLoader", true)
        .set("spStaffComplianceSettingUpdateError", "");
    }
    case ACTIONS.SP_STAFF_COMPLIANCE_SETTING_UPDATE.SUCCESS: {
      return state
        .set("spStaffComplianceSettingUpdateLoader", false)
        .set("spStaffComplianceSettingUpdateError", "")
        .set("spStaffComplianceSetting", action.data);
    }
    case ACTIONS.SP_STAFF_COMPLIANCE_SETTING_UPDATE.ERROR: {
      return state
        .set("spStaffComplianceSettingUpdateLoader", false)
        .set("spStaffComplianceSettingUpdateError", action?.error);
    }

    case ACTIONS.SP_ROSTERING_SETTING.PENDING: {
      return state
        .set("spRosteringSettingFetchLoader", true)
        .set("spRosteringSettingFetchError", "");
    }
    case ACTIONS.SP_ROSTERING_SETTING.SUCCESS: {
      return state
        .set("spRosteringSettingFetchLoader", false)
        .set("spRosteringSettingFetchError", "")
        .set("spRosteringSetting", action.data);
    }
    case ACTIONS.SP_ROSTERING_SETTING.ERROR: {
      return state
        .set("spRosteringSettingFetchLoader", false)
        .set("spRosteringSettingFetchError", action?.error);
    }

    case ACTIONS.SP_ROSTERING_SETTING_UPDATE.PENDING: {
      return state
        .set("spRosteringSettingUpdateLoader", true)
        .set("spRosteringSettingUpdateError", "");
    }
    case ACTIONS.SP_ROSTERING_SETTING_UPDATE.SUCCESS: {
      return state
        .set("spRosteringSettingUpdateLoader", false)
        .set("spRosteringSettingUpdateError", "")
        .set("spRosteringSetting", action.data);
    }
    case ACTIONS.SP_ROSTERING_SETTING_UPDATE.ERROR: {
      return state
        .set("spRosteringSettingUpdateLoader", false)
        .set("spRosteringSettingUpdateError", action?.error);
    }

    default: {
      return state;
    }
  }
}
