import { connect } from "react-redux";
import SilForms from "./SilForms";
import {
  fetchSilAllFormsRequest,
  fetchSilFormCategoriesRequest,
} from "store/actions/sil.actions";
import {
  getSilAllForms,
  getSilAllFormsLoading,
  getSilFormCategories,
  getSilFormCategoriesLoading,
} from "store/selector/sil.selector";

const mapDispatchToProps = {
  fetchFormCategories: fetchSilFormCategoriesRequest,
  fetchFormTemplate: fetchSilAllFormsRequest,
};

const mapStateToProps = (state) => {
  const formCategoriesList = getSilFormCategories(state);
  const categoryLoader = getSilFormCategoriesLoading(state);
  const spFormTemplateLoader = getSilAllFormsLoading(state);
  const silFormTemplatesList = getSilAllForms(state);
  return {
    categoryLoader,
    formCategoriesList,
    spFormTemplateLoader,
    silFormTemplatesList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SilForms);
