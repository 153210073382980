import CircularLoader from "components/common/CircularLoader/CircularLoader";
import SideBarNew from "components/common/SideBar/SideBarNew";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import SilHomeProfileHeader from "./components/SilHomeProfileHeader";
import SilHomeProfileTabs from "./components/SilhomeProfileTabs";
import { SilHomeselectedTabState } from "Utils/utils";
import { SIL_HOME_PROFILE_TABS } from "Constants/constant";

const SilHomeProfile = ({
  setSelectedSilAccommodationLocally,
  fetchSilAccommodationDatailRequest,
  selectedSilAccommodation,
  silAccommodationDetailLoading,
}) => {
  const { uuid } = useParams();
  const { sideBarPadding } = useContext(LoginContext);

  const [selectedTab, setSelectedTab] = useState(0);
  const [toggleState, setToggleState] = useState(null);

  useEffect(() => {
    if (toggleState && toggleState.hasOwnProperty("isOpen")) {
      SilHomeselectedTabState.save(JSON.stringify(toggleState));
    }
    if (toggleState) {
      // storeCurrentOpenTab(toggleState);
      setSelectedTab(toggleState.id);
    }
  }, [toggleState]);

  useEffect(() => {
    if (uuid) {
      fetchSilAccommodationDatailRequest(uuid);
    }
    setToggleState(
      SilHomeselectedTabState.get()
        ? { ...SilHomeselectedTabState.get() }
        : SIL_HOME_PROFILE_TABS[0]
    );

    return () => {
      setSelectedSilAccommodationLocally(null);
      SilHomeselectedTabState.remove();
    };
  }, [uuid]);

  return (
    <>
      <div className='min-h-full overflow-hidden'>
        <SideBarNew />
        <div
          className={`${
            sideBarPadding ? "lg:pl-[4rem]" : "lg:pl-64"
          } flex flex-col duration-300`}
        >
          {toggleState &&
          selectedSilAccommodation &&
          Object.keys(selectedSilAccommodation).length > 0 &&
          silAccommodationDetailLoading === false ? (
            <main className='flex-1 w-full'>
              <div className='sticky top-0 z-[9]'>
                <SilHomeProfileHeader
                  selectedTab={selectedTab}
                  selectedAccommodationInfo={selectedSilAccommodation}
                />
              </div>
              <div className='max-w-7xl mx-auto px-6 bg-white'>
                <SilHomeProfileTabs
                  setSelectedSilAccommodationLocally={
                    setSelectedSilAccommodationLocally
                  }
                  setToggleState={setToggleState}
                  toggleState={toggleState}
                  selectedAccommodationInfo={selectedSilAccommodation}
                  // tabClass='flex space-x-4 max-w-2xl min-w-full w-full overflow-x-auto'
                />
              </div>
            </main>
          ) : (
            <div className='px-0 flex justify-center items-center min-h-screen bg-gray-50'>
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SilHomeProfile;
