import { emptyPaginationObj } from "Constants/constant";
import createSelector from "../../Utils/reselect";

const silHomeStateData = (state) => {
  const { sil } = state;
  return sil || {};
};

export const getPropertyTypeLoading = createSelector(
  silHomeStateData,
  (sil) => sil.get("propertyTypeLoading") || false
);

export const getPropertyTypeList = createSelector(silHomeStateData, (sil) => {
  const data = sil.get("propertyType") || [];
  return data
    ?.map((x) => ({ ...x, value: x.id, label: x.name }))
    .sort((a, b) => a.label.localeCompare(b.label));
});

export const getSdaTypeLoading = createSelector(
  silHomeStateData,
  (sil) => sil.get("sdaTypeLoading") || false
);

export const getSdaTypeList = createSelector(silHomeStateData, (sil) => {
  const data = sil.get("sdaType") || [];
  return data
    ?.map((x) => ({ ...x, value: x.id, label: x.name }))
    .sort((a, b) => a.label.localeCompare(b.label));
});

export const getCreateSilAccommodationLoading = createSelector(
  silHomeStateData,
  (sil) => sil.get("createSilAccommodationLoading") || false
);

export const getSilAccommodations = createSelector(
  silHomeStateData,
  (sil) => sil.get("silAccommodations") || []
);

export const getSilAccommodationListLoading = createSelector(
  silHomeStateData,
  (sil) => sil.get("silAccommodationListLoading") || false
);

export const getSilAccommodationListPagination = createSelector(
  silHomeStateData,
  (sil) => sil.get("silAccommodationsPagination") || emptyPaginationObj
);

export const getSilAccommodationDetailLoading = createSelector(
  silHomeStateData,
  (sil) => sil.get("silAccommodationDetailLoading") || false
);

export const getSelectedSilAccommodation = createSelector(
  silHomeStateData,
  (sil) => {
    const data = sil.get("selectedSilAccommodation") || null;

    if (data?.uuid) {
      return {
        ...data,
        escalator: data?.escalator === true ? "yes" : "no",
        air_conditioning: data?.air_conditioning === true ? "yes" : "no",
        dishwasher: data?.dishwasher === true ? "yes" : "no",
        state: data?.state?.id || "",
        stateObj: data?.state,
        property_type: data?.property_type?.id || "",
        property_type_name: data?.property_type?.name || "",
        sda_type: data?.sda_type?.id || "",
        sda_type_name: data?.sda_type?.name || "",
        managers: data?.managers?.map((x) => x.manager_id),
        managersList: data?.managers,
        participants: data?.participants?.map((x) => x.id),
        participantsList: data?.participants,
        workersList: data?.workers,
        workers: data?.workers?.map((x) => x.id),
        region_id: data?.region || null,
        // state: {
        //   ...data?.state,
        //   label: data?.state?.name || "",
        //   value: data?.state?.id || "",
        // },
        // property_type: {
        //   ...data?.property_type,
        //   label: data?.property_type?.name || "",
        //   value: data?.property_type?.id || "",
        // },
        // sda_type: {
        //   ...data?.sda_type,
        //   label: data?.sda_type?.name || "",
        //   value: data?.sda_type?.id || "",
        // },
      };
    }
    return data;
  }
);

//Sil Forms Selectors
export const getSilFormCategoriesLoading = createSelector(
  silHomeStateData,
  (sil) => sil.get("silFormCategoriesLoading") || false
);

export const getSilFormCategories = createSelector(silHomeStateData, (sil) => {
  const data = sil.get("silFormCategories") || [];
  return data;
});

export const getSilAllFormsLoading = createSelector(
  silHomeStateData,
  (sil) => sil.get("silAllFormsListLoader") || false
);

export const getSilAllForms = createSelector(
  silHomeStateData,
  (sil) => sil.get("silAllForms") || []
);

export const getSilAccommodationUpdateLoading = createSelector(
  silHomeStateData,
  (sil) => sil.get("silAccommodationUpdateLoading") || false
);

export const getSilAccommodationRoomListLoading = createSelector(
  silHomeStateData,
  (sil) => sil.get("silAccommodationRoomListLoading") || false
);

export const getSelectedSilAccommodationRooms = createSelector(
  silHomeStateData,
  (sil) => {
    const data = sil.get("selectedSilAccommodationRooms") || [];
    return data?.sort((a, b) => a.id - b.id);
  }
);

export const getSilAccommodationRoomDeleteLoading = createSelector(
  silHomeStateData,
  (sil) => sil.get("silAccommodationRoomDeleteLoading") || false
);

// Sil Documents
export const getSilhomeDocumentsListLoader = createSelector(
  silHomeStateData,
  (sil) => sil.get("silhomeDocumentsListLoader") || false
);

export const getSilHomeDocumentFolderList = createSelector(
  silHomeStateData,
  (sil) => sil.get("silHomeDocumentFolderList") || []
);

export const getSilHomeDocumentFileList = createSelector(
  silHomeStateData,
  (sil) => sil.get("silHomeDocumentFileList") || []
);

export const getSilHomeDocumentFolderCreateLoading = createSelector(
  silHomeStateData,
  (sil) => sil.get("silhomeDocumentFolderCreateLoader") || false
);

export const getSilHomeDocumentFolderUpdateLoading = createSelector(
  silHomeStateData,
  (sil) => sil.get("silhomeDocumentFolderUpdateLoader") || false
);
