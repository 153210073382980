import { connect } from "react-redux";
import TeamManagment from "./TeamManagment";
import { getHRMTeamManagmentRequest } from "../../../../store/actions/sp.actions";
import {
  getTeamManagmentList,
  getLoading,
  getTeamManagmentListLoading,
} from "../../../../store/selector/sp.selector";

const mapDispatchToProps = {
  getTeamManagmentListCall: getHRMTeamManagmentRequest,
};

const mapStateToProps = (state) => {
  const teamManagementList = getTeamManagmentList(state);
  const loading = getTeamManagmentListLoading(state);
  return { loading, teamManagementList };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamManagment);
