import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import InputField from "components/common/Input/Input";
import { REGEX_ONLY_DIGITS } from "Constants/constant";
import SelectOption from "components/common/Select/Select";
import {
  createSilAccommodationRequest,
  fetchPropertyTypeListRequest,
  fetchSdaTypeListRequest,
} from "store/actions/sil.actions";
import {
  getCreateSilAccommodationLoading,
  getPropertyTypeList,
  getPropertyTypeLoading,
  getSdaTypeList,
  getSdaTypeLoading,
} from "store/selector/sil.selector";
import { getCatalogServicesSubCategoryRequest } from "store/actions/cos.actions";
import { getCatalogSubCategoryStatesLoading } from "store/selector/cos.selector";
import { getStateOptions } from "store/selector/general.selector";
import AddressAutoComplete from "components/common/GoogleMap/AddressAutoComplete";
import { useNavigate } from "react-router";
import CheckBox from "components/common/checkBox/checkbox";

const AddSilAccommodationModal = ({
  openModal,
  handleClose,
  isUserGracePeriodOver,
  fetchPropertyTypeListRequest: fetchPropertyTypeListRequestCall,
  propertyTypeLoading,
  propertyTypeList,
  fetchSdaTypeListRequest: fetchSdaTypeListRequestCall,
  sdaTypeLoading,
  sdaTypeList,
  silAccommodationCreateLoading,
  createSilAccommodationRequest: createSilAccommodationRequestCall,
  getCatalogServicesSubCategoryRequest: stateCatServicesRequestCall,
  catalogSubCategoryStatesLoading,
  stateOptions,
}) => {
  const methods = useForm();
  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
    setValue,
    reset,
    control,
  } = methods;

  const watchFormData = watch();

  const navigate = useNavigate();

  useEffect(() => {
    fetchPropertyTypeListRequestCall();
    fetchSdaTypeListRequestCall();
    stateCatServicesRequestCall();

    return () => {
      reset();
    };
  }, []);

  const modalFooter = () => (
    <CustomButton
      showLoading={silAccommodationCreateLoading}
      isDisabled={silAccommodationCreateLoading || isUserGracePeriodOver}
      label='Create'
      variant='primary'
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById(
          "addSilAccommodationButtonModal"
        );
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    createSilAccommodationRequestCall(
      {
        ...data,
        land_size: parseFloat(
          (data?.land_size_length || 0) * (data?.land_size_width || 0)
        )?.toFixed(2),
      },
      (res) => {
        if (res) {
          handleClose();
          setTimeout(() => {}, 2000);
          navigate(`/sil-accommodation/${res}`);
        }
      }
    );
  };

  const selectedStateOption = useMemo(() => {
    return stateOptions?.find(({ id }) => id === watchFormData?.state_id);
  }, [watchFormData]);

  // console.log("watchFormData", watchFormData);

  return (
    <div>
      <ModalSkeleton
        title='Create Home'
        cssClass='w-[50%]'
        isOpen={openModal}
        closeModal={handleClose}
        modalFooter={modalFooter()}
      >
        <div>
          <FormProvider {...methods}>
            <form className='px-4' onSubmit={handleSubmit(onSubmit)}>
              <div className=''>
                <label className='text-gray-700 text-lg font-bold '>
                  Home Information
                </label>
                <div>
                  <AddressAutoComplete google={window?.google} errors={errors}>
                    <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-5 mt-2 mb-5'>
                      <div className='col-span-1'>
                        <InputField
                          isDisabled={
                            catalogSubCategoryStatesLoading ||
                            stateOptions?.length <= 0
                          }
                          id='address-input'
                          setValue={setValue}
                          register={register}
                          label='Street Address'
                          type='text'
                          value={watchFormData?.street_address}
                          name='street_address'
                          isRequired={true}
                          redstar={true}
                          ErrorMessage={
                            errors?.street_address?.type === "required"
                              ? "Field is required!"
                              : null
                          }
                        />
                      </div>

                      <div className='col-span-1'>
                        <InputField
                          setValue={setValue}
                          register={register}
                          label='Suburb'
                          type='text'
                          value={watchFormData?.subrub}
                          name='suburb'
                          isRequired={true}
                          redstar={true}
                          ErrorMessage={
                            errors?.suburb?.type === "required"
                              ? "Field is required!"
                              : null
                          }
                        />
                      </div>

                      <div className='col-span-1'>
                        <label className='block text-sm font-medium text-gray-700 mb-1'>
                          State
                          <span className='text-red-500 ml-1'>*</span>
                        </label>
                        <SelectOption
                          altName='google_state_id'
                          clearErrors={clearErrors}
                          isDisabled={catalogSubCategoryStatesLoading}
                          loading={catalogSubCategoryStatesLoading}
                          cssClass='truncate'
                          isRequired={true}
                          register={register}
                          setValue={setValue}
                          name='state_id'
                          isSearch={false}
                          data={stateOptions}
                          selectedOption={selectedStateOption}
                          errorMessage={
                            errors?.state_id?.type === "required"
                              ? // <div className='text-red-500'>
                                "Field is required!"
                              : // </div>
                                null
                          }
                        />
                      </div>

                      <div className='col-span-1'>
                        <InputField
                          isGeneralPattern={true}
                          pattern={REGEX_ONLY_DIGITS}
                          setValue={setValue}
                          register={register}
                          label='Postal Code'
                          type='type'
                          value={watchFormData?.postal_code ?? null}
                          name='postal_code'
                          isRequired={true}
                          redstar={true}
                          ErrorMessage={
                            errors?.postal_code?.type === "required"
                              ? "Field is required!"
                              : null
                          }
                        />
                      </div>
                    </div>
                  </AddressAutoComplete>
                </div>
              </div>
              <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-5 my-6'>
                <div className='col-span-1'>
                  <label className='text-gray-700 text-sm font-medium mb-1'>
                    Property Type
                    {/* <span className='text-red-500'>*</span> */}
                  </label>
                  <SelectOption
                    isDisabled={propertyTypeLoading}
                    loading={propertyTypeLoading}
                    isRequired={false}
                    register={register}
                    setValue={setValue}
                    name='property_type_id'
                    isSearch={false}
                    clearErrors={clearErrors}
                    data={propertyTypeList}
                    selectedOption={propertyTypeList?.find(
                      (x) => x?.value === watchFormData?.property_type_id
                    )}
                    errorMessage={
                      errors?.property_type_id?.type === "required"
                        ? "Field is required!"
                        : null
                    }
                  />
                </div>
                <div className='col-span-1'>
                  <label className='text-gray-700 text-sm font-medium mb-1'>
                    SDA Type (Specialist Disability Accommodation)
                    {/* <span className='text-red-500'>*</span> */}
                  </label>
                  <SelectOption
                    isDisabled={sdaTypeLoading}
                    loading={sdaTypeLoading}
                    isRequired={false}
                    register={register}
                    setValue={setValue}
                    name='sda_type_id'
                    isSearch={false}
                    clearErrors={clearErrors}
                    data={sdaTypeList}
                    selectedOption={sdaTypeList?.find(
                      ({ id }) => id === watchFormData?.sda_type_id
                    )}
                    errorMessage={
                      errors?.sda_type_id?.type === "required"
                        ? "Field is required!"
                        : null
                    }
                  />
                </div>

                {/* <div className='col-span-2'>
                  <div className='grid grid-cols-11 gap-x-2'>
                    <div className='col-span-3'>
                      <InputField
                        isGeneralPattern={true}
                        pattern={REGEX_ONLY_DIGITS}
                        setValue={setValue}
                        register={register}
                        label='Length'
                        type='text'
                        value={watchFormData?.land_size_length ?? null}
                        name='land_size_length'
                        placeHolder={"Length in meter"}
                        isRequired={true}
                        redstar={true}
                        ErrorMessage={
                          errors?.land_size_length?.type === "required"
                            ? "Field is required!"
                            : null
                        }
                      />
                    </div>
                    <div className='col-span-3'>
                      <InputField
                        isGeneralPattern={true}
                        pattern={REGEX_ONLY_DIGITS}
                        setValue={setValue}
                        register={register}
                        label='Width'
                        type='text'
                        placeHolder={"Width in meter"}
                        value={watchFormData?.land_size_width ?? null}
                        name='land_size_width'
                        isRequired={true}
                        redstar={true}
                        ErrorMessage={
                          errors?.land_size_width?.type === "required"
                            ? "Field is required!"
                            : null
                        }
                      />
                    </div>

                    <div className='col-span-1 flex items-center justify-center'>
                      <span className='mt-[20px] text-xl font-semibold text-gray-500'>
                        =
                      </span>
                    </div>

                    <div className='col-span-4'>
                      <InputField
                        label='Land Size (m²)'
                        type='text'
                        value={parseFloat(
                          (watchFormData?.land_size_length || 0) *
                            (watchFormData?.land_size_width || 0)
                        )?.toFixed(2)}
                        readOnly={true}
                        ErrorMessage={
                          errors?.land_size?.type === "required"
                            ? "Field is required!"
                            : null
                        }
                      />
                    </div>
                  </div>
                </div> */}

                <div className='col-span-1'>
                  <InputField
                    isGeneralPattern={true}
                    pattern={REGEX_ONLY_DIGITS}
                    setValue={setValue}
                    register={register}
                    label='Rooms'
                    placeHolder='Enter number of rooms'
                    type='text'
                    value={watchFormData?.total_rooms ?? null}
                    name='total_rooms'
                    isRequired={true}
                    redstar={true}
                    ErrorMessage={
                      errors?.total_rooms?.type === "required"
                        ? "Field is required!"
                        : null
                    }
                  />
                </div>

                <div className='col-span-1'>
                  <InputField
                    isGeneralPattern={true}
                    pattern={REGEX_ONLY_DIGITS}
                    setValue={setValue}
                    register={register}
                    label='Bathrooms'
                    placeHolder='Enter number of bathrooms'
                    type='text'
                    value={watchFormData?.total_bath_rooms ?? null}
                    name='total_bath_rooms'
                    isRequired={false}
                    redstar={false}
                    ErrorMessage={
                      errors?.total_bath_rooms?.type === "required"
                        ? "Field is required!"
                        : null
                    }
                  />
                </div>

                {/* <div className='col-span-1'>
                  <div className='grid grid-cols-5 gap-x-2'>
                    <div className='col-span-2'>
                      <InputField
                        isGeneralPattern={true}
                        pattern={REGEX_ONLY_DIGITS}
                        setValue={setValue}
                        register={register}
                        label='Land Size'
                        type='text'
                        value={watchFormData?.land_size ?? null}
                        name='land_size'
                        isRequired={true}
                        redstar={true}
                        ErrorMessage={
                          errors?.land_size?.type === "required"
                            ? "Field is required!"
                            : null
                        }
                      />
                    </div>
                    {"*"}
                    <div className='col-span-2'>
                      <InputField
                        isGeneralPattern={true}
                        pattern={REGEX_ONLY_DIGITS}
                        setValue={setValue}
                        register={register}
                        // label='Land Size'
                        type='text'
                        value={watchFormData?.land_size ?? null}
                        name='land_size'
                        isRequired={true}
                        redstar={true}
                        ErrorMessage={
                          errors?.land_size?.type === "required"
                            ? "Field is required!"
                            : null
                        }
                      />
                    </div>
                    {"="}
                    <div className='col-span-1'>
                      <label />
                      <input className='did-floating-input' />
                    </div>
                  </div>
                </div> */}

                <div className='col-span-1'>
                  <InputField
                    isGeneralPattern={true}
                    pattern={REGEX_ONLY_DIGITS}
                    setValue={setValue}
                    register={register}
                    label='Kitchens'
                    placeHolder='Enter number of kitchens'
                    type='text'
                    value={watchFormData?.total_kitchens ?? null}
                    name='total_kitchens'
                    isRequired={false}
                    redstar={false}
                    ErrorMessage={
                      errors?.total_kitchens?.type === "required"
                        ? "Field is required!"
                        : null
                    }
                  />
                </div>

                <div className='col-span-1'>
                  <InputField
                    isGeneralPattern={true}
                    pattern={REGEX_ONLY_DIGITS}
                    setValue={setValue}
                    register={register}
                    label='Parking Spaces'
                    placeHolder='Enter number of parking spaces'
                    type='text'
                    value={watchFormData?.total_parking ?? null}
                    name='total_parking'
                    isRequired={false}
                    redstar={false}
                    ErrorMessage={
                      errors?.total_parking?.type === "required"
                        ? "Field is required!"
                        : null
                    }
                  />
                </div>

                <div className='col-span-1'>
                  <InputField
                    isGeneralPattern={true}
                    pattern={REGEX_ONLY_DIGITS}
                    setValue={setValue}
                    register={register}
                    label='Shared Spaces'
                    placeHolder='Enter number of shared spaces'
                    type='text'
                    value={watchFormData?.total_shared_spaces ?? null}
                    name='total_shared_spaces'
                    isRequired={false}
                    redstar={false}
                    ErrorMessage={
                      errors?.total_shared_spaces?.type === "required"
                        ? "Field is required!"
                        : null
                    }
                  />
                </div>

                {/* <div className='col-span-1'>
                  <InputField
                    isGeneralPattern={true}
                    pattern={REGEX_ONLY_DIGITS}
                    setValue={setValue}
                    register={register}
                    label='No. of Yards'
                    type='text'
                    value={watchFormData?.total_yards ?? null}
                    name='total_yards'
                    isRequired={true}
                    redstar={true}
                    ErrorMessage={
                      errors?.total_yards?.type === "required"
                        ? "Field is required!"
                        : null
                    }
                  />
                </div> */}
                <div />
                <div className='col-span-2'>
                  <div className='grid sm:grid-cols-5 grid-cols-3 gap-x-3'>
                    <div>
                      <Controller
                        name={"front_yard"}
                        control={control}
                        defaultValue={watchFormData?.front_yard}
                        render={({ field }) => {
                          return (
                            <div className='flex justify-start items-center space-x-2'>
                              <CheckBox
                                checked={watchFormData?.front_yard}
                                cssClass='border-gray-500 rounded-sm'
                                handleChange={(value) => {
                                  field.onChange(value);
                                }}
                              />
                              <label className='block text-sm font-medium text-gray-700'>
                                Front Yard
                              </label>
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div>
                      <Controller
                        name={"back_yard"}
                        control={control}
                        defaultValue={watchFormData?.back_yard}
                        render={({ field }) => {
                          return (
                            <div className='flex justify-start items-center space-x-2'>
                              <CheckBox
                                checked={watchFormData?.back_yard}
                                handleChange={(value) => {
                                  field.onChange(value);
                                }}
                                cssClass='border-gray-500 rounded-sm'
                              />
                              <label className='block text-sm font-medium text-gray-700'>
                                Backyard
                              </label>
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div>
                      <Controller
                        name={"swimming_pool"}
                        control={control}
                        defaultValue={watchFormData?.swimming_pool}
                        render={({ field }) => {
                          return (
                            <div className='flex justify-start items-center space-x-2'>
                              <CheckBox
                                checked={watchFormData?.swimming_pool}
                                handleChange={(value) => {
                                  field.onChange(value);
                                }}
                                cssClass='border-gray-500 rounded-sm'
                              />
                              <label className='block text-sm font-medium text-gray-700'>
                                Swimming Pool
                              </label>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                className='sr-only hidden'
                id='addSilAccommodationButtonModal'
              />
            </form>
          </FormProvider>
        </div>
      </ModalSkeleton>
    </div>
  );
};

const mapDispatchToProps = {
  fetchPropertyTypeListRequest,
  fetchSdaTypeListRequest,
  createSilAccommodationRequest,
  getCatalogServicesSubCategoryRequest,
};

const mapStateToProps = (state) => {
  const propertyTypeLoading = getPropertyTypeLoading(state);
  const propertyTypeList = getPropertyTypeList(state);
  const sdaTypeLoading = getSdaTypeLoading(state);
  const sdaTypeList = getSdaTypeList(state);
  const silAccommodationCreateLoading = getCreateSilAccommodationLoading(state);
  const stateOptions = getStateOptions(state);
  const catalogSubCategoryStatesLoading =
    getCatalogSubCategoryStatesLoading(state);

  return {
    propertyTypeLoading,
    propertyTypeList,
    sdaTypeLoading,
    sdaTypeList,
    silAccommodationCreateLoading,
    catalogSubCategoryStatesLoading,
    stateOptions,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSilAccommodationModal);
