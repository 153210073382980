import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import SwitchInput from "../../../components/common/Input/SwitchInput";
import ModalSkeleton from "../../../components/Modals/ModalSkeleton";
import CustomButton from "../../../components/common/Button/CustomButton";
import { useEffect } from "react";
import {
  EMAIL_REGEX_EXP,
  LOADER_STATUS,
  REGEX_NDIS_NUMBER,
  REGEX_PHONE_NUMBER,
  STATES_LIST,
} from "../../../Constants/constant";
import { LoginContext } from "helpers/LoginContext";

const ExternalSPModal = (props) => {
  const {
    catalogStates,
    open,
    setOpen,
    createExternalSP,
    ExternalSpCreateLoader,
    externalSpCreateError,
  } = props;

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    clearErrors();
    reset();
    setRenderCreateLoader(LOADER_STATUS.NA);
  }, []);

  useEffect(() => {
    if (
      renderCreateLoader !== LOADER_STATUS.NA &&
      !ExternalSpCreateLoader &&
      externalSpCreateError === ""
    ) {
      reset();
      setOpen(false);
      setRenderCreateLoader(LOADER_STATUS.COMPLATE);
    }
  }, [ExternalSpCreateLoader, externalSpCreateError]);

  const [remitance, setRemitance] = useState(false);
  const [info, setInfo] = useState(false);
  const [renderCreateLoader, setRenderCreateLoader] = useState(
    LOADER_STATUS.NA
  );

  const onSubmit = async (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    const externalSpData = {
      name: data.firstName,
      email: data.email,
      phone: data.phone,
      street: data.street,
      state: data.state,
      suburb: data.suburb,
      postcode: data.postcode,
      billing_contact: data.billingContact,
      abn: data.abn,
      ndis_regis_no: data.ndisNo,
      bsb_no: data.bsb_no,
      account_title: data.account_title,
      // account_name: data.accountName,
      account_number: data.account_number,
      provider_no: data.provider_no,
      send_remittance: remitance,
    };
    setRenderCreateLoader(LOADER_STATUS.PENDING);
    createExternalSP({
      externalSpData,
    });
  };

  const modalFooter = () => (
    <CustomButton
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("externalSPButton");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
      showLoading={ExternalSpCreateLoader}
      isDisabled={ExternalSpCreateLoader || isUserGracePeriodOver}
      label={"Submit"}
    />
  );

  return (
    <ModalSkeleton
      modalFooter={modalFooter()}
      isOpen={open}
      closeModal={() => setOpen(false)}
      cssClass='w-[55%]'
      title={"Create New External Service Provider"}
    >
      <div className='mt-2'>
        <p className='text-sm text-gray-500'>
          Please enter the following details to create new external service
          provider
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='mt-3 space-y-2'>
        <div className='grid grid-cols-2 gap-x-4'>
          <div className='col-span-1'>
            <label
              htmlFor='firstName'
              className='block text-sm font-medium text-gray-700'
            >
              Name
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='firstName'
                id='firstName'
                className='did-floating-input capitalize w-full'
                {...register("firstName", { required: true, maxLength: 254 })}
              />
              {errors.firstName && (
                <span className='text-sm text-red-500'>Name is required</span>
              )}
            </div>
          </div>
          <div className='col-span-1'>
            <label
              htmlFor='phone'
              className='capitalize block text-sm font-medium text-gray-700'
            >
              Phone
            </label>
            <div className='mt-1'>
              <input
                type='number'
                name='phone'
                id='phone'
                className='did-floating-input capitalize w-full'
                {...register("phone", {
                  required: true,
                  // maxLength: 10,
                  // minLength: 10,
                  pattern: REGEX_PHONE_NUMBER,
                  onChange: (e) => {
                    const sanitizedValue = e.target.value.replace(/\D/g, "");
                    const formattedValue = sanitizedValue.slice(0, 10);
                    e.target.value = formattedValue;
                  },
                })}
              />
              {errors?.phone?.type === "required" && (
                <span className='text-sm text-red-500'>Phone is required!</span>
              )}
              {/* {errors?.phone?.type === "maxLength" && (
                <span className="text-sm text-red-500">
                  Phone number must have 10 digits
                </span>
              )} */}
              {errors?.phone?.type === "minLength" && (
                <span className='text-sm text-red-500'>
                  Phone number must have 10 digits
                </span>
              )}
              {errors?.phone?.type === "pattern" && (
                <span className='text-sm text-red-500'>
                  {/* Phone number must start with 04, 1300 or 1800 */}
                  Please enter a valid phone number!
                </span>
              )}
            </div>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-x-4'>
          <div className='col-span-1'>
            <label
              htmlFor='email'
              className='capitalize block text-sm font-medium text-gray-700'
            >
              Email
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='email'
                id='email'
                className='did-floating-input w-full'
                {...register("email", {
                  required: true,
                  pattern: EMAIL_REGEX_EXP,
                })}
              />
              {errors?.email?.type === "required" && (
                <span className='text-sm text-red-500'>Email is required!</span>
              )}
              {errors?.email?.type === "pattern" && (
                <span className='text-sm text-red-500'>
                  Please enter a valid email address!
                </span>
              )}
            </div>
          </div>
          <div className='col-span-1'>
            <label
              htmlFor='billingContact'
              className='capitalize block text-sm font-medium text-gray-700'
            >
              Billing Contact
            </label>
            <div className='mt-1'>
              <input
                type='number'
                name='billingContact'
                id='billingContact'
                className='did-floating-input capitalize w-full'
                {...register("billingContact", {
                  // minLength: 10,
                  // pattern: /^0[0-9]*$/,
                  pattern: /^\d+$/,
                  onChange: (e) => {
                    e.target.value =
                      // Math.max(
                      // 0,
                      // parseInt(e.target.value)
                      // )
                      e.target.value.toString().slice(0, 10);
                  },
                })}
              />
              {errors?.billingContact?.type === "minLength" && (
                <span className='text-sm text-red-500'>
                  Phone number must have 10 digits
                </span>
              )}
              {errors?.billingContact?.type === "pattern" && (
                <span className='text-sm text-red-500'>
                  {/* Phone number must start with 0 */}
                  Please enter a valid phone number!
                </span>
              )}
              {/* {errors.billingContact && (
                          <span className="text-red-500">
                            Billing Contact is required
                          </span>
                        )} */}
            </div>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-x-4'>
          <div className='col-span-1'>
            <label
              htmlFor='street'
              className='capitalize block text-sm font-medium text-gray-700'
            >
              Street
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='street'
                id='street'
                className='did-floating-input capitalize w-full'
                {...register("street", {
                  // required: true
                  maxLength: 254,
                })}
              />
              {/* {errors.street && (
                          <span className="text-red-500">
                            Street is required
                          </span>
                        )} */}
            </div>
          </div>
          <div className='col-span-1'>
            <label
              htmlFor='suburb'
              className='capitalize block text-sm font-medium text-gray-700'
            >
              Suburb
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='suburb'
                id='suburb'
                className='did-floating-input capitalize w-full'
                {...register("suburb", {
                  maxLength: 254,
                  //  required: true
                })}
              />
            </div>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-x-4'>
          <div className='col-span-1'>
            <div className=''>
              <label className='block text-sm font-medium text-gray-700'>
                State
              </label>
              <select
                className='mt-1 did-floating-select'
                // onClick={this.setAttribute('value', this.value)}
                // onChange={this.setAttribute('value', this.value)}
                {...register("state", {
                  // required: true,
                  // value: "New south Wales",
                })}
              >
                {STATES_LIST.map((item) => {
                  return (
                    <option key={`${item.value}`} value={`${item.value}`}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
              {/*{errors?.state?.type === "required" && (*/}
              {/*  <span className="text-sm text-red-500">*/}
              {/*    This field is required!*/}
              {/*  </span>*/}
              {/*)}*/}
            </div>
          </div>
          {/* <div className="col-span-1">
              <label
                htmlFor="state"
                className="capitalize block text-sm font-medium text-gray-700"
              >
                State
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="state"
                  id="state"
                  className="did-floating-input capitalize w-full"
                  {...register(
                    "state"
                    // { required: true }
                  )}
                />
                {errors.state && (
                          <span className="text-red-500">
                            State is required
                          </span>
                        )}
              </div>
            </div> */}
          <div className='col-span-1'>
            <label
              htmlFor='postcode'
              className='capitalize block text-sm font-medium text-gray-700'
            >
              Post code
            </label>
            <div className='mt-1'>
              <input
                type='number'
                name='postcode'
                id='postcode'
                className='did-floating-input capitalize w-full'
                {...register("postcode", {
                  // required: true,
                  maxLength: 4,
                  minLength: 4,
                  onChange: (e) => {
                    e.target.value = e.target.value.toString().slice(0, 4);
                  },
                })}
              />
              {/* {errors?.postalCode?.type === "required" && (
                          <span className="text-sm text-red-500">
                            Postal Code is required!
                          </span>
                        )} */}
              {errors?.postcode?.type === "maxLength" && (
                <span className='text-sm text-red-500'>
                  Postal Code must have 4 digits
                </span>
              )}
              {errors?.postcode?.type === "minLength" && (
                <span className='text-sm text-red-500'>
                  Postal Code must have 4 digits
                </span>
              )}
              {/* {errors.postalCode && (
                          <span className="text-red-500">
                            Postal Code is required
                          </span>
                        )} */}
            </div>
          </div>
        </div>

        <div className='grid grid-cols-4 gap-x-4'>
          <div className='col-span-1'>
            <label
              htmlFor='account_title'
              className='capitalize block text-sm font-medium text-gray-700'
            >
              Account Title
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='account_title'
                id='account_title'
                className='did-floating-input capitalize w-full'
                {...register(
                  "account_title",

                  {
                    //  required: true
                    maxLength: 254,
                  }
                )}
              />
              {/* {errors.accountTitle && (
                          <span className="text-red-500">
                            Account Title is required
                          </span>
                        )} */}
            </div>
          </div>
          <div className='col-span-1'>
            <label
              htmlFor='bsb_no'
              className='capitalize block text-sm font-medium text-gray-700'
            >
              BSB
            </label>
            <div className='mt-1'>
              <input
                type='number'
                name='bsb_no'
                id='bsb_no'
                className='did-floating-input capitalize w-full'
                {...register(
                  "bsb_no"
                  // { required: true }
                )}
              />
              {/* {errors.bsb && (
                          <span className="text-red-500">BSB is required</span>
                        )} */}
            </div>
          </div>
          <div className='col-span-2'>
            <label
              htmlFor='account_number'
              className='capitalize block text-sm font-medium text-gray-700'
            >
              Account Number
            </label>
            <div className='mt-1'>
              <input
                type='number'
                name='account_number'
                id='account_number'
                className='did-floating-input capitalize w-full'
                {...register(
                  "account_number"
                  // { required: true }
                )}
              />
              {errors.account_number && (
                <span className='text-red-500'>Account Number is required</span>
              )}
            </div>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-x-4'>
          <div className='col-span-1'>
            <label
              htmlFor='abn'
              className='block text-sm font-medium text-gray-700'
            >
              ABN
            </label>
            <input
              {...register("abn", {
                //required: true,
                pattern: "[0-9]*",
                maxLength: 11,
                minLength: 11,
                onChange: (e) =>
                  (e.target.value = e.target.value.toString().slice(0, 11)),
              })}
              className='did-floating-input mt-1'
              // placeholder="Enter ABN number"
              type='number'
              id='abn'
            />
            {errors?.abn?.type === "required" ? (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            ) : errors?.abn?.type === "maxLength" ? (
              <span className='text-sm text-red-500'>
                Please enter a valid ABN number max 11 digits.
              </span>
            ) : errors?.abn?.type === "minLength" ? (
              <span className='text-sm text-red-500'>
                Please enter a valid ABN number min 11 digits.
              </span>
            ) : null}
          </div>
          <div className='col-span-1'>
            <label
              htmlFor='ndisNo'
              className='capitalize block text-sm font-medium text-gray-700'
            >
              NDIS Registration No.
            </label>
            <div className='mt-1'>
              <input
                type='number'
                name='ndisNo'
                id='ndisNo'
                className='did-floating-input capitalize w-full'
                {...register("ndisNo", {
                  // required: true,
                  pattern: {
                    value: REGEX_NDIS_NUMBER,
                    message: "NDIS number should start with either '43' or '5'",
                  },
                })}
              />
              {errors.required
                ? errors.ndisNo && (
                    <span className='text-red-500'>This field is required</span>
                  )
                : errors?.ndisNo?.type === "pattern" && (
                    <span className='text-red-500'>
                      {/* NDIS number should be 43XXXXXXX */}
                      {errors?.ndisNo?.message}
                    </span>
                  )}
            </div>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-x-4'>
          <div className='col-span-1'>
            <label
              htmlFor='provider_no'
              className='capitalize block text-sm font-medium text-gray-700'
            >
              Provider Code
            </label>
            <div className='mt-1'>
              <input
                type='number'
                name='provider_no'
                id='provider_no'
                className='did-floating-input capitalize w-full'
                {...register(
                  "provider_no"
                  // { required: true }
                )}
              />
              {/* {errors.providerNumber && (
                          <span className="text-red-500">
                            Provider Code is required
                          </span>
                        )} */}
            </div>
          </div>
        </div>

        {/* <div className="grid grid-cols-2 gap-x-4">
          <div className="col-span-1">
            <SwitchInput
              handleChange={setRemitance}
              checked={remitance}
              label="Send Remittance Email"
              subLabel=""
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-4">
          <div className="col-span-1">
            <SwitchInput
              handleChange={setInfo}
              checked={info}
              label="Exclude from ABA file"
              subLabel=""
            />
          </div>
        </div> */}

        <button
          className='hidden sr-only'
          id='externalSPButton'
          type='submit'
        />
      </form>
    </ModalSkeleton>
  );
};

export default ExternalSPModal;
