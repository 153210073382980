import React, { useEffect, useRef, useState } from "react";
import CircularLoader from "../CircularLoader/CircularLoader";
import { BiEdit } from "react-icons/bi";
import CustomButton from "../Button/CustomButton";
import { toast } from "react-toastify";

const Accordion = ({
  title,
  children,
  callbackOnOpen,
  isLoading = false,
  handleEditTile,
  updateLoader,
  updateAccordianHeight,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    updateAccordianHeight();
  };

  useEffect(() => {
    if (callbackOnOpen && isOpen) {
      callbackOnOpen();
      updateAccordianHeight();
    }
  }, [isOpen]);

  const [isEdit, setIsEdit] = useState(false);
  const [accordianName, setAccordianName] = useState(title);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEdit && inputRef?.current) {
      inputRef.current?.focus();
    }
  }, [isEdit]);

  return (
    <div className='w-full'>
      <div className='shadow-md rounded-md'>
        <div className='py-4 px-6 flex justify-between items-center bg-blue-50 shodow-lg rounded-md'>
          <div className='w-full flex justify-start items-center'>
            <span
              role='button'
              onClick={toggleAccordion}
              className='text-sm pr-3'
            >
              {isOpen ? "-" : "+"}
            </span>
            {isEdit ? (
              <input
                ref={inputRef}
                className='input-without-css text-sm font-medium w-[90%]'
                value={accordianName}
                onChange={(e) => setAccordianName(e.target.value)}
              />
            ) : (
              <span
                role='button'
                onClick={toggleAccordion}
                id='accordion-heading'
                className='p-o m-0 text-sm font-medium capitalize'
              >
                {title}
              </span>
            )}
          </div>
          <div>
            {!isEdit && handleEditTile ? (
              <span
                role='button'
                className=''
                onClick={() => {
                  if (handleEditTile) {
                    setIsEdit(true);
                  }
                }}
              >
                <BiEdit color='black' size={15} />
              </span>
            ) : (
              <div className='flex justify-center items-center space-x-3'>
                <CustomButton
                  loaderCssClass='mr-0'
                  showLoading={updateLoader}
                  isDisabled={updateLoader}
                  cssClass='h-[30px] px-2 py-1 text-sm'
                  label='Save'
                  clickHandler={() => {
                    if (accordianName?.trim()?.length <= 0) {
                      toast("Please enter room name!");
                      return;
                    } else if (accordianName?.length > 50) {
                      toast.error("Room name cannot exceed 50 characters.", {
                        toastId: "room-name-update",
                      });
                      return;
                    }
                    handleEditTile(accordianName, (res) => {
                      // console.log("res", res);p
                      if (res) {
                        setIsEdit(false);
                      }
                    });
                  }}
                />
                <CustomButton
                  isDisabled={updateLoader}
                  cssClass='h-[30px] px-1 py-0'
                  label='Cancel'
                  variant={"tertiary"}
                  clickHandler={() => setIsEdit(false)}
                />
              </div>
            )}
          </div>
        </div>
        <div
          id='accordion-body'
          className={`${
            isOpen ? "max-h-screen" : "max-h-0"
          } overflow-hidden overflow-y-auto transition-all duration-300 ease-in-out`}
          aria-labelledby='accordion-heading'
        >
          {isLoading ? (
            <div className='px-0 flex justify-center items-center min-h-screen bg-gray-50'>
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          ) : (
            <div className=''>{children}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
