import React from "react";
import RoomDocsUploader from "./RoomDocsUploader";

const SilRoomDocs = ({ room, handleUpdateRoomDetails }) => {
  return (
    <div
      className='pl-6 flex flex-col justify-between'
      style={{ height: "100%" }}
    >
      <RoomDocsUploader
        type='incoming'
        handleUpdateRoomDetails={handleUpdateRoomDetails}
        room={{
          ...room,
          attachments: room?.attachments?.filter(
            ({ type }) => type === "incoming"
          ),
        }}
      />
      {/* Apart */}
      <div className='pt-2'>
        <RoomDocsUploader
          type='outgoing'
          handleUpdateRoomDetails={handleUpdateRoomDetails}
          room={{
            ...room,
            attachments: room?.attachments?.filter(
              ({ type }) => type === "outgoing"
            ),
          }}
        />
      </div>
    </div>
  );
};

export default SilRoomDocs;
