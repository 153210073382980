import React from "react";
import { NumericFormat } from "react-number-format";

const NumberFormatter = (props) => {
  const { value, prefix, cssClass, handleChange } = props;

  return (
    <NumericFormat
      onChange={(e) => {
        if (handleChange) {
          handleChange(e);
        }
      }}
      style={{ borderColor: "transparent" }}
      className={`${cssClass}`}
      type='text'
      value={value ? parseFloat(value).toFixed(2) : 0}
      thousandsGroupStyle='thousand'
      thousandSeparator=','
      valueIsNumericString={true}
      disabled
      prefix={prefix}
    />
  );
};

export default NumberFormatter;
