import createSelector from "../../Utils/reselect";

const generalStateData = (state) => {
  const { general } = state;
  return general || {};
};

const cosStateData = (state) => {
  const { cos } = state;
  return cos || {};
};

export const getMessageBoxObj = createSelector(
  generalStateData,
  (general) => general.get("showMessageBox") || {}
);
export const getConfirmationMessageBoxObj = createSelector(
  generalStateData,
  (general) => general.get("showConfirmationMessageBox") || {}
);
export const getMessageBoxOnExpiryObj = createSelector(
  generalStateData,
  (general) => general.get("showMessageBoxForTimeExpiryData") || {}
);

export const getToastList = createSelector(
  generalStateData,
  (general) => general.get("toastList") || null
);

export const getStateOptions = createSelector(cosStateData, (cos) => {
  const data = cos.get("catalog_sub_category") || [];
  return data
    ?.filter(({ id }) => ![9, 10]?.includes(id))
    ?.map((x) => ({ ...x, value: x.id, label: x.name?.toUpperCase() }));
});
