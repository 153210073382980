import React, { useEffect, useState, useMemo } from "react";
import {
  PARENT_FORM_CATEGORY_SLUG,
  SIL_HOME_PROFILE_TABS,
  //   PARENT_FORM_CATEGORY_SLUG,
  //   USER_ROLE,
} from "Constants/constant";
import {
  classNames,
  parseBooleanVal,
  SilHomeselectedTabState,
} from "Utils/utils";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import SilHomeProfileTabItem from "./SilHomeProfileTabItem";
import { roleAccess } from "helpers/RolesPermission";
import PropertyProfile from "../sections/PropertyProfile";
import SubmittedFormView from "components/ServiceProvider/SubmittedFormsView";
import SilRooms from "../sections/SilRooms";
import CaseNotesTab from "components/ServiceProvider/Participant/CaseNotes";
import SilAccommodationSettings from "../sections/Settings";
import Documents from "pages/HRM/Pages/Worker/Documents/index";
import "./SilHomeProfileTabs.css";

const SilHomeProfileTabs = ({ ...props }) => {
  const {
    selectedAccommodationInfo,
    toggleState,
    setToggleState,
    setSelectedSilAccommodationLocally,
  } = props;

  const [tabs] = useState(SIL_HOME_PROFILE_TABS);

  const [selectedTab] = useState(
    SilHomeselectedTabState.get()
      ? SilHomeselectedTabState.get()
      : SIL_HOME_PROFILE_TABS[0]
  );

  const toggleTab = (tab) => {
    setToggleState(tab);
    SilHomeselectedTabState.save(tab);
  };

  useEffect(() => {
    if (toggleState.id !== selectedTab.id) setToggleState(selectedTab);
  }, [selectedTab]);

  const loadTabComponent = () => {
    switch (toggleState.id) {
      case "property-profile":
        return (
          <>
            <PropertyProfile
              setSelectedSilAccommodationLocally={
                setSelectedSilAccommodationLocally
              }
              selectedAccommodationInfo={selectedAccommodationInfo}
              setToggleState={setToggleState}
            />
          </>
        );

      case "form-property-management":
        return (
          <SubmittedFormView
            parentSlug={PARENT_FORM_CATEGORY_SLUG.PropertyManagement.SLUG}
            participantId={selectedAccommodationInfo.id}
            supportPlansId={PARENT_FORM_CATEGORY_SLUG.PropertyManagement.UUID}
            participantsUid={selectedAccommodationInfo.uuid}
            title='Property Management'
            role='sil'
          />
        );

      case "form-participant-enrollment":
        return (
          <SubmittedFormView
            parentSlug={PARENT_FORM_CATEGORY_SLUG.PropertyEnrollment.SLUG}
            participantId={selectedAccommodationInfo.id}
            supportPlansId={PARENT_FORM_CATEGORY_SLUG.PropertyEnrollment.UUID}
            participantsUid={selectedAccommodationInfo.uuid}
            title='Property Enrollment'
            role='sil'
          />
        );
      case "form-others":
        return (
          <SubmittedFormView
            parentSlug={PARENT_FORM_CATEGORY_SLUG.SilFromsOthers.SLUG}
            participantId={selectedAccommodationInfo.id}
            supportPlansId={PARENT_FORM_CATEGORY_SLUG.SilFromsOthers.UUID}
            participantsUid={selectedAccommodationInfo.uuid}
            title='Others'
            role='sil'
          />
        );

      case "documents":
        return (
          // roleAccess(
          //   [],
          //   PERMISSION_KEYS.PARTICIPANT,
          //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOCUMEMT.SHOW
          // ) && (
          <div>
            <Documents
              requiredDocuments={false}
              role={"sil"}
              worker={selectedAccommodationInfo}
            />
          </div>
          // )
        );

      case "sil-room":
        return (
          // roleAccess(
          // []
          // PERMISSION_KEYS.PARTICIPANT,
          // PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOCUMEMT.SHOW
          // ) && <div>Sil Room</div>
          <SilRooms selectedAccommodationInfo={selectedAccommodationInfo} />
        );

      case "notes":
        return (
          <CaseNotesTab
            callFrom='silProfile'
            participantInfo={selectedAccommodationInfo}
            setToggleState={setToggleState}
            // isCaseNoteCall={isCaseNoteCall}
            // resetCaseNoteCall={resetCaseNoteCall}
          />
        );

      case "settings":
        return (
          // roleAccess(
          //   [],
          //   PERMISSION_KEYS.PARTICIPANT,
          //   PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
          // )
          // &&
          <SilAccommodationSettings />
        );

      default:
        return <></>;
    }
  };

  const tabsToMap = useMemo(() => {
    const filterTabs = (tab) => {
      const permissions = tab.permissions;
      return (
        !permissions ||
        roleAccess([], PERMISSION_KEYS.PARTICIPANT, permissions.SHOW)
      );
    };
    const filterChildTabs = (childTab) => {
      const permissions = childTab.permissions;
      return (
        !permissions || roleAccess([], PERMISSION_KEYS.PARTICIPANT, permissions)
      );
    };
    const filteredTabs = tabs.filter(filterTabs);
    filteredTabs.forEach((tab) => {
      const childs = tab.children;
      if (childs && childs.length > 0) {
        tab.children = childs.filter(filterChildTabs);
      }
    });
    const fundingIndex = filteredTabs.findIndex(
      (x) =>
        x.id === 17 &&
        !parseBooleanVal(
          selectedAccommodationInfo?.support_coordination || false
        )
    );
    const finalTabs =
      fundingIndex !== -1
        ? filteredTabs.filter(
            (x) => x.id !== 17 && roleAccess([...x.roleAccess])
          )
        : filteredTabs.filter((x) => roleAccess([...x.roleAccess]));

    return finalTabs;
  }, [tabs, selectedAccommodationInfo]);

  return (
    <div className='bg-white shadow-lg rounded-lg'>
      <div className='print:hidden sticky z-[8] top-[79px]'>
        <div className='bloc-tabs bg-gradient py-4 rounded-t-lg text-white '>
          {tabsToMap.map((tab, index) => (
            <SilHomeProfileTabItem
              tabItem={tab}
              key={index}
              toggleTab={toggleTab}
              selectedTab={toggleState.id}
            />
          ))}
        </div>
      </div>
      <div className='content-tabs mb-8'>
        <div
          className={classNames(
            "content active-content",
            toggleState.id === "property-profile" && "!px-0 !py-0"
          )}
        >
          {loadTabComponent()}
        </div>
      </div>
    </div>
  );
};

export default SilHomeProfileTabs;
