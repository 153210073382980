import React, { useState } from "react";
import { toast } from "react-toastify";
import { getUrlRole } from "Utils/utils";
import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { apiDeleteRequest, apiPostRequest } from "helpers/Requests";
import { MdDelete } from "react-icons/md";
import { IoAlertCircle } from "react-icons/io5";
import {
  BASE_URL,
  URL_SIL_ACCOMMODATION_CASE_NOTE_DELETE,
} from "store/services/URL";
// import { TbAlertOctagonFilled } from "react-icons/tb";

const DeleteCaseNoteModal = ({
  deleteModalOpen,
  selectedComment,
  onCloseModal,
  fetchNotes,
  setFetchNotes,
  callFrom = "",
}) => {
  const [isDeleteLoader, setIsDeleteLoader] = useState(false);

  const onDelete = async () => {
    try {
      setIsDeleteLoader(true);
      const response =
        callFrom === "silProfile"
          ? await apiPostRequest(
              `${URL_SIL_ACCOMMODATION_CASE_NOTE_DELETE.replace(
                BASE_URL,
                ""
              )}/${selectedComment.uuid}`
            )
          : await apiDeleteRequest(
              `/${getUrlRole()}/case-notes/${selectedComment.id}`
            );
      if (response.status === 403) {
        toast.error("You do not have permission to delete this case note.");
        console.log("Failed to delete case note. Status:", response.status);
      } else if (response.status !== 200) {
        toast.error("Failed to delete case note. Please try again later.");
        console.log("Failed to delete case note. Status:", response.status);
      } else {
        const updatedNotes = fetchNotes?.filter(
          (note) => note.id !== selectedComment.id
        );
        setFetchNotes(updatedNotes);
        setIsDeleteLoader(false);
        toast.success(
          `${
            callFrom === "silProfile" ? "Note" : "Case Note"
          } deleted successfully`
        );
        onCloseModal(false);
      }
    } catch (err) {
      setIsDeleteLoader(false);
      console.error("Error deleting case note:", err);
      toast.error(
        "An error occurred while deleting case note. Please try again later."
      );
    }
  };

  const modalDeleteFooterButton = () => (
    <div className='flex space-x-2'>
      <CustomButton
        showLoading={isDeleteLoader}
        isDisabled={isDeleteLoader}
        clickHandler={() => {
          if (!selectedComment) return;
          onDelete();
        }}
        type='button'
        label='Delete'
        variant='danger'
        Icon={<MdDelete />}
      />
      <CustomButton
        isDisabled={isDeleteLoader}
        clickHandler={onCloseModal}
        type='button'
        label='Close'
        variant='primary'
      />
    </div>
  );

  return (
    <>
      {deleteModalOpen && (
        // Import the delete icon

        <ModalSkeleton
          isOpen={deleteModalOpen}
          title={callFrom === "silProfile" ? "Delete Note" : "Delete CaseNote"}
          closeModal={onCloseModal}
          cssClass='w-[40%]'
          modalFooter={modalDeleteFooterButton()}
        >
          <div className='flex justify-center items-center'>
            <IoAlertCircle className='text-red-500 w-6 h-6 mr-2' />
            <p className='text-red-500'>
              {`Are you sure you want to delete this ${
                callFrom === "silProfile" ? "Note" : "Case Note"
              }?`}
            </p>
          </div>
        </ModalSkeleton>
      )}
    </>
  );
};

export default DeleteCaseNoteModal;
