import React, { useContext, useEffect, useState } from "react";
import Header from "components/common/Header/Header";
import SideBarNew from "components/common/SideBar/SideBarNew";
import { LoginContext } from "helpers/LoginContext";
import { useDispatch, useSelector } from "react-redux";
import { stripePricingTableListRequest } from "store/actions/sp.actions";
import {
  getStripePricingList,
  getStripePricingTableLoader,
} from "store/selector/sp.selector";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { getPaymentSettings } from "store/selector/auth.selector";
import api from "store/services/sp.services";
import { classNames } from "Utils/utils";
import CustomButton from "components/common/Button/CustomButton";
import { BiExit } from "react-icons/bi";
import { callLogout } from "store/actions/auth.actions";
import { useNavigate } from "react-router";
import PricingTableNew from "./NewPricingTable/PricingTableNew";

const PricingTable = ({ istrailExpired = false }) => {
  const { sideBarPadding, unSubscribeChannels, isTrialChoosePlanBtnClick } =
    useContext(LoginContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pricingList = useSelector(getStripePricingList);
  
  const pricingListLoader = useSelector(getStripePricingTableLoader);
  const paymentSettings = useSelector(getPaymentSettings);

  useEffect(() => {
    dispatch(stripePricingTableListRequest());
  }, []);

  const [isFetchCurrentSubs, setIsFetchCurrentSubs] = useState(false);
  const [isCurrentSubscription, setIsCurrentSubscription] = useState(null);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    unSubscribeChannels();
    dispatch(callLogout());
    navigate("/");
  };

  useEffect(() => {
    getCurrentSubscription();
  }, []);

  const getCurrentSubscription = async () => {
    try {
      setIsFetchCurrentSubs(true);
      const response = await api.stripeCurrentSubscription();
      setIsFetchCurrentSubs(false);
      if (response) {
        if (response?.subscription?.plan?.nickname) {
          const [participantsRange, pricingDetails] =
            response?.subscription?.plan?.nickname.split("$");
          setIsCurrentSubscription(
            {
              ...response?.subscription?.plan,
              noOfParticipants: participantsRange.trim(),
              duration: pricingDetails.trim(),
              subscriptionPlan: response?.subscription?.metadata?.product_name,
            } || null
          );
        }
      }
    } catch (err) {
      setIsFetchCurrentSubs(false);
      // console.log("err", err);
    }
  };

  // console.log("pricingList", pricingList);

  return (
    <div className='min-h-full'>
      {istrailExpired === false && <SideBarNew />}
      <main>
        <div
          className={classNames(
            istrailExpired === false
              ? sideBarPadding
                ? "lg:pl-[4rem] duration-300"
                : "lg:pl-64 duration-300"
              : "",
            `${sideBarPadding} flex flex-col fullScreenHeight`
          )}
        >
          {istrailExpired === false ? (
            <Header title='Subscription Plans' cssClass={"z-[9]"} />
          ) : (
            <header className='bg-white flex items-center sticky top-0 z-[10] border-b border-gray-200 sm:px-6 lg:px-8 py-[20px] h-[80px]'>
              <div className='items-center w-full flex sm:flex sm:items-center sm:justify-between'>
                <div className='flex-1 min-w-0'>
                  <h1 className='text-xl font-semi bold text-gray-900 capitalize'>
                    Subscription Plans
                  </h1>
                </div>
                <div className='mt-4 flex justify-space-between sm:mt-0 space-x-2 print:hidden'>
                  <div className='md:mt-0 md:ml-auto space-x-2 md:space-x-4 hidden sm:flex'>
                    <CustomButton
                      clickHandler={handleLogout}
                      label={"Logout"}
                      Icon={<BiExit />}
                      variant={"danger"}
                    />
                  </div>
                </div>
              </div>
            </header>
          )}
          {/* <stripe-pricing-table
                    pricing-table-id='prctbl_1OUhxTKaVQE0Yj31iQG5kzOv'
                    publishable-key='pk_live_51OUh3DKaVQE0Yj313K7isXPQT8ezdLAX5NtaZdImduttVKADKoobqwgtpCOanchlzHsMjOV6ZaJ5PB8W9dDDKg2500QecN0mvg'
                  ></stripe-pricing-table> */}

          {pricingListLoader || isFetchCurrentSubs ? (
            <CircularLoader
              classes='flex justify-center items-center'
              classloader='loader-l'
            />
          ) : (
            <>
              {/* Pricing Table */}
              {/*  <div>
                  <PricingSection
                    isCurrentSubscription={isCurrentSubscription}
                    paymentSettings={paymentSettings}
                    data={pricingList}
                  />
                </div> */}

              {/* New Pricing Table */}

              <PricingTableNew
                isCurrentSubscription={isCurrentSubscription}
                paymentSettings={paymentSettings}
                data={pricingList}
                isTrialChoosePlanBtnClick={isTrialChoosePlanBtnClick}
              />

              {/* Subscription checkout testing */}
              {/* <SubscriptionCheckout /> */}
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default PricingTable;
