import React, { useEffect, useState, useContext } from "react";
import { Tabs, TabPanel } from "react-tabs";
import Header from "components/common/Header/Header";
import { AiOutlinePlus } from "react-icons/ai";
import Heading from "components/DynamicForms/Heading/Heading";
import ConfirmationModal from "components/common/Modal/ConfirmationModal";
import _ from "lodash";
import { DropDown } from "components/common/DropDown/DropDown";
import { apiGetRequest, apiPostRequest, apiPutRequest } from "helpers/Requests";
import { toast } from "react-toastify";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import InputCreationForm from "components/common/InputCreationForm/InputCreationForm";
import SectionCreationForm from "components/common/SectionCreationForm/SectionCreationForm";
import SideBar from "components/common/SideBar/SideBarNew";
import { LoginContext } from "helpers/LoginContext";
import CustomButton from "components/common/Button/CustomButton";
import LabelCheckBox from "components/common/checkBox/labelCheckBox";
import Breadcrumb from "components/common/Breadcrumb";
import {
  SUPER_ADMIN_BREADCRUMB,
  SUPER_ADMIN_BREADCRUMBS_PAGES,
} from "Constants/BreadcrumbConstant";
import { parseBooleanVal } from "Utils/utils";
import SelectOption from "components/common/Select/Select";
import { useNavigate, useParams } from "react-router";
import { MdPublish } from "react-icons/md";
import { RiDraftFill } from "react-icons/ri";
import SweetAlertConfirmationModal from "components/common/SweetAlertConfirmation/SweetAlertConfirmation";
import { roleAccess } from "helpers/RolesPermission";
import { USER_ROLE } from "Constants/constant";
import apiCall from "store/services/sp.services";
import api from "store/services/sil.services";

const CreateFormTemplate = (props) => {
  const { title, role } = props;
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const navigate = useNavigate();

  const { form_uuid, form_status } = useParams();
  const [actionMode, setActionMode] = useState("add");
  const [showInputModal, setShowInputModal] = useState(false);
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [showSubSectionModal, setShowSubSectionModal] = useState(false);
  const [formCategories, setFormCategories] = useState([]);
  const [formSubCategories, setFormSubCategories] = useState([]);
  const [selectedFormCategory, setselectedFormCategory] = useState(null);
  const [selectedformSubCategory, setSelectedFormSubCategory] = useState(null);
  const [formCatError, setFormCatError] = useState("");
  const [showSubSectionInputModal, setShowSubSectionInputModal] =
    useState(false);
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);
  const [showEditSectionTitle, setShowEditSectionTitle] = useState(false);
  const [showEditSubSectionModal, setShowEditSubSectionModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [
    showSectionTitleConfirmationModal,
    setShowSectionTitleConfirmationModal,
  ] = useState(false);
  const [showSubSectionConfirmationModal, setShowSubSectionConfirmationModal] =
    useState(false);
  const [sectionIndex, setSectionIndex] = useState(null);
  const [subSectionIndex, setSubSectionIndex] = useState(null);
  const [fieldIndex, setFieldIndex] = useState(null);
  const [subFieldIndex, setSubFieldIndex] = useState(null);
  const [editData, setEditData] = useState(null);
  const [template, setTemplate] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    form_category_id: null,
    form_sub_category_id: null,
    description: "",
  });
  const [decodedFormType, setDecodedFormType] = useState(null);
  const [showConfirmationModalPublish, setShowConfirmationModalPublish] =
    useState(false);

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
    saveRequested: false,
    saveSuccess: false,
    saveFailed: false,
    submissionType: "",
  });

  useEffect(() => {
    if (
      (form_uuid !== undefined && form_status !== undefined && form_status,
      form_uuid)
    ) {
      const formType = decodeURIComponent(window.atob(form_status));
      if (formType === "publish" || formType === "draft") {
        setDecodedFormType(formType);
      }
      setActionMode("edit");
    }
  }, []);

  useEffect(() => {
    if (
      actionMode === "edit" &&
      formCategories &&
      formCategories.length > 0 &&
      decodedFormType
    ) {
      getFormTemplate();
    }
  }, [actionMode, formCategories]);

  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    console.log("formData", formData);
    if (formData?.form_category_id) {
      const isExistSubCats = formCategories.find(
        (x) => x.value === formData.form_category_id
      );
      if (
        isExistSubCats &&
        isExistSubCats.form_sub_categories &&
        isExistSubCats.form_sub_categories.length > 0
      ) {
        const data = isExistSubCats.form_sub_categories.map((x) => {
          const obj = {
            label: x.name,
            value: x.id,
          };
          return { ...x, ...obj };
        });
        setFormSubCategories([...data]);
        if (formData.form_sub_category_id) {
          setSelectedFormSubCategory(
            data.find((x) => x.value === formData.form_sub_category_id)
          );
        }
      } else {
        setFormSubCategories([]);
        setSelectedFormSubCategory(null);
      }
    }
  }, [formData]);

  const openInputModalHandler = (index) => {
    setShowInputModal(true);
    setSectionIndex(index);
  };

  const closeInputModalHandler = () => {
    setShowInputModal(false);
  };

  const openSubSectionModalHandler = (index) => {
    setShowSubSectionModal(true);
    setSectionIndex(index);
  };

  const closeSubSectionModalHandler = () => {
    setShowSubSectionModal(false);
  };

  const openSubSectionInputModalHandler = (index, i) => {
    setShowSubSectionInputModal(true);
    setSectionIndex(index);
    setFieldIndex(i);
  };

  const closeSubSectionInputModalHandler = () => {
    setShowSubSectionInputModal(false);
  };

  const openSectionModalHandler = () => {
    setShowSectionModal(true);
  };

  const closeSectionModalHandler = () => {
    setShowSectionModal(false);
  };

  const openEditSectionModalHandler = (sectionIndex, fieldIndex, item) => {
    setFieldIndex(fieldIndex);
    setSectionIndex(sectionIndex);
    if (item.hasOwnProperty("required")) {
      item.required.toString();
    }
    setEditData(item);
    setShowEditSectionModal(true);
  };

  const closeEditSectionModalHandler = () => {
    setShowEditSectionModal(false);
  };

  const openEditSectionTitleModal = (
    sectionIndex,
    fieldIndex,
    item,
    subFieldIndex,
    type = "section"
  ) => {
    setSectionIndex(sectionIndex);
    setSubSectionIndex(fieldIndex);
    setEditData(item);
    setShowEditSectionTitle(true);
  };

  const closeSectionTitleHandler = () => {
    setShowEditSectionTitle(false);
  };

  const openEditSubSectionModalHandler = (
    sectionIndex,
    fieldIndex,
    item,
    subFieldIndex
  ) => {
    setFieldIndex(fieldIndex);
    setSectionIndex(sectionIndex);
    setSubFieldIndex(subFieldIndex);
    if (item.hasOwnProperty("required")) {
      item.required.toString();
    }
    setEditData(item);
    setShowEditSubSectionModal(true);
  };

  const closeEditSubSectionModalHandler = () => {
    setShowEditSubSectionModal(false);
  };

  const openConfirmationModalHandler = (
    sectionIndex,
    fieldIndex,
    subFieldIndex,
    type
  ) => {
    setFieldIndex(fieldIndex);
    setSectionIndex(sectionIndex);
    setSubFieldIndex(subFieldIndex);
    setShowConfirmationModal(true);
  };

  const closeConfirmationModalHandler = () => {
    setShowConfirmationModal(false);
  };

  const openSectionTitleConfirmationModalHandler = (sectionIndex) => {
    setSectionIndex(sectionIndex);
    setShowSectionTitleConfirmationModal(true);
  };

  const closeSectionTitleConfirmationModalHandler = () => {
    setShowSectionTitleConfirmationModal(false);
  };

  const openSubSectionConfirmationModalHandler = (
    sectionIndex,
    fieldIndex,
    subFieldIndex
  ) => {
    setFieldIndex(fieldIndex);
    setSectionIndex(sectionIndex);
    setSubFieldIndex(subFieldIndex);
    setShowSubSectionConfirmationModal(true);
  };

  const closeSubSectionConfirmationModalHandler = () => {
    setShowSubSectionConfirmationModal(false);
  };

  const fetchCategories = async () => {
    setRequest({ ...request, isRequested: true });
    try {
      const res =
        role === "sil"
          ? await api.silFormCategoriesApi()
          : await apiCall.formCategoriesRequest({ role });
      const data = res.form_categories.map((x) => {
        const obj = {
          label: x.name,
          value: x.id,
        };
        return { ...x, ...obj };
      });
      setFormCategories([...data]);
      setRequest({ ...request, isRequested: false, isSuccess: true });
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.error(err.message);
    }
  };

  const getFormTemplate = async () => {
    setRequest({ ...request, isRequested: true });
    try {
      let url = null;
      if (roleAccess([USER_ROLE.Super_Admin])) {
        url = role === "hrm" ? "super-admin/hrm" : "super-admin";
      } else if (roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) {
        url =
          role === "sil"
            ? "sil/form/template"
            : role === "hrm"
            ? "sp/hrm/form_template"
            : "sp/form_template";
      }

      if (url === null) return;

      const res = await apiGetRequest(
        roleAccess([USER_ROLE.Super_Admin])
          ? `${url}/form-template/show?form_uuid=${form_uuid}&form_type=${decodedFormType}`
          : `${url}?form_template_slug=${form_uuid}&form_type=${decodedFormType}`
      );
      console.log("res", res);
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        throw new Error(res.data.error);
      }
      if (res.status === 200 && res.data.data) {
        const formCat =
          role === "sil"
            ? formCategories?.find(
                (x) => res.data.data?.form_category_with_name?.id === x.id
              )
            : formCategories.find(
                (x) => res.data.data.form_category_id === x.id
              );
        if (formCat) {
          const payloadData = {
            ...formData,
            form_sub_category_id: res.data.data?.form_sub_category_id || null,
            description: res.data.data.description,
            uuid: res.data.data.uuid,
            title: decodedFormType === "draft" ? res.data.data.title : "",
          };

          Object.assign(payloadData, {
            form_category_id:
              role === "sil"
                ? res.data.data?.form_category_with_name?.id
                : res.data.data.form_category_id,
          });
          setFormData(payloadData);
          setTemplate(JSON.parse(res.data.data.template));
          setselectedFormCategory(formCat);
        }
        setRequest({ ...request, isRequested: false, isSuccess: true });
      }
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const submitFormTemplate = async (creationType) => {
    if (template && template.length === 0) {
      toast("Create at least One Section!");
      return;
    }

    const data = {
      ...formData,
      template: template,
      form_type: creationType,
    };

    setRequest({
      ...request,
      saveRequested: true,
      submissionType: creationType,
    });

    if (role === "sil") {
      Object.assign(data, {
        category_id: data.form_category_id,
      });
    }

    try {
      let res = null;

      if (roleAccess([USER_ROLE.Super_Admin])) {
        const routeRole = role === "hrm" ? "super-admin/hrm" : "super-admin";
        res =
          title === "Create New Form" ||
          (decodedFormType && decodedFormType === "publish")
            ? await apiPostRequest(`${routeRole}/form-template`, data)
            : await apiPutRequest(
                `${routeRole}/form-template/${form_uuid}`,
                data
              );
      } else if (roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) {
        const routeRole =
          role === "sil"
            ? "sil/form"
            : role === "hrm"
            ? "sp/hrm/form-templates"
            : "sp/form-templates";
        const url =
          title === "Create New Form" ||
          (decodedFormType && decodedFormType === "publish")
            ? `${routeRole}/create`
            : role === "sil"
            ? `${routeRole}/update?id=${formData.uuid}&form_type=${decodedFormType}`
            : `${routeRole}?id=${formData.uuid}&form_type=${decodedFormType}`;

        res =
          title === "Create New Form" ||
          (decodedFormType && decodedFormType === "publish")
            ? await apiPostRequest(url, data)
            : await apiPostRequest(url, data);

        //   await apiPostRequest(
        //     `sp/hrm/provider_form_template?form_template_slug=${form_uuid}&form_type=${form_status}`,
        //     data
        //   ));

        // ? await apiPostRequest(`${routeRole}/provider_form_template`, data)
        // : await apiPostRequest(
        //     `${routeRole}/provider_form_template?form_template_slug=${form_uuid}&form_type=${form_status}`,
        //     data
        //   );
      }

      if (res === null) return;
      if (
        res.status !== 201 &&
        decodedFormType &&
        decodedFormType === "publish"
      ) {
        setRequest({
          ...request,
          saveRequested: false,
          submissionType: null,
          saveFailed: false,
        });
        throw new Error(res.data.error);
      }

      if (res.status === 201) {
        setRequest({
          ...request,
          saveRequested: false,
          submissionType: null,
          saveSuccess: true,
        });
        setFormData({
          title: "",
          form_category_id: null,
          form_sub_category_id: null,
          description: "",
        });
        setTemplate([]);
        setSectionIndex(null);
        setFieldIndex(null);
        setSubFieldIndex(null);
        toast.success("Form Template created successfully");
        role === "sil"
          ? navigate("/forms-accommodation")
          : role === "hrm"
          ? navigate("/forms-list-hrm")
          : navigate("/forms-list");
      }
      if (res.status === 200) {
        toast.success("Form Template updated successfully");
        role === "sil"
          ? navigate("/forms-accommodation")
          : role === "hrm"
          ? navigate("/forms-list-hrm")
          : navigate("/forms-list");
      }
      setRequest({
        ...request,
        saveRequested: false,
        submissionType: null,
        saveFailed: false,
      });
    } catch (err) {
      setRequest({
        ...request,
        saveRequested: false,
        submissionType: null,
        saveFailed: false,
      });
      // console.log("err", err);
      toast.error(
        err.hasOwnProperty("message") && err.message
          ? err.message
          : "Something went wrong!"
      );
    }
  };

  const addInputFieldHandler = (data) => {
    const list = _.cloneDeep(template);
    if (parseBooleanVal(data.required)) {
      data.required = true;
    } else {
      data.required = false;
    }
    list[sectionIndex].input_fields.push(data);
    setTemplate(list);
  };

  const updateFieldHandler = (data) => {
    const list = _.cloneDeep(template);
    if (parseBooleanVal(data.required)) {
      data.required = true;
    } else {
      data.required = false;
    }
    list[sectionIndex].input_fields[fieldIndex] = data;

    setTemplate(list);
  };

  const updateSubSectionFieldHandler = (data) => {
    const list = _.cloneDeep(template);
    if (parseBooleanVal(data.required)) {
      data.required = true;
    } else {
      data.required = false;
    }
    list[sectionIndex].input_fields[fieldIndex].sub_fields[subFieldIndex] =
      data;
    setTemplate(list);
  };

  const deleteFieldHandler = () => {
    const list = _.cloneDeep(template);
    list[sectionIndex].input_fields.splice(fieldIndex, 1);
    setTemplate(list);
    setShowConfirmationModal(false);
  };

  const deleteSectionHandler = () => {
    const list = _.cloneDeep(template);
    list.splice(sectionIndex, 1);
    setTemplate(list);
    setShowSectionTitleConfirmationModal(false);
  };

  const deleteSubSectionFieldHandler = () => {
    const list = _.cloneDeep(template);
    if (subFieldIndex) {
      list[sectionIndex].input_fields[fieldIndex].sub_fields.splice(
        subFieldIndex,
        1
      );
    } else list[sectionIndex].input_fields.splice(fieldIndex, 1);
    setTemplate(list);
    setShowSubSectionConfirmationModal(false);
  };

  const addSubSectionHandler = (data) => {
    data.type = "sub_section";
    data.lable = "";
    data.sub_fields = [];
    const list = _.cloneDeep(template);
    list[sectionIndex].input_fields.push(data);
    setTemplate(list);
  };

  const addSectionHandler = (data) => {
    const list = _.cloneDeep(template);
    data.input_fields = [];
    list.push(data);
    setTemplate(list);
  };

  const editSectionTitleHandler = (data) => {
    const list = _.cloneDeep(template);
    if (data.type === "sub_section") {
      list[sectionIndex].input_fields[subSectionIndex].isAddOther =
        data.isAddOther;
      list[sectionIndex].input_fields[subSectionIndex].section_title =
        data.section_title;
    } else {
      list[sectionIndex].section_title = data.section_title;
    }
    setTemplate(list);
    setShowEditSectionTitle(false);
  };

  const addSubSectionInputFieldHandler = (data) => {
    const list = _.cloneDeep(template);
    if (parseBooleanVal(data.required)) {
      data.required = true;
    } else {
      data.required = false;
    }
    list[sectionIndex].input_fields[fieldIndex].sub_fields.push(data);
    setTemplate(list);
  };

  return (
    <div>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300 flex flex-col"
              : "lg:pl-64 duration-300 flex flex-col"
          }`}
        >
          <main className='flex-1'>
            <Header
              title={
                title === "Create New Form" ||
                (decodedFormType && decodedFormType === "publish")
                  ? "Create New Form"
                  : title
              }
              desc='Supercharge your business with customization on the standard forms.'
              breadcrumb={
                <Breadcrumb
                  pages={SUPER_ADMIN_BREADCRUMB(
                    title === "Create New Form" ||
                      (decodedFormType && decodedFormType === "publish")
                      ? SUPER_ADMIN_BREADCRUMBS_PAGES.CREATE_FORM_TEMPLATE
                      : SUPER_ADMIN_BREADCRUMBS_PAGES.UPDATE_FORM_TEMPLATE,
                    role
                  )}
                />
              }
            />
            {/* <div className='sm:px-6 lg:px-8 my-3'>
              <Breadcrumb
                pages={SUPER_ADMIN_BREADCRUMB(
                  title === "Create New Form" ||
                    (decodedFormType && decodedFormType === "publish")
                    ? SUPER_ADMIN_BREADCRUMBS_PAGES.CREATE_FORM_TEMPLATE
                    : SUPER_ADMIN_BREADCRUMBS_PAGES.UPDATE_FORM_TEMPLATE,
                  role
                )}
              />
            </div> */}
            {request.isRequested ? (
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            ) : (
              <div className='py-4 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto'>
                <div>
                  <div className='col-span-6 flex flex-col did-floating-label-content'>
                    <label htmlFor='' className='did-floating-label'>
                      Title
                    </label>
                    <input
                      value={formData?.title}
                      type='text'
                      className='did-floating-input'
                      name='title'
                      onChange={onChangeHandler}
                    />
                  </div>
                  <div className='col-span-6 flex flex-col did-floating-label-content'>
                    <label htmlFor='' className='did-floating-label'>
                      Form Category
                    </label>
                    <SelectOption
                      cssClass='mt-1'
                      isSearch={false}
                      data={formCategories}
                      selectedOption={selectedFormCategory}
                      handleChange={(e) => {
                        setselectedFormCategory(e);
                        setFormData({ ...formData, form_category_id: e.value });
                        setFormCatError("");
                      }}
                    />
                    {formCatError && (
                      <span className='text-sm text-red-500'>
                        This field is require
                      </span>
                    )}
                  </div>

                  {formSubCategories && formSubCategories.length > 0 && (
                    <div className='col-span-6 flex flex-col did-floating-label-content'>
                      <label htmlFor='' className='did-floating-label'>
                        Form Sub Category
                      </label>
                      <SelectOption
                        isClearable={true}
                        cssClass='mt-1'
                        isSearch={false}
                        data={formSubCategories}
                        selectedOption={selectedformSubCategory}
                        handleChange={(e) => {
                          setSelectedFormSubCategory(e);
                          setFormData({
                            ...formData,
                            form_sub_category_id: e ? e.value : null,
                          });
                        }}
                      />
                    </div>
                  )}
                  <div className='col-span-6 flex flex-col did-floating-label-content'>
                    <label htmlFor='' className='did-floating-label'>
                      Description
                    </label>
                    <textarea
                      value={formData.description}
                      type='textarea'
                      className='resize-none overflow-y-auto focus:bg-[#4b6bb710] border px-4 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                      rows={3}
                      name='description'
                      onChange={onChangeHandler}
                    />
                  </div>
                </div>
                <div className='sm:px-6 lg:px-8'>
                  <Tabs>
                    {/* <TabList className="flex border-b space-x-4">
                      <Tab className="text-gray-500">Standard</Tab>
                    </TabList> */}
                    <TabPanel>
                      {/* General */}
                      {template.length > 0 &&
                        template.map((section, index) => {
                          return (
                            <div
                              key={index}
                              className='grid grid-cols-3 py-4 border-b'
                            >
                              <div className='col-span-3 lg:col-span-1 space-y-6'>
                                <div className='flex justify-between'>
                                  <Heading
                                    heading={section.section_title}
                                    desc=''
                                  />
                                  <DropDown
                                    type='section'
                                    openConfirmationModalHandler={
                                      openSectionTitleConfirmationModalHandler
                                    }
                                    openEditSectionModalHandler={
                                      openEditSectionTitleModal
                                    }
                                    item={section}
                                    sectionIndex={index}
                                    fieldIndex='null'
                                    subFieldIndex='null'
                                  />
                                </div>
                                <button
                                  onClick={() => openInputModalHandler(index)}
                                  className='flex items-center space-x-2 text-gray-500 text-sm'
                                >
                                  <AiOutlinePlus />
                                  <span>Add new field </span>
                                </button>
                                <button
                                  onClick={() =>
                                    openSubSectionModalHandler(index)
                                  }
                                  className='flex items-center space-x-2 text-gray-500 text-sm'
                                >
                                  <AiOutlinePlus />
                                  <span>Add new Sub Section </span>
                                </button>
                              </div>
                              <div className='col-span-3 lg:col-span-2 flex items-start flex-wrap'>
                                {section?.input_fields.length > 0 &&
                                  section?.input_fields.map((field, i) => {
                                    if (field.type === "sub_section") {
                                      return (
                                        <div className='flex bg-gray-50 mb-4 p-2 rounded-lg border w-full'>
                                          <div
                                            key={i}
                                            className='w-full flex flex-wrap justify-between items-start'
                                          >
                                            <div className='flex w-full'>
                                              <LabelCheckBox
                                                heading={field.section_title}
                                                desc={field.type}
                                              />
                                              <DropDown
                                                type='sub_section'
                                                openConfirmationModalHandler={
                                                  openSubSectionConfirmationModalHandler
                                                }
                                                openEditSectionModalHandler={
                                                  openEditSectionTitleModal
                                                }
                                                item={field}
                                                sectionIndex={index}
                                                fieldIndex={i}
                                                subFieldIndex={null}
                                              />
                                            </div>
                                            <button
                                              onClick={() =>
                                                openSubSectionInputModalHandler(
                                                  index,
                                                  i
                                                )
                                              }
                                              className='flex items-center space-x-2 text-gray-500 text-sm'
                                            >
                                              <AiOutlinePlus />
                                              <span>Add new field </span>
                                            </button>
                                            {field.sub_fields.map(
                                              (item, sf) => {
                                                return (
                                                  <div
                                                    key={sf}
                                                    className='w-full sm:w-1/2 pb-4 flex justify-between items-start'
                                                  >
                                                    <LabelCheckBox
                                                      heading={item.lable}
                                                      desc={item.type}
                                                    />
                                                    <div>
                                                      <DropDown
                                                        openConfirmationModalHandler={
                                                          openSubSectionConfirmationModalHandler
                                                        }
                                                        openEditSectionModalHandler={
                                                          openEditSubSectionModalHandler
                                                        }
                                                        item={item}
                                                        sectionIndex={index}
                                                        fieldIndex={i}
                                                        subFieldIndex={sf}
                                                      />
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                            {/* <div>
                                      <DropDown
                                        openConfirmationModalHandler={
                                          openConfirmationModalHandler
                                        }
                                        openEditSectionModalHandler={
                                          openEditSectionModalHandler
                                        }
                                        item={field}
                                        sectionIndex={index}
                                        fieldIndex={i}
                                      />
                                    </div> */}
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          key={i}
                                          className='w-full sm:w-1/2 pb-4 flex justify-between items-start'
                                        >
                                          <LabelCheckBox
                                            heading={field.lable}
                                            desc={field.type}
                                          />
                                          <div>
                                            <DropDown
                                              openConfirmationModalHandler={
                                                openConfirmationModalHandler
                                              }
                                              openEditSectionModalHandler={
                                                openEditSectionModalHandler
                                              }
                                              item={field}
                                              sectionIndex={index}
                                              fieldIndex={i}
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          );
                        })}
                    </TabPanel>
                  </Tabs>
                </div>

                <div className='flex justify-between items-center pt-3'>
                  <button
                    onClick={openSectionModalHandler}
                    className='flex items-center text-gray-500 text-sm'
                  >
                    <AiOutlinePlus />
                    <span>Add New Section </span>
                  </button>
                  <div className='flex items-center space-x-2'>
                    <CustomButton
                      type='button'
                      label='Clear & Exit'
                      variant='danger'
                      clickHandler={(e) => {
                        e.preventDefault();
                        if (
                          roleAccess([
                            USER_ROLE.Super_Admin,
                            USER_ROLE.Sp_Admin,
                            USER_ROLE.Sp_manager,
                          ])
                        ) {
                          role === "sil"
                            ? navigate(`/forms-accommodation`)
                            : role === "hrm"
                            ? navigate(`/forms-list-hrm`)
                            : navigate(`/forms-list`);
                          return;
                        }
                      }}
                    />
                    {decodedFormType && decodedFormType === "draft" ? null : (
                      <CustomButton
                        clickHandler={() => {
                          if (isUserGracePeriodOver) {
                            return;
                          }
                          setShowConfirmationModalPublish(true);
                        }}
                        isDisabled={
                          !formData.title ||
                          !formData.description ||
                          !formData.form_category_id ||
                          request.saveRequested ||
                          isUserGracePeriodOver
                        }
                        Icon={<MdPublish />}
                        showLoading={
                          request.saveRequested &&
                          request.submissionType === "publish"
                        }
                        label='Publish'
                      />
                    )}

                    <CustomButton
                      clickHandler={() => {
                        if (isUserGracePeriodOver) {
                          return;
                        }
                        submitFormTemplate("draft");
                      }}
                      isDisabled={
                        !formData.title ||
                        !formData.description ||
                        !formData.form_category_id ||
                        request.saveRequested ||
                        isUserGracePeriodOver
                      }
                      Icon={<RiDraftFill />}
                      showLoading={
                        request.saveRequested &&
                        request.submissionType === "draft"
                      }
                      label={`${
                        title === "Create New Form" ||
                        (decodedFormType && decodedFormType === "publish")
                          ? "Save as draft"
                          : "Update as draft"
                      }`}
                    />
                  </div>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>

      {showInputModal && (
        <InputCreationForm
          formCategoryId={selectedFormCategory ? selectedFormCategory.id : null}
          showModal={showInputModal}
          title='Add Section Input Field'
          dataSubmitHandler={addInputFieldHandler}
          closeModalHandler={closeInputModalHandler}
          template={template}
          sectionIndex={sectionIndex}
          subSectionIndex={subSectionIndex}
        />
      )}
      {showSubSectionModal && (
        <SectionCreationForm
          title='Add New Sub Section'
          showModal={showSubSectionModal}
          type='sub_section'
          dataSubmitHandler={addSubSectionHandler}
          closeModalHandler={closeSubSectionModalHandler}
        />
      )}
      {showSubSectionInputModal && (
        <InputCreationForm
          formCategoryId={selectedFormCategory ? selectedFormCategory.id : null}
          title='Add Sub Section Input Field'
          showModal={showSubSectionInputModal}
          dataSubmitHandler={addSubSectionInputFieldHandler}
          closeModalHandler={closeSubSectionInputModalHandler}
          sectionIndex={sectionIndex}
          subSectionIndex={subSectionIndex}
        />
      )}
      {showSectionModal && (
        <SectionCreationForm
          title='Add New Section'
          showModal={showSectionModal}
          type='section'
          dataSubmitHandler={addSectionHandler}
          closeModalHandler={closeSectionModalHandler}
        />
      )}
      {showEditSectionTitle && (
        <SectionCreationForm
          title='Edit Section Title'
          showModal={showEditSectionTitle}
          type={
            editData && editData.type === "sub_section"
              ? "sub_section"
              : "section"
          }
          editData={editData}
          dataSubmitHandler={editSectionTitleHandler}
          closeModalHandler={closeSectionTitleHandler}
        />
      )}
      {showEditSectionModal && (
        <InputCreationForm
          formCategoryId={selectedFormCategory ? selectedFormCategory.id : null}
          title='Edit Section Input Field'
          showModal={showEditSectionModal}
          template={template}
          editData={editData}
          dataSubmitHandler={updateFieldHandler}
          closeModalHandler={closeEditSectionModalHandler}
          sectionIndex={sectionIndex}
          subSectionIndex={subSectionIndex}
        />
      )}
      {showEditSubSectionModal && (
        <InputCreationForm
          formCategoryId={selectedFormCategory ? selectedFormCategory.id : null}
          title='Edit Sub Section Input Field'
          showModal={showEditSubSectionModal}
          editData={editData}
          dataSubmitHandler={updateSubSectionFieldHandler}
          closeModalHandler={closeEditSubSectionModalHandler}
          sectionIndex={sectionIndex}
          subSectionIndex={subSectionIndex}
        />
      )}

      <ConfirmationModal
        showModal={showConfirmationModal}
        closeModalHandler={closeConfirmationModalHandler}
        submitHandler={deleteFieldHandler}
      />
      <ConfirmationModal
        showModal={showSectionTitleConfirmationModal}
        closeModalHandler={closeSectionTitleConfirmationModalHandler}
        submitHandler={deleteSectionHandler}
      />
      <ConfirmationModal
        showModal={showSubSectionConfirmationModal}
        closeModalHandler={closeSubSectionConfirmationModalHandler}
        submitHandler={deleteSubSectionFieldHandler}
      />
      {showConfirmationModalPublish && (
        <SweetAlertConfirmationModal
          isOpen={showConfirmationModalPublish}
          title='Confirm your action?'
          content='Are you sure to publish this form?'
          setIsConfirm={(e) => {
            if (e) {
              setShowConfirmationModalPublish(false);
              submitFormTemplate("publish");
            } else {
              setShowConfirmationModalPublish(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default CreateFormTemplate;
