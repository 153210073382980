import React, { useState, useContext, useEffect } from "react";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import SideBar from "components/common/SideBar/SideBarNew";
import { useNavigate } from "react-router";
import { LoginContext } from "helpers/LoginContext";
import {
  ALL_FORM_LIST_LIMIT,
  emptyPaginationObj,
  SORT_TYPE,
  USER_ROLE,
} from "Constants/constant";
import { getPaginationObj } from "Utils/utils";
import { LOADER_STATUS } from "store/constants";
import { roleAccess } from "helpers/RolesPermission";
import SilFormsTable from "./components/SilFormTable";

const SilForms = (props) => {
  const {
    categoryLoader,
    fetchFormTemplate,
    fetchFormCategories,
    formCategoriesList,
    silFormTemplatesList,
    spFormTemplateLoader,
  } = props;

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const navigate = useNavigate();

  useEffect(() => {
    if (formCategoriesList?.length <= 0) {
      setRenderLoading(LOADER_STATUS.PENDING);
      fetchFormCategories();
    }
  }, []);

  const [paginationObj, setPaginationObj] = useState(
    silFormTemplatesList
      ? getPaginationObj(
          silFormTemplatesList.form_templates,
          silFormTemplatesList.query_params
        )
      : emptyPaginationObj
  );

  const [sortBy, setSortBy] = useState(null);

  useEffect(() => {
    if (silFormTemplatesList) {
      setPaginationObj(
        getPaginationObj(
          silFormTemplatesList.form_templates,
          silFormTemplatesList.query_params
        )
      );
    }
  }, [silFormTemplatesList]);

  useEffect(() => {
    if (silFormTemplatesList) {
      setPaginationObj(
        getPaginationObj(
          silFormTemplatesList.form_templates,
          silFormTemplatesList.query_params
        )
      );
    }
  }, []);

  const editFormTemplateHandler = (item) => {
    const url = "/sil/edit-template";
    navigate(`${url}/${item.slug}`, {
      state: { templateUUID: item.uuid },
    });
  };

  const fetchFormTemplateList = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: ALL_FORM_LIST_LIMIT });
    const bodyFilter = filters.hasOwnProperty("filter")
      ? { ...filters.filter, status: filters.status }
      : null;
    if (filters.hasOwnProperty("filter")) {
      delete filters.filter;
    }
    fetchFormTemplate?.({ filters, bodyFilter });
  };

  useEffect(() => {
    if (!categoryLoader && renderLoading === LOADER_STATUS.PENDING) {
      setRenderLoading(LOADER_STATUS.COMPLETE);
    }
  }, [categoryLoader]);

  const handleFetch = (obj, sortBy) => {
    if (sortBy) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (paginationObj.page || obj?.page) {
      Object.assign(obj, { page: obj.page ? obj.page : paginationObj.page });
    }
    if (paginationObj.filter) {
      !paginationObj.filter.hasOwnProperty("form_category")
        ? Object.assign(obj, {
            filter: paginationObj.filter,
          })
        : Object.assign(obj, {
            filter: {
              form_category: paginationObj.filter.form_category.map(
                (page) => page.id
              ),
            },
          });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.form_category !== "both" && obj.hasOwnProperty("form_category")) {
      Object.assign(obj, {
        filter: {
          form_category: obj.form_category.map((some) => some.id),
        },
      });
    }
    if (obj.hasOwnProperty("status")) {
      const filterData = { form_category: [] };
      if (obj?.filter) {
        Object.assign(filterData, {
          ...obj?.filter,
        });
      }
      Object.assign(obj, {
        page: obj.page ? obj.page : 1,
        status: obj.status,
        filter: {
          ...filterData,
        },
      });
    }

    fetchFormTemplateList({ ...obj });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paginationObj.page]);

  return (
    <>
      <div className='min-h-full'>
        {renderLoading === LOADER_STATUS.PENDING && (
          <div className='absolute top-1/2 -translate-y-1/2 z-50 bg-blue-dark bg-opacity-20  flex  w-full flex-1  flex-col justify-center py-12 px-4 sm:px-6 lg:flex lg:justify-center lg:px-20 xl:px-24 h-full'>
            <CircularLoader
              classes='flex justify-center items-center'
              classloader='loader-l'
            />
          </div>
        )}
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          } flex flex-col`}
        >
          {formCategoriesList && formCategoriesList.length > 0 && (
            <SilFormsTable
              editFormTemplateHandler={editFormTemplateHandler}
              templateList={
                silFormTemplatesList
                  ? silFormTemplatesList?.form_templates?.data
                  : []
              }
              isLoading={spFormTemplateLoader}
              queryParams={paginationObj}
              formCategoriesList={formCategoriesList}
              handleFetch={handleFetch}
              getSortBy={(e) => setSortBy(e)}
              paginationObj={paginationObj}
              setPaginationObj={setPaginationObj}
              isUserGracePeriodOver={isUserGracePeriodOver}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SilForms;
