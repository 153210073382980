import React from "react";
import AgencyAddress from "./AgencyAddress";

const RealEstateAgentInfo = ({ selectedAccommodationInfo }) => {
  return (
    <>
      <div className='py-5 bg-white'>
        <div className='px-12 pt-2 pb-4'>
          <label className='text-gray-700 text-lg font-bold'>
            Real Estate Information
          </label>
        </div>
        <div className='px-12 grid grid-cols-2 gap-y-6 gap-x-12'>
          <div className='col-span-1'>
            <label
              htmlFor='agent_full_name'
              className='capitalize block text-sm font-medium text-gray-800'
            >
              Full Name
            </label>
            <div className='mt-1'>
              <input
                disabled
                value={selectedAccommodationInfo?.agency_agent_name}
                maxLength={50}
                type='text'
                name='agent_full_name'
                id='agent_full_name'
                className='did-floating-input capitalize disabled:opacity-50'
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='agent_phone'
              className='capitalize block text-sm font-medium text-gray-700'
            >
              Phone Number
            </label>
            <div className='mt-1'>
              <input
                disabled
                value={selectedAccommodationInfo?.agency_agent_phone}
                maxLength={50}
                type='text'
                name='agent_phone'
                id='agent_phone'
                className='did-floating-input capitalize disabled:opacity-50'
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='agent_email'
              className='capitalize block text-sm font-medium text-gray-800'
            >
              Email Address
            </label>
            <div className='mt-1'>
              <input
                disabled
                value={selectedAccommodationInfo?.agency_agent_email}
                maxLength={50}
                type='text'
                name='agent_email'
                id='agent_email'
                className='did-floating-input capitalize disabled:opacity-50'
              />
            </div>
          </div>
          {/* <div>
          <label
            htmlFor='property_team_leader_name'
            className='capitalize block text-sm font-medium text-gray-700'
          >
            Property Team Leader Name
          </label>
          <div className='mt-1'>
            <input
              disabled
              value={selectedAccommodationInfo?.agency_team_leader_name}
              maxLength={50}
              type='text'
              name='property_team_leader_name'
              id='property_team_leader_name'
              className='did-floating-input capitalize disabled:opacity-50'
            />
          </div>
        </div> */}
          <div />
        </div>
      </div>
      <div className='py-5 grid grid-cols-2 bg-gray-100 px-12 gap-y-6 gap-x-12'>
        <AgencyAddress selectedAccommodationInfo={selectedAccommodationInfo} />
      </div>
    </>
  );
};

export default RealEstateAgentInfo;
